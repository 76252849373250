<div class="update-field-action pb-20 max-w-md">
  <h6 class="mb-4 text-lg">Update Relationships</h6>
  <form
    class="field-to-update flex flex-col space-y-4"
    [formGroup]="relationshipsFieldForm"
    (ngSubmit)="emitActionToSave()"
  >
    <!-- Select add or remove relationship -->
    <section class="add-or-remove-selector">
      <p class="mb-1 text-sm text-primary-300">
        Remove relationships or assign a new one:
      </p>
      <mat-form-field
        appearance="outline"
        class="w-full"
        subscriptSizing="dynamic"
      >
        <mat-label class="text-base">Update type</mat-label>
        <mat-select
          id="update-relationship-type"
          data-testid="update-relationship-type"
          formControlName="updateRelationship"
          (selectionChange)="setRelatedToInformation()"
        >
          <mat-option
            id="add-relationships"
            data-testid="add-relationships"
            [value]="actionTypesEnum.AddRelationship"
          >
            Add relationships
          </mat-option>
          <mat-option
            id="delete-relationship"
            data-testid="delete-relationship"
            [value]="actionTypesEnum.DeleteRelationship"
          >
            Remove relationship(s)
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <!-- Show all the available relationships -->
    <section class="available-relationships-section">
      <p class="mb-1 text-sm text-primary-300">
        Relationships available for
        {{ termsGeneric.Container.Plural | lowercase }} at this
        {{ termsGeneric.Station.Single | lowercase }}
      </p>

      @if (!librariesLoading) {
        <mat-form-field
          appearance="outline"
          class="w-full"
          subscriptSizing="dynamic"
        >
          <mat-label class="text-base">Relationship type</mat-label>

          <mat-select
            formControlName="relationshipType"
            id="relationship-type"
            data-testid="relationship-type"
            multiple
          >
            <app-ngx-search
              [stationLibraryRelationships]="libraryRelationships"
              (getFilteredArrays)="libraryDataFiltered = $event"
            />

            @for (
              library of libraryDataFiltered.stationLibraryRelationship;
              track library.rithmId
            ) {
              @for (relation of library.relationships; track $index) {
                <mat-optgroup [label]="relation.name">
                  @if (relation.inwardName) {
                    <mat-option
                      [value]="
                        relation.inwardName +
                        '[' +
                        relation.rithmId +
                        ']_inwardName'
                      "
                    >
                      {{ relation.inwardName }}
                      <span class="text-primary-200"> (inbound)</span>
                    </mat-option>
                  }
                  @if (relation.outwardName) {
                    <mat-option
                      [value]="
                        relation.outwardName +
                        '[' +
                        relation.rithmId +
                        ']_outwardName'
                      "
                    >
                      {{ relation.outwardName }}
                      <span class="text-primary-200">(outbound)</span>
                    </mat-option>
                  }
                </mat-optgroup>
              }
            }
          </mat-select>
        </mat-form-field>
      } @else {
        <ng-container *ngTemplateOutlet="loading" />
      }
      <ng-container *ngTemplateOutlet="relationshipsAvailable" />
    </section>

    @if (
      relationshipsFieldForm.value.updateRelationship ===
      actionTypesEnum.AddRelationship
    ) {
      <div class="related-to-section">
        <p class="mb-1 text-sm text-primary-300">Related to:</p>
        <mat-form-field
          appearance="outline"
          class="w-full"
          subscriptSizing="dynamic"
        >
          <mat-label class="text-base">Related Container</mat-label>
          <mat-select
            formControlName="relatedTo"
            id="related-to"
            data-testid="related-to"
          >
            <mat-option
              id="parent-relationship"
              data-testid="parent-relationship"
              [value]="parentId"
            >
              Parent {{ termsGeneric.Container.Single.toLowerCase() }} ID
            </mat-option>
            <!-- TODO: NEED TO ADD FUNCTIONALITY WHEN SELECTING CHILDREN RELATIONSHIPS -->
            @if (showParentChildRelationship) {
              <mat-option
                id="children-relationship"
                data-testid="children-relationship"
                [value]="'children'"
              >
                Children
                {{ termsGeneric.Container.Single.toLowerCase() }} (all)
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
  </form>
</div>
<ng-container *ngTemplateOutlet="controlButtons" />

<ng-template #relationshipsAvailable>
  <section class="w-full h-15 custom-chips overflow-x-auto scrollbar">
    <mat-chip-listbox>
      @for (
        relation of relationshipsFieldForm.value.relationshipType;
        track $index
      ) {
        <mat-chip>
          <span class="text-sm">
            {{ cleanRelationLabel(relation) | titlecase }}</span
          >
          <button
            matChipRemove
            type="button"
            [id]="'remove-' + relation"
            [attr.data-testid]="'remove-' + relation"
            (click)="removeRelation($index)"
          >
            <i class="fa-light fa-circle-xmark"></i>
          </button>
        </mat-chip>
      }
    </mat-chip-listbox>
  </section>
</ng-template>

<ng-template #controlButtons>
  <section
    class="container-action-control-buttons flex justify-between mb-20 -mt-16"
  >
    <div class="flex space-x-4">
      <button
        mat-button
        data-testid="update-relationship-action-cancel"
        [disabled]="(savingAction || deletingAction) && orderOfOperations"
        id="update-relationship-action-cancel"
        type="button"
        (click)="onCancelAction()"
      >
        <span class="font-semibold">CANCEL</span>
      </button>
      <button
        mat-raised-button
        [ngClass]="{
          '!bg-sky-500 !text-white !font-semibold': orderOfOperations,
          '!opacity-60': !relationshipsFieldForm.valid
        }"
        type="submit"
        color="accent"
        id="update-relationship-action-save"
        data-testid="update-relationship-action-save"
        [disabled]="
          savingAction || deletingAction || !relationshipsFieldForm.valid
        "
        (click)="emitActionToSave()"
      >
        @if (orderOfOperations) {
          @if (savingAction) {
            <ng-container
              [ngTemplateOutlet]="loadingInlineText"
              [ngTemplateOutletContext]="{ $implicit: 'Saving...' }"
            />
          } @else {
            SAVE
          }
        } @else {
          DONE
        }
      </button>
    </div>
    @if (orderOfOperations && editingAction) {
      <div class="flex-grow text-right">
        <button
          mat-stroked-button
          [disabled]="(savingAction || deletingAction) && orderOfOperations"
          class="!bg-red-500 !text-white"
          type="button"
          id="delete-action"
          data-testid="delete-action"
          (click)="removeParentAction()"
        >
          @if (deletingAction) {
            <ng-container
              [ngTemplateOutlet]="loadingInlineText"
              [ngTemplateOutletContext]="{ $implicit: 'Deleting...' }"
            />
          } @else {
            <i class="fa-light fa-trash text-lg"></i> DELETE ACTION
          }
        </button>
      </div>
    }
  </section>
</ng-template>

@if (orderOfOperations && editingAction) {
  <div class="text-primary-400 w-full -mt-16">
    <mat-divider />
  </div>
}

<ng-template #loading>
  <app-loading-indicator
    [inlineText]="'Loading Relationship types'"
    id="loader-libraries"
  />
</ng-template>

<!-- In line text loading indicator -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator id="loading-inline-text" [inlineText]="message" />
</ng-template>
