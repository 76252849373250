<ng-container [formGroup]="actionForm">
  <section class="relative pb-3">
    <h6 class="mb-4 text-lg">
      Create {{ termsGeneric.Container.Single | lowercase }}
    </h6>
    <div>
      @if (!showRelationshipActions) {
        <section class="bg-white absolute z-10">
          <div
            class="absolute left-0 top-0 w-full h-full z-[2]"
            data-testid="open-multiselect-search"
            (click)="multiSelect.open()"
          ></div>

          <mat-form-field
            class="overlap-form-field"
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <mat-label class="text-base"
              >Available {{ termsGeneric.Station.Plural }}</mat-label
            >
            <input matInput formControlName="formOverlay" />
            <button matPrefix mat-icon-button type="button">
              <i class="fa-solid fa-search"></i>
            </button>
          </mat-form-field>
        </section>

        <section class="multiselect-section opacity-0">
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <mat-label class="text-base"
              >Available {{ termsGeneric.Station.Plural }}</mat-label
            >
            <mat-select
              formControlName="formStations"
              appMatSelectInfinityScroll
              placeholder="Stations"
              #multiSelect
              [attr.data-testid]="'add-station-button'"
              [classStyleNameScroll]="'scroll-when-opened-select'"
              (onScrollDown)="getMoreElements()"
            >
              <mat-option>
                <ngx-mat-select-search
                  placeholderLabel="Search..."
                  formControlName="formStationsFilter"
                  noEntriesFoundLabel="Not match found..."
                  [attr.data-testid]="'add-station-search'"
                  [attr.id]="'add-station-search'"
                >
                  <span
                    aria-hidden="true"
                    class="close"
                    ngxMatSelectSearchClear
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </span>
                </ngx-mat-select-search>
              </mat-option>
              @if (!loadingStations) {
                @for (
                  station of filteredStationsAutocomplete;
                  track station.rithmId
                ) {
                  <mat-option
                    [value]="station.rithmId"
                    [attr.data-testid]="'remove-station-' + station.rithmId"
                    [attr.id]="'remove-station-' + station.rithmId"
                    (click)="removeStationBackup(station.rithmId)"
                  >
                    {{ station.name }}
                  </mat-option>
                }
              }
              @if (loadingStations || currentPageElement.loading) {
                <mat-option class="pointer-events-none check-center-loading">
                  <app-loading-indicator
                    id="loading-elements"
                    data-testid="loading-elements"
                    [inlineText]="'Loading..'"
                  />
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </section>
      } @else {
        <ng-container [ngTemplateOutlet]="stationsSelect" />
      }

      <ng-template #stationsSelect>
        <mat-form-field
          class="w-full max-w-md"
          subscriptSizing="dynamic"
          appearance="outline"
          [ngClass]="{
            'animate-pulse': loadingStations
          }"
        >
          <button matPrefix mat-icon-button type="button">
            <i class="fa-light fa-search text-primary-300 text-xl"></i>
          </button>
          <mat-label class="text-base"
            >Available {{ termsGeneric.Station.Plural }}</mat-label
          >
          <mat-select
            formControlName="stationsSelection"
            appMatSelectInfinityScroll
            multiple
            (selectionChange)="createContainerAction($event.value)"
            [classStyleNameScroll]="'scroll-when-opened-select'"
            (onScrollDown)="getMoreElements()"
            [ngClass]="{
              'animate-pulse': loadingStations
            }"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search..."
                formControlName="formStationsFilter"
                noEntriesFoundLabel="Not match found..."
                [attr.data-testid]="'add-station-search'"
                [attr.id]="'add-station-search'"
              >
                <span aria-hidden="true" class="close" ngxMatSelectSearchClear>
                  <i class="fa-solid fa-xmark"></i>
                </span>
              </ngx-mat-select-search>
            </mat-option>
            @if (!loadingStations) {
              @for (
                station of filteredStationsAutocomplete;
                track station.rithmId
              ) {
                <mat-option
                  [value]="station"
                  (click)="removeStationBackup(station.rithmId)"
                >
                  <p class="text-md font-semibold line-clamp-[1]">
                    {{ station.name }}
                  </p>
                  <p
                    class="text-sm text-secondary-500 leading-tight line-clamp-[1]"
                  >
                    ({{
                      station.groupName ||
                        'Without ' + termsGeneric.Group.Single
                    }})
                  </p>
                </mat-option>
              }
            }
            @if (loadingStations || currentPageElement.loading) {
              <mat-option class="pointer-events-none check-center-loading">
                <app-loading-indicator
                  id="loading-elements"
                  data-testid="loading-elements"
                  [inlineText]="'Loading..'"
                />
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (selectedStationsToShowInChips.length) {
          <section class="custom-chips overflow-x-auto scrollbar">
            <mat-chip-listbox>
              @for (
                selectedStation of selectedStationsToShowInChips;
                track $index
              ) {
                <ng-container>
                  <mat-chip>
                    <span>{{ selectedStation.name }}</span>
                    <button
                      (click)="removeCurrentStation(selectedStation)"
                      matChipRemove
                      type="button"
                      [attr.data-testid]="
                        'remove-station-' + selectedStation.rithmId
                      "
                      [attr.id]="'remove-station-' + selectedStation.rithmId"
                    >
                      <i class="fa fa-times-circle-o"></i>
                    </button>
                  </mat-chip>
                </ng-container>
              }
            </mat-chip-listbox>
          </section>
        }
      </ng-template>
    </div>

    @if (selectedStations.length) {
      <section class="selected-stations-chips flex py-2">
        <mat-chip-listbox>
          @for (station of selectedStations; track $index) {
            <mat-chip-option>
              {{ station.name }}
              <button
                class="pl-2"
                type="button"
                (click)="removeStation(station.rithmId)"
              >
                <i
                  class="fa-light fa-circle-xmark text-lg text-primary-200"
                ></i>
              </button>
            </mat-chip-option>
          }
        </mat-chip-listbox>
      </section>
    }
  </section>

  @if (
    (actionForm.controls.formStations.value.length &&
      showRelationshipActions) ||
    showSharedValueSelection
  ) {
    <section class="py-3">
      @if (selectedStationsToShowInChips.length) {
        <section>
          @if (!sharedValuesLoading) {
            <mat-form-field
              class="w-full max-w-md"
              appearance="outline"
              subscriptSizing="dynamic"
            >
              <mat-label class="text-base">Shared Values</mat-label>
              <mat-select formControlName="formSharedValues" multiple>
                <app-ngx-search
                  [stationBucketQuestion]="currentStationFields"
                  (getFilteredArrays)="
                    filteredStationFields = $event.stationBucketQuestion || []
                  "
                />
                @for (field of filteredStationFields; track field.rithmId) {
                  <mat-option [value]="field.rithmId">{{
                    field.prompt
                  }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <ng-container [ngTemplateOutlet]="loadingSharedValues" />
          }
        </section>
      }

      @if (selectedCurrentStationFields.length) {
        <section class="flex py-2 custom-chips overflow-x-auto scrollbar">
          <mat-chip-listbox>
            @for (stationField of selectedCurrentStationFields; track $index) {
              <mat-chip>
                <span class="text-sm">
                  {{ stationField.prompt }}
                </span>

                <button
                  matChipRemove
                  type="button"
                  (click)="removeCurrentStationFields(stationField.rithmId)"
                >
                  <i class="fa-light fa-circle-xmark"></i>
                </button>
              </mat-chip>
            }
          </mat-chip-listbox>
        </section>
      }
    </section>
  }

  <!-- Assigned relationship select.  -->
  @if (selectedStationsToShowInChips.length) {
    <section class="py-3">
      @if (showRelationshipActions) {
        <ng-container *ngTemplateOutlet="relationshipsAvailable" />
      }
    </section>
  }

  <section
    class="container-action-control-buttons flex justify-between items-center py-2"
  >
    <div class="flex space-x-4">
      <button
        mat-button
        type="button"
        [disabled]="(savingAction || deletingAction) && orderOfOperations"
        (click)="cancelAction()"
        data-testid="action-cancel"
        id="action-cancel"
      >
        <span class="font-semibold">CANCEL</span>
      </button>
      <button
        mat-raised-button
        [ngClass]="{
          '!bg-sky-500 !text-white !font-semibold': orderOfOperations,
          '!opacity-60':
            !actionForm.valid ||
            (showRelationshipActions && !selectedStationsToShowInChips.length)
        }"
        type="button"
        color="accent"
        (click)="addOrEditAction()"
        [disabled]="
          !actionForm.valid ||
          savingAction ||
          deletingAction ||
          (showRelationshipActions && !selectedStationsToShowInChips.length)
        "
        id="action-save"
        data-testid="action-save"
      >
        @if (orderOfOperations) {
          @if (savingAction) {
            <ng-container
              [ngTemplateOutlet]="loadingInlineText"
              [ngTemplateOutletContext]="{ $implicit: 'Saving...' }"
            />
          } @else {
            SAVE
          }
        } @else {
          DONE
        }
      </button>
    </div>
    @if (orderOfOperations && editingAction) {
      <div class="flex-grow text-right">
        <button
          mat-stroked-button
          class="!bg-red-500 !text-white"
          type="button"
          id="delete-action"
          data-testid="delete-action"
          [disabled]="(savingAction || deletingAction) && orderOfOperations"
          (click)="removeParentAction()"
        >
          <i class="fa-light fa-trash text-lg"></i>
          DELETE ACTION
        </button>
      </div>
    }
  </section>
</ng-container>

<!-- Loading Shared Values-->
<ng-template #loadingSharedValues>
  <app-loading-indicator
    [diameter]="25"
    inlineText="Loading Shared Values.."
    id="shared-values-loading"
  />
</ng-template>

<ng-template #relationshipsAvailable [formGroup]="actionForm">
  @if (actionTypeValue !== actionTypesEnum.CreateContainer) {
    <p class="mb-4 text-sm text-primary-300">
      Relationships available for
      {{ termsGeneric.Container.Plural | lowercase }} at this
      {{ termsGeneric.Station.Single | lowercase }}
    </p>
  }
  @if (!stationLibrariesLoading) {
    <mat-form-field
      appearance="outline"
      class="w-full max-w-md"
      subscriptSizing="dynamic"
    >
      <mat-label class="text-base">{{
        actionTypeValue === actionTypesEnum.CreateContainer
          ? 'Assigned Relationship(s)'
          : 'Relationship type'
      }}</mat-label>
      <mat-select
        formControlName="assignedRelationship"
        id="relationship-type"
        data-testid="relationship-type"
        [(value)]="relationsSelected"
        multiple
        (selectionChange)="setChips($event)"
      >
        <app-ngx-search
          [stationLibraryRelationships]="stationLibraryRelationships"
          (getFilteredArrays)="
            filteredStationLibraryRelationships =
              $event.stationLibraryRelationship || []
          "
        />
        @for (
          library of filteredStationLibraryRelationships;
          track library.rithmId
        ) {
          @for (relation of library.relationships; track relation.rithmId) {
            <mat-optgroup [label]="relation.name">
              @if (relation.inwardName) {
                <mat-option
                  [value]="
                    relation.inwardName +
                    '[' +
                    relation.rithmId +
                    ']_inwardName'
                  "
                >
                  {{ relation.inwardName }}
                  <span class="text-primary-200"> (inbound)</span>
                </mat-option>
              }
              @if (relation.outwardName) {
                <mat-option
                  [value]="
                    relation.outwardName +
                    '[' +
                    relation.rithmId +
                    ']_outwardName'
                  "
                >
                  {{ relation.outwardName }}
                  <span class="text-primary-200">(outbound)</span>
                </mat-option>
              }
            </mat-optgroup>
          }
        }
      </mat-select>
    </mat-form-field>
  }
  @if (stationLibrariesLoading) {
    <app-loading-indicator
      [inlineText]="'Loading Relationship types'"
      id="loader-libraries"
    />
  }
  @if (relationsSelected) {
    <section class="w-full h-15 custom-chips overflow-x-auto scrollbar">
      <mat-chip-listbox>
        @for (relation of relationsSelected; track $index) {
          <mat-chip>
            <span class="text-sm">
              {{ cleanRelationLabel(relation) | titlecase }}</span
            >
            <button
              matChipRemove
              type="button"
              [id]="'remove-' + relation"
              [attr.data-testid]="'remove-' + relation"
              (click)="removeIndexRelation($index)"
            >
              <i class="fa-light fa-circle-xmark"></i>
            </button>
          </mat-chip>
        }
      </mat-chip-listbox>
    </section>
  }
</ng-template>

<!-- In line text loading indicator -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator id="loading-inline-text" [inlineText]="message" />
</ng-template>
