@defer (on immediate) {
  <h6 class="mt-4 text-gray-800 text-lg font-semibold mb-6">Calculate Field</h6>
  <section class="w-full flex flex-row justify-between">
    <app-calculation-action-form
      [bucketQuestions]="bucketQuestions()"
      [calculationItemsToGenerate]="calculationItemsToGenerate()"
      [editingAction]="editingAction()"
      [calculationActionData]="action()"
      (statusOfCalculationActionForm)="setStatusSaveButton($event)"
      (calculationActionFormValue)="calculationData = $event"
      (addCalculationItem)="addCalculationItem($event)"
    />
    <app-calculation-hint />
  </section>
  <!-- Button +Values-->
  <button
    mat-button
    type="button"
    class="text-gray-800 !font-semibold w-[106px] mt-5"
    (click)="addCalculationItem()"
  >
    <i class="fa-light fa-plus text-lg mr-2"></i>
    <span>VALUE</span>
  </button>
  <section class="flex space-x-2 py-8">
    <button
      mat-button
      type="button"
      class="!font-semibold w-[84px] text-gray-800"
      (click)="cancelCalculationAction()"
    >
      CANCEL
    </button>
    <div class="button-custom-design">
      <button
        type="button"
        mat-button
        class="!font-semibold w-[65px]"
        [disabled]="!enabledSaveButton"
        (click)="saveCalculationAction()"
      >
        SAVE
      </button>
    </div>
  </section>
} @placeholder (minimum 1000ms) {
  <div class="mt-4 h-14 flex justify-start">
    <app-loading-indicator [diameter]="38" color="text-sky-500" />
  </div>
}
