<div
  class="notification-item flex flex-col"
  [ngClass]="
    orderOfOperations ? 'order-operations-notifications' : 'gap-y-4 p-4'
  "
>
  <section class="notification-item-title">
    @if (orderOfOperations) {
      <p class="text-base font-medium action-title">Notification Internal</p>
    } @else {
      <h6 class="text-xl tracking-wide">Internal Notification</h6>
    }
  </section>
  <section class="notification-item-type notification-item-content">
    <p
      class="text-primary-500 text-xs"
      [ngClass]="{ 'sub-section-title': orderOfOperations }"
    >
      Type:
    </p>
    <mat-chip-set>
      @for (type of getNotificationTypes(); track $index) {
        <mat-chip
          class="m-0"
          [attr.data-testid]="'chip-' + type"
          [attr.id]="'chip-' + type"
        >
          <span [ngClass]="{ 'label-chip': orderOfOperations }">{{
            type
          }}</span>
        </mat-chip>
      }
    </mat-chip-set>
  </section>

  <section
    class="notification-item-recipients notification-message"
    [ngClass]="{ 'space-y-4': !orderOfOperations }"
  >
    <p
      class="text-primary-500 text-xs"
      [ngClass]="{ 'sub-section-title': orderOfOperations }"
    >
      To:
    </p>
    <div class="flex gap-2 flex-wrap">
      @for (member of memberRecipients; track $index) {
        <mat-chip
          [attr.data-testid]="'to-member-' + member.rithmId"
          [id]="'to-member-' + member.rithmId"
          class="mr-2"
        >
          <div class="flex items-center">
            <ng-container
              *ngTemplateOutlet="
                recipientOption;
                context: { $implicit: member }
              "
            ></ng-container>
          </div>
        </mat-chip>
      }
    </div>
    <p class="bg-gray-200 rounded p-2 w-full">
      {{ getNotificationMessage() }}
    </p>
  </section>
</div>

<!-- Recipient Option.-->
<ng-template #recipientOption let-member>
  @if (showIcon(member)) {
    <i
      [ngClass]="componentHelper.originIcon(member.type)"
      class="text-secondary-300"
    ></i>
  } @else {
    <ng-container
      [ngTemplateOutletContext]="{
        data: member
      }"
      [ngTemplateOutlet]="avatarImage"
    ></ng-container>
  }
  <p>{{ member.name }}</p>
</ng-template>

<!-- Avatar profile image field-->
<ng-template #avatarImage let-data="data">
  <app-user-avatar
    [firstName]="data.firstName || ''"
    [lastName]="data.lastName || ''"
    [size]="'20'"
    [profileImageRithmId]="data.profileImageRithmId || ''"
    class="relative right-1"
  ></app-user-avatar>
</ng-template>
