<section class="px-4 w-full">
  @if (
    (!orderOfOperations() || !modeListOfActions()) &&
    !addingAction &&
    !showManagementMembersForm()
  ) {
    <div class="action-chips">
      @for (
        action of getUsersActions;
        track action.rithmId;
        let index = $index
      ) {
        <app-management-members-action-item
          [orderOfOperations]="orderOfOperations()"
          [action]="action"
          [actions]="actions"
          [stationRithmId]="stationRithmId()"
          [bucketQuestion]="bucketQuestions()"
          [ruleRithmId]="ruleRithmId()"
          [totalActions]="totalActions()"
          (editActionManagementMember)="editActionEvent(action)"
          (hasBeenDeleted)="actionsUpdEmitter.emit()"
        />
      }
    </div>
  }
  @if (showManagementMembersForm()) {
    <app-management-members-action-form
      [orderOfOperations]="orderOfOperations()"
      [(action)]="actions[editActionIndex]"
      [orderAction]="editActionIndex > -1 ? editActionIndex : actions.length"
      [stationRithmId]="stationRithmId()"
      [ruleRithmId]="ruleRithmId()"
      [bucketQuestions]="bucketQuestions()"
      (closeEvent)="closeForm()"
      (hasBeenUpdated)="actionsUpdEmitter.emit()"
      (actionManagementMember)="addAction($event)"
    />
  }

  @if (addingAction && !showManagementMembersForm()) {
    <mat-form-field
      appearance="outline"
      class="w-full max-w-md"
      subscriptSizing="dynamic"
    >
      <mat-label class="text-base">Action Type</mat-label>
      <mat-select [(ngModel)]="actionTypeValue">
        <mat-option [value]="'management-members'">Manage members</mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngTemplateOutlet="controlButtons" />
  }

  @if (!addingAction && modeListOfActions() && !showManagementMembersForm()) {
    <button
      mat-stroked-button
      type="button"
      color="accent"
      id="new-action"
      data-testid="new-action"
      class="!leading-tight !tracking-wide"
      [ngClass]="{ '!bg-sky-500 !text-white': orderOfOperations() }"
      (click)="addingAction = true"
    >
      New Action
    </button>
  }
</section>

<ng-template #controlButtons>
  <section class="container-action-control-buttons space-x-4 pt-3">
    <button
      mat-button
      type="button"
      data-testid="action-cancel"
      id="action-cancel"
      (click)="addingAction = false; showManagementMembersForm.set(false)"
    >
      <span class="font-semibold">CANCEL</span>
    </button>
    <button
      mat-raised-button
      [ngClass]="{
        '!bg-sky-500 !text-white !font-semibold': orderOfOperations(),
        '!opacity-60': !actionTypeValue.length
      }"
      type="button"
      color="accent"
      id="action-save"
      data-testid="action-save"
      [disabled]="!actionTypeValue.length"
      (click)="showManagementMembersForm.set(true)"
    >
      @if (orderOfOperations()) {
        SAVE
      } @else {
        DONE
      }
    </button>
  </section>
</ng-template>
