import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, shareReplay } from 'rxjs';
import { UserSearchReturnViewModel } from 'src/models/user-search-data';
import { MemberPermissionData } from 'src/models';

const MICROSERVICE_PATH_ORGANIZATION = '/userservice/api/organization';

/**
 * Service for all behavior involving push and in-app notifications.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  /**
   * Get organization members.
   * @param pageSize The number of users to retrieve per page.
   * @param pageNum The desired page number of results.
   * @param search Search value.
   * @param type Search associate origin type.
   * @param rithmId Search associate rithmId.
   * @returns Member array.
   */
  getOrganizationMembers(
    pageSize: number,
    pageNum: number,
    search = '',
    type?: string,
    rithmId?: string,
  ): Observable<UserSearchReturnViewModel> {
    const params = new HttpParams()
      .set('pageSize', pageSize)
      .set('pageNum', pageNum)
      .set('search', search)
      .set('type', type || '')
      .set('rithmId', rithmId || '');

    return this.http
      .get<UserSearchReturnViewModel>(
        `${environment.baseApiUrl}${MICROSERVICE_PATH_ORGANIZATION}/search-members`,
        { params },
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Get user association info.
   * @param searchText Search Text.
   * @returns List info of the user association.
   */
  getUserAssociationSearch(
    searchText: string,
  ): Observable<MemberPermissionData[]> {
    const params = new HttpParams().set('query', searchText);
    return this.http
      .get<MemberPermissionData[]>(
        `${environment.baseApiUrl}${MICROSERVICE_PATH_ORGANIZATION}/filter`,
        {
          params,
        },
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }
}
