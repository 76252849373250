<div class="modal-container bucket-modal-content flex flex-col h-full p-4">
  <!-- Modal header -->
  <section class="header-bucket-section flex flex-col">
    <div class="flex flex-row justify-between items-center h-full">
      <div class="items-start">
        <p class="text-xl font-semibold tracking-wide">
          {{ termsGeneric.Station.Single }} Bucket
        </p>
        <span class="text-sm text-primary-300 tracking-wider"
          >{{ termsGeneric.Station.Single }}: {{ stationName }}</span
        >
      </div>
      <button
        mat-icon-button
        type="button"
        id="close-modal-bucket"
        data-testid="close-modal-bucket"
        (click)="closeModal()"
      >
        <i class="fas fa-times text-sm text-secondary-500"></i>
      </button>
    </div>

    <!-- search bar-->
    <div class="flex justify-between items-center pt-3 h-full">
      <div class="search-bar">
        <mat-form-field class="text-sm" appearance="outline">
          <input
            #inputText
            type="text"
            matInput
            (keyup)="filterBucket($event)"
            placeholder="Search fields"
            id="search-bucket-fields"
            data-testid="search-bucket-fields"
            [disabled]="modalLoading || !dataSource.data.length"
          />
          <span matPrefix>
            <i class="fa-thin fa-search text-secondary-300"></i>
          </span>
        </mat-form-field>
      </div>
      @if (selection.selected.length) {
        <button
          mat-icon-button
          type="button"
          class="hover:text-error-500"
          id="remove-selected-bucket"
          data-testid="remove-selected-bucket"
          (click)="removeStationBucketQuestions()"
        >
          <i class="fa-light fa-trash text-lg text-secondary-500"></i>
        </button>
      }
    </div>
  </section>
  <section
    mat-dialog-content
    class="content-bucket-section overflow-y-auto scrollbar"
  >
    @if (modalLoading || bucketLoading) {
      <div class="flex w-full h-full items-center justify-center">
        <app-loading-indicator />
      </div>
    }

    @if (!modalLoading && !bucketLoading) {
      <section class="table-section w-full">
        @if (dataSource.data.length) {
          <table
            mat-table
            [dataSource]="dataSource"
            class="w-full bucket-question-table"
            [trackBy]="trackBy"
          >
            @if (isGroupAdmin) {
              <!-- Checkbox Column -->
              <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                  />
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)"
                    [disabled]="disableBucketQuestion(row)"
                  />
                </td>
              </ng-container>
            }

            <!-- Field Column -->
            <ng-container matColumnDef="Field">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="w-1/3 whitespace-nowrap"
              >
                FIELD
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="flex flex-row items-center space-x-2">
                  <i
                    class="flex justify-center items-center text-accent-500 fa-lg h-10 min-w-[40px]"
                    [ngClass]="
                      componentHelper.componentIcon(element.questionType)
                    "
                  >
                  </i>
                  <div>
                    <p class="text-sm text-primary-500 tracking-wide cell-text">
                      {{ element.prompt }}
                    </p>
                    <p
                      class="text-xs text-primary-300 tracking-wider cell-text"
                    >
                      {{
                        componentHelper.componentTypeAsTitle(
                          element.questionType
                        )
                      }}
                    </p>
                  </div>
                </div>
              </td>
            </ng-container>

            <!-- OnGrid Column -->
            <ng-container matColumnDef="OnGrid">
              <th mat-header-cell *matHeaderCellDef class="text-center">
                ON GRID
              </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                {{ element.onTemplate ? 'Yes' : 'No' }}
              </td>
            </ng-container>

            <!-- Origin Column -->
            <ng-container matColumnDef="Origin">
              <th mat-header-cell *matHeaderCellDef>ORIGIN</th>
              <td mat-cell *matCellDef="let element">
                <div class="text-left flex items-center">
                  <i
                    class="mr-2 text-secondary-300"
                    [ngClass]="{
                      'fa-thin fa-globe':
                        element.originType === 'Organization Library',
                      'fa-solid fa-grid-2':
                        element.originType === 'Group Library',
                      'fak fa-station': element.originType === 'Station group'
                    }"
                  ></i>
                  <p class="text-sm">{{ element.origin }}</p>
                </div>
              </td>
            </ng-container>

            <!-- UpdatedAt Column -->
            <ng-container matColumnDef="UpdatedAt">
              <th mat-header-cell *matHeaderCellDef>UPDATED AT</th>
              <td mat-cell *matCellDef="let element">
                <popper-content #popperUpdatedAt>
                  @if (questionStationsLoading) {
                    <div
                      class="questions-loading-indicator flex items-center justify-center pt-2"
                    >
                      <app-loading-indicator [diameter]="24" />
                    </div>
                  } @else {
                    @for (station of questionStations; track $index) {
                      <div class="flex flex-col items-start py-2">
                        <p class="font-semibold text-sm text-left">
                          {{ station.stationName }}
                        </p>
                        <p class="text-xs pt-1 text-primary-200">
                          {{ station.stationGroupName }}
                        </p>
                      </div>
                    }
                  }

                  <div class="flex justify-end items-end">
                    <button
                      mat-button
                      type="button"
                      color="accent"
                      id="close-station-bucket-popper"
                      data-testid="close-station-bucket-popper"
                      (click)="popperUpdatedAt.hide()"
                      class="text-accent-500 text-sm font-bold"
                    >
                      CLOSE
                    </button>
                  </div>
                </popper-content>

                @if (element.updatedAt === 0) {
                  <i class="fa-solid fa-dash mr-1 text-secondary-300"></i>
                }

                @if (element.updatedAt > 0) {
                  <button
                    type="button"
                    [popper]="popperUpdatedAt"
                    [popperShowOnStart]="false"
                    [popperTrigger]="popperTrigger.click"
                    [popperHideOnClickOutside]="true"
                    [popperHideOnScroll]="true"
                    [popperPlacement]="popperPlacement.BOTTOM"
                    [popperPositionFixed]="true"
                    [popperStyles]="{
                      border: 0,
                      maxWidth: '250px',
                      zIndex: 1000,
                      backgroundColor: 'white',
                      boxShadow: '3px 3px 10px rgb(0 0 0 / 60%)'
                    }"
                    (popperOnShown)="
                      getLibraryQuestionAtStations(element.rithmId)
                    "
                    [id]="'popover-bucket' + element.rithmId"
                    [attr.data-testid]="'popover-bucket' + element.rithmId"
                    class="updated-at-button"
                  >
                    <p class="text-sm">
                      {{
                        element.updatedAt +
                          ' ' +
                          termsGeneric.Station.Single +
                          '(s)'
                      }}
                    </p>
                  </button>
                }
              </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="Actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <popper-content #popperDeleteFields>
                  <button
                    mat-button
                    type="button"
                    (click)="removeStationBucketQuestions(element.rithmId)"
                    data-testid="btn-delete-fields"
                    id="btn-delete-fields"
                  >
                    <div
                      class="flex justify-between items-center space-x-2 text-error-500 text-base"
                    >
                      <span>Remove item</span>
                      <i class="fa-light fa-trash"></i>
                    </div>
                  </button>
                </popper-content>
                <button
                  type="button"
                  [popperDisabled]="disableBucketQuestion(element)"
                  [popper]="popperDeleteFields"
                  [popperShowOnStart]="false"
                  [popperTrigger]="popperTrigger.click"
                  [popperHideOnClickOutside]="true"
                  [popperHideOnScroll]="true"
                  [popperPlacement]="popperPlacement.LEFT"
                  [popperPositionFixed]="true"
                  [popperStyles]="{
                    border: 0,
                    maxWidth: '250px',
                    zIndex: 2000,
                    backgroundColor: 'white',
                    boxShadow: '3px 3px 10px rgb(0 0 0 / 60%)'
                  }"
                  [id]="'popover-delete-' + element.rithmId"
                  [attr.data-testid]="'popover-delete-' + element.rithmId"
                  class="detail-badge-button"
                >
                  <i class="fas fa-ellipsis"></i>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{ 'disabled-row': disableBucketQuestion(row) }"
            ></tr>
          </table>
        } @else {
          <div class="flex h-full w-full items-center justify-center">
            <span class="text-center p-2 text-base text-secondary-300">
              No questions found for this bucket.
            </span>
          </div>
        }
      </section>
    }
  </section>
  <!-- Modal content area -->
  <div mat-dialog-actions>
    <button
      mat-button
      type="button"
      class="text-sm tracking-[.016em] text-primary-400 font-semibold uppercase"
      mat-dialog-close
      data-testid="close-modal-btn"
      id="close-modal-btn"
      (click)="closeModal()"
    >
      Close
    </button>
  </div>
</div>
