<div
  class="flow-container-form-action action-tabs-section w-full px-4 pb-4 bg-white"
>
  <section class="tab-flow-content pt-6">
    <div class="flex items-center">
      <mat-form-field
        class="md:w-2/5 w-full"
        appearance="outline"
        [formGroup]="flowFieldForm"
        subscriptSizing="dynamic"
      >
        <mat-label class="text-base">
          Available {{ termsGeneric.Station.Plural }}
        </mat-label>
        <mat-select
          formControlName="stations"
          multiple
          (selectionChange)="flowStationSelect()"
        >
          @for (option of nextStations; track $index) {
            <mat-option [value]="option.rithmId">
              {{ option.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <section class="station-chip-list">
      <mat-chip-set
        #chipList
        [attr.aria-label]="termsGeneric.Station.Single + ' selection'"
      >
        @for (station of flowStations; track $index) {
          <mat-chip>
            {{ station.name }}
          </mat-chip>
        }
      </mat-chip-set>
    </section>
    <div
      class="condition-control-buttons flex justify-between gap-4 items-start7 pt-3"
    >
      <div class="flex space-x-4">
        <button
          mat-button
          type="button"
          data-testid="condition-cancel"
          class="!font-semibold"
          id="condition-cancel"
          (click)="closeFormAction()"
        >
          CANCEL
        </button>
        <div class="button-custom-design">
          <button
            type="button"
            mat-button
            [disabled]="!flowStations.length"
            class="!font-semibold"
            (click)="
              isEditing
                ? addOrUpdateSpecificAction(action, true)
                : createNewAction()
            "
          >
            @if (savingAction) {
              <ng-container
                *ngTemplateOutlet="
                  loadingInlineText;
                  context: { $implicit: 'Saving...' }
                "
              ></ng-container>
            } @else {
              SAVE
            }
          </button>
        </div>
      </div>

      @if (isEditing) {
        <button
          mat-stroked-button
          class="!bg-red-500 !text-white"
          type="button"
          id="delete-action"
          data-testid="delete-action"
          (click)="confirmRemoveIntegration()"
        >
          @if (deletingAction) {
            <ng-container
              *ngTemplateOutlet="
                loadingInlineText;
                context: { $implicit: 'Deleting...' }
              "
            ></ng-container>
          } @else {
            <i class="fa-light fa-trash text-lg"></i> DELETE ACTION
          }
        </button>
      }
    </div>
  </section>
</div>

<!-- Loading question list. -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator
    id="loading-inline-text"
    [inlineText]="message"
  ></app-loading-indicator>
</ng-template>
