<div
  class="flex flex-col w-full py-4"
  [ngClass]="orderOfOperations ? 'space-y-2' : 'space-y-4'"
>
  @if (orderOfOperations) {
    <p class="text-base font-medium">SQL: {{ sqlIntegrationType }}</p>
  } @else {
    <p class="title-from-order-operation">Action: {{ sqlIntegrationType }}</p>
  }
  <section class="flex flex-col mt-2">
    <p [ngClass]="orderOfOperations ? 'text-xs' : 'text-sm'">Database:</p>
    <mat-chip-listbox>
      <mat-chip> {{ integration.header }}</mat-chip>
    </mat-chip-listbox>
  </section>

  <section class="flex flex-col">
    <p [ngClass]="orderOfOperations ? 'text-xs' : 'text-sm'">Table:</p>
    <mat-chip-listbox>
      <mat-chip> {{ sqlIntegrationData.tableName }}</mat-chip>
    </mat-chip-listbox>
  </section>

  <section class="flex flex-col">
    <p [ngClass]="orderOfOperations ? 'text-xs' : 'text-sm'">Key/row ID:</p>
    <mat-chip-listbox>
      <mat-chip
        >{{ sqlIntegrationData.keyField.column }}
        <span class="px-2"><i class="fal fa-arrow-right"></i></span>
        @if (timeConversion.isDate(sqlIntegrationData.keyField.field)) {
          {{
            sqlIntegrationData.keyField.field | utcDateFormat: questionSettings
          }}
        } @else {
          {{ sqlIntegrationData.keyField.field }}
        }
      </mat-chip>
    </mat-chip-listbox>
  </section>

  <section class="flex flex-col">
    <p [ngClass]="orderOfOperations ? 'text-xs' : 'text-sm'">Values:</p>
    <mat-chip-listbox>
      @for (pair of sqlIntegrationData.columnValues; track $index) {
        <mat-chip>
          {{ pair.column }}
          <span class="px-2"><i class="fal fa-arrow-right"></i></span
          >{{ pair.field }}
        </mat-chip>
      }
    </mat-chip-listbox>
  </section>
</div>
