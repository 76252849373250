<div
  class="integration-actions px-4 w-full"
  [ngClass]="{
    'pb-4': !orderOfOperations
  }"
>
  @if (showIntegration) {
    <section
      class="integration-content flex flex-col gap-4 w-full"
      [ngClass]="{ 'pb-2': actionToBeUpdated }"
    >
      @if (!sqlFormDisplayed && !actionToBeUpdated) {
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          class="w-full max-w-lg"
        >
          <mat-select
            #integrationSelection
            placeholder="Select Integration"
            id="integration-select"
            data-testid="integration-select"
            [(ngModel)]="selectedIntegrationType"
            (selectionChange)="selectIntegrationType($event.value)"
          >
            @for (type of integrationActionTypes; track $index) {
              <mat-option
                [value]="type"
                [id]="'integration-' + type"
                [attr.data-testid]="'integration-' + type"
                >{{ type }}</mat-option
              >
            }
          </mat-select>
        </mat-form-field>
      }
      @if (!sqlFormDisplayed && showActionType) {
        <app-sql (typeSelected)="sqlTypeActionSelected($event)" />
      }
      <ng-container
        *ngTemplateOutlet="
          integrationsForms;
          context: { $implicit: selectedIntegrationType }
        "
      ></ng-container>

      @if (showSqlAction) {
        <app-sql-form
          #sqlFormComponent
          [stationRithmId]="stationRithmId"
          [bucketQuestions]="bucketQuestions"
          [sqlSelected]="sqlTypeSelected"
          [action]="actionToBeUpdated"
          (sqlFormStatus)="onSqlFormStatus($event)"
          (sqlPowerEmitter)="saveIntegrationAction($event)"
        />
      }

      <div class="content-controls flex gap-4 items-start">
        <button
          type="button"
          color="accent"
          mat-button
          id="cancel-button"
          data-testid="cancel-button"
          class="!font-semibold"
          [ngClass]="{ '!text-black': orderOfOperations }"
          [disabled]="savingAction || deletingAction"
          (click)="cancelActions()"
        >
          CANCEL
        </button>
        @if (showDoneButton) {
          <div [ngClass]="{ 'button-custom-design': orderOfOperations }">
            <button
              type="button"
              (click)="submitIntegration()"
              mat-raised-button
              color="accent"
              class="uppercase buttonDisable !font-semibold"
              id="done-button"
              data-testid="done-button"
              [disabled]="
                (!showSqlAction
                  ? !isChatGptFormValid
                  : !isSqlFormValid || (savingAction && orderOfOperations)) ||
                deletingAction
              "
            >
              @if (savingAction) {
                <ng-container
                  *ngTemplateOutlet="
                    loadingInlineText;
                    context: { $implicit: 'Saving...' }
                  "
                ></ng-container>
              } @else {
                SAVE
              }
            </button>
          </div>
        }

        @if (showList && orderOfOperations) {
          <div class="flex-grow text-right">
            <button
              mat-stroked-button
              class="!bg-red-500 !text-white"
              type="button"
              id="delete-action"
              data-testid="delete-action"
              (click)="
                actionToBeUpdated && confirmRemoveIntegration(actionToBeUpdated)
              "
            >
              @if (deletingAction) {
                <ng-container
                  *ngTemplateOutlet="
                    loadingInlineText;
                    context: { $implicit: 'Deleting...' }
                  "
                ></ng-container>
              } @else {
                <i class="fa-light fa-trash text-lg"></i> DELETE ACTION
              }
            </button>
          </div>
        }

        @if (selectedIntegrationType === actionType.Sql && !sqlFormDisplayed) {
          <div [ngClass]="{ 'button-custom-design': orderOfOperations }">
            <button
              type="button"
              (click)="nexActions()"
              mat-raised-button
              color="accent"
              class="uppercase !font-semibolds"
              id="next-button"
              data-testid="next-button"
              [disabled]="!sqlTypeSelected"
            >
              NEXT
              <span class="ml-3">
                <i class="fal fa-arrow-right"></i>
              </span>
            </button>
          </div>
        }
      </div>
    </section>
  }
  @if (!showIntegration) {
    @if (showList) {
      <ng-container *ngTemplateOutlet="integrationList" />
    }

    @if (!orderOfOperations || !showList) {
      <div
        class="w-full"
        [ngClass]="{ 'button-custom-design': orderOfOperations }"
      >
        <button
          mat-stroked-button
          type="button"
          color="accent"
          class="!leading-tight !tracking-wide"
          [ngClass]="{
            '!bg-sky-500 !text-white': orderOfOperations
          }"
          (click)="createIntegrationAction()"
          id="new-action-button"
          data-testid="new-action-button"
        >
          New Action
        </button>
      </div>
    }
  }
</div>

<ng-template #integrationsForms let-integration let-item>
  @if (integration === actionType.ChatGPT) {
    <app-chat-gpt-form
      #chatGptFormComponent
      [action]="actionToBeUpdated"
      [stationRithmId]="stationRithmId"
      [bucketQuestions]="bucketQuestions"
      (isFormValid)="isChatGptFormValid = $event"
      (chatGptPowerEmitter)="saveIntegrationAction($event)"
    />
  }
</ng-template>

<ng-template #integrationList>
  @for (item of getIntegrationsActions; track $index; let isLast = $last) {
    <div class="flex items-start justify-center space-y-2">
      <section class="w-full action-chips">
        @if (item.type === actionType.ChatGPT) {
          <app-chat-gpt-item
            [integration]="item"
            [orderOfOperations]="orderOfOperations"
          />
        } @else if (
          [actionType.SqlPush, actionType.SqlPull].includes(item.type)
        ) {
          <app-sql-item
            [integration]="item"
            [bucketQuestions]="bucketQuestions"
            [orderOfOperations]="orderOfOperations"
          />
        } @else {
          <p>Any other integration</p>
        }
      </section>
      <button
        mat-icon-button
        type="button"
        [matMenuTriggerFor]="options_rule"
        class="text-lg"
        id="option-integration"
        data-testid="option-integration"
        aria-label="option"
      >
        <i class="fa-light fa-ellipsis"></i>
      </button>
      <mat-menu #options_rule="matMenu">
        <button
          mat-menu-item
          type="button"
          id="option-integration-edit"
          data-testid="option-integration-edit"
          (click)="editIntegration(item)"
        >
          <span class="p-4">Edit Integration</span>
        </button>
        @if (orderOfOperations) {
          <button
            mat-menu-item
            type="button"
            id="option-integration-duplicate"
            data-testid="option-integration-duplicate"
            (click)="addOrUpdateSpecificAction(item, false, true)"
          >
            <span class="p-4">Duplicate action</span>
          </button>
        }
        <button
          mat-menu-item
          type="button"
          id="option-integration-delete"
          data-testid="option-integration-delete"
          (click)="confirmRemoveIntegration(item)"
        >
          <span class="p-4 text-error-500">Delete Integration</span>
        </button>
      </mat-menu>
    </div>
    @if (!isLast) {
      <div class="w-full">
        <mat-divider></mat-divider>
      </div>
    }
  }
</ng-template>

<!-- Loading question list. -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator
    id="loading-inline-text"
    [inlineText]="message"
  ></app-loading-indicator>
</ng-template>
