<div class="w-full flex select-none px-4">
  <div class="w-full">
    <div
      class="w-full border border-color rounded-t-md"
      [style.height.px]="height"
    >
      <div class="flex h-[calc(100%-30px)]">
        <!-- List scripts -->
        <div class="w-1/4 border-r border-color">
          <div
            class="flex flex-wrap justify-between items-center pl-2 border-b border-color h-10"
          >
            <span class="text-sm font-bold">Scripts</span>
            <div class="flex justify-center items-center w-10">
              @if (!isCreatingFile) {
                <button
                  mat-icon-button
                  type="button"
                  color="accent"
                  id="add-new-script"
                  data-testid="add-new-script"
                  (click)="newScript()"
                  [disabled]="isLoadingScriptList || isErrorLoadScriptList"
                >
                  <i class="fa-light fa-file-plus"></i>
                </button>
              }
              @if (isCreatingFile) {
                <app-loading-indicator [inlineText]="' '" />
              }
            </div>
          </div>
          <div
            class="w-full h-[calc(100%-40px)] overflow-y-auto scrollbar file-list"
          >
            <ng-container [ngTemplateOutlet]="ScriptList" />
          </div>
        </div>
        <!-- Edit area -->
        <div class="w-9/12 h-full">
          <div
            class="w-full h-[40px] bg-secondary-50 overflow-x-auto overflow-y-hidden scrollbar"
          >
            @if (scriptsToEdit.length) {
              <div class="px-2 h-full flex">
                <ng-container *ngTemplateOutlet="TabsToEditScript" />
              </div>
            }
          </div>
          @if (scriptsToEdit.length) {
            <div class="h-[calc(100%-40px)] not-global-edit-code">
              <ng-container *ngTemplateOutlet="CodeEditorScripts" />
            </div>
          } @else {
            <ng-container *ngTemplateOutlet="EmptyArea" />
          }
        </div>
      </div>
      <!-- Footer -->
      <div class="w-full flex border-t border-color h-[30px]">
        <ng-container *ngTemplateOutlet="Footer" />
      </div>
    </div>
    <div
      id="resize-container"
      class="resize-script-container"
      appResizeElement
      [height]="height"
      [minHeight]="300"
      [idElementResize]="'resize-container'"
      (size)="height = $event"
      (isActiveResize)="activeResize($event)"
    >
      <span></span>
      <span></span>
    </div>
  </div>
</div>

<ng-template #Footer>
  <div class="w-1/4 border-r border-color flex items-center">
    <span class="text-sm ml-2 w-full truncate">Documentation</span>
  </div>
  <div class="w-9/12 flex justify-between items-center px-2 text-primary-200">
    <div class="flex justify-between items-center gap-x-3">
      <button
        mat-icon-button
        type="button"
        title="{{ isEditingDocumentSelected ? 'Save changes' : 'All Saved' }}"
        [disabled]="
          !isEditingDocumentSelected ||
          isCreatingFile ||
          isRenamingFile ||
          isDeletingFile ||
          isSavingFile
        "
        [ngClass]="{ 'animate-pulse': isSavingFile }"
        (click)="saveScriptActions()"
      >
        <i
          [ngClass]="{
            'text-accent-500 fa-solid fa-floppy-disk-pen cursor-pointer':
              isEditingDocumentSelected,
            'fa-light fa-floppy-disk ': !isEditingDocumentSelected
          }"
          class="fa-lg"
        ></i>
      </button>
      <span class="text-sm"> Test status</span>
      <i
        class="fa-light fa-triangle-exclamation"
        [ngClass]="
          scriptsToEditWarning[indexTabScriptEdit] > 0
            ? 'text-log-warning'
            : 'text-log-success'
        "
      ></i>
      {{ scriptsToEditWarning[indexTabScriptEdit] }}
    </div>
    <div class="space-x-2">
      <span class="text-sm">Enable script</span>
      <mat-slide-toggle
        [disabled]="
          indexScriptSelected === -1 ||
          !dataScriptActions[indexScriptSelected].rithmId
        "
        (change)="
          enabledScriptActions(
            dataScriptActions[indexScriptSelected].rithmId || '',
            $event.checked
          )
        "
        [checked]="
          indexScriptSelected !== -1
            ? dataScriptActions[indexScriptSelected].scriptEnabled
            : null
        "
        color="accent"
        [ngClass]="{ 'animate-pulse': isSavingFile }"
      ></mat-slide-toggle>
    </div>
  </div>
</ng-template>

<ng-template #EmptyArea>
  <div
    class="w-full h-[calc(100%-40px)] flex justify-center items-center overflow-hidden"
  >
    <div>
      <div class="w-full flex justify-center">
        <img
          ngSrc="assets/logo/black-icon-only.svg"
          width="150"
          height="150"
          class="w-[30%]"
          alt="Logo Rithm"
        />
      </div>
      <div class="flex justify-center mt-4">
        <div class="w-9/12">
          @for (
            command of [
              { title: 'New script', key: 'N' },
              { title: 'Run test', key: 'T' }
            ];
            track $index
          ) {
            <div class="flex justify-between items-center">
              <span class="text-sm text-primary-200">{{ command.title }}</span>
              <div class="flex gap-1 mt-2">
                @if (this.mobileBrowserChecker.os() !== 'Mac') {
                  <span class="letter-command text-xs">CTRL</span>
                }
                @if (this.mobileBrowserChecker.os() !== 'Mac') {
                  <span class="letter-command text-xs">ALT</span>
                }
                @if (this.mobileBrowserChecker.os() === 'Mac') {
                  <i class="fa-thin fa-chevron-up letter-command"></i>
                }
                <span class="letter-command text-xs">{{ command.key }}</span>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ScriptList>
  @if (isLoadingScriptList) {
    <div class="w-full h-full flex items-center justify-center">
      <app-loading-indicator />
    </div>
  }

  @if (isErrorLoadScriptList) {
    <div
      id="error-load-script"
      class="w-full h-full flex items-center justify-center"
    >
      <div class="text-center">
        <p class="text-xs text-error-500">
          An error occurred while getting the scripts
        </p>
        <button
          mat-stroked-button
          type="button"
          color="warn"
          (click)="getScriptActions()"
          id="try-again"
          data-testid="try-again"
          class="text-xs"
        >
          Try Again
        </button>
      </div>
    </div>
  }

  @if (
    !dataScriptActions.length && !isLoadingScriptList && !isErrorLoadScriptList
  ) {
    <div class="w-full h-full flex items-center justify-center">
      <span class="text-sm text-primary-200">Empty file list</span>
    </div>
  }

  @if (!isLoadingScriptList && !isErrorLoadScriptList) {
    <mat-selection-list [multiple]="false">
      @for (file of dataScriptActions; track $index) {
        <mat-list-option
          [id]="'script-list-' + $index"
          [attr.data-testid]="'script-list-' + $index"
          [matTooltip]="file.fileName"
          matTooltipPosition="right"
          [matTooltipDisabled]="
            enabledRenameScript && indexOptionScriptList === $index
          "
          (click)="openScript(file, $index)"
          (contextmenu)="openMatMenu($event, $index)"
          [selected]="
            indexScriptSelected >= 0 ? $index === indexScriptSelected : null
          "
          [ngClass]="{
            'animate-pulse bg-primary-50':
              (isRenamingFile || isDeletingFile) &&
              indexOptionScriptList === $index,
            'pointer-events-none': isSavingFile
          }"
        >
          <div
            style="visibility: hidden; position: fixed"
            [style.left]="menuPosition.x"
            [style.top]="menuPosition.y"
            [matMenuTriggerFor]="menu"
          ></div>

          <div class="w-full flex items-center">
            <mat-icon><i class="fa-brands fa-js js-color"></i></mat-icon>
            <input
              type="text"
              #InputName
              class="lowercase outline-none border-0 w-11/12 rounded-xl pl-1"
              [ngClass]="[
                enabledRenameScript && indexOptionScriptList === $index
                  ? 'cursor-ns-resize bg-secondary-50 text-black'
                  : 'cursor-pointer bg-transparent',
                isSavingFile ? 'text-primary-200' : ''
              ]"
              [value]="file.fileName"
              (click)="$event.stopPropagation()"
              (keydown.enter)="renameScriptFile($index, InputName.value, file)"
              (focusout)="renameScriptFile($index, InputName.value, file)"
              [disabled]="
                !(enabledRenameScript && indexOptionScriptList === $index)
              "
            />

            @if (enabledRenameScript && indexOptionScriptList === $index) {
              <i
                class="fa-light fa-circle-question ml-2"
                matTooltip="Allowed characters ( ) - _ a-z 0-9"
                matTooltipPosition="right"
              ></i>
            }
          </div>
        </mat-list-option>
      }
    </mat-selection-list>
  }

  <mat-menu #menu="matMenu">
    <div class="menu-option">
      <button
        mat-menu-item
        type="button"
        (click)="enableRenameScriptFile()"
        id="rename-script-button"
        data-testid="rename-script-button"
      >
        <mat-icon><i class="fa-light fa-pen-to-square"></i></mat-icon>
        <span>Rename</span>
      </button>
      <button
        mat-menu-item
        type="button"
        (click)="confirmRemoveScript()"
        id="delete-script-button"
        data-testid="delete-script-button"
      >
        <mat-icon><i class="fa-light fa-trash text-error-500"></i></mat-icon>
        <span>Delete</span>
      </button>
    </div>
  </mat-menu>
</ng-template>

<div class="px-8 py-5">
  <mat-tab-group
    [selectedIndex]="indexTabTesting"
    (selectedTabChange)="setIndexTab($event)"
    class="script-tabs"
    [ngClass]="{ barWidth: indexTabTesting === 0 }"
    mat-align-tabs="start"
    id="script-tabs-group"
    data-testid="script-tabs-group"
  >
    <mat-tab
      id="script-tabs-group-test"
      data-testid="script-tabs-group-test"
      label="TEST"
    >
      <ng-container [ngTemplateOutlet]="testSection" />
    </mat-tab>
    <mat-tab
      id="script-tabs-group-console"
      data-testid="script-tabs-group-console"
      label="CONSOLE"
    >
      <ng-container [ngTemplateOutlet]="consoleSection" />
    </mat-tab>
    <mat-tab
      id="script-tabs-group-history"
      data-testid="script-tabs-group-history"
      label="HISTORY"
    >
      <ng-container [ngTemplateOutlet]="historySection" />
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #testSection>
  <section class="flex justify-between pt-5">
    <div>
      <p class="font-semibold text-sm">Script Testing</p>
      <p class="text-xs text-primary-200">All files must pass before saving</p>
    </div>
    <div>
      <button
        mat-stroked-button
        type="button"
        title="{{
          indexScriptSelected === -1
            ? 'Please Select any file to RUN'
            : 'RUN TEST'
        }}"
        data-testid="run-test"
        id="run-test"
        color="accent"
        [disabled]="
          indexScriptSelected === -1 ||
          isEditingDocumentSelected ||
          isSavingFile ||
          !this.scriptsToEdit[indexTabScriptEdit] ||
          !this.scriptsToEdit[indexTabScriptEdit].contents.trim()
        "
        (click)="executedScriptTesting()"
      >
        RUN TEST
      </button>
    </div>
  </section>

  @if (!dataScriptTesting.length) {
    <section>
      <div class="flex justify-center">
        <i class="fa-light fa-ban-bug text-accent-500 fa-5x"></i>
      </div>
      <div class="flex justify-center">
        <div class="pt-4">
          <p class="text-center text-sm font-semibold pb-2">Script Testing</p>
          <p class="text-xs text-center text-primary-200">
            In order to save and run scripts, they must first pass a script<br />
            test to ensure they are bug free.
          </p>
        </div>
      </div>
    </section>
  }
</ng-template>

<ng-template #consoleSection>
  <section class="flex justify-between items-center pt-3 px-3">
    <p class="font-semibold text-sm">Console</p>
    <div>
      <button
        mat-icon-button
        type="button"
        data-testid="console-section-update"
        id="console-section-update"
        (click)="setLastConsoleLog()"
        [disabled]="
          this.copyLastConsoleLog.length !== this.dataScriptTesting.length
        "
      >
        <i class="fa-solid fa-arrow-rotate-right fa-lg"></i>
      </button>
      <button
        mat-icon-button
        type="button"
        data-testid="console-section-ban"
        id="console-section-ban"
        [disabled]="!dataScriptTesting.length"
        (click)="clearConsoleLogs()"
      >
        <i class="fa-solid fa-ban fa-lg"></i>
      </button>
    </div>
  </section>
</ng-template>

<ng-template #historySection>
  <section class="flex justify-between pt-2">
    <div class="pt-5">
      <p class="font-semibold text-sm">
        {{ termsGeneric.Container.Single }} History
      </p>
      <p class="text-xs text-primary-200">
        {{ termsGeneric.Container.Plural }} that matched rule condition
      </p>
    </div>
    <div class="widthInput items-center">
      <mat-form-field appearance="outline">
        <input
          type="text"
          matInput
          placeholder="Search"
          id="search-history"
          data-testid="search-history"
          maxlength="55"
          [(ngModel)]="searchHistory"
        />
        <span matPrefix
          ><i class="fas fa-search p-1 text-secondary-300"></i
        ></span>
      </mat-form-field>
    </div>
  </section>
  @if (!scriptsToEdit[indexTabScriptEdit]) {
    <div class="mt-3 w-full text-center">There are not script selected.</div>
  }
  @if (
    !isLoadingHistory &&
    scriptsToEdit[indexTabScriptEdit] &&
    !historyScripts.length
  ) {
    <div class="mt-3 w-full text-center">There are not history for show.</div>
  }

  @if (isLoadingHistory) {
    <div class="mt-3">
      <app-loading-indicator
        inlineText="Loading..."
        id="is-loading-history"
        data-testid="is-loading-history"
      />
    </div>
  }
</ng-template>

<ng-template #TabsToEditScript>
  @for (scriptEdit of scriptsToEdit; track $index) {
    <div
      [id]="'tab' + '-' + $index"
      [attr.data-testid]="'tab' + '-' + $index"
      class="flex cursor-pointer items-center justify-between px-2 w-[170px]"
      [ngClass]="{
        'bg-white rounded-t': indexTabScriptEdit === $index
      }"
    >
      <div
        class="flex items-center w-[140px]"
        (click)="changeTabScriptOpened($index)"
      >
        <mat-icon><i class="fa-brands fa-js js-color"></i></mat-icon>
        <p class="truncate pl-1" [title]="scriptEdit.fileName">
          {{ scriptEdit.fileName }}
        </p>
      </div>
      @if (indexTabScriptEdit === $index) {
        <i (click)="confirmCloseScript()" class="fa-light fa-xmark"></i>
      }
    </div>
  }
</ng-template>
<ng-template #CodeEditorScripts>
  @if (!isLoadingCodeEditor) {
    <ngs-code-editor
      theme="vs"
      class="h-full"
      [codeModel]="{
        language: 'javascript',
        uri: scriptsToEdit[indexTabScriptEdit].fileName,
        value: scriptsToEdit[indexTabScriptEdit].contents
      }"
      [readOnly]="isDisabledCodeEdit"
      (valueChanged)="valueEditScript = $event"
    />
  }
  @if (isLoadingCodeEditor) {
    <div class="w-full h-full flex items-center justify-center">
      <img
        ngSrc="assets/logo/black-icon-only.svg"
        width="150"
        height="150"
        class="w-1/6 animate-pulse"
        alt="Logo Rithm"
      />
    </div>
  }
</ng-template>

<app-scripts-logs-actions
  [typeLog]="indexTabTesting"
  [dataScriptTesting]="dataScriptTesting"
  [historyScripts]="scriptsToEdit[indexTabScriptEdit] ? historyScripts : []"
  [isLoadingTesting]="isLoadingScriptsTesting"
  [inputSearchHistory]="searchHistory"
  [scriptName]="
    scriptsToEdit[indexTabScriptEdit]
      ? scriptsToEdit[indexTabScriptEdit].fileName
      : ''
  "
/>
