import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  effect,
  input,
  OnDestroy,
  OnInit,
  output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SelectAiModelComponent } from 'src/app/station/rules/actions/rithm-ai-action/select-ai-model/select-ai-model.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  TypedFormControls,
  ActionType,
  Question,
  QuestionFieldType,
  AiAction,
  AiPromptSetup,
  AiParameterOption,
  AiImageQuality,
  AiImageStyle,
  AiImageSize,
  AiImageSettings,
  StationFieldPrefix,
  AiActionFormData,
  FieldsGroup,
  PowerAction,
  AiActionData,
  FieldDataType,
} from 'src/models';
import {
  ComponentHelper,
  JsonValidator,
  RithmAiActionHelper,
  TermsGeneric,
} from 'src/helpers';
import { takeUntil, Subject, map, merge } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SelectPromptFieldComponent } from 'src/app/station/rules/actions/rithm-ai-action/select-prompt-field/select-prompt-field.component';
import { v4 as uuidv4 } from 'uuid';
import { NgxSearchComponent } from 'src/app/shared/ngx-search/ngx-search.component';

/** Structure for Rithm Ai Form. */
type RithmAiTypedForm = TypedFormControls<AiPromptSetup>;

/** Structure for Image Setting form. */
type ImageSettingsForm = TypedFormControls<AiImageSettings>;

/**
 * Rithm AI Form Component.
 */
@Component({
  selector: 'app-rithm-ai-action-form',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    SelectAiModelComponent,
    MatSlideToggleModule,
    MatTooltipModule,
    SelectPromptFieldComponent,
    NgxSearchComponent,
  ],
  styleUrls: ['./rithm-ai-action-form.component.scss'],
  templateUrl: './rithm-ai-action-form.component.html',
})
export class RithmAiActionFormComponent implements OnInit, OnDestroy {
  /** The generic terms. */
  termsGeneric = TermsGeneric;

  /** Component helper. */
  componentHelper = ComponentHelper;

  /** Rithm AI Action Helper. */
  rithmAiActionHelper = RithmAiActionHelper;

  /** Image quality types. */
  imageQualityType = AiImageQuality;

  /** Action Type. */
  actionType = ActionType;

  /** The station field prefix. */
  stationFieldPrefix = StationFieldPrefix;

  /** The selected model input. */
  selectedModelInput = input.required<AiAction>();

  /** All the bucket questions. */
  bucketQuestions = input.required<Question[]>();

  /** The action to edit. */
  actionToEdit = input<PowerAction | null>(null);

  /** Form valid status event. */
  isFormValid = output<boolean>();

  /** Form value event. */
  formValue = output<AiActionFormData>();

  /** The Destroyed. */
  private destroyed$ = new Subject<void>();

  /** The Rithm AI Form. */
  rithmAiForm = new FormGroup<RithmAiTypedForm>({
    model: new FormControl('' as AiAction, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    responseField: new FormControl('' as string, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    formattedSpacing: new FormControl(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    prompt: new FormArray<FormControl<string> | AbstractControl<string>>(
      [
        new FormControl('', {
          nonNullable: true,
          validators: [Validators.required],
        }),
      ],
      {
        validators: [Validators.required],
      },
    ),
  });

  /** Image Setting Form.*/
  imageSettingsForm = new FormGroup<ImageSettingsForm>({
    quality: new FormControl<AiImageQuality>(AiImageQuality.Standard, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    style: new FormControl<AiImageStyle>(AiImageStyle.Vivid, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    size: new FormControl<AiImageSize>('1024x1024', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  /** The available options for the response field. */
  responseFieldOptions: FieldsGroup = {
    fields: [],
    containerInfo: [],
    stationInfo: [],
  };

  /** The field for updating the filtered data in the selection ngx-search. */
  fieldDataFiltered!: FieldDataType;

  /** The preselected model. */
  preselectedModel = computed(() => this.actionToEdit()?.type as AiAction);

  /**
   * The prompt IDs.
   * Necessary to track the prompt items correctly in the form. It just holds unique values.
   */
  promptIds = [uuidv4()];

  constructor() {
    effect(() => {
      this.setFormValues();
      this.responseFieldOptions = this.getResponseFieldOptionsByModel();
    });
  }

  /**
   * The on init life cycle.
   */
  ngOnInit(): void {
    this.subscriptionModelField();
    this.subscribeImageQuality();
    this.notifyFormValidStatus();
  }

  /**
   * Set the form values.
   */
  setFormValues(): void {
    if (this.actionToEdit()) {
      const actionData = JsonValidator.getObjectFromString<AiActionData>(
        this.actionToEdit()?.data as string,
      );

      this.rithmAiForm.controls.model.setValue(
        this.actionToEdit()?.type as AiAction,
      );

      if (actionData.prompt.length > 1) {
        actionData.prompt.forEach((prompt, index) => {
          if (index > 0) {
            this.addPromptControlInForm(prompt);
          }
        });
      }

      this.rithmAiForm.patchValue({
        ...actionData,
      });

      if (actionData.imageSettings) {
        this.imageSettingsForm.patchValue(actionData.imageSettings);
      }
    } else if (this.selectedModelInput()) {
      this.rithmAiForm.controls.model.setValue(this.selectedModelInput());
    }
  }

  /**
   * Notify the form status.
   */
  notifyFormValidStatus(): void {
    merge(
      this.rithmAiForm.statusChanges.pipe(
        map(
          (status) =>
            status === 'VALID' &&
            this.rithmAiForm.controls.responseField.enabled,
        ),
      ),
      this.imageSettingsForm.valueChanges.pipe(
        map(
          () =>
            this.rithmAiForm.status === 'VALID' &&
            this.rithmAiForm.controls.responseField.enabled &&
            this.imageSettingsForm.status === 'VALID',
        ),
      ),
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (isValid) => {
          this.isFormValid.emit(isValid);

          if (isValid) {
            const aiActionFormValue =
              this.rithmAiForm.controls.model.value === ActionType.TextGenerator
                ? (this.rithmAiForm.value as AiActionFormData)
                : <AiActionFormData>{
                    ...this.rithmAiForm.value,
                    imageSettings: this.imageSettingsForm.value,
                  };
            this.formValue.emit(aiActionFormValue);
          }
        },
      });
  }

  /**
   * The subscription to model field.
   */
  subscriptionModelField(): void {
    this.rithmAiForm.controls.model.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.responseFieldOptions = this.getResponseFieldOptionsByModel();
          const responseFieldControl = this.rithmAiForm.controls.responseField;
          responseFieldControl.reset();

          if (
            this.responseFieldOptions.fields.length ||
            this.responseFieldOptions.containerInfo.length
          ) {
            responseFieldControl.enable();
          } else {
            responseFieldControl.disable();
          }
        },
      });
  }

  /**
   * Subscribe to the image quality.
   */
  subscribeImageQuality(): void {
    this.imageSettingsForm.controls.quality.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (quality) => {
          if (
            (quality === AiImageQuality.Standard &&
              ['1792x1024', '1024x1792'].includes(
                this.imageSettingsForm.controls.size.value,
              )) ||
            (quality === AiImageQuality.HD &&
              ['256x256', '512x512'].includes(
                this.imageSettingsForm.controls.size.value,
              ))
          ) {
            this.imageSettingsForm.controls.size.setValue('1024x1024');
          }
        },
      });
  }

  /**
   * Get Response Field Options by model selected.
   * @returns The available response Field options.
   */
  getResponseFieldOptionsByModel(): FieldsGroup {
    const model =
      this.rithmAiForm.controls.model.value ?? this.selectedModelInput();

    return this.bucketQuestions().reduce(
      (fieldsGroup, field) => {
        if (model === ActionType.TextGenerator) {
          if (field.questionType === QuestionFieldType.ContainerName) {
            fieldsGroup.containerInfo.push(field);
          }

          if (
            [QuestionFieldType.ShortText, QuestionFieldType.LongText].includes(
              field.questionType,
            )
          ) {
            fieldsGroup.fields.push(field);
          }
        }

        if (
          model === ActionType.ImageGenerator &&
          field.questionType === QuestionFieldType.File
        ) {
          fieldsGroup.fields.push(field);
        }

        return fieldsGroup;
      },
      <FieldsGroup>{
        fields: [],
        containerInfo: [],
        stationInfo: [],
      },
    );
  }

  /**
   * Set the prompt value.
   * @param index The number prompt value in prompts.
   * @param value The value prompt.
   */
  setPromptValue(index: number, value: string): void {
    this.rithmAiForm.controls.prompt.controls[index].setValue(value);
  }

  /**
   * Get the select label.
   * @param option The option selected.
   * @param list The list of options.
   * @returns The label of the selected option.
   */
  getSelectLabel(
    option: AiParameterOption['value'],
    list: AiParameterOption[],
  ): string {
    return list.find(({ value }) => value === option)?.title ?? '';
  }

  /**
   * Add prompt control in form.
   * @param value The value of the prompt.
   */
  addPromptControlInForm(value = ''): void {
    this.rithmAiForm.controls.prompt.push(
      new FormControl(value, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    );
    this.promptIds.push(uuidv4());
  }

  /**
   * Remove prompt Control in form.
   * @param index The index in fromArray.
   * @param promptId The prompt id.
   */
  removePromptControlInForm(index: number, promptId: string): void {
    const prompts = this.rithmAiForm.controls.prompt;
    if (prompts.controls.at(index) !== undefined) {
      prompts.removeAt(index);
    }
    this.promptIds = this.promptIds.filter((id) => id !== promptId);
  }

  /** The destroy life cycle.*/
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
