@if (!updatingField) {
  @for (trigger of fieldUpdatedList; track $index) {
    <section class="w-full flex pt-2 pb-4 justify-between items-center">
      <!-- TRIGGER CONTENT. -->
      <div class="flex items-center space-x-4">
        <mat-slide-toggle
          id="toggle-field-update"
          data-testid="toggle-field-update"
          [checked]="!trigger.isDisabled"
          (change)="handleFieldUpdateTrigger(trigger, true)"
        />

        <span class="text-base text-gray-800 font-medium">Field Update</span>
        <div class="input-slim-mdc w-[280px]" [formGroup]="questionsSelected">
          @if (questionsSelected.get('question-' + trigger.rithmId)) {
            <mat-form-field
              appearance="outline"
              subscriptSizing="dynamic"
              [id]="'question-' + trigger.rithmId"
            >
              <mat-label>Field</mat-label>
              <mat-select
                [formControlName]="'question-' + trigger.rithmId"
                (selectionChange)="
                  addQuestionToTrigger($event.value, trigger.rithmId)
                "
              >
                <app-ngx-search
                  [multipleQuestions]="[
                    filteredQuestions.stationInfo,
                    filteredQuestions.containerInfo,
                    filteredQuestions.fields
                  ]"
                  (getFilteredArrays)="filterFieldDataType = $event"
                />
                @if (
                  filterFieldDataType.multipleQuestions &&
                  !filterFieldDataType.noMatchElements
                ) {
                  <mat-optgroup
                    [label]="termsGeneric.Station.Single + ' Info'"
                    id="group-select-trigger"
                  >
                    @for (
                      question of filterFieldDataType.multipleQuestions[0];
                      track question.rithmId
                    ) {
                      <mat-option
                        [value]="'info:' + question.questionType"
                        [disabled]="
                          isDisabled(
                            'info:' + question.questionType,
                            trigger.rithmId
                          )
                        "
                      >
                        {{ question.prompt }}
                      </mat-option>
                    }
                  </mat-optgroup>

                  <mat-optgroup
                    [label]="termsGeneric.Container.Single + ' Info'"
                    id="group-select-trigger"
                  >
                    @for (
                      question of filterFieldDataType.multipleQuestions[1];
                      track question.rithmId
                    ) {
                      <mat-option
                        [value]="'info:' + question.questionType"
                        [disabled]="
                          isDisabled(
                            'info:' + question.questionType,
                            trigger.rithmId
                          )
                        "
                      >
                        {{ question.prompt }}
                      </mat-option>
                    }
                  </mat-optgroup>

                  <mat-optgroup [label]="'Fields'" id="group-select-trigger">
                    @for (
                      question of filterFieldDataType.multipleQuestions[2];
                      track question.rithmId
                    ) {
                      <mat-option
                        [value]="question.rithmId"
                        [disabled]="
                          isDisabled(question.rithmId, trigger.rithmId)
                        "
                      >
                        {{ question.prompt }}
                      </mat-option>
                    }
                  </mat-optgroup>
                }
              </mat-select>
            </mat-form-field>
          }
        </div>
      </div>

      <button type="button" [matMenuTriggerFor]="settingsMenu">
        <i class="fal fa-ellipsis-h text-xl"></i>
      </button>

      <mat-menu
        #settingsMenu="matMenu"
        class="w-[360px] h-[56px] flex flex-col justify-center"
      >
        <button
          type="button"
          class="w-full h-full px-3 text-left"
          (click)="handleFieldUpdateTrigger(trigger)"
        >
          <span class="text-gray-800 text-sm font-medium">remove trigger</span>
        </button>
      </mat-menu>
    </section>
  }
}
