import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ComponentHelper, TermsGeneric } from 'src/helpers';
import { first } from 'rxjs';
import { ErrorService } from 'src/app/core/error.service';
import { StationService } from 'src/app/core/station.service';
import {
  StationBucketQuestion,
  LibraryQuestionStation,
  OriginTypeBucket,
} from 'src/models';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import {
  NgxPopperjsModule,
  NgxPopperjsPlacements,
  NgxPopperjsTriggers,
} from 'ngx-popperjs';
import { StationGroupService } from 'src/app/core/station-group.service';
import { LibraryService } from 'src/app/core/library.service';

/** Interface dialog data. */
interface MatData {
  /** Station bucket questions from bucket. */
  bucketQuestion: StationBucketQuestion[];
  /** Station name. */
  stationName: string;
  /** Station Rithm Id. */
  stationRithmId: string;
}

/**
 * Component for station bucket modal.
 */
@Component({
  selector: 'app-station-bucket-modal',
  templateUrl: './station-bucket-modal.component.html',
  styleUrls: ['./station-bucket-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LoadingIndicatorComponent,
    MatButtonModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatTableModule,
    MatCheckboxModule,
    NgxPopperjsModule,
  ],
})
export class StationBucketModalComponent implements OnInit {
  /** Component Helper. */
  componentHelper = ComponentHelper;

  /* System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** List of station bucket questions. */
  stationBucketQuestions: StationBucketQuestion[] = [];

  /** List of Question Stations of the current question. */
  questionStations: LibraryQuestionStation[] = [];

  /* Origin Type Bucket. */
  originTypeBucket = OriginTypeBucket;

  /** Enum for define orientation popover.*/
  popperPlacement = NgxPopperjsPlacements;

  /** Enum for define event popover.*/
  popperTrigger = NgxPopperjsTriggers;

  /** Data from the table about the bucket question. */
  dataSource = new MatTableDataSource<StationBucketQuestion>(
    this.stationBucketQuestions,
  );

  /** Contains the selected bucket questions. */
  selection = new SelectionModel<StationBucketQuestion>(true, []);

  /** Table information. */
  displayedColumns: string[] = [
    'check',
    'Field',
    'OnGrid',
    'Origin',
    'UpdatedAt',
    'Actions',
  ];

  /** Style popper. */
  style = {
    'z-index': 1000,
    'background-color': '#fff',
    'min-width': '200px',
    'max-width': '250px',
    'min-height': '40px',
    'max-height': '100px',
    padding: '5px',
    border: 0,
    'box-shadow': '0 0 5px 3px #bbbbbb',
  };

  /** Name of current station. */
  stationName = '';

  /** RithmId of current station. */
  stationRithmId = '';

  /** RithmId of the station group if it exists. */
  stationGroupRithmId = '';

  /** Whether the current user is a Group admin in the current station. */
  isGroupAdmin = false;

  /** Whether the request to get question stations is underway. */
  questionStationsLoading = false;

  /** Modal Loading Indicator. */
  modalLoading = false;

  /** Modal Loading Indicator. */
  bucketLoading = false;

  /** Whether the bucket has been updated to notify the afterClose() . */
  bucketHasBeenUpdated = false;

  constructor(
    private dialogRef: MatDialogRef<StationBucketModalComponent>,
    private stationService: StationService,
    private libraryService: LibraryService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public matData: MatData,
    private stationGroupService: StationGroupService,
  ) {
    this.stationName = matData.stationName;
    this.stationRithmId = matData.stationRithmId;
  }

  /**
   * Life cycle init the component.
   */
  ngOnInit(): void {
    this.getStationBucketQuestions();
  }

  /**
   * Retrieve the Station Group Path.
   */
  private getStationGroupPath(): void {
    this.modalLoading = true;
    this.stationService
      .getStationGroupPath(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (stationGroupData) => {
          if (stationGroupData && stationGroupData.groupPath.length) {
            this.stationGroupRithmId = stationGroupData.groupPath[0].rithmId;
            this.getIsStationGroupAdmin();
          } else {
            this.displayedColumns.shift();
            this.dataSource.data = this.stationBucketQuestions;
            this.modalLoading = false;
          }
        },
        error: (error: unknown) => {
          this.modalLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Return if the user is an station group admin or not.
   */
  private getIsStationGroupAdmin(): void {
    this.stationGroupService
      .getIsStationGroupAdmin(this.stationGroupRithmId)
      .pipe(first())
      .subscribe({
        next: (isStationGroupAdmin) => {
          this.isGroupAdmin = isStationGroupAdmin;
          if (!this.isGroupAdmin) {
            this.displayedColumns.shift();
          }
          this.dataSource.data = this.stationBucketQuestions;
          this.modalLoading = false;
        },
        error: (error: unknown) => {
          this.modalLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Get's list of station bucket questions.
   *
   */
  private getStationBucketQuestions(): void {
    this.bucketLoading = true;
    this.stationService
      .getStationBucketQuestions(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (bucketQuestions) => {
          this.stationBucketQuestions = bucketQuestions;
          this.getStationGroupPath();
          this.bucketLoading = false;
        },
        error: (error: unknown) => {
          this.bucketLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Close the modal.
   */
  closeModal(): void {
    this.dialogRef.close({
      data: this.stationBucketQuestions,
      bucketHasBeenUpdated: this.bucketHasBeenUpdated,
    });
  }

  /**
   * Get All the stations where a question is shared.
   * @param questionRithmId The specific question id.
   */
  getLibraryQuestionAtStations(questionRithmId: string): void {
    this.questionStationsLoading = true;
    this.libraryService
      .getLibraryQuestionAtStations(questionRithmId)
      .pipe(first())
      .subscribe({
        next: (questionStations) => {
          this.questionStations = questionStations;
          this.questionStationsLoading = false;
        },
        error: (error: unknown) => {
          this.questionStationsLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Whether the number of selected elements matches the total number of rows.
   * @returns Whether isAllSelected.
   */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    /** Only questions that are not previous question will be selected. */
    this.selection.select(
      ...this.dataSource.data.filter((q) => !this.disableBucketQuestion(q)),
    );
  }

  /**
   * The label for the checkbox on the passed row.
   * @param row The current Question.
   * @returns Question Prompt.
   */
  checkboxLabel(row?: StationBucketQuestion): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.prompt + 1
    }`;
  }

  /**
   * Disable question from another station in the bucket.
   * @param row Column in progress.
   * @returns A true or false.
   */
  disableBucketQuestion(row: StationBucketQuestion): boolean {
    return (
      row.originType === OriginTypeBucket.Previous ||
      row.origin.trim().toLowerCase() !== this.stationName.trim().toLowerCase()
    );
  }

  /**
   * Delete the bucket question.
   * @param rithmId The Specific id for the question type.
   */
  removeStationBucketQuestions(rithmId?: string): void {
    const bucketQuestions: string[] = [];
    this.modalLoading = true;

    if (rithmId) {
      bucketQuestions.push(rithmId);
    } else {
      this.selection.selected.forEach((stBucket) =>
        bucketQuestions.push(stBucket.rithmId),
      );
    }
    const questionsId = { data: bucketQuestions };
    this.stationService
      .removeStationBucketQuestions(this.stationRithmId, questionsId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.bucketHasBeenUpdated = true;
          bucketQuestions.forEach((question) => {
            this.stationBucketQuestions.splice(
              this.stationBucketQuestions.findIndex(
                (questionBucket) => questionBucket.rithmId === question,
              ),
              1,
            );
          });
          this.dataSource.data = this.stationBucketQuestions;
          this.selection.clear();
          this.modalLoading = false;
        },
        error: (error: unknown) => {
          this.modalLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Search filter in the table.
   * @param event Catches the event in the search.
   */
  filterBucket(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * TrackBy in table.
   * @param index Number of index.
   * @returns Item id index the element.
   */
  trackBy(index: number): string {
    return index.toString();
  }
}
