<section class="action-container">
  <section class="action-chips flex flex-col w-full overflow-x-auto scrollbar">
    <p
      [ngClass]="
        orderOfOperations() ? 'action-title' : 'title-from-order-operation'
      "
    >
      @if (!orderOfOperations()) {
        <ng-container>Action: </ng-container>
      }

      Rithm AI
    </p>

    <section class="mt-3">
      <p class="sub-section-title">Model:</p>
      <mat-chip-set class="custom-chips">
        <mat-chip>
          <span class="label-chip">
            {{ actionModelLabel() }}
          </span>
        </mat-chip>
      </mat-chip-set>
    </section>

    @if (imageSettingsLabels().length) {
      <section class="mt-2">
        <p class="sub-section-title">Image settings:</p>
        <mat-chip-set class="custom-chips">
          @for (label of imageSettingsLabels(); track $index) {
            <mat-chip>
              <span class="label-chip">
                {{ label }}
              </span>
            </mat-chip>
          }
        </mat-chip-set>
      </section>
    }

    <section class="mt-2">
      <p class="sub-section-title">Response field:</p>
      <mat-chip-set class="custom-chips">
        <mat-chip>
          <i
            class="icon-chip"
            [ngClass]="
              componentHelper.componentIcon(responseField()?.questionType || '')
            "
          ></i>
          <span class="label-chip">
            {{ responseField()?.prompt }}
          </span>
        </mat-chip>
      </mat-chip-set>
    </section>

    <section class="mt-2">
      <p class="sub-section-title">Prompt:</p>
      <mat-chip-set class="custom-chips">
        @for (promptItem of actionData().prompt; track $index) {
          <mat-chip>
            @if (
              componentHelper.getQuestionByFieldReference(
                promptItem,
                bucketQuestions(),
                action().type
              )
            ) {
              <i
                class="icon-chip"
                [ngClass]="
                  componentHelper.componentIcon(
                    componentHelper.getQuestionByFieldReference(
                      promptItem,
                      bucketQuestions(),
                      action().type
                    )?.questionType || ''
                  )
                "
              ></i>
            }
            <span class="label-chip">
              {{
                componentHelper.getQuestionByFieldReference(
                  promptItem,
                  bucketQuestions(),
                  action().type
                )?.prompt || promptItem
              }}
            </span>
          </mat-chip>
        }
      </mat-chip-set>
    </section>
  </section>

  <button
    mat-icon-button
    type="button"
    [matMenuTriggerFor]="actionOptions"
    id="rithm-ai-action-settings"
    data-testid="rithm-ai-action-settings"
    class="text-lg"
  >
    <i class="fa-light fa-ellipsis-h"></i>
  </button>
  <mat-menu #actionOptions="matMenu" xPosition="before">
    <button
      mat-menu-item
      type="button"
      class="text-md"
      data-testid="edit-rithm-ai-action"
      id="edit-rithm-ai-action"
      (click)="emitActionEvent(powerEventType.Update)"
    >
      <span class="p-4">Edit action</span>
    </button>

    @if (orderOfOperations()) {
      <button
        mat-menu-item
        type="button"
        class="text-md"
        data-testid="duplicate-rithm-ai-action"
        id="duplicate-rithm-ai-action"
        (click)="emitActionEvent(powerEventType.Duplicate)"
      >
        <span class="p-4">Duplicate action</span>
      </button>
    }
    <button
      mat-menu-item
      type="button"
      class="text-md"
      data-testid="delete-rithm-ai-action"
      id="delete-rithm-ai-action"
      (click)="emitActionEvent(powerEventType.Delete)"
    >
      <span class="p-4 text-error-500">Delete action</span>
    </button>
  </mat-menu>
</section>
