import { Component, Input } from '@angular/core';
import { ConnectedStationInfo, PowerAction } from 'src/models';
import { FlowContainerItemActionComponent } from './flow-container-item-action/flow-container-item-action.component';
import { FlowContainerFormActionComponent } from './flow-container-form-action/flow-container-form-action.component';
import { CommonModule } from '@angular/common';

/**
 * Flow container action.
 */
@Component({
  selector: 'app-flow-container-action',
  standalone: true,
  imports: [
    CommonModule,
    FlowContainerItemActionComponent,
    FlowContainerFormActionComponent,
  ],
  templateUrl: './flow-container-action.component.html',
  styleUrl: './flow-container-action.component.scss',
})
export class FlowContainerActionComponent {
  /** Stations. */
  @Input() stations: ConnectedStationInfo[] = [];

  /** Power action. */
  @Input() action!: PowerAction;

  /** Rule power. */
  @Input({ required: true }) ruleRithmId = '';

  /** Feature flag order of operations. */
  @Input({ required: true }) orderOfOperations = false;

  /** Total actions. */
  @Input({ required: true }) totalActions = 0;

  /** Whether is edit action. */
  isEdit = false;
}
