import { Component, effect, input, OnDestroy, output } from '@angular/core';
import {
  ActionType,
  AiAction,
  FieldDataType,
  FieldsGroup,
  Question,
  QuestionFieldType,
  StationFieldPrefix,
} from 'src/models';
import { ComponentHelper, ConditionHelper, TermsGeneric } from 'src/helpers';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { NgxSearchComponent } from 'src/app/shared/ngx-search/ngx-search.component';

/** The Select Prompt Field Component. */
@Component({
  selector: 'app-select-prompt-field',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxSearchComponent,
  ],
  templateUrl: './select-prompt-field.component.html',
  styleUrl: './select-prompt-field.component.scss',
})
export class SelectPromptFieldComponent implements OnDestroy {
  /** The terms Generic. */
  termsGeneric = TermsGeneric;

  /** Component Helper. */
  componentHelper = ComponentHelper;

  /** Action Type. */
  actionType = ActionType;

  /** Station Field Prefix. */
  stationFieldPrefix = StationFieldPrefix;

  /** All the bucket questions. */
  bucketQuestions = input.required<Question[]>();

  /** The Selected Model. */
  selectedModel = input.required<AiAction>();

  /** Prompt Value. */
  promptValue = input<string>('');

  /** It can be removed. */
  canBeRemoved = input<boolean>(false);

  /** Prompt Value output. */
  promptValueOutput = output<string>();

  /** Remove Prompt.*/
  removePrompt = output<void>();

  /** The destroyed. */
  destroyed$ = new Subject<void>();

  /** The show custom value. */
  showCustomValue = false;

  /** Filtered questions. */
  filteredQuestions: FieldsGroup = {
    /** Fields. */
    fields: [],
    /** Container info questions. */
    containerInfo: [],
    /** Station info questions. */
    stationInfo: [],
  };

  /** The field for updating the filtered data in the selection ngx-search. */
  fieldDataFiltered!: FieldDataType;

  /** List of fields no included in select. */
  readonly areNotFieldInSelect = [
    QuestionFieldType.ParentStationLink,
    QuestionFieldType.ParentContainerLink,
  ];

  /** List of container info fields in select. */
  readonly areContainerInfoFieldsInSelect = [
    QuestionFieldType.AssignedUser,
    QuestionFieldType.ContainerName,
    QuestionFieldType.StationName,
    QuestionFieldType.StationId,
    QuestionFieldType.ContainerId,
    QuestionFieldType.ParentContainerRithmId,
    QuestionFieldType.TimeInStation,
    QuestionFieldType.CreatedOn,
    QuestionFieldType.CreatedBy,
    QuestionFieldType.PresentTime,
  ];

  /** Prompt form. */
  promptForm = new FormGroup({
    selectPromptValue: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    customPromptValue: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor() {
    effect(() => {
      this.filteredQuestionsInFunction();
    });

    effect(() => {
      if (this.promptValue()) {
        this.setPromptValue();
      }
    });
    this.subscriptionPromptForm();
  }

  /**
   * Set the prompt value.
   */
  setPromptValue(): void {
    this.showCustomValue = !ComponentHelper.isFieldReference(
      this.promptValue(),
    );

    if (this.showCustomValue) {
      this.promptForm.controls.customPromptValue.setValue(this.promptValue());
    } else {
      this.promptForm.controls.selectPromptValue.setValue(this.promptValue());
    }
  }

  /**
   * Subscription Prompt Form.
   */
  subscriptionPromptForm(): void {
    this.promptForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => {
        if (this.showCustomValue) {
          this.promptValueOutput.emit(
            this.promptForm.controls.customPromptValue.value,
          );
        } else {
          this.promptValueOutput.emit(
            this.promptForm.controls.selectPromptValue.value,
          );
        }
      },
    });
  }

  /**
   * Filtered questions.
   */
  filteredQuestionsInFunction(): void {
    this.filteredQuestions.fields = ConditionHelper.getStationQuestions(
      this.bucketQuestions(),
    ).filter(
      ({ questionType }) => !this.areNotFieldInSelect.includes(questionType),
    );
    this.filteredQuestions.containerInfo = this.bucketQuestions().filter(
      ({ questionType }) =>
        this.areContainerInfoFieldsInSelect.includes(questionType),
    );
    this.filteredQuestions.stationInfo = [
      ComponentHelper.getSpecialField(QuestionFieldType.ContainerCount),
    ];
  }

  /**
   * Reset the added value in case of clicking the button (bucket or custom).
   */
  resetPromptValue(): void {
    this.showCustomValue = !this.showCustomValue;
    this.promptForm.reset();
    this.promptValueOutput.emit('');
  }

  /** The life cycle. */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
