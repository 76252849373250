import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionType,
  PowerAction,
  StationLibraryRelationship,
  UpdateRelationshipAction,
} from 'src/models';
import { TermsGeneric } from 'src/helpers';
import { MatChipsModule } from '@angular/material/chips';

/**
 * Represents an item for relationship actions added.
 */
@Component({
  selector: 'app-update-relationship-item[action]',
  standalone: true,
  imports: [CommonModule, MatChipsModule],
  templateUrl: './update-relationship-item.component.html',
  styleUrls: ['./update-relationship-item.component.scss'],
})
export class UpdateRelationshipItemComponent implements OnInit {
  /** Contains the current displayed action. */
  @Input() action!: PowerAction;

  /** Station Libraries.*/
  @Input() libraryRelationships: StationLibraryRelationship[] = [];

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Project Glossary. */
  termsGeneric = TermsGeneric;

  /** Type of actions. */
  actionTypesEnum = ActionType;

  /** Data action. */
  updateRelationshipAction!: UpdateRelationshipAction;

  /**
   * Init life cycle.
   */
  ngOnInit(): void {
    this.updateRelationshipAction = JSON.parse(
      this.action.data,
    ) as UpdateRelationshipAction;
  }

  /**
   * Get a clean label.
   * @param relation Relation label.
   * @returns Clean relation.
   */
  cleanRelationLabel(relation: string): string {
    return relation.split('[')[0];
  }

  /**
   * Get relations to show.
   * @param action Update Relationships.
   * @returns Relations as array to show.
   */
  getRelationshipTypes(action: PowerAction): string[] {
    if (action.data) {
      const loadedRelations: string[] = [];
      const updateRelationshipAction = JSON.parse(
        action.data,
      ) as UpdateRelationshipAction;
      updateRelationshipAction.relationships.forEach((relation) => {
        const relationshipRithmId = relation.relationshipRithmId;
        this.libraryRelationships.forEach((libraryRelation) => {
          libraryRelation.relationships.forEach((rela) => {
            if (rela.rithmId === relationshipRithmId) {
              if (relation.inward) {
                const relaTionToAdd =
                  rela.inwardName + '[' + rela.rithmId + ']_inwardName';
                loadedRelations.push(relaTionToAdd);
              }
              if (relation.outward) {
                const relaTionToAdd =
                  rela.outwardName + '[' + rela.rithmId + ']_outwardName';
                loadedRelations.push(relaTionToAdd);
              }
            }
          });
        });
      });
      return loadedRelations;
    } else {
      return [];
    }
  }
}
