import {
  AfterViewInit,
  Directive,
  input,
  model,
  OnDestroy,
  output,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';

/** Directive for emit event for mat select infinity scroll. */
@Directive({
  selector: '[appMatSelectInfinityScroll]',
  standalone: true,
})
export class MatSelectInfinityScrollDirective
  implements AfterViewInit, OnDestroy
{
  /** Subject for when the directive is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Tag id for scroll in element.*/
  classStyleNameScroll = model<string>();

  /** Buffer for emit event corresponding if is down o up event. */
  bufferScroll = input<number>(250);

  /** Output for executed new pagination for list down. */
  onScrollDown = output<boolean>();

  /** Output for executed new pagination for list up. */
  onScrollUp = output<boolean>();

  /** Last value for compare and emit event correctly. */
  lastEventValue = 0;

  constructor(private select: MatSelect) {}

  /**
   * Listen event scroll for the current matSelect.
   */
  ngAfterViewInit(): void {
    // Current element where the class is positioned.
    let currentElement: HTMLCollectionOf<HTMLDivElement> | null = null;
    if (this.classStyleNameScroll() !== '') {
      currentElement = document.getElementsByClassName(
        this.classStyleNameScroll() || '',
      ) as HTMLCollectionOf<HTMLDivElement>;
    }
    // When the select has been opened.
    this.select.openedChange.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      setTimeout(() => {
        if (currentElement) {
          for (let i = 0; i < currentElement.length; i++) {
            currentElement[i].style.overflowY = 'hidden';
            currentElement[i].style.overflowX = 'hidden';
          }
        }
        if (this.select.panel) {
          // Listen to scroll changes.
          this.select.panel.nativeElement.addEventListener(
            'scroll',
            (event: Event) => {
              this.validateScroll(event);
            },
          );
        }
      }, 300);
    });

    /* Only subscribe whether there is a element to add scroll. */
    if (this.classStyleNameScroll()) {
      // When the select has been closed.
      this.select._closedStream
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          if (currentElement) {
            for (let i = 0; i < currentElement.length; i++) {
              currentElement[i].style.overflowY = 'scroll';
              currentElement[i].style.overflowX = 'scroll';
            }
          }
        });
    }
  }

  /**
   * Validate scroll for executed new petition.
   * @param scroll Event of callback scroll.
   */
  private validateScroll(scroll: Event): void {
    const target = scroll?.target as HTMLElement;
    const elementScrollHeight = target?.scrollHeight;
    const elementViewHeight = target?.offsetHeight;
    const scrollLocation = target?.scrollTop;
    const limit = elementScrollHeight - elementViewHeight - this.bufferScroll();
    if (scrollLocation > limit && this.lastEventValue < scrollLocation) {
      this.onScrollDown.emit(true);
    }
    if (
      scrollLocation < this.bufferScroll() &&
      this.lastEventValue > scrollLocation
    ) {
      this.onScrollUp.emit(true);
    }
    this.lastEventValue = scrollLocation;
  }

  /**
   * Cleaning method for the directive.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
