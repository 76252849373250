<div class="mx-8 overflow-auto scrollbar max-h-[300px]">
  <!-- Testing -->
  @if (typeLog === 0) {
    @for (script of dataScriptTesting; track $index) {
      <div
        [ngClass]="{
          'bg-secondary-50': isLoadingTesting[$index],
          'bg-log-error':
            !isLoadingTesting[$index] &&
            !script?.scriptExecutions?.at(0)?.success,
          'bg-log-success':
            !isLoadingTesting[$index] &&
            script?.scriptExecutions?.at(0)?.success
        }"
        class="flex justify-between items-center p-2"
      >
        @if (isLoadingTesting[$index]) {
          <div class="flex justify-between spinner-log-warning">
            <mat-spinner diameter="22" color="accent" />
            <p class="pl-2">Running Test</p>
          </div>
        }
        @if (!isLoadingTesting[$index]) {
          <div class="flex items-center">
            <i
              [ngClass]="
                script?.scriptExecutions?.at(0)?.success
                  ? 'fa-circle-check text-log-success'
                  : 'fa-circle-xmark text-log-error'
              "
              class="fa-solid text-xl"
            ></i>
            <p class="pl-2">
              {{
                script?.scriptExecutions?.at(0)?.success
                  ? 'File Passed!'
                  : 'File Error!'
              }}
            </p>
          </div>
        }
        <p class="text-secondary-500">
          {{ script.fileName }}
        </p>
      </div>
    }
  }

  <!-- Console -->
  @if (typeLog === 1) {
    @for (script of dataScriptTesting; track $index) {
      @for (logScript of getScriptLogs(script); track $index) {
        <div
          [ngClass]="{
            'bg-log-info':
              logScript.historyType === enumScriptHistoryType.Console,
            'bg-log-error':
              logScript.historyType === enumScriptHistoryType.Error,
            'bg-log-warning':
              logScript.historyType === enumScriptHistoryType.Warning
          }"
          class="flex justify-between items-center p-2"
        >
          <div class="flex items-center">
            <i
              [ngClass]="{
                'fa-circle-xmark text-log-error':
                  logScript.historyType === enumScriptHistoryType.Error,
                'fa-triangle-exclamation text-log-warning':
                  logScript.historyType === enumScriptHistoryType.Warning,
                'fa-circle-info text-log-info':
                  logScript.historyType === enumScriptHistoryType.Console
              }"
              class="fa-solid text-xl"
            ></i>
            <div class="pl-2">
              <p
                class="text-sm font-semibold"
                [ngClass]="{
                  'text-log-error':
                    logScript.historyType === enumScriptHistoryType.Error,
                  'text-log-warning':
                    logScript.historyType === enumScriptHistoryType.Warning,
                  'text-log-info':
                    logScript.historyType === enumScriptHistoryType.Console
                }"
              >
                {{ logScript.message }}
              </p>
              <p class="text-sm">line {{ logScript.lineNumber }}</p>
            </div>
          </div>
          <p class="text-secondary-500">
            {{ script.fileName }}
          </p>
        </div>
      }
    }

    <div class="p-2">
      <i class="fa-solid fa-chevron-right text xl text-accent-600"></i>
    </div>
  }

  <!-- History -->
  @if (typeLog === 2) {
    @for (script of historyWithFilter; track $index) {
      <div
        [ngClass]="{
          'bg-log-error': !script.success,
          'bg-log-success': script.success
        }"
        class="flex items-center p-2"
      >
        <span
          (click)="expansionHistory[$index] = !expansionHistory[$index]"
          class="cursor-pointer"
        >
          <i
            [ngClass]="
              script.success
                ? 'fa-circle-check text-log-success'
                : 'fa-circle-xmark text-log-error'
            "
            class="fa-solid text-xl"
          ></i>
          <i
            [ngClass]="
              expansionHistory[$index] ? 'fa-caret-down' : 'fa-caret-right'
            "
            class="fa-solid text-xl text-secondary-500 pl-2"
          ></i>
        </span>
        <p class="pl-2">
          {{ script.containerName || scriptName }}
        </p>
      </div>
      <!-- Console logs -->
      @if (expansionHistory[$index]) {
        <div class="mb-2 mx-2">
          @for (logScript of script.scriptTestLogs; track $index) {
            <div
              [ngClass]="{
                'bg-log-info':
                  logScript.historyType === enumScriptHistoryType.Console,
                'bg-log-error':
                  logScript.historyType === enumScriptHistoryType.Error,
                'bg-log-warning':
                  logScript.historyType === enumScriptHistoryType.Warning
              }"
              class="flex justify-between items-center p-2"
            >
              <div class="flex items-center">
                <i
                  [ngClass]="{
                    'fa-circle-xmark text-log-error':
                      logScript.historyType === enumScriptHistoryType.Error,
                    'fa-triangle-exclamation text-log-warning':
                      logScript.historyType === enumScriptHistoryType.Warning,
                    'fa-circle-info text-log-info':
                      logScript.historyType === enumScriptHistoryType.Console
                  }"
                  class="fa-solid text-xl"
                ></i>
                <p
                  class="pl-2 text-sm font-semibold"
                  [ngClass]="{
                    'text-log-error':
                      logScript.historyType === enumScriptHistoryType.Error,
                    'text-log-warning':
                      logScript.historyType === enumScriptHistoryType.Warning,
                    'text-log-info':
                      logScript.historyType === enumScriptHistoryType.Console
                  }"
                >
                  {{ logScript.message }}
                </p>
              </div>
              <p class="text-secondary-500">line {{ logScript.lineNumber }}</p>
            </div>
          }
        </div>
      }
    }
  }
</div>
