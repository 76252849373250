<section
  class="modal-container flex flex-col h-full min-h-[600px] max-h-[860px] w-full p-4"
>
  <!-- Modal header -->
  <section class="modal-title flex flex-col gap-4">
    <span class="text-xl font-semibold tracking-wide">Advanced Search:</span>
    <span class="text-primary-300 text-sm">
      Search for things like {{ termsGeneric.Group.Plural | lowercase }},
      {{ termsGeneric.Station.Plural | lowercase }}, teams to send notifications
      to specific member in different areas of your org.
    </span>
  </section>

  <!-- Modal content area -->
  <section mat-dialog-content class="modal-content !px-0 !py-4">
    <section class="notification-recipients w-full primary-search pt-2">
      <mat-form-field
        class="w-full"
        appearance="outline"
        subscriptSizing="dynamic"
      >
        @if (!selectedFilterType.length) {
          <span matPrefix>
            <i class="fa-light fa-search p-1 text-secondary-300"></i
          ></span>
        }

        <mat-chip-grid #chipList>
          @for (search of selectedFilterType; track $index) {
            <mat-chip
              [removable]="removable"
              (removed)="removeSearchFilter(search.rithmId)"
            >
              <div class="flex items-center space-x-2">
                <ng-container
                  *ngTemplateOutlet="
                    memberOption;
                    context: { $implicit: search }
                  "
                ></ng-container>
                <button
                  matChipRemove
                  type="button"
                  [attr.data-testid]="'remove-search-' + search.rithmId"
                  [attr.id]="'remove-search-' + search.rithmId"
                >
                  <i class="fa fa-times-circle-o"></i>
                </button>
              </div>
            </mat-chip>
          }

          <input
            matInput
            #searchInput
            [matChipInputFor]="chipList"
            id="search-filter"
            data-testid="search-filter"
            [formControl]="primarySearchControl"
            [matAutocomplete]="auto"
            placeholder="{{ !selectedFilterType.length ? 'search' : '' }}"
            (keyup)="searchFilter()"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectFilterType($event.option.value)"
          [hideSingleSelectionIndicator]="true"
          id="select-filter"
          data-testid="select-filter"
        >
          <div class="w-full h-full overflow-auto scrollbar">
            @if (!userLoading && userAssociateSearch.length) {
              @for (search of userAssociateSearch; track $index) {
                <mat-option
                  [value]="search"
                  [disabled]="isDisabledFilter(search.rithmId)"
                >
                  <div class="flex items-center space-x-2">
                    <ng-container
                      *ngTemplateOutlet="
                        memberOption;
                        context: { $implicit: search }
                      "
                    ></ng-container>
                  </div>
                </mat-option>
              }
            }
            @if (
              userLoading ||
              (primarySearchControl.value &&
                !userAssociateSearch.length &&
                !userLoading &&
                !isErrorGetUser)
            ) {
              <mat-option class="pointer-events-none center-loading">
                <ng-template
                  [ngTemplateOutlet]="userLoading ? loadingMemberList : noMatch"
                ></ng-template>
              </mat-option>
            }
            @if (isErrorGetUser) {
              <mat-option>
                <span class="text-sm text-error-500">
                  -- Error getting user associate --
                </span>
              </mat-option>
            }
          </div>
        </mat-autocomplete>
      </mat-form-field>
    </section>
    @if (showMembersList) {
      <section class="w-full member-search pt-2">
        <mat-form-field
          class="w-full"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <span matPrefix> To: </span>
          <mat-chip-grid #memberChipList>
            @for (member of selectedMembers; track $index) {
              <mat-chip
                [removable]="removable"
                (removed)="removeMember(member.rithmId)"
              >
                <section class="w-full flex items-center space-x-2">
                  <ng-container
                    *ngTemplateOutlet="
                      memberOption;
                      context: { $implicit: member }
                    "
                  ></ng-container>
                  <button
                    matChipRemove
                    type="button"
                    [attr.data-testid]="'remove-member-' + member.rithmId"
                    [attr.id]="'remove-member-' + member.rithmId"
                  >
                    <i class="fa fa-times-circle-o"></i>
                  </button>
                </section>
              </mat-chip>
            }

            <input
              matInput
              #memberInput
              id="search-member"
              data-testid="search-member"
              [matChipInputFor]="memberChipList"
              [formControl]="memberSearchControl"
              [matAutocomplete]="autoMember"
              [ngClass]="{ 'animate-pulse': searchLoading }"
              placeholder="{{ !selectedFilterType.length ? 'search' : '' }}"
              (keyup)="memberSearchFilter()"
            />
          </mat-chip-grid>
          <mat-autocomplete
            appInfinityScrollAutocomplete
            #autoMember="matAutocomplete"
            [classStyleNameDisableScroll]="
              'disable-scroll-when-opened-autocomplete'
            "
            [hideSingleSelectionIndicator]="true"
            [bufferScroll]="1"
            (onScrollDown)="getMemberList()"
            (optionSelected)="selectMembers($event.option)"
            (opened)="(!recipientLists.users.length)"
            data-testid="members-add-recipient"
            id="members-add-recipient"
            class="scrollbar custom-mat-option"
          >
            @if (recipientLists && !userLoading) {
              @if (recipientLists.users.length) {
                <mat-option
                  [disabled]="isDisabledMember(selectedFilterType[0].rithmId)"
                >
                  <div class="flex items-center pl-4 space-x-2">
                    <i
                      class="text-secondary-300"
                      [ngClass]="
                        componentHelper.originIcon(selectedFilterType[0].type)
                      "
                    ></i>
                    <ng-container
                      *ngTemplateOutlet="
                        teamMembers;
                        context: { $implicit: 'All' }
                      "
                    ></ng-container>
                  </div>
                </mat-option>
              }

              @if (recipientLists.teams?.length) {
                <mat-optgroup label="Team">
                  @for (team of recipientLists.teams; track $index) {
                    <mat-option
                      [disabled]="
                        isDisabledMember(team.rithmId) ||
                        isDisabledMember(selectedFilterType[0].rithmId)
                      "
                      [attr.data-testid]="'team-selected' + team.rithmId"
                      [attr.id]="'team-selected' + team.rithmId"
                      [value]="team"
                    >
                      <div class="flex items-center space-x-2">
                        <ng-container
                          *ngTemplateOutlet="
                            memberOption;
                            context: { $implicit: team }
                          "
                        ></ng-container>
                      </div>
                    </mat-option>
                  }
                </mat-optgroup>
              }
              @if (recipientLists.users.length) {
                <mat-optgroup label="Member">
                  @for (member of recipientLists.users; track $index) {
                    <mat-option
                      [disabled]="
                        isDisabledMember(member.rithmId) ||
                        isDisabledMember(selectedFilterType[0].rithmId)
                      "
                      [attr.data-testid]="'member-selected' + member.rithmId"
                      [attr.id]="'member-selected' + member.rithmId"
                      [value]="member"
                    >
                      <div class="flex items-center">
                        <ng-template
                          *ngTemplateOutlet="
                            avatarImage;
                            context: { data: member }
                          "
                        ></ng-template>
                        <span class="pl-2">{{
                          member.firstName + ' ' + member.lastName
                        }}</span>
                      </div>
                    </mat-option>
                  }
                </mat-optgroup>
              }
            } @else {
              <ng-container *ngTemplateOutlet="showLoading" />
            }

            <ng-template #showLoading>
              <mat-option class="pointer-events-none center-loading">
                <ng-container
                  *ngTemplateOutlet="loadingMemberList"
                ></ng-container>
              </mat-option>
            </ng-template>
            @if (
              !userLoading &&
              (searchLoading || !recipientLists.users.length || isErrorGetUser)
            ) {
              <mat-option class="pointer-events-none center-loading">
                @if (searchLoading) {
                  <ng-container *ngTemplateOutlet="loadingMemberList" />
                }
                @if (
                  !recipientLists.users.length &&
                  !searchLoading &&
                  !isErrorGetUser
                ) {}
                <ng-container *ngTemplateOutlet="noMatch"></ng-container>

                @if (isErrorGetUser) {
                  <span class="text-sm text-error-500">
                    -- Error getting members --
                  </span>
                }
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </section>
    }
  </section>

  <!-- Modal footer area -->
  <mat-dialog-actions align="end">
    <button
      mat-button
      type="button"
      class="font-semibold uppercase"
      color="accent"
      id="close-modal-button"
      data-testid="close-modal-button"
      [mat-dialog-close]="true"
    >
      Close
    </button>
    <button
      mat-raised-button
      type="button"
      color="accent"
      class="font-semibold uppercase"
      id="done-modal-button"
      data-testid="done-modal-button"
      (click)="closeModal()"
      [disabled]="!selectedMembers.length"
    >
      Done
    </button>
  </mat-dialog-actions>
</section>

<!--Icon type.-->
<ng-template #memberOption let-member>
  @if (showIcon(member)) {
    <i
      [ngClass]="componentHelper.originIcon(member.type)"
      class="text-secondary-300"
    ></i>
  } @else {
    <ng-container
      [ngTemplateOutletContext]="{
        data: member
      }"
      [ngTemplateOutlet]="avatarImage"
    />
  }
  <p>{{ member.name }}</p>
</ng-template>

<!-- Team members.-->
<ng-template #teamMembers let-team>
  <span class="pl-2"> {{ team }}</span>
  <i class="fa-solid fa-circle text-[5px] text-primary-200"></i>
  <span class="text-xs text-primary-200 tracking-wide"
    >{{ recipientLists.totalMembers }} Members</span
  >
</ng-template>

<!-- Avatar profile image field-->
<ng-template #avatarImage let-data="data">
  <app-user-avatar
    [firstName]="data.firstName || ''"
    [lastName]="data.lastName || ''"
    [size]="'20'"
    [profileImageRithmId]="data.profileImageRithmId || ''"
  />
</ng-template>

<!-- Loading member. -->
<ng-template #loadingMemberList>
  <app-loading-indicator
    id="loading-member-list"
    data-testid="loading-member-list"
    [inlineText]="'Loading..'"
  />
</ng-template>

<!-- No match found.-->
<ng-template #noMatch>
  <span class="text-sm text-primary-300">-- No match found --</span>
</ng-template>
