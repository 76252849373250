import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { ChatGptActionInfo, PowerAction } from 'src/models';

/** Chat GPT action item. */
@Component({
  selector: 'app-chat-gpt-item',
  templateUrl: './chat-gpt-item.component.html',
  styleUrls: ['./chat-gpt-item.component.scss'],
  standalone: true,
  imports: [CommonModule, MatChipsModule],
})
export class ChatGptItemComponent implements OnInit {
  /** Chat GPT object. */
  @Input() integration!: PowerAction;

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** The prompt text coming from the integration data. */
  promptText = '';

  /** The text of the response coming from the integration data. */
  responseText = '';

  /** Whether or not the action is customized. */
  isCustomAction = false;

  /**
   * Life cycle init the component.
   */
  ngOnInit(): void {
    this.setPromptText();
    this.setResponseText();
  }

  /**
   * Sets the prompt field text.
   */
  private setPromptText(): void {
    const validatedJson = this.integration.data.replace(/'/g, ' ');
    const chatGptAction = JSON.parse(validatedJson) as ChatGptActionInfo;
    this.promptText = chatGptAction.isCustomText
      ? chatGptAction.prompt
      : chatGptAction.promptDetails?.prompt || '';
    this.isCustomAction = chatGptAction.isCustomText;
  }

  /**
   * Sets the response field text.
   */
  private setResponseText(): void {
    const validatedJson = this.integration.data.replace(/'/g, ' ');
    const chatGptAction = JSON.parse(validatedJson) as ChatGptActionInfo;
    this.responseText = chatGptAction.responseDetails.prompt;
  }
}
