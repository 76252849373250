import { Component, input, output, signal } from '@angular/core';
import { CalculationActionFormComponent } from 'src/app/station/rules/actions/calculation-action/calculation-action-form/calculation-action-form.component';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import {
  ActionType,
  type PowerAction,
  type Question,
  type CalculationData,
  StationFieldPrefix,
} from 'src/models';
import { MatButtonModule } from '@angular/material/button';
import { CalculationHintComponent } from 'src/app/station/rules/actions/calculation-action/calculation-hint/calculation-hint.component';
import { PowerService } from 'src/app/core/power.service';
import { v4 as uuidv4 } from 'uuid';

/**
 * Calculation Action main component.
 */
@Component({
  selector: 'app-calculation-action',
  standalone: true,
  imports: [
    CalculationActionFormComponent,
    LoadingIndicatorComponent,
    MatButtonModule,
    CalculationHintComponent,
  ],
  templateUrl: './calculation-action.component.html',
  styleUrl: './calculation-action.component.scss',
})
export class CalculationActionComponent {
  /** All the bucket questions. */
  bucketQuestions = input.required<Question[]>();

  /** If is Edit Calculation Action. */
  editingAction = input.required<boolean>();

  /** Action Update. */
  action = input<PowerAction | null>();

  /** Cancel form event. */
  cancelAction = output<void>();

  /** Save form event. */
  saveAction = output<PowerAction>();

  /** The calculation items to generate in the form. */
  calculationItemsToGenerate = signal<string[]>([]);

  /** The calculation data. */
  calculationData!: CalculationData;

  /** The enabled save button. */
  enabledSaveButton = true;

  constructor(private powerService: PowerService) {}

  /**
   * Set the disabled or enabled Save Button.
   * @param isValid Is valid the calculation action form.
   */
  setStatusSaveButton(isValid: boolean): void {
    this.enabledSaveButton = isValid;
  }

  /**
   * Cancel the calculation action process.
   */
  cancelCalculationAction(): void {
    this.powerService.resetCalculationActionForm$.next();
    this.cancelAction.emit();
  }

  /**
   * Save the calculation action.
   */
  saveCalculationAction(): void {
    if (!this.calculationData.operations.length) return;
    const targetField = this.calculationData.operations[0].replace(
      StationFieldPrefix.CustomField,
      '',
    );
    const powerAction: PowerAction = {
      order: this.action()?.order ?? 1,
      rithmId: this.action()?.rithmId ?? uuidv4(),
      type: ActionType.CalculateField,
      target: targetField,
      data: JSON.stringify(<CalculationData>{
        operations: this.calculationData.operations.slice(1),
      }),
      resultMapping: '',
      header: '',
    };
    this.saveAction.emit(powerAction);
  }

  /**
   * Add new calculation items to the form.
   * @param rithmIds The rithmIds.
   */
  addCalculationItem(rithmIds: string[] = [uuidv4()]): void {
    this.calculationItemsToGenerate.update((currentItems) => [
      ...currentItems,
      ...rithmIds,
    ]);
  }
}
