<section class="sub-section relative">
  <form [formGroup]="promptForm">
    <div class="flex space-x-2 w-full min-w-[340px] flex-shrink-0">
      @if (!showCustomValue) {
        <mat-form-field
          class="w-full"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <mat-label>Field</mat-label>
          <mat-select formControlName="selectPromptValue">
            <app-ngx-search
              [multipleQuestions]="[
                filteredQuestions.stationInfo,
                filteredQuestions.containerInfo,
                filteredQuestions.fields
              ]"
              (getFilteredArrays)="fieldDataFiltered = $event"
            />

            <mat-optgroup
              [label]="termsGeneric.Station.Single + ' Info'"
              id="station-info-action"
              class="custom-select"
            >
              @for (
                question of fieldDataFiltered.multipleQuestions
                  ? fieldDataFiltered.multipleQuestions[0]
                  : [];
                track question.rithmId
              ) {
                <mat-option
                  [value]="
                    stationFieldPrefix.ContainerInfoItem + question.questionType
                  "
                >
                  <ng-container
                    [ngTemplateOutletContext]="{
                      question: question
                    }"
                    [ngTemplateOutlet]="questionOptionDiv"
                  />
                </mat-option>
              }
            </mat-optgroup>
            <mat-optgroup
              [label]="termsGeneric.Container.Single + ' Info'"
              id="container-info-action"
              class="custom-select"
            >
              @for (
                question of fieldDataFiltered.multipleQuestions
                  ? fieldDataFiltered.multipleQuestions[1]
                  : [];
                track question.rithmId
              ) {
                <mat-option
                  [value]="
                    stationFieldPrefix.ContainerInfoItem + question.questionType
                  "
                >
                  <ng-container
                    [ngTemplateOutletContext]="{
                      question: question
                    }"
                    [ngTemplateOutlet]="questionOptionDiv"
                  />
                </mat-option>
              }
            </mat-optgroup>

            <mat-optgroup
              label="Fields"
              id="custom-field-action"
              class="custom-select last-select"
            >
              @for (
                question of fieldDataFiltered.multipleQuestions
                  ? fieldDataFiltered.multipleQuestions[2]
                  : [];
                track question.rithmId
              ) {
                <mat-option
                  [value]="stationFieldPrefix.CustomField + question.rithmId"
                >
                  <ng-container
                    [ngTemplateOutletContext]="{
                      question: question
                    }"
                    [ngTemplateOutlet]="questionOptionDiv"
                  />
                </mat-option>
              }
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      } @else {
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          class="w-full"
        >
          <mat-label>{{
            selectedModel() === actionType.TextGenerator
              ? 'Custom Input'
              : 'Textarea'
          }}</mat-label>
          <textarea
            matInput
            #inputCustomValue
            formControlName="customPromptValue"
          ></textarea>
        </mat-form-field>
      }
      <button mat-stroked-button type="button" (click)="resetPromptValue()">
        <i
          class="fa-light text-sky-500 fa-xl"
          [ngClass]="!showCustomValue ? 'fa-keyboard' : 'fa-angle-down'"
        ></i>
      </button>
    </div>
    @if (canBeRemoved()) {
      <button
        mat-icon-button
        type="button"
        color="warn"
        class="remove-item hover:text-error-500"
        id="remove-item"
        (click)="removePrompt.emit()"
      >
        <i class="fa-light fa-trash text-primary-400"></i>
      </button>
    }
  </form>
</section>

<!-- The Question  Option Div template. -->
<ng-template #questionOptionDiv let-question="question">
  <div class="flex gap-4 items-center">
    <span class="w-5 text-center">
      <i
        class="text-gray-400 text-lg"
        [ngClass]="componentHelper.componentIcon(question.questionType)"
      ></i>
    </span>
    <p class="text-base font-medium text-primary-800">
      {{ question.prompt }}
    </p>
  </div>
</ng-template>
