<app-sub-header
  class="fixed z-10 w-full"
  [itemInfo]="stationInformation"
  [stationEditMode]="editMode"
  [isStation]="true"
  [editMode]="editMode"
  [indexTab]="indexTab"
  [nonEditableStation]="nonEditableStation"
  [stationGrid]="stationGrid"
  [inputFrameWidgetItems]="inputFrameWidgetItems"
  (addWidgetEvent)="stationGrid.addInputFrame($event)"
  (headerSelectedTab)="headerSelectedTab($event)"
/>
<mat-drawer-container
  [hasBackdrop]="drawerHasBackdrop"
  class="h-full info-drawer-station info-drawer-new-station"
>
  <!-- right match drawer -->
  <mat-drawer #rightDrawer mode="over" position="end" disableClose>
    @if (
      drawerContext === 'stationInfo' ||
      drawerContext === 'containerInfo' ||
      drawerContext === 'fieldSetting'
    ) {
      @defer {
        <app-info-drawer [isUsedFromMap]="isUsedFromMap" />
      }
    }
  </mat-drawer>

  <mat-drawer-content class="scrollbar station-page">
    <div class="station-content">
      @if (stationInformation) {
        <!-- Tabs -->
        <mat-tab-group
          class="station-component-tabs w-full"
          [selectedIndex]="headerTabIndex"
          animationDuration="0"
        >
          <mat-tab bodyClass="container-tab">
            <ng-template matTabContent>
              <div class="flex bg-white w-full h-full">
                <aside
                  class="station-left-column flex flex-col flex-shrink-0 w-80 overflow-hidden relative"
                >
                  <ng-container *ngTemplateOutlet="componentsTreeColumn" />
                </aside>
                <!-- Gridster Section  -->
                <section
                  class="relative flex flex-col flex-grow max-w-full border-x border-secondary-100"
                >
                  @if (!widgetLoading) {
                    <app-station-grid
                      #stationGrid
                      [stationWidgetUpdatesFlag]="stationWidgetUpdatesFlag"
                      [relationshipWidgetFlag]="relationshipWidgetFlag"
                      [stationRithmId]="stationRithmId"
                      [inputFrameWidgetItems]="inputFrameWidgetItems"
                      [drawerContext]="drawerContext"
                      [stationLoading]="stationLoading"
                      [stationOverlay]="stationOverlay"
                      [selectedInnerLeftIndex]="selectedInnerLeftIndex"
                      [forwardStations]="forwardStations"
                      [headerTabIndex]="headerTabIndex"
                      [showWidgetFramesUpdate]="showWidgetFramesUpdate"
                      [headerFeatureFlag]="headerFeatureFlag"
                      [orderOfOperations]="orderOfOperations"
                      [showSelfAssign]="showSelfAssign"
                      [containerStationOverlayFeature]="
                        containerStationOverlayFeature
                      "
                      [widgetColumnFeatureFlag]="widgetDataPhase2Flag"
                      [multiSortingFeatureFlag]="multiSortingFeatureFlag"
                      [inputFrameList]="
                        selectedInnerLeftIndex === '1'
                          ? bucketConnectedListCDK
                          : inputFrameList
                      "
                      [allBucketQuestions]="allBucketQuestions"
                      (openDashboardWidgetSettings)="
                        openDashboardWidgetSetting(
                          $event.widgetItem,
                          $event.widgetIndex,
                          $event.quantityElementsWidget
                        )
                      "
                      (isEditMode)="editMode = $event"
                      (getAllInputFrameWidgets)="inputFrameWidgetItems = $event"
                      (openSettingSection)="
                        openSettingTab(
                          $event.objectToConfig,
                          $event.containerWidgetType,
                          $event.isBucketQuestion,
                          $event.bucketQuestionNotInGrid
                        )
                      "
                      (getAllStationWidgets)="
                        reloadStationWidgetsBucketQuestions()
                      "
                      (checkLibraryTab)="checkLibraryTabQuestions()"
                      (checkBucketTab)="checkBucketQuestions()"
                      (configurableData)="configurableObject = $event"
                      (inputFramesList)="inputFrameList = $event"
                      (restoreBucketData)="restoreBucketData()"
                    />
                  } @else {
                    <ng-container
                      [ngTemplateOutlet]="stLoadingContent"
                    ></ng-container>
                  }
                </section>

                <!-- right column -->
                <aside class="station-right-column w-[320px]">
                  @if (editMode && configurableObject !== undefined) {
                    @defer {
                      <app-setting-column
                        [stationWidgetUpdatesFlag]="stationWidgetUpdatesFlag"
                        [relationshipWidgetFlag]="relationshipWidgetFlag"
                        [configurableObject]="configurableObject"
                        [stationRithmId]="stationRithmId"
                        [stationName]="stationName"
                        (widgetTextAdjustment)="widgetTextAdjustment($event)"
                        (checkBucketQuestions)="checkBucketQuestions()"
                        (stationOverlay)="stationOverlay = $event"
                        [dateFieldSettingsFlag]="dateFieldSettingsFlag"
                        [showDetachComponentFlag]="showDetachComponentFlag"
                        [showDocumentationDialogFlag]="
                          showDocumentationDialogFlag
                        "
                        [rearrangeOptionsFlag]="rearrangeOptionsFlag"
                        [attachmentFieldLogicFlag]="attachmentFieldLogicFlag"
                        [flagAttachmentsConditionsFilters]="
                          flagAttachmentsConditionsFilters
                        "
                        [showNumberFieldRules]="showNumberFieldRules"
                        [showOptionAssignedToConditions]="
                          showOptionAssignedToConditions
                        "
                      />
                    }
                  }
                </aside>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab bodyClass="rules-tab">
            <ng-template matTabContent>
              @defer {
                <app-rules
                  [rithmId]="stationRithmId"
                  [flagAttachmentsConditionsFilters]="
                    flagAttachmentsConditionsFilters
                  "
                  [showRelationshipActions]="showRelationshipActions"
                  [showAppendFieldActions]="showAppendFieldActions"
                  [showActionMoveContainer]="showActionMoveContainer"
                  [showAdvancedUpdateAction]="showAdvancedUpdateAction"
                  [showParentChildRelationship]="showParentChildRelationship"
                  [showFlowedBy]="showFlowedBy"
                  [showUpdateFieldTrigger]="showUpdateFieldTrigger"
                  [nextStations]="forwardStations"
                  [inputFrameWidgetItems]="inputFrameWidgetItems"
                  [flowButtonName]="stationInformation.flowButton"
                  [showOptionSqlIntegration]="showOptionSqlIntegration"
                  [assignedToConditions]="showOptionAssignedToConditions"
                  [attachmentFieldLogicFlag]="attachmentFieldLogicFlag"
                  [showNumberFieldRules]="showNumberFieldRules"
                  [orderOfOperations]="orderOfOperations"
                  [rithmAIActionFlag]="rithmAIActionFlag"
                  (standOutCustomId)="standOutCustomId()"
                />
              }
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      } @else {
        <div class="w-full" id="stationLandingLoading">
          <div
            class="w-full h-full flex flex-col justify-center items-center space-y-4"
          >
            <ng-container *ngTemplateOutlet="stLoadingContent" />
          </div>
        </div>
      }
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<ng-template #stLoadingContent>
  <div
    class="w-full h-full flex flex-col justify-center items-center space-y-4"
  >
    <app-loading-indicator />
    <p class="overline-font text-accent-500">
      Loading {{ termsGeneric.Station.Single }} Information
    </p>
  </div>
</ng-template>

<ng-template #alertStationEmpty>
  <div
    class="absolute top-0 h-full w-full flex flex-col justify-center items-center"
  >
    <div class="bg-white text-center px-5 py-8 rounded drop-shadow-lg max-w-sm">
      <i class="fa-light fa-lightbulb-on text-6xl text-accent-500"></i>
      <h3 class="font-semibold py-4 text-base">Quick Tip</h3>
      <p class="text-secondary-500 text-sm">
        Get started with adding a frame to the
        {{ termsGeneric.Station.Single | lowercase }} grid.
      </p>
    </div>
  </div>
</ng-template>

<!-- Loading with description. -->
<ng-template #loadingWithDescription let-description>
  <div class="flex justify-start items-center pl-4 pt-1">
    <app-loading-indicator class="text-sm" [inlineText]="description" />
  </div>
</ng-template>

<!-- New library design. -->
<ng-template #componentsTreeColumn>
  <section class="components-tree-header sticky top-0 bg-white z-10">
    <header
      class="w-full h-[40px] flex justify-between items-center pl-4 pr-1 border-b border-gray-200"
    >
      <span class="text-sky-500 text-xs font-semibold">Components</span>
      <div
        [matTooltip]="
          userService.isAdmin()
            ? ''
            : 'Only architect users can open the library'
        "
      >
        <button
          mat-icon-button
          type="button"
          id="modal-library-button"
          data-testid="modal-library-button"
          [disabled]="!userService.isAdmin()"
          (click)="openStationLibraryModal()"
        >
          <i
            class="fa-light fa-books text-lg"
            [ngClass]="
              userService.isAdmin()
                ? 'text-secondary-500'
                : 'text-secondary-200'
            "
          ></i>
        </button>
      </div>
    </header>
    <div class="input-slim-mdc library-global-search">
      <mat-form-field class="w-full">
        <input
          type="text"
          matInput
          placeholder="search..."
          id="input-library-search"
          data-testid="input-library-search"
          autocomplete="off"
          [(ngModel)]="searchText"
          (ngModelChange)="searchComponents()"
          [disabled]="disableLibrarySearchText"
        />
        <span matPrefix
          ><i class="fa-light fa-search fa-sm text-gray-400"></i
        ></span>
      </mat-form-field>
    </div>
  </section>
  <div class="w-full h-full scrollbar overflow-auto px-2 pb-10">
    <ng-container *ngTemplateOutlet="localComponent" />
    <mat-divider />
    <ng-container *ngTemplateOutlet="availableComponent" />
  </div>

  <!-- Drag and drop station bucket zone. -->
  <div
    cdkDropList
    (cdkDropListDropped)="dropStationBucket($event)"
    data-testid="input-station-bucket"
    id="bucket-zone"
    class="station-bucket-drag-and-drop"
    [ngClass]="{
      'hover:bg-accent-50': !updatingBucketLoading,
      invisible: !draggedStarted
    }"
  >
    <div class="flex space-x-2">
      <i class="fa-light fa-bucket text-accent-500"></i>
      <span class="text-xs text-accent-500"
        >Add To {{ termsGeneric.Station.Single }} Bucket</span
      >
      @if (updatingBucketLoading) {
        <ng-container
          *ngTemplateOutlet="
            loadingWithDescription;
            context: { $implicit: ' ' }
          "
        />
      }
    </div>
  </div>
</ng-template>

<!-- Section local component. -->
<ng-template #localComponent>
  <section class="py-2">
    <div class="w-full h-[36px] flex justify-between items-center">
      <span class="text-primary-200 text-xs font-medium">Local Component</span>

      <i
        class="fa-sharp fa-light fa-circle-question pr-1 text-secondary-300"
        [matTooltipPosition]="'right'"
        [matTooltip]="localComponentTooltip"
      ></i>
    </div>

    <div class="relative">
      <div
        cdkDropList
        (cdkDropListDropped)="dropStationBucket($event)"
        data-testid="bucket-zone-tree"
        id="bucket-zone-tree"
        class="w-full h-full absolute bg-[#e4e8f196] border-2 border-accent-500 station-bucket-drag-and-drop-tree"
        [ngClass]="{
          invisible: !(
            showDropZoneStationComponent &&
            draggedStarted &&
            !updatingBucketLoading
          )
        }"
      ></div>
      <app-component-tree
        #componentTree
        [showSelfAssign]="showSelfAssign"
        [stationRithmId]="stationRithmId"
        [stationBucketComponents]="allBucketQuestions"
        [inputFrameWidgets]="inputFrameWidgetItems"
        [editMode]="editMode"
        [inputFrameList]="localComponentConnectedListCDK"
        [searchText]="searchText"
        [expandTreeInCustomId]="showCustomId"
        [componentListType]="componentListType.LocalComponent"
        [parentStationLinkFlag]="parentStationLinkFlag"
        [parentContainerLinkFlag]="parentContainerLinkFlag"
        (componentTreeDataReceived)="
          componentTreeDataReceived = $event;
          componentTree.checkContainerInfoQuestions()
        "
        (enableDropZone)="showDropZoneStationComponent = $event"
        (openSettingTab)="
          openSettingTab($event, widgetType.InputWidget, true, $event.enabled)
        "
      />
    </div>
  </section>
</ng-template>

<!-- Section available component. -->
<ng-template #availableComponent>
  <section class="py-2">
    <span class="text-primary-200 text-xs font-medium"
      >Available Components</span
    >
    <app-component-tree
      #componentTreeComponents
      [showSelfAssign]="showSelfAssign"
      [stationRithmId]="stationRithmId"
      [stationBucketComponents]="allBucketQuestions"
      [inputFrameWidgets]="inputFrameWidgetItems"
      [editMode]="editMode"
      [inputFrameList]="connectedListCDK"
      [componentListType]="componentListType.AvailableComponents"
      [searchText]="searchText"
      [expandTreeInCustomId]="showCustomId"
      [parentStationLinkFlag]="parentStationLinkFlag"
      [parentContainerLinkFlag]="parentContainerLinkFlag"
      (dragMode)="!updatingBucketLoading && (draggedStarted = $event)"
      (componentTreeDataReceived)="
        componentTreeDataReceived = $event;
        componentTreeComponents.checkContainerInfoQuestions()
      "
    />
    @if (showFormulaComponents) {
      <app-component-tree
        #componentTreeComponents
        [showSelfAssign]="showSelfAssign"
        [stationRithmId]="stationRithmId"
        [stationBucketComponents]="allBucketQuestions"
        [inputFrameWidgets]="inputFrameWidgetItems"
        [editMode]="editMode"
        [inputFrameList]="connectedListCDK"
        [componentListType]="componentListType.FormulaComponents"
        [searchText]="searchText"
        [expandTreeInCustomId]="showCustomId"
        [parentStationLinkFlag]="parentStationLinkFlag"
        [parentContainerLinkFlag]="parentContainerLinkFlag"
        (dragMode)="!updatingBucketLoading && (draggedStarted = $event)"
        (componentTreeDataReceived)="
          componentTreeDataReceived = $event;
          componentTreeComponents.checkContainerInfoQuestions()
        "
      />
    }
    @if (!loadingGetLibraries) {
      <app-library-component-list
        #libraryTree
        [libraries]="searchResults"
        [stationRithmId]="stationRithmId"
        [isPanelExpanded]="isPanelExpanded"
        [stationWidgetUpdatesFlag]="stationWidgetUpdatesFlag"
        [relationshipWidgetFlag]="relationshipWidgetFlag"
        [isStation]="true"
        [editMode]="editMode"
        [inputFrameList]="connectedListCDK"
        [inputFrameWidgets]="inputFrameWidgetItems"
        (dragMode)="!updatingBucketLoading && (draggedStarted = $event)"
      />
    } @else {
      <ng-container
        *ngTemplateOutlet="
          loadingWithDescription;
          context: { $implicit: 'Loading libraries...' }
        "
      />
    }
  </section>
</ng-template>
