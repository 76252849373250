<section class="container-tab-actions">
  <ng-container
    *ngTemplateOutlet="!addingAction ? actionList : createActionForm"
  />

  <ng-template #createActionForm>
    <section class="container-action-form px-4">
      @if (
        actionTypeValue === actionTypesEnum.ArchiveContainer ||
        actionTypeValue === ''
      ) {
        <section class="container-action-type-selector">
          <mat-form-field
            appearance="outline"
            class="w-full max-w-md"
            subscriptSizing="dynamic"
          >
            <mat-label class="text-base">Action Type</mat-label>
            <mat-select
              [(ngModel)]="actionTypeValue"
              (selectionChange)="listenActionType()"
            >
              <mat-option [value]="actionTypesEnum.CreateContainer"
                >{{ termsGeneric.Container.Single }} Creation</mat-option
              >
              @if (showActionMoveContainer) {
                <mat-option [value]="actionTypesEnum.MoveContainer"
                  >Move {{ termsGeneric.Container.Single }}</mat-option
                >
              }

              <mat-option
                [value]="actionTypesEnum.ArchiveContainer"
                [disabled]="hasArchiveAction"
                >Archive {{ termsGeneric.Container.Single }}
              </mat-option>
              <mat-option
                [value]="actionTypesEnum.UpdateField"
                [disabled]="!bucketQuestions.length"
                (click)="actionToBeUpdated = null"
                >Update Field</mat-option
              >
              @if (showRelationshipActions) {
                <mat-option
                  [value]="actionTypesEnum.UpdateRelationship"
                  (click)="actionToBeUpdated = null"
                  >Update Relationship</mat-option
                >
              }
              @if (showAdvancedUpdateAction) {
                <mat-option [value]="actionTypesEnum.AdvancedUpdateField"
                  >Advanced Update Field</mat-option
                >
              }
              @if (showAppendFieldActions) {
                <mat-option [value]="actionTypesEnum.AppendField"
                  >Append Field</mat-option
                >
              }

              <mat-option [value]="actionTypesEnum.CalculateField"
                >Calculate Field
              </mat-option>
            </mat-select>
          </mat-form-field>
        </section>
      }

      @if (actionTypeValue === actionTypesEnum.CreateContainer) {
        <app-create-container-action
          [stations]="stations"
          [orderOfOperations]="orderOfOperations"
          [stationRithmId]="stationRithmId"
          [showRelationshipActions]="showRelationshipActions"
          [relationsSelected]="relationsSelected"
          [actionToUpdate]="actionToBeUpdated"
          [updateRelationshipAction]="updateRelationshipAction"
          [stationLibrariesLoading]="stationLibrariesLoading"
          [stationLibraryRelationships]="stationLibraryRelationships"
          [actionTypeValue]="actionTypeValue"
          [currentStation]="currentStation"
          [savingAction]="savingAction"
          [deletingAction]="deletingAction"
          [editingAction]="editingAction"
          (containerPowerEmitter)="saveActionType($event)"
          (cancelForm)="cancelAction()"
          (emptyRelationships)="relationsSelected = []"
          (addActionsToSave)="addActionsToSave()"
          (actionToRemove)="confirmRemoveAction($event)"
        />
      }

      @if (actionTypeValue === actionTypesEnum.UpdateField) {
        <app-update-field-action
          [stationRithmId]="stationRithmId"
          [editingAction]="editingAction"
          [orderOfOperations]="orderOfOperations"
          [actionToUpdate]="actionToBeUpdated"
          [bucketQuestions]="bucketQuestions"
          [showFlowedBy]="showFlowedBy"
          [savingAction]="savingAction"
          [showNumberFieldRules]="showNumberFieldRules"
          [deletingAction]="deletingAction"
          (containerPowerEmitter)="saveActionType($event)"
          (cancelForm)="cancelAction()"
          (actionToRemove)="confirmRemoveAction($event)"
        />
      }

      @if (
        actionTypeValue === actionTypesEnum.ArchiveContainer ||
        actionTypeValue === ''
      ) {
        <ng-container *ngTemplateOutlet="controlButtons" />
      }

      @if (
        actionTypeValue === actionTypesEnum.UpdateRelationship ||
        actionTypeValue === actionTypesEnum.DeleteRelationship ||
        actionTypeValue === actionTypesEnum.AddRelationship
      ) {
        <app-update-relationship-form
          [stationRithmId]="stationRithmId"
          [orderOfOperations]="orderOfOperations"
          [savingAction]="savingAction"
          [editingAction]="editingAction"
          [parentId]="parentContainer.rithmId"
          [librariesLoading]="stationLibrariesLoading"
          [libraryRelationships]="stationLibraryRelationships"
          [deletingAction]="deletingAction"
          [action]="actionToBeUpdated"
          [showParentChildRelationship]="showParentChildRelationship"
          (cancelAction)="cancelAction()"
          (actionEmitter)="saveActionType($event)"
          (actionToRemove)="confirmRemoveAction($event)"
        />
      }

      @if (actionTypeValue === actionTypesEnum.AppendField) {
        <app-append-action
          [orderOfOperations]="orderOfOperations"
          [bucketQuestions]="_bucketQuestions"
          [action]="actionToBeUpdated"
          [savingAction]="savingAction"
          [editingAction]="editingAction"
          [deletingAction]="deletingAction"
          (cancelAppendField)="cancelAction()"
          (actionEmitter)="saveActionType($event)"
          (actionToRemove)="confirmRemoveAction($event)"
        />
      }

      @if (actionTypeValue === actionTypesEnum.MoveContainer) {
        <ng-container *ngTemplateOutlet="moveContainer" />
      }

      @if (actionTypeValue === actionTypesEnum.AdvancedUpdateField) {
        <app-advanced-update-action-form
          [stations]="stations"
          [orderOfOperations]="orderOfOperations"
          [stationRithmId]="stationRithmId"
          [currentStation]="currentStation"
          [isEditAdvanceUpdateAction]="isEditAdvanceUpdateAction"
          [action]="actionToBeUpdated"
          [savingAction]="savingAction"
          [editingAction]="editingAction"
          [deletingAction]="deletingAction"
          [showNumberFieldRules]="showNumberFieldRules"
          (cancelForm)="cancelAction()"
          (actionEmitter)="saveActionType($event)"
          (actionToRemove)="confirmRemoveAction($event)"
        />
      }

      @if (actionTypeValue === actionTypesEnum.CalculateField) {
        <app-calculation-action
          [bucketQuestions]="bucketQuestions"
          [action]="actionToBeUpdated"
          [editingAction]="editingAction"
          (cancelAction)="cancelAction()"
          (saveAction)="saveActionType($event)"
        />
      }
    </section>
  </ng-template>
</section>

<ng-template #actionList>
  <section
    class="w-full container-action-list container-actions-section action-chips"
  >
    @for (
      action of getContainerActions;
      track action.rithmId;
      let index = $index
    ) {
      <div
        class="action-list-item"
        [ngClass]="
          orderOfOperations
            ? 'hover:!bg-white !items-start'
            : 'border-order-operations'
        "
      >
        <section class="py-4 w-full overflow-x-auto scrollbar">
          @switch (action.type) {
            @case (actionTypesEnum.ArchiveContainer) {
              <div>
                <span [ngClass]="{ 'action-title': orderOfOperations }">
                  Archive {{ termsGeneric.Container.Single }}
                </span>
              </div>
            }
            @case (actionTypesEnum.CreateContainer) {
              <div>
                @if (!showRelationshipActions) {
                  <span
                    [ngClass]="
                      orderOfOperations
                        ? 'action-title'
                        : 'px-8 title-from-order-operation'
                    "
                  >
                    @if (!orderOfOperations) {
                      <ng-container>Action: </ng-container>
                    }

                    Create {{ termsGeneric.Container.Single }}
                  </span>
                }

                @if (!showRelationshipActions) {
                  <div class="flex items-center space-x-2">
                    <span class="whitespace-nowrap">
                      Create {{ termsGeneric.Container.Single }}
                    </span>
                    <strong> in </strong>

                    @if (!stationsLoading) {
                      <p class="label-chip">
                        {{ createContainerIn(action) }}
                      </p>
                    }
                    @if (stationsLoading) {
                      <ng-container
                        [ngTemplateOutlet]="loader"
                        [ngTemplateOutletContext]="{
                          $implicit:
                            'loading ' + termsGeneric.Container.Plural + '...'
                        }"
                      />
                    }
                  </div>
                }
                @if (showRelationshipActions) {
                  <div>
                    <span
                      [ngClass]="
                        orderOfOperations
                          ? 'action-title'
                          : 'title-from-order-operation'
                      "
                    >
                      @if (!orderOfOperations) {
                        <ng-container>Action: </ng-container>
                      }
                      Create {{ termsGeneric.Container.Single }}
                    </span>
                  </div>

                  <ng-container>
                    <p class="sub-section-title mt-4">
                      {{ termsGeneric.Station.Single }}:
                    </p>
                    @if (!stationsLoading) {
                      <div class="w-full h-15 custom-chips">
                        <mat-chip-listbox>
                          @for (
                            station of getTargetValues(action);
                            track $index
                          ) {
                            <ng-container class="pr-2">
                              <mat-chip>
                                @if (orderOfOperations) {
                                  <i class="fak fa-station icon-chip"></i>
                                  <span class="label-chip">
                                    {{ getStationName(station) }}
                                  </span>
                                } @else {
                                  {{ getStationName(station) }}
                                }
                              </mat-chip>
                            </ng-container>
                          }
                        </mat-chip-listbox>
                      </div>
                    }
                    @if (stationsLoading) {
                      <ng-container
                        [ngTemplateOutlet]="loader"
                        [ngTemplateOutletContext]="{
                          $implicit:
                            'loading ' + termsGeneric.Station.Plural + '...'
                        }"
                      />
                    }
                  </ng-container>

                  @if (
                    getQuestionsRelationShip(action).length &&
                    bucketQuestions.length
                  ) {
                    <div class="w-full h-15 custom-chips mt-2">
                      <p class="sub-section-title">Shared fields:</p>

                      <mat-chip-listbox>
                        @for (
                          question of getQuestionsRelationShip(action);
                          track $index
                        ) {
                          <ng-container class="pr-2">
                            <mat-chip>
                              @if (orderOfOperations) {
                                <i
                                  class="icon-chip"
                                  [ngClass]="getQuestionTypeIcon(question)"
                                ></i>
                                <span class="label-chip lowercase">
                                  {{ getQuestionPrompt(question) }}
                                </span>
                              } @else {
                                {{ getQuestionPrompt(question) }}
                              }
                            </mat-chip>
                          </ng-container>
                        }
                      </mat-chip-listbox>
                    </div>
                  }

                  @if (
                    !stationLibrariesLoading &&
                    getRelationshipTypes(action).length
                  ) {
                    <div class="w-full h-15 custom-chips mt-2">
                      @if (getRelationshipTypes.length) {
                        <p class="sub-section-title">Assigned relationships:</p>
                      }

                      <mat-chip-listbox>
                        @for (
                          relation of getRelationshipTypes(action);
                          track $index
                        ) {
                          <ng-container class="pr-2">
                            <mat-chip>
                              @if (orderOfOperations) {
                                <i class="fal fa-circle-nodes icon-chip"></i>
                                <span class="label-chip">
                                  {{ cleanRelationLabel(relation) }}
                                </span>
                              } @else {
                                {{ cleanRelationLabel(relation) }}
                              }
                            </mat-chip>
                          </ng-container>
                        }
                      </mat-chip-listbox>
                    </div>
                  }

                  @if (stationLibrariesLoading) {
                    <ng-container
                      [ngTemplateOutlet]="loader"
                      [ngTemplateOutletContext]="{
                        $implicit: 'loading relationships...'
                      }"
                    />
                  }
                }
              </div>
            }

            @case (actionTypesEnum.UpdateField) {
              <div
                class="flex flex-col justify-start"
                [ngClass]="{
                  'py-4': !orderOfOperations
                }"
              >
                <span
                  class="text-primary-400"
                  [ngClass]="
                    orderOfOperations
                      ? 'action-title'
                      : 'title-from-order-operation'
                  "
                >
                  @if (!orderOfOperations) {
                    <ng-container>Action: </ng-container>
                  }
                  Update Field
                </span>
                @if ({ data: getUpdateFieldData(action) }; as updateField) {
                  @if (orderOfOperations) {
                    <section class="mt-4">
                      <div class="w-full h-15 custom-chips">
                        <p class="sub-section-title">Updating:</p>
                        <mat-chip-listbox>
                          <mat-chip class="pr-2">
                            <i
                              class="icon-chip"
                              [ngClass]="
                                componentHelper.componentIcon(
                                  updateField.data.target.questionType
                                )
                              "
                            ></i>
                            @if (updateField.data.target.text) {
                              <span class="label-chip"
                                >{{ updateField.data.target.text }}
                              </span>
                            }
                          </mat-chip>
                        </mat-chip-listbox>
                      </div>
                      <div class="w-full h-15 custom-chips mt-2">
                        <p class="sub-section-title">With:</p>
                        <mat-chip-listbox>
                          <mat-chip class="pr-2">
                            @if (
                              (updateField.data.source.questionType ||
                                updateField.data.target.questionType) &&
                              updateField.data.source.type !== 'string'
                            ) {
                              <i
                                class="icon-chip"
                                [ngClass]="
                                  componentHelper.componentIcon(
                                    updateField.data.source.questionType ||
                                      updateField.data.target.questionType
                                  )
                                "
                              ></i>
                            }
                            <ng-container
                              [ngTemplateOutlet]="updateFieldTextTemp"
                              [ngTemplateOutletContext]="{
                                $implicit: updateField.data
                              }"
                            />
                          </mat-chip>
                        </mat-chip-listbox>
                      </div>
                    </section>
                  } @else {
                    <div class="flex flex-row space-x-2 items-center">
                      @if (updateField.data.target.text) {
                        <span class="label-chip"
                          >{{ updateField.data.target.text }}
                        </span>
                      }
                      <p class="text-primary-500">
                        <span class="font-light">updates to: </span>
                        <ng-container
                          [ngTemplateOutlet]="updateFieldTextTemp"
                          [ngTemplateOutletContext]="{
                            $implicit: updateField.data
                          }"
                        />
                      </p>
                    </div>
                  }
                }
              </div>
            }

            @case (actionTypesEnum.DeleteRelationship) {
              <app-update-relationship-item
                [action]="action"
                [libraryRelationships]="stationLibraryRelationships"
                [orderOfOperations]="orderOfOperations"
              />
            }

            @case (actionTypesEnum.AddRelationship) {
              <app-update-relationship-item
                [action]="action"
                [libraryRelationships]="stationLibraryRelationships"
                [orderOfOperations]="orderOfOperations"
              />
            }

            @case (actionTypesEnum.AppendField) {
              <app-appended-action-item
                [appendedAction]="action"
                [questionList]="_bucketQuestions"
                [orderOfOperations]="orderOfOperations"
              />
            }

            @case (actionTypesEnum.AdvancedUpdateField) {
              <ng-template
                [ngTemplateOutlet]="advanceUpdateAction"
                [ngTemplateOutletContext]="{
                  action: action,
                  stations: stations,
                  currentStation: currentStation
                }"
              >
              </ng-template>
            }

            @case (actionTypesEnum.MoveContainer) {
              <!-- Show move container action. -->
              <div class="flex flex-col">
                <span
                  [ngClass]="
                    orderOfOperations
                      ? 'action-title'
                      : 'title-from-order-operation'
                  "
                >
                  @if (!orderOfOperations) {
                    <ng-container>Action: </ng-container>
                  }
                  Move {{ termsGeneric.Container.Single }}
                </span>
                <p class="sub-section-title mt-4">
                  {{ termsGeneric.Station.Single }}:
                </p>
                <div class="w-full custom-chips">
                  <mat-chip-listbox>
                    @for (station of getTargetValues(action); track $index) {
                      <mat-chip>
                        @if (orderOfOperations) {
                          <i class="fak fa-station icon-chip"></i>
                          <span class="label-chip">
                            {{ getStationName(station) }}
                          </span>
                        } @else {
                          <p>{{ getStationName(station) }}</p>
                        }
                      </mat-chip>
                    }
                  </mat-chip-listbox>
                </div>
              </div>
            }

            @case (actionTypesEnum.CalculateField) {
              <ng-container
                [ngTemplateOutlet]="calculateFieldAction"
                [ngTemplateOutletContext]="{ action: action }"
              />
            }
          }
        </section>

        <button
          mat-icon-button
          type="button"
          [matMenuTriggerFor]="actionOptions"
          id="actions-container"
          data-testid="actions-container"
          class="text-lg"
        >
          <i class="fa-light fa-ellipsis-h"></i>
        </button>
        <mat-menu #actionOptions="matMenu" xPosition="before">
          @if (isEditableAction(action.type)) {
            <button
              mat-menu-item
              type="button"
              class="text-md"
              data-testid="edit-action"
              id="edit-action"
              (click)="editContainerAction(action, index)"
            >
              <span class="p-4">Edit action</span>
            </button>
          }

          @if (orderOfOperations) {
            <button
              mat-menu-item
              type="button"
              class="text-md"
              data-testid="duplicate-container-action"
              id="duplicate-container-action"
              (click)="
                addOrUpdateSpecificAction(action, powerEventType.Duplicate)
              "
            >
              <span class="p-4">Duplicate action</span>
            </button>
          }
          <button
            mat-menu-item
            type="button"
            class="text-md"
            data-testid="delete-container-action"
            id="delete-container-action"
            (click)="confirmRemoveAction(action)"
          >
            <span class="p-4 text-error-500">Delete action</span>
          </button>
        </mat-menu>
      </div>
    }
  </section>
  <section [ngClass]="{ 'px-4 pb-4': !orderOfOperations || !actions.length }">
    @if ((!addingAction && !orderOfOperations) || !actions.length) {
      <button
        mat-stroked-button
        type="button"
        color="accent"
        id="new-action"
        data-testid="new-action"
        class="!leading-tight !tracking-wide"
        [ngClass]="{ '!bg-sky-500 !text-white': orderOfOperations }"
        (click)="addingAction = true"
      >
        New Action
      </button>
    }
  </section>
</ng-template>

<ng-template #loader let-message>
  <app-loading-indicator [inlineText]="message" />
</ng-template>

<ng-template #controlButtons>
  <section class="container-action-control-buttons space-x-4">
    <button
      mat-button
      [disabled]="(savingAction || deletingAction) && orderOfOperations"
      type="button"
      (click)="cancelAction()"
      data-testid="action-cancel"
      id="action-cancel"
    >
      <span class="font-semibold">CANCEL</span>
    </button>
    @if (actionTypeValue !== '') {
      <button
        mat-raised-button
        [ngClass]="{
          '!bg-sky-500 !text-white !font-semibold': orderOfOperations
        }"
        [disabled]="
          (savingAction || deletingAction) &&
          orderOfOperations &&
          !stationsToShowInChips.length
        "
        type="button"
        color="accent"
        (click)="saveActionType()"
        id="action-save"
        data-testid="action-save"
      >
        @if (orderOfOperations) {
          @if (savingAction) {
            <ng-container
              [ngTemplateOutlet]="loadingInlineText"
              [ngTemplateOutletContext]="{ $implicit: 'Saving...' }"
            />
          } @else {
            SAVE
          }
        } @else {
          DONE
        }
      </button>
    }
  </section>
</ng-template>

<ng-template #moveContainer>
  <section [formGroup]="moveContainerForm" class="w-full py-2">
    <h6 class="mb-4 text-lg">Move {{ termsGeneric.Container.Single }}</h6>
    <div class="py-4 w-full">
      <mat-form-field
        appearance="outline"
        class="w-full max-w-md"
        subscriptSizing="dynamic"
        [ngClass]="{
          'animate-pulse': loadingStations
        }"
      >
        <button matPrefix mat-icon-button type="button">
          <i class="fa-light fa-search text-primary-300 text-xl"></i>
        </button>
        <mat-label class="text-base">
          {{ termsGeneric.Station.Single }}
        </mat-label>

        <mat-select
          multiple
          formControlName="stations"
          appMatSelectInfinityScroll
          class="w-full"
          (onScrollDown)="getMoreElements()"
          [ngClass]="{
            'animate-pulse': loadingStations
          }"
        >
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              formControlName="formStationsFilter"
              noEntriesFoundLabel="Not match found..."
              [attr.data-testid]="'add-station-search'"
              [attr.id]="'add-station-search'"
            >
              <span aria-hidden="true" class="close" ngxMatSelectSearchClear>
                <i class="fa-solid fa-xmark"></i>
              </span>
            </ngx-mat-select-search>
          </mat-option>
          @if (!loadingStations) {
            @for (
              station of filteredStationsAutocomplete;
              track station.rithmId
            ) {
              <mat-option
                [value]="station.rithmId"
                (click)="addOrRemoveStation(station.rithmId)"
              >
                <p class="line-clamp-[1] text-md font-semibold">
                  {{ station.name }}
                </p>
                <p
                  class="text-sm text-secondary-500 leading-tight line-clamp-[1]"
                >
                  ({{
                    station.groupName || 'Without ' + termsGeneric.Group.Single
                  }})
                </p>
              </mat-option>
            }
          }
          @if (loadingStations || currentPageElement.loading) {
            <mat-option class="pointer-events-none check-center-loading">
              <app-loading-indicator
                id="loading-elements"
                data-testid="loading-elements"
                [inlineText]="'Loading..'"
              />
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <div class="custom-chips overflow-x-auto scrollbar">
        <mat-chip-listbox>
          @for (station of stationsToShowInChips; track $index) {
            <ng-container class="pr-2">
              <mat-chip>
                {{ getStationName(station) }}
                <button
                  (click)="removeChipMoveContainer(station)"
                  matChipRemove
                  type="button"
                  [attr.data-testid]="'remove-station'"
                  [attr.id]="'remove-station'"
                >
                  <i class="fa fa-times-circle-o"></i>
                </button>
              </mat-chip>
            </ng-container>
          }
        </mat-chip-listbox>
      </div>
    </div>
    <section class="container-action-control-buttons flex justify-between">
      <div class="flex space-x-4">
        <button
          mat-button
          type="button"
          [disabled]="savingAction && orderOfOperations"
          data-testid="update-relationship-action-cancel"
          id="update-relationship-action-cancel"
          (click)="closeMoveContainerAction()"
        >
          <span class="font-semibold">CANCEL</span>
        </button>
        <button
          mat-raised-button
          [ngClass]="{
            '!bg-sky-500 !text-white !font-semibold': orderOfOperations,
            '!opacity-60':
              !moveContainerForm.valid || !stationsToShowInChips.length
          }"
          type="button"
          color="accent"
          id="update-relationship-action-save"
          data-testid="update-relationship-action-save"
          [disabled]="!moveContainerForm.valid || !stationsToShowInChips.length"
          (click)="actionMoveContainer()"
        >
          @if (orderOfOperations) {
            @if (savingAction) {
              <ng-container
                [ngTemplateOutlet]="loadingInlineText"
                [ngTemplateOutletContext]="{ $implicit: 'Saving...' }"
              />
            } @else {
              SAVE
            }
          } @else {
            DONE
          }
        </button>
      </div>
      @if (orderOfOperations && editingAction) {
        <div class="flex-grow text-right">
          <button
            mat-stroked-button
            class="!bg-red-500 !text-white"
            type="button"
            id="delete-action"
            data-testid="delete-action"
            [disabled]="(savingAction || deletingAction) && orderOfOperations"
            (click)="confirmRemoveMoveContainerAction()"
          >
            @if (deletingAction) {
              <ng-container
                [ngTemplateOutlet]="loadingInlineText"
                [ngTemplateOutletContext]="{ $implicit: 'Deleting...' }"
              />
            } @else {
              <i class="fa-light fa-trash text-lg"></i> DELETE ACTION
            }
          </button>
        </div>
      }
    </section>
  </section>
</ng-template>

<ng-template #advanceUpdateAction let-action="action" let-stations="stations">
  <app-advanced-update-action-item
    [action]="action"
    [stations]="stations"
    [currentStation]="currentStation"
    [orderOfOperations]="orderOfOperations"
  />
</ng-template>

<!-- In line text loading indicator -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator id="loading-inline-text" [inlineText]="message" />
</ng-template>

<!-- Template for the text when it is of type updateField. -->
<ng-template #updateFieldTextTemp let-updateFieldData>
  <span
    class="font-medium"
    [ngClass]="{
      'text-sm': orderOfOperations,
      'text-gray-800 ':
        orderOfOperations || updateFieldData.source.text === '--no value--'
    }"
  >
    @if (updateFieldData.source.text !== '--no value--') {
      @if (
        updateFieldData.source.questionType === questionFieldType.Currency &&
        updateFieldData.source.type !== 'field'
      ) {
        {{ updateFieldData.source.text | currency }}
      } @else {
        @if (
          [
            questionFieldType.TimeInStation,
            questionFieldType.DateTime,
            questionFieldType.Date
          ].includes(updateFieldData.source.questionType)
        ) {
          {{
            updateFieldData.source.value === updateFieldData.source.text
              ? (updateFieldData.source.value || ''
                | utcDateFormat: getDateSettings(updateFieldData.target))
              : updateFieldData.source.text
          }}
        } @else {
          {{ getAssignedValues(updateFieldData) }}
        }
      }
    } @else {
      No value selected
    }
  </span>
</ng-template>

<ng-template #calculateFieldAction let-action="action">
  <section class="flex flex-col">
    <span
      [ngClass]="
        orderOfOperations ? 'action-title' : 'title-from-order-operation'
      "
    >
      @if (!orderOfOperations) {
        <ng-container>Action: </ng-container>
      }
      Calculation
    </span>

    <section class="mt-4">
      <p class="sub-section-title">Result field</p>
      <mat-chip-set class="custom-chips">
        <mat-chip>
          <i
            class="icon-chip"
            [ngClass]="getQuestionTypeIcon(action.target)"
          ></i>
          <span class="label-chip">
            {{ getQuestionPrompt(action.target) }}
          </span>
        </mat-chip>
      </mat-chip-set>
    </section>

    <section class="mt-2">
      <p class="sub-section-title">Calculation</p>
      <mat-chip-set class="custom-chips">
        @for (
          calculationField of getCalculationData(action).operations;
          track $index
        ) {
          <mat-chip>
            @if (getQuestionTypeIcon(calculationField)) {
              <i
                class="icon-chip"
                [ngClass]="getQuestionTypeIcon(calculationField)"
              ></i>
            }
            <span class="label-chip">
              {{ getQuestionPrompt(calculationField) }}
            </span>
          </mat-chip>
        }
      </mat-chip-set>
    </section>
  </section>
</ng-template>
