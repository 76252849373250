<div class="h-full w-full">
  <section [ngClass]="{ 'hover:!bg-white': orderOfOperations }">
    <div class="w-full overflow-x-auto scrollbar">
      <span
        [ngClass]="
          orderOfOperations ? 'action-title' : 'text-primary-400 text-sm'
        "
      >
        @if (!orderOfOperations) {
          <ng-container>Action: </ng-container>
        }
        Appended field</span
      >

      <section class="mt-4">
        <p class="sub-section-title">Base field:</p>
        <mat-chip-set>
          <mat-chip>
            @if (orderOfOperations) {
              <i
                class="icon-chip"
                [ngClass]="getQuestionTypeIcon(baseFieldPrompt)"
              ></i>
              <span class="label-chip">
                {{ baseFieldPrompt }}
              </span>
            } @else {
              {{ baseFieldPrompt }}
            }
          </mat-chip>
        </mat-chip-set>
      </section>

      @if (actionData) {
        <section class="mt-2">
          <p class="sub-section-title">Appended Values:</p>
          <mat-chip-set>
            @for (item of readableDataValues; track $index) {
              @if (orderOfOperations) {
                <mat-chip>
                  @if (getQuestionTypeIcon(item)) {
                    <i
                      class="icon-chip"
                      [ngClass]="getQuestionTypeIcon(item)"
                    ></i>
                  }
                  <span class="label-chip">
                    {{ item }}
                  </span>
                </mat-chip>
              } @else {
                <mat-chip>{{ item }}</mat-chip>
              }
            }
          </mat-chip-set>
        </section>
      }
    </div>
  </section>
</div>
