<span
  [ngClass]="
    orderOfOperations && action.type === actionTypesEnum.AddRelationship
      ? 'action-title'
      : 'title-from-order-operation'
  "
>
  @if (!orderOfOperations) {
    <ng-container>Action: </ng-container>
  }
  Update Relationship</span
>
@if (!orderOfOperations) {
  <section class="mt-4">
    <p class="sub-section-title">Update Type:</p>
    <mat-chip>
      {{ action.type === actionTypesEnum.AddRelationship ? 'Add' : 'Remove' }}
      Relationship
    </mat-chip>
  </section>
}
<section class="custom-chips mt-4">
  <p class="sub-section-title">
    @if (orderOfOperations) {
      {{ action.type === actionTypesEnum.AddRelationship ? 'Add' : 'Remove' }}:
    } @else {
      Relationship type:
    }
  </p>
  <mat-chip-listbox>
    @for (relation of getRelationshipTypes(action); track $index) {
      <div class="pr-2">
        <mat-chip>
          @if (orderOfOperations) {
            <i class="fal fa-circle-nodes icon-chip"></i>
            <span class="label-chip">
              {{ cleanRelationLabel(relation) }}
            </span>
          } @else {
            {{ cleanRelationLabel(relation) }}
          }
        </mat-chip>
      </div>
    }
  </mat-chip-listbox>
</section>
@if (actionTypesEnum.AddRelationship === action.type) {
  <div class="mt-2">
    <p class="sub-section-title">
      @if (orderOfOperations) {
        To:
      } @else {
        Related {{ termsGeneric.Container.Single.toLowerCase() }}
      }
    </p>
    <mat-chip>
      @if (orderOfOperations) {
        <i class="fak fa-parent-container-id icon-chip"></i>
        <span class="label-chip">
          <ng-container [ngTemplateOutlet]="matChipText" />
        </span>
      } @else {
        <ng-container [ngTemplateOutlet]="matChipText" />
      }
    </mat-chip>
  </div>
}
<ng-template #matChipText>
  {{
    updateRelationshipAction.relatesTo === 'children'
      ? 'Children ' + termsGeneric.Container.Plural.toLowerCase() + ' (all)'
      : 'Parent ' + termsGeneric.Container.Single.toLowerCase()
  }}
</ng-template>
