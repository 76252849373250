import {
  OnChanges,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
  input,
  output,
  inject,
} from '@angular/core';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import {
  ContainerStationInformation,
  GridsterItemWidget,
  StationInfoDrawerData,
  StationInformation,
  WidgetType,
} from 'src/models';
import { OpenWindow, TermsGeneric } from 'src/helpers';
import { StationService } from 'src/app/core/station.service';
import { first, Subject, takeUntil } from 'rxjs';
import { ErrorService } from 'src/app/core/error.service';
import { ContainerService } from 'src/app/core/container.service';
import { UserService } from 'src/app/core/user.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RightClickMenuDirective } from 'src/helpers/directives/right-click-menu/right-click-menu.directive';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { StationGridComponent } from 'src/app/station/station-grid/station-grid.component';
import { PopupService } from 'src/app/core/popup.service';

/**
 * Component for the sub header on document/station views that houses the
 * comments and history.
 */
@Component({
  selector: 'app-sub-header[itemInfo]',
  templateUrl: './sub-header.component.html',
  standalone: true,
  imports: [
    CommonModule,
    LoadingIndicatorComponent,
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
    MatTabsModule,
    MatTooltipModule,
    RightClickMenuDirective,
  ],
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit, OnChanges, OnDestroy {
  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Information about the item displayed on the page with the sub header. */
  private _itemInfo!: ContainerStationInformation | StationInformation;

  /** Information about the item displayed on the page with the sub header. */
  @Input() set itemInfo(
    value: ContainerStationInformation | StationInformation,
  ) {
    this._itemInfo = value;
    if (this.isStation && value) {
      this.isWorkerOrOwner =
        (value as StationInformation).workers.some(
          (w) => w.rithmId === this.userService.user().rithmId,
        ) ||
        (value as StationInformation).stationOwners.some(
          (o) => o.rithmId === this.userService.user().rithmId,
        );
    }
  }

  /**
   * Get item info.
   * @returns Item info value.
   */
  get itemInfo(): ContainerStationInformation | StationInformation {
    return this._itemInfo;
  }

  /** Emit event to add widget. */
  addWidgetEvent = output<WidgetType>();

  /** Event to detect click comment outside. */
  @Output() checkClickSubHeader: EventEmitter<boolean> = new EventEmitter();

  /** Edit mode. */
  editMode = input.required<boolean>();

  /** Is component viewed in station edit mode? */
  @Input() stationEditMode = false;

  /** Whether the subheader is rendered in stationSection or containerSection. */
  @Input() isStation = false;

  /** Index tab selected. */
  @Input() indexTab = 0;

  /** The old index of the previously selected tab. */
  @Input() oldIndexTab = 0;

  /** The selected tab index/init. */
  @Output() headerSelectedTab = new EventEmitter<number>();

  /** Whether the screen width is smaller and too narrow. */
  @Input() nonEditableStation = false;

  /** Station grid instance. */
  @Input() stationGrid!: StationGridComponent;

  /** Station Widgets array. */
  @Input() inputFrameWidgetItems: GridsterItemWidget[] = [];

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Current active icon. */
  activeItem = 'none';

  /** Current Container Label. */
  containerLabel = '';

  /** Widget type enum. */
  widgetType = WidgetType;

  /** True if the user is architect else False. */
  isArchitect = false;

  /** True if the current user is into worker or owner list. */
  isWorkerOrOwner = false;

  /** Service of popup's. */
  private popupService: PopupService = inject(PopupService);

  constructor(
    private sidenavDrawerService: SidenavDrawerService,
    private stationService: StationService,
    private errorService: ErrorService,
    private containerService: ContainerService,
    private userService: UserService,
  ) {}

  /**
   * Init Component.
   *
   */
  ngOnInit(): void {
    this.subscribeContainerLabelText$();
    this.isArchitect = this.userService.isAdmin();
  }

  /**
   * Subscribe to containerLabelText$.
   *
   */
  private subscribeContainerLabelText$(): void {
    this.containerService.containerLabelText$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((containerLabel) => {
        if (containerLabel.rithmId === this.stationRithmId) {
          this.containerLabel =
            containerLabel.value || TermsGeneric.Container.Single;
        }
      });
  }

  /** OnChanges. */
  ngOnChanges(): void {
    if (this.itemInfo && !this.containerLabel.length) {
      if ('name' in this.itemInfo) {
        this.getContainerLabel(this.itemInfo.rithmId);
      }
    }
  }

  /**
   * Get the container label of the current product.
   * @param stationRithmId The current station rithmId.
   */
  private getContainerLabel(stationRithmId: string): void {
    this.stationService
      .getContainerLabel(stationRithmId)
      .pipe(first())
      .subscribe({
        next: (containerLabel) => {
          this.containerLabel = containerLabel || 'Container';
          this.containerService.containerLabelText$.next({
            rithmId: this.stationRithmId,
            value: this.containerLabel,
          });
        },
        error: (error: unknown) => {
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Add widget to frame.
   * @param event Type widget to add.
   */
  addInputFrame(event: WidgetType): void {
    this.addWidgetEvent.emit(event);
  }

  /**
   * The title to be displayed on the sub header.
   * @returns The title for the component.
   */
  get title(): string {
    if (!this.itemInfo) {
      return '';
    }
    return 'containerName' in this.itemInfo
      ? TermsGeneric.Container.Single
      : TermsGeneric.Station.Single;
  }

  /**
   * Get name of station from StationInformation based on type.
   * @returns The Station Name.
   */
  get stationName(): string {
    if (!this.itemInfo) {
      return '';
    }
    return 'stationName' in this.itemInfo
      ? this.itemInfo.stationName
      : this.itemInfo.name;
  }

  /**
   * The id of the station or document.
   * @returns The id of the station or document.
   */
  get stationRithmId(): string {
    if (!this.itemInfo) {
      return '';
    }
    return 'rithmId' in this.itemInfo
      ? this.itemInfo.rithmId
      : this.itemInfo.stationRithmId;
  }

  /**
   * Whether the info-drawer is opened.
   * @returns Return true if info-drawer is opened, false otherwise.
   */
  get isDrawerOpen(): boolean {
    return this.sidenavDrawerService.isDrawerOpen;
  }

  /**
   * Toggles the open state detail drawer for comments or history.
   * @param drawerItem The drawer item to toggle.
   */
  toggleDrawer(
    drawerItem: 'comments' | 'history' | 'stationInfo' | 'containerInfo',
  ): void {
    if (
      (drawerItem === 'history' && this.activeItem === 'none') ||
      (drawerItem === 'comments' && this.activeItem === 'none') ||
      (drawerItem === 'stationInfo' && this.activeItem === 'none')
    ) {
      this.activeItem = drawerItem;
    } else {
      this.activeItem = 'none';
    }
    if (drawerItem === 'stationInfo') {
      const dataInformationDrawer: StationInfoDrawerData = {
        stationRithmId: this.stationRithmId,
        stationName: this.stationName,
        editMode: this.stationEditMode,
        openedFromMap: false,
      };
      this.sidenavDrawerService.toggleDrawer(drawerItem, dataInformationDrawer);
    } else if (drawerItem === 'containerInfo') {
      this.sidenavDrawerService.toggleDrawer(drawerItem, {
        stationRithmId: this.stationRithmId,
        isStation: this.isStation,
      });
    } else {
      this.sidenavDrawerService.toggleDrawer(drawerItem, this.itemInfo);
    }
  }

  /**
   * Updating the tab's indexes and emitting.
   * @param index The index number of the tab.
   */
  updateTabIndex(index: number): void {
    this.indexTab = index;
    this.oldIndexTab = index;
    this.headerSelectedTab.emit(index);
  }

  /**
   * Detect tabs changed.
   * @param currentTab Receives the detail from tab selected.
   */
  async tabSelectedChanged(currentTab: MatTabGroup): Promise<void> {
    const newIndex = currentTab.selectedIndex;
    // If the index is equal to the old index we return.
    if (newIndex === this.oldIndexTab) {
      return;
    }
    // If it is current to the container tab, we return.
    if (newIndex === 0) {
      this.updateTabIndex(newIndex);
      return;
    }

    // If the new index is different from the old one, if it is edit mode we open the modal otherwise we don't show anything.
    if (newIndex !== this.oldIndexTab) {
      let response: boolean = false;
      // If there is any change in the grid.
      const somethingHasChanged =
        JSON.stringify(this.inputFrameWidgetItems) !==
        JSON.stringify(this.stationService.storedInputFrameWidgetItems);
      if (this.stationEditMode && somethingHasChanged) {
        currentTab.selectedIndex = this.oldIndexTab;
        response = await this.popupService.confirm({
          title: 'Are you sure?',
          message: 'Unsaved data will be lost',
          okButtonText: 'Continue',
          cancelButtonText: 'Cancel',
          important: true,
        });
        if (response) this.stationService.resetGridChanges$.next();
      } else {
        response = true;
      }
      if (response && newIndex) {
        this.updateTabIndex(newIndex);
      }
    }
  }

  /**
   * For open in new tab on right click.
   * @param containerRithmId The Id of the container to view.
   * @returns Url with param for the selected.
   */
  getUrl(containerRithmId: string): string {
    return OpenWindow.getUrlRightClick(containerRithmId, this.stationRithmId);
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
