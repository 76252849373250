import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SqlIntegrationType } from 'src/models';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

/**
 * Represents steps before creating an action to add sql integrations.
 */
@Component({
  selector: 'app-sql',
  standalone: true,
  imports: [CommonModule, FormsModule, MatSelectModule],
  templateUrl: './sql.component.html',
  styleUrls: ['./sql.component.scss'],
})
export class SqlComponent {
  /** Integration Action options types Push data and Pull data . */
  sqlIntegrationTypeOptions = [
    SqlIntegrationType.Push,
    SqlIntegrationType.Pull,
  ];

  /** Issues the value of type of sql integration selected. */
  @Output() typeSelected = new EventEmitter<SqlIntegrationType>();

  /**
   * Once the type is selected, the form will be output.
   * @param value The type selected.
   */
  emitSqlIntegrationType(value: SqlIntegrationType): void {
    this.typeSelected.emit(value);
  }
}
