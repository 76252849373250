<div
  class="notification-item flex flex-col"
  [ngClass]="
    orderOfOperations ? 'order-operations-notifications' : 'gap-y-4 p-4'
  "
>
  <section class="notification-item-title">
    @if (orderOfOperations) {
      <p class="text-base font-medium action-title">Notification Email</p>
    } @else {
      <h6 class="text-xl tracking-wide">Email</h6>
    }
  </section>

  <section class="notification-item-recipients notification-item-content">
    <p
      class="text-primary-500 text-xs"
      [ngClass]="{ 'sub-section-title': orderOfOperations }"
    >
      To:
    </p>
    <div class="chips-container">
      @for (member of memberRecipients; track $index) {
        <mat-chip
          [attr.data-testid]="'to-member-' + member.rithmId"
          [id]="'to-member-' + member.rithmId"
          class="mr-2"
        >
          <div class="flex items-center">
            <ng-container
              *ngTemplateOutlet="
                recipientOption;
                context: { $implicit: member }
              "
            ></ng-container>
          </div>
        </mat-chip>
      }

      @for (recipient of emailRecipients; track $index) {
        <mat-chip class="mr-2" [ngClass]="{ 'label-chip': orderOfOperations }">
          <i class="fal fa-at text-sky-500 text-sm"></i>
          {{ getChipLabel(recipient) }}
        </mat-chip>
      }
    </div>
  </section>

  <section
    class="self-start w-full notification-message"
    [ngClass]="{ 'space-y-4': !orderOfOperations }"
  >
    <p
      class="text-primary-500 text-xs"
      [ngClass]="{ 'sub-section-title': orderOfOperations }"
    >
      Subject
    </p>
    <div class="notification-chips chips-container w-full">
      @for (subject of getNotificationSubjects(); track $index) {
        <mat-chip class="mr-2 max-w-full truncate">
          <i [ngClass]="getSubjectIcon(subject)"></i>
          {{ getChipLabel(subject) }}
        </mat-chip>
      }
    </div>
    <p class="bg-gray-200 rounded p-2 w-full">
      {{ getNotificationMessage() }}
    </p>
  </section>
</div>

<!-- Recipient Option.-->
<ng-template #recipientOption let-member>
  @if (showIcon(member)) {
    <i
      [ngClass]="componentHelper.originIcon(member.type)"
      class="text-secondary-300 pr-2"
    ></i>
  } @else {
    <ng-container
      [ngTemplateOutletContext]="{
        data: member
      }"
      [ngTemplateOutlet]="avatarImage"
    ></ng-container>
  }
  <p>{{ member.name }}</p>
</ng-template>

<!-- Avatar profile image field-->
<ng-template #avatarImage let-data="data">
  <app-user-avatar
    [firstName]="data.firstName || ''"
    [lastName]="data.lastName || ''"
    [size]="'20'"
    [profileImageRithmId]="data.profileImageRithmId || ''"
    class="relative right-1"
  ></app-user-avatar>
</ng-template>
