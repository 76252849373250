import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  ActionType,
  PowerAction,
  PowerEventType,
  Question,
  SqlIntegrationType,
} from 'src/models';
import { ChatGptFormComponent } from './chat-gpt/chat-gpt-form/chat-gpt-form.component';
import { ChatGptItemComponent } from './chat-gpt/chat-gpt-item/chat-gpt-item.component';
import { MatIconModule } from '@angular/material/icon';
import { PopupService } from 'src/app/core/popup.service';
import { MatMenuModule } from '@angular/material/menu';
import { SqlComponent } from './sql/sql/sql.component';
import { SqlFormComponent } from './sql/sql-form/sql-form.component';
import { SqlItemComponent } from './sql/sql-item/sql-item.component';
import { MatDividerModule } from '@angular/material/divider';
import { PowerService } from 'src/app/core/power.service';
import { first } from 'rxjs';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { v4 as uuidv4 } from 'uuid';
import { ActionHelper } from 'src/helpers';
/** Integrations component. */
@Component({
  selector: 'app-integrations[actions][stationRithmId]',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    ChatGptFormComponent,
    ChatGptItemComponent,
    MatIconModule,
    MatMenuModule,
    SqlFormComponent,
    SqlItemComponent,
    MatDividerModule,
    SqlComponent,
    LoadingIndicatorComponent,
  ],
})
export class IntegrationsComponent implements OnInit {
  /** The component to access the chatGpt form methods. */
  @ViewChild('chatGptFormComponent', { static: false })
  chatGptFormComponent!: ChatGptFormComponent;

  /** The component for accessing sql form methods. */
  @ViewChild('sqlFormComponent', { static: false })
  sqlFormComponent!: SqlFormComponent;

  /** The modified/added action type to send back to flow. */
  @Output() actionsUpdEmitter = new EventEmitter<PowerAction[]>();

  /** The station id used to get shared values. */
  @Input() stationRithmId!: string;

  /** Actions of the current power. */
  @Input() actions: PowerAction[] = [];

  /** Feature flag Sql Integration. */
  @Input() showOptionSqlIntegration = false;

  /** Whether should show list integration. */
  @Input() showList = false;

  /** Rule rithmId. */
  @Input() ruleRithmId = '';

  /** Bucket questions. */
  @Input() set bucketQuestions(bucket: Question[]) {
    this._bucketQuestions = bucket;
  }

  /** This is a setter. It is called when the value of the input property changes. */
  @Input() set refreshIdContainer(value: string) {
    this._refreshIdContainer = value;
    this.showIntegration = false;
  }

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /**
   * Get bucket question.
   * @returns Bucket question data.
   */
  get bucketQuestions(): Question[] {
    return this._bucketQuestions;
  }

  /* A private variable that is used to store the value of the input property. */
  private _refreshIdContainer = '';

  /**
   * It returns the value of the private variable _refreshIdContainer.
   * @returns The value of the new id container.
   */
  get refreshIdContainer(): string {
    return this._refreshIdContainer;
  }

  /**
   * Returns list of integrations-actions .
   * @returns List of actions.
   */
  get getIntegrationsActions(): PowerAction[] {
    return this.actions.filter((action) =>
      ActionHelper.integrationActions.includes(action.type),
    );
  }

  /**
   * Whether to show or hide the DoneButton.
   * @returns Boolean.
   */
  get showDoneButton(): boolean {
    if (this.selectedIntegrationType.length) {
      if (this.selectedIntegrationType === ActionType.Sql) {
        return this.sqlFormDisplayed;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  /** Bucket questions. */
  _bucketQuestions: Question[] = [];

  /** Show/hide Integration form. */
  showIntegration = false;

  /** Integration Action types. */
  integrationActionTypes = [ActionType.Sql, ActionType.ChatGPT];

  /** Action of type UpdateField to be edited. */
  actionToBeUpdated: PowerAction | null = null;

  /** Action type enum. */
  actionType = ActionType;

  /** Is chat GPT form valid or not. */
  isChatGptFormValid = false;

  /** Selected Integration Type. */
  selectedIntegrationType = '';

  /** Whether the sql form is already displayed. */
  sqlFormDisplayed = false;

  /** Show/hide Action type form. */
  showActionType = false;

  /** Show/hide SQL Action type form. */
  showSqlAction = false;

  /** Sql Integration Type Selected. */
  sqlTypeSelected!: SqlIntegrationType | null;

  /** Whether you are saving the current integration action . */
  savingAction = false;

  /** Whether is loading petition delete action. */
  deletingAction = false;

  /** Integration action types. */
  readonly integrationActions = [
    ActionType.ChatGPT,
    ActionType.Sql,
    ActionType.SqlPull,
    ActionType.SqlPush,
  ];

  /* Whether the sql form is valid or not. */
  isSqlFormValid = false;

  constructor(
    private popupService: PopupService,
    private powerService: PowerService,
  ) {}

  /**
   * Initial Method.
   */
  ngOnInit(): void {
    if (!this.showOptionSqlIntegration) {
      this.integrationActionTypes = [ActionType.ChatGPT];
    }
  }

  /**
   * Set the integration action to initial view.
   */
  createIntegrationAction(): void {
    this.showIntegration = true;
    this.selectedIntegrationType = '';
    this.actionToBeUpdated = null;
  }

  /**
   * Select a integration type from autocomplete dropdown.
   * @param type Selected integration type.
   */
  selectIntegrationType(type: string): void {
    this.isChatGptFormValid = false;
    this.selectedIntegrationType = type;
    this.showActionType = type === ActionType.Sql ? true : false;
  }

  /**
   * Saving Integration action changes.
   * @param powerToAdd The received power to add/edit into the action object.
   */
  public saveIntegrationAction(powerToAdd: PowerAction): void {
    const actionIndex = this.actions.findIndex(
      (action) => action.rithmId === powerToAdd.rithmId,
    );
    if (this.orderOfOperations) {
      // Integration has not been saved.
      if (actionIndex < 0) {
        const newOrder = this.actions.length + 1;
        // We update the order of the current action.
        powerToAdd.order = newOrder;
      }
      this.addOrUpdateSpecificAction(
        powerToAdd,
        this.actions.some((x) => x.rithmId === powerToAdd.rithmId),
      );
    } else {
      if (actionIndex >= 0) {
        this.actions[actionIndex] = powerToAdd as PowerAction;
      } else {
        this.actions.push(powerToAdd);
      }
      this.actionsUpdEmitter.emit(this.actions);
      this.actionToBeUpdated = null;
    }
  }

  /**
   * Add or update action.
   * @param powerAction The current action to be created or updated.
   * @param isUpdate Whether should update action.
   * @param isDuplicate Whether should duplicate action.
   */
  addOrUpdateSpecificAction(
    powerAction: PowerAction,
    isUpdate = false,
    isDuplicate = false,
  ): void {
    const eventAction = isDuplicate
      ? PowerEventType.Duplicate
      : isUpdate
        ? PowerEventType.Update
        : PowerEventType.Add;
    this.savingAction = true;
    if (isDuplicate) {
      powerAction = {
        ...powerAction,
        rithmId: uuidv4(),
      };
    }
    this.powerService
      .addOrUpdateSpecificAction(this.ruleRithmId, powerAction)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: eventAction,
            action: powerAction,
            powerRithmId: this.ruleRithmId,
          });
          this.savingAction = false;
          this.cancelActions();
        },
        error: () => {
          this.savingAction = false;
          this.popupService.notify('Error to save action', true);
        },
      });
  }

  /**
   * Delete action.
   * @param action  Delete the current action.
   */
  deleteAction(action: PowerAction): void {
    this.deletingAction = true;
    this.powerService
      .deleteAction(this.ruleRithmId, action.rithmId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: PowerEventType.Delete,
            action,
            powerRithmId: this.ruleRithmId,
          });
          this.deletingAction = false;
          this.cancelActions();
        },
        error: () => {
          this.deletingAction = false;
          this.popupService.notify('Error to delete action', true);
        },
      });
  }

  /**
   * Pass the edit integration object to form.
   * @param action Action to be edited.
   */
  editIntegration(action: PowerAction): void {
    this.actionToBeUpdated = action;
    this.showIntegration = true;
    this.selectedIntegrationType = action.type;
    if ([ActionType.SqlPull, ActionType.SqlPush].includes(action.type)) {
      this.sqlFormDisplayed = this.showSqlAction = this.showIntegration;
      this.sqlTypeSelected =
        action.type === ActionType.SqlPull
          ? SqlIntegrationType.Pull
          : SqlIntegrationType.Push;
    }
  }

  /**
   * Popup confirmation delete/update integration.
   * @param action Action of the integration to be deleted.
   */
  async confirmRemoveIntegration(action: PowerAction): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'This Integration will be deleted',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (response) {
      if (this.orderOfOperations) {
        this.deleteAction(action);
      } else {
        const actionIndex = this.actions.findIndex(
          (x) => x.rithmId === action.rithmId,
        );
        this.actions.splice(actionIndex, 1);
        this.actionsUpdEmitter.emit(this.actions);
      }
    }
  }

  /**
   * Add/update new integration action.
   */
  submitIntegration(): void {
    !this.showSqlAction
      ? this.chatGptFormComponent.addUpdateIntegrationAction()
      : this.sqlFormComponent.emitIntegrationAction();

    if (!this.orderOfOperations) this.cancelActions();
  }

  /**
   * Once selected the sql integration type.
   * @param value Type.
   */
  sqlTypeActionSelected(value: SqlIntegrationType): void {
    this.sqlTypeSelected = value;
  }

  /**
   * Actions when you press cancel button.
   */
  cancelActions(): void {
    this.showIntegration = this.sqlFormDisplayed = false;
    this.selectedIntegrationType = '';
    this.showActionType = false;
    this.showSqlAction = false;
    this.sqlTypeSelected = null;
  }

  /**
   * Actions when you press Next button.
   */
  nexActions(): void {
    this.showSqlAction = true;
    this.sqlFormDisplayed = true;
    this.showActionType = false;
  }

  /**
   * The status of the sql form.
   * @param status The validity status in the sql form.
   */
  onSqlFormStatus(status: boolean): void {
    this.isSqlFormValid = status;
  }
}
