import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AdvancedUpdateFieldData,
  CustomField,
  PowerAction,
  Question,
  QuestionFieldType,
  Station,
  StationContainerLabel,
  StationFieldPrefix,
} from 'src/models';
import { StationService } from 'src/app/core/station.service';
import { first, forkJoin } from 'rxjs';
import _ from 'lodash';
import { MatChipsModule } from '@angular/material/chips';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { ComponentHelper, TermsGeneric } from 'src/helpers';

/** Advance update action as item on the list. */
@Component({
  selector: 'app-advanced-update-action-item',
  standalone: true,
  imports: [CommonModule, MatChipsModule, LoadingIndicatorComponent],
  templateUrl: './advanced-update-action-item.component.html',
  styleUrls: ['./advanced-update-action-item.component.scss'],
})
export class AdvancedUpdateActionItemComponent implements OnInit {
  /** Power action to display. */
  @Input({ required: true }) action!: PowerAction;

  /** Stations to display as option selected. */
  @Input({ required: true }) stations: Station[] = [];

  /** Stations to display as option selected. */
  @Input({ required: true }) currentStation!: Station | undefined;

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /**Advance data to use. */
  advancedUpdateFieldData: AdvancedUpdateFieldData = {
    targetStations: [],
    field: '',
    sourceStations: [],
    sourceValue: '',
    matchingFields: [],
  };

  /** Container Label Updating. */
  containerLabelUpdating = '';

  /**Global Match to use to get updated names of fields. */
  globalMatching: (Question | StationContainerLabel)[] = [];

  /** Matching updating section. */
  matchingUpdating: Question[] | StationContainerLabel[] = [];

  /** Matching source section. */
  matchingSource: Question[] | StationContainerLabel[] = [];

  /** Matching source section. */
  matchingUpdatingAll: Question[] | StationContainerLabel[] = [];

  /** Is loading. */
  isLoading = true;

  /** Is error getting field. */
  isError = false;

  /* System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** The Container Info. */
  containerInfo: CustomField[] = [
    {
      prompt: this.termsGeneric.Container.Single + ' Name',
      enabled: true,
      icon: 'fa-thin fa-box',
      questionType: QuestionFieldType.ContainerName,
    },
    {
      prompt: 'Assigned User',
      enabled: true,
      icon: 'fa-kit fa-container-user',
      questionType: QuestionFieldType.AssignedUser,
    },
  ];

  /** The Container Info. */
  containerInfoSource: CustomField[] = [
    {
      prompt: this.termsGeneric.Container.Single + ' Name',
      enabled: true,
      icon: 'fa-thin fa-box',
      questionType: QuestionFieldType.ContainerName,
    },
    {
      prompt: 'Assigned User',
      enabled: true,
      icon: 'fa-kit fa-container-user',
      questionType: QuestionFieldType.AssignedUser,
    },
  ];

  constructor(private stationService: StationService) {}

  /** Init method. */
  ngOnInit(): void {
    this.advancedUpdateFieldData = JSON.parse(
      this.action.data,
    ) as AdvancedUpdateFieldData;
    this.getDataSection('station', this.advancedUpdateFieldData.targetStations);
    this.getDataSection(
      'matching',
      this.advancedUpdateFieldData.targetStations.concat(
        this.advancedUpdateFieldData.sourceStations,
      ),
    );
    this.getDataSection('source', this.advancedUpdateFieldData.sourceStations);
  }

  /**
   * Get prompt station name by id.
   * @param rithmId Id to find.
   * @returns Bucket prompt.
   */
  getStationName(rithmId: string): string {
    return (
      this.stations.find((bucket) => bucket.rithmId === rithmId)?.name ||
      (this.currentStation &&
        (this.currentStation.rithmId === rithmId
          ? this.currentStation.name
          : '')) ||
      ''
    );
  }

  /**
   * Get prompt station name by id.
   * @param rithmId Id to find.
   * @returns Bucket prompt.
   */
  getQuestionName(rithmId: string): string {
    const id = rithmId.replace('rid:', '');
    return (
      _.find(
        this.globalMatching as Question[],
        (item: Question) => item.rithmId === id,
      )?.prompt ||
      _.find(this.containerInfo, (item) => item.questionType === id)?.prompt ||
      rithmId
    );
  }

  /**
   * Get prompt station name by id.
   * @param customField Id to find.
   * @returns Bucket prompt.
   */
  getCustomName(customField: unknown): string {
    //It is object question
    if ((customField as Question).rithmId) {
      return this.orderOfOperations
        ? ''
        : 'Field: ' + (customField as Question).prompt;
    } else if (typeof customField === 'string') {
      const id = customField.replace('rid:', '');
      const fieldValue =
        (this.globalMatching as Question[]).find(
          (item: Question) => item.rithmId === id,
        )?.prompt ||
        this.containerInfoSource.find((item) => item.questionType === id)
          ?.prompt;
      let textCustom = '';
      if (this.orderOfOperations) {
        textCustom = fieldValue ? fieldValue : id;
      } else {
        textCustom = fieldValue ? 'Field: ' + fieldValue : 'Custom: ' + id;
      }
      return textCustom;
    } else {
      return (
        (this.orderOfOperations ? '' : 'Custom: ') +
        (customField as CustomField).prompt
      );
    }
  }

  /**
   * Get questions by getMatchingQuestions of stationService.
   * @param section 1 = updating section, 2 = source Section.
   * @param stationRithmIdsUpdatedSection Station ids.
   */
  getDataSection(
    section: string,
    stationRithmIdsUpdatedSection: string[],
  ): void {
    this.isError = false;
    this.isLoading = true;
    const requestArray = [
      this.stationService.getMatchingQuestions(
        stationRithmIdsUpdatedSection,
        section === 'matching',
      ),
      this.stationService.getStationContainerLabels(
        stationRithmIdsUpdatedSection,
      ),
    ];
    forkJoin(requestArray)
      .pipe(first())
      .subscribe({
        next: ([matchingQuestions, labelData]) => {
          if (section === 'station') {
            this.matchingUpdating = matchingQuestions;
            const labelDataArray = labelData as StationContainerLabel[];

            this.containerInfo[0].prompt =
              this.getContainerName(labelDataArray);
          }
          if (section === 'source') {
            this.matchingSource = matchingQuestions;
            const labelDataArray: [] = labelData as [];
            this.containerInfoSource[0].prompt =
              this.getContainerName(labelDataArray);
          }

          if (section === 'matching') {
            this.matchingUpdatingAll = matchingQuestions;
          }
          this.globalMatching = [
            ...this.matchingUpdating,
            ...this.matchingSource,
            ...this.matchingUpdatingAll,
          ];
          this.isError = false;
          this.isLoading = false;
        },
        error: () => {
          this.isError = true;
          this.isLoading = false;
        },
      });
  }

  /**
   * Get Container Name.
   * @param labelDataArray Label Array.
   * @returns Container name.
   */
  private getContainerName(labelDataArray: StationContainerLabel[]): string {
    return labelDataArray.some(
      (data: StationContainerLabel) => data.containerLabel,
    )
      ? _.join(_.compact(_.map(labelDataArray, 'containerLabel')), ' / ')
      : this.termsGeneric.Container.Single + ' Name';
  }

  /**
   * Get the icon of the question type by id.
   * @param rithmIdOrQuestionType The identifier or type of question to find.
   * @returns The icon class.
   */
  getQuestionTypeIcon(rithmIdOrQuestionType: string): string {
    const isCustomField = rithmIdOrQuestionType.includes(
      StationFieldPrefix.CustomField,
    );

    if (!isCustomField) {
      const currentIcon = ComponentHelper.componentIcon(rithmIdOrQuestionType);
      return currentIcon !== 'fal fa-list-ul' ? currentIcon : '';
    }

    const id = rithmIdOrQuestionType.replace(
      StationFieldPrefix.CustomField,
      '',
    );
    const currentQuestionType =
      _.find(
        this.globalMatching as Question[],
        (item: Question) => item.rithmId === id,
      )?.questionType ||
      (_.find(this.containerInfo, (item) => item.questionType === id)
        ?.questionType as QuestionFieldType);
    return ComponentHelper.componentIcon(currentQuestionType);
  }
}
