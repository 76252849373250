import { Component, EventEmitter, OnInit, Output, input } from '@angular/core';
import { PowerTrigger, PowerTriggerToUpdate, Question } from 'src/models';
import { ScheduledTriggerListComponent } from 'src/app/station/rules/triggers/scheduled-trigger/scheduled-trigger-list/scheduled-trigger-list.component';
import { ScheduledTriggerFormComponent } from 'src/app/station/rules/triggers/scheduled-trigger/scheduled-trigger-form/scheduled-trigger-form.component';
import { SplitService } from 'src/app/core/split.service';
import { UserService } from 'src/app/core/user.service';
import { first } from 'rxjs';
import { UtcTimeConversionPipe } from 'src/helpers/pipes/utc-time-conversion/utc-time-conversion.pipe';

/**
 * Scheduled Trigger Component.
 */
@Component({
  selector: 'app-scheduled-trigger',
  standalone: true,
  imports: [ScheduledTriggerListComponent, ScheduledTriggerFormComponent],
  providers: [UtcTimeConversionPipe],
  templateUrl: './scheduled-trigger.component.html',
  styleUrl: './scheduled-trigger.component.scss',
})
export class ScheduledTriggerComponent implements OnInit {
  /** Scheduled triggers list. */
  scheduledTriggers = input.required<PowerTrigger[]>();

  /** List of questions. */
  bucketQuestions = input.required<Question[]>();

  /** Handle scheduled trigger event. */
  @Output() handleScheduledTrigger = new EventEmitter<PowerTriggerToUpdate>();

  /** Change trigger status event. */
  @Output() changeTriggerStatus = new EventEmitter<{
    /** Trigger id. */
    triggerId: string;
    /** Trigger status. */
    status: boolean;
  }>();

  /** If the form is visible. */
  isFormVisible = false;

  /** Handle trigger data. */
  handleTriggerData!: Partial<PowerTriggerToUpdate>;

  /** Timezone setting flag. */
  timeZoneSettingFlag = false;

  constructor(
    private userService: UserService,
    private splitService: SplitService,
  ) {}

  /**
   * On init lifecycle hook.
   */
  ngOnInit(): void {
    this.getTreatment();
  }

  /**
   * Get treatment for the feature flag.
   */
  private getTreatment(): void {
    const organizationId = this.userService.user().organization;
    this.splitService.initSdk(organizationId);
    this.splitService.sdkReady$.pipe(first()).subscribe({
      next: () => {
        this.timeZoneSettingFlag =
          this.splitService.getTimeZoneSetting() === 'on';
      },
    });
  }

  /**
   * Handle trigger update/creation.
   * @param event Trigger to update.
   */
  handleScheduledTriggerChanges(event: Partial<PowerTriggerToUpdate>): void {
    this.handleTriggerData = event;
    if (event.trigger) {
      this.handleScheduledTrigger.emit(event as PowerTriggerToUpdate);
    }
  }

  /**
   * Handle trigger status update.
   * @param triggerId Trigger id.
   * @param status Trigger status.
   */
  handleChangeTriggerStatus(triggerId: string, status: boolean): void {
    this.changeTriggerStatus.emit({
      triggerId,
      status,
    });
  }
}
