<section class="flex flex-col h-full w-full p-4">
  <!-- Modal header -->
  <section class="modal-title flex flex-col gap-4">
    <span class="text-xl font-semibold tracking-wide">Custom Field:</span>
    <span class="text-primary-300 text-sm">
      Link data from the {{ termsGeneric.Container.Single | lowercase }} or a
      custom field to populate the subject field. When a
      {{ termsGeneric.Container.Single | lowercase }} triggers this rule, we'll
      use data from that {{ termsGeneric.Container.Single | lowercase }} to
      populate the info.
    </span>

    <form [formGroup]="subjectForm">
      <mat-form-field
        class="w-full"
        appearance="outline"
        subscriptSizing="dynamic"
      >
        <mat-label>Field Name</mat-label>
        <span matPrefix>
          <i class="fa-light fa-magnifying-glass text-gray-500"></i>
        </span>
        <input
          matInput
          type="text"
          formControlName="subjectSearch"
          autocomplete="off"
          #filterInput
        />
      </mat-form-field>
    </form>
  </section>

  <!-- Modal content area -->
  <section class="modal-content scrollbar">
    @if ((filteredInfoOptions$ | async)?.length) {
      <div class="w-full">
        <p class="text-gray-400 text-sm">
          {{ termsGeneric.Container.Single }} Info
        </p>
        <mat-list>
          @for (option of filteredInfoOptions$ | async; track $index) {
            <mat-list-item
              class="!h-auto mb-2 !cursor-pointer hover:bg-gray-100 !p-2"
              (click)="closeModal(option)"
            >
              <div class="field-option">
                <i
                  class="fa-sm field-icon text-secondary-400"
                  [ngClass]="componentHelper.componentIcon(option.questionType)"
                >
                </i>
                {{ option.prompt }}
              </div>
            </mat-list-item>
          }
        </mat-list>
      </div>
    }

    @if ((filteredNonInfoOptions$ | async)?.length) {
      <div class="w-full">
        <p class="text-gray-400 text-sm">Custom Fields</p>
        <mat-list>
          @for (option of filteredNonInfoOptions$ | async; track $index) {
            <mat-list-item
              class="!h-auto mb-2 !cursor-pointer hover:bg-gray-100 !p-2"
              (click)="closeModal(option)"
            >
              <div class="field-option">
                <i
                  class="fa-sm field-icon text-accent-500"
                  [ngClass]="componentHelper.componentIcon(option.questionType)"
                >
                </i>
                <div class="w-full flex justify-between">
                  <div>{{ option.prompt }}</div>
                  <div class="w-1/2 flex justify-between">
                    @if (option.onTemplate) {
                      <div class="field-label">on template</div>
                    }
                    <div class="w-full flex justify-end">
                      <div class="field-label">
                        type:
                        {{
                          componentHelper.componentTypeAsTitle(
                            option.questionType
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-list-item>
          }
        </mat-list>
      </div>
    }

    @if (customFieldsLoading) {
      <ng-template *ngTemplateOutlet="loadingTemplate"></ng-template>
    }

    @if (customFieldsError) {
      <ng-template *ngTemplateOutlet="errorTemplate"></ng-template>
    }
  </section>

  <!-- Modal footer area -->
  <mat-dialog-actions align="end">
    <button
      mat-button
      type="button"
      class="font-semibold uppercase"
      color="accent"
      id="close-modal-button"
      data-testid="close-modal-button"
      [mat-dialog-close]="true"
    >
      Close
    </button>
  </mat-dialog-actions>
</section>

<!-- Loading template-->
<ng-template #loadingTemplate>
  <div class="w-full flex justify-center pt-2">
    <app-loading-indicator inlineText="Loading..."></app-loading-indicator>
  </div>
</ng-template>
<!-- Error template-->
<ng-template #errorTemplate>
  <p class="text-error-500">Error getting list.</p>
</ng-template>
