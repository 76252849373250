<section class="scheduled-section">
  @if (!isFormVisible) {
    <app-scheduled-trigger-list
      [timeZoneSettingFlag]="timeZoneSettingFlag"
      [scheduledTriggers]="scheduledTriggers()"
      (handleTrigger)="handleScheduledTriggerChanges($event)"
      (changeTriggerStatus)="
        handleChangeTriggerStatus($event.triggerId, $event.status)
      "
      (showForm)="isFormVisible = true"
    />
  } @else {
    <app-scheduled-trigger-form
      [scheduledTrigger]="handleTriggerData.trigger"
      [bucketQuestions]="bucketQuestions()"
      (saveScheduledTrigger)="handleScheduledTriggerChanges($event)"
      (hideForm)="isFormVisible = false"
    />
  }
</section>
