import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  inject,
  input,
  model,
  output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { first } from 'rxjs';
import { OrganizationService } from 'src/app/core/organization.service';
import { PopupService } from 'src/app/core/popup.service';
import { PowerService } from 'src/app/core/power.service';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';
import { TermsGeneric } from 'src/helpers';
import {
  ActionType,
  ManagementMembersActionData,
  ManagementMembersActionDataItem,
  PowerAction,
  PowerEventType,
  Question,
  QuestionFieldType,
  StationOrGroup,
} from 'src/models';
import { v4 as uuidv4 } from 'uuid';

/**
 * Component to show action management members.
 */
@Component({
  selector: 'app-management-members-action-item',
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    UserAvatarComponent,
  ],
  templateUrl: './management-members-action-item.component.html',
  styleUrl: './management-members-action-item.component.scss',
})
export class ManagementMembersActionItemComponent implements OnInit {
  /** Feature flag order of operations. */
  orderOfOperations = input.required<boolean>();

  /** Action management members. */
  @Input({ required: true }) action!: PowerAction;

  /** Total actions. */
  totalActions = input.required<number>();

  /** The station id used to get shared values. */
  stationRithmId = input.required<string>();

  /** Current rithmId of the rule. */
  ruleRithmId = input.required<string>();

  /** Bucket questions. */
  bucketQuestion = input.required<Question[]>();

  /** Emit edit action. */
  editActionManagementMember = output<void>();

  /** Emits when an action has been deleted. */
  hasBeenDeleted = output<void>();

  /** The actions for removing, updating or duplicating bilaterally. */
  actions = model<PowerAction[]>([]);

  /** Data of action management members. */
  dataAction!: ManagementMembersActionData;

  /** QuestionFieldType enum. */
  enumQuestionFieldType = QuestionFieldType;

  /** Data of groups and stations of org. */
  listStationsAndGroups: StationOrGroup[] = [];

  /** Users, teams and questions saved on action mark as workers. */
  usersWorkers: ManagementMembersActionDataItem[] = [];

  /** Users, teams and questions saved on action mark as not workers. */
  usersMembers: ManagementMembersActionDataItem[] = [];

  /** Station and groups indexes. */
  stationsAndGroups: {
    [key: string]: {
      /** Name element. */
      name: string;
      /** Type element. */
      type: 'station' | 'group';
    };
  } = {};

  /** Terms generic. */
  termsGeneric = TermsGeneric;

  /** Action type. */
  enumActionType = ActionType;

  /** Loading stations and groups. */
  loadingStationsAndGroup = false;

  /** Whether the delete action request is loading or not. */
  deletingAction = false;

  /** Organization service. */
  private organizationService = inject(OrganizationService);

  /** Service of popup's. */
  private popupService: PopupService = inject(PopupService);

  /** Service of power's. */
  private powerService: PowerService = inject(PowerService);

  /** Init life cycle. */
  ngOnInit(): void {
    //Variable to get data information to action.
    this.action?.data && (this.dataAction = JSON.parse(this.action?.data));
    const questions = this.dataAction.source.questions.map((question) => {
      return {
        ...question,
        isQuestion: true,
        firstName:
          this.bucketQuestion().find(
            (bucket) => bucket.rithmId === question.rithmId,
          )?.prompt || question.firstName,
      };
    });

    this.usersWorkers = [
      ...questions,
      ...this.dataAction.source.users,
      ...this.dataAction.source.teams,
    ].filter((element) => element.setAsWorker);

    this.usersMembers = [
      ...questions,
      ...this.dataAction.source.users,
      ...this.dataAction.source.teams,
    ].filter((element) => element.setAsMember);

    this.getStationsAndGroups();
  }

  /** Get list of stations and groups of organization. */
  getStationsAndGroups(): void {
    this.loadingStationsAndGroup = true;
    this.organizationService
      .getStationsAndGroups(
        '',
        this.dataAction.target.stations.length +
          this.dataAction.target.groups.length,
        1,
        this.dataAction.target.stations,
        this.dataAction.target.groups,
      )
      .pipe(first())
      .subscribe({
        next: (data) => {
          for (const element of data) {
            this.stationsAndGroups = {
              ...this.stationsAndGroups,
              [element.rithmId]: {
                name: element.name,
                type: element.type,
              },
            };
          }
          this.loadingStationsAndGroup = false;
        },
        error: () => {
          this.loadingStationsAndGroup = false;
        },
      });
  }

  /**
   * Method to duplicate action.
   */
  duplicateAction(): void {
    this.action.order = this.totalActions() + 1;
    const powerAction = {
      ...this.action,
      rithmId: uuidv4(),
    };
    this.powerService
      .addOrUpdateSpecificAction(this.ruleRithmId(), powerAction)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: PowerEventType.Duplicate,
            action: powerAction,
            powerRithmId: this.ruleRithmId(),
          });
        },
        error: () => {
          this.popupService.notify('Error to save action', true);
        },
      });
  }

  /**
   * Popup confirmation delete action.
   */
  async confirmDeleteAction(): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'This Action will be deleted',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (response) {
      this.deleteAction();
    }
  }

  /** Delete action. */
  deleteAction(): void {
    if (this.orderOfOperations()) {
      this.deletingAction = true;
      this.powerService
        .deleteAction(this.ruleRithmId(), this.action.rithmId)
        .pipe(first())
        .subscribe({
          next: () => {
            this.powerService.setPowerAction({
              eventAction: PowerEventType.Delete,
              action: this.action,
              powerRithmId: this.ruleRithmId(),
            });
            this.deletingAction = false;
          },
          error: () => {
            this.deletingAction = false;
            this.popupService.notify('Error to delete action', true);
          },
        });
    } else {
      const actionIndex = this.actions().findIndex(
        (currentAction) => currentAction.rithmId === this.action.rithmId,
      );
      this.actions().splice(actionIndex, 1);
      this.hasBeenDeleted.emit();
    }
  }

  /** Edit action. */
  editAction(): void {
    this.editActionManagementMember.emit();
  }
}
