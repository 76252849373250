import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { TermsGeneric } from 'src/helpers';
import {
  ActionType,
  ConnectedStationInfo,
  PowerAction,
  PowerEventType,
} from 'src/models';
import { FlowContainerFormActionComponent } from '../flow-container-form-action/flow-container-form-action.component';
import { PowerService } from 'src/app/core/power.service';
import { first } from 'rxjs';
import { PopupService } from 'src/app/core/popup.service';
import { v4 as uuidv4 } from 'uuid';

/** List a single item for each flow container action item. */
@Component({
  selector: 'app-flow-container-item-action',
  standalone: true,
  imports: [
    CommonModule,
    MatChipsModule,
    MatButtonModule,
    MatMenuModule,
    FlowContainerFormActionComponent,
  ],
  templateUrl: './flow-container-item-action.component.html',
  styleUrl: './flow-container-item-action.component.scss',
})
export class FlowContainerItemActionComponent implements OnInit {
  /** Total actions. */
  @Input({ required: true }) totalActions = 0;

  /** Stations. */
  @Input() stations: ConnectedStationInfo[] = [];

  /** Action. */
  private _action!: PowerAction;

  /** Stations. */
  @Input() set action(value: PowerAction) {
    if (value) {
      this._action = value;
      this.setStation();
    }
  }

  /**
   * Get current power action.
   * @returns Action.
   */
  get action(): PowerAction {
    return this._action;
  }

  /** Rule power. */
  @Input({ required: true }) ruleRithmId = '';

  /** Feature flag order of operations. */
  @Input({ required: true }) orderOfOperations = false;

  /** Emit event for close form. */
  @Output() editMode = new EventEmitter<void>();

  /** Type of actions. */
  actionTypesEnum = ActionType;

  /** Stations to flow. */
  stationsToFlow: ConnectedStationInfo[] = [];

  /** Ids of stations to flow. */
  actionsIds: string[] = [];

  /** Whether actions is update action. */
  isEditAction = false;

  /** Deleting action. */
  deletingAction = false;

  /* System-wide generic terms. */
  termsGeneric = TermsGeneric;

  constructor(
    private powerService: PowerService,
    private popupService: PopupService,
  ) {}

  /**
   * Init Method.
   */
  ngOnInit(): void {
    this.setStation();
  }

  /**
   * Set next stations.
   */
  setStation(): void {
    if (this.action) {
      this.stationsToFlow = [];
      const targetArray: string[] = this.action.target.split(',');
      targetArray.forEach((id) => {
        this.stations.forEach((station) => {
          if (station.rithmId === id) {
            this.stationsToFlow.push(station);
          }
        });
      });
    }
  }

  /**
   * Duplicate action.
   */
  duplicateAction(): void {
    const powerAction: PowerAction = {
      ...this.action,
      rithmId: uuidv4(),
      order: this.totalActions + 1,
    };

    this.powerService
      .addOrUpdateSpecificAction(this.ruleRithmId, powerAction)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: PowerEventType.Duplicate,
            action: powerAction,
            powerRithmId: this.ruleRithmId,
          });
        },
        error: () => {
          this.popupService.notify('Error to save action', true);
        },
      });
  }

  /**
   * Popup confirmation delete/update integration.
   */
  async confirmRemoveIntegration(): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'This action will be deleted',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (response) this.deleteAction(this.action);
  }

  /**
   * Delete action.
   * @param action  Delete the current action.
   */
  deleteAction(action: PowerAction): void {
    this.deletingAction = true;
    this.powerService
      .deleteAction(this.ruleRithmId, action.rithmId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: PowerEventType.Delete,
            action,
            powerRithmId: this.ruleRithmId,
          });
          this.deletingAction = false;
        },
        error: () => {
          this.deletingAction = false;
          this.popupService.notify('Error to delete action', true);
        },
      });
  }

  /**
   * Close form.
   */
  closeForm(): void {
    this.isEditAction = false;
  }
}
