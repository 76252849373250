import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { ScheduledTriggerHelper } from 'src/helpers';
import { UtcDateFormatPipe } from 'src/helpers/pipes/utc-date-format/utc-date-format.pipe';
import { UtcTimeConversionPipe } from 'src/helpers/pipes/utc-time-conversion/utc-time-conversion.pipe';
import { PowerTrigger, PowerTriggerToUpdate } from 'src/models';

/**
 * Scheduled Trigger List Component.
 */
@Component({
  selector: 'app-scheduled-trigger-list',
  standalone: true,
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatButtonModule,
    UtcDateFormatPipe,
    UtcTimeConversionPipe,
  ],
  templateUrl: './scheduled-trigger-list.component.html',
  styleUrl: './scheduled-trigger-list.component.scss',
})
export class ScheduledTriggerListComponent {
  /** Timezone setting flag. */
  private _timeZoneSettingFlag = false;

  /** Set timezone setting flag. */
  @Input() set timeZoneSettingFlag(value: boolean) {
    this._timeZoneSettingFlag = value;
    if (this._timeZoneSettingFlag) {
      this.currentTimeZone = this.localStorageService.getCurrentTimeZone();
    }
  }

  /**
   * Get timezone setting flag.
   * @returns Timezone setting flag.
   */
  get timeZoneSettingFlag(): boolean {
    return this._timeZoneSettingFlag;
  }

  /** Scheduled triggers list. */
  scheduledTriggers = input.required<PowerTrigger[]>();

  /** Show form event. */
  @Output() showForm = new EventEmitter<void>();

  /** Handle trigger event. */
  @Output() handleTrigger = new EventEmitter<Partial<PowerTriggerToUpdate>>();

  /** Change trigger status event. */
  @Output() changeTriggerStatus = new EventEmitter<{
    /** Trigger id. */
    triggerId: string;
    /** Status. */
    status: boolean;
  }>();

  /** Current time zone. */
  currentTimeZone = '';

  constructor(
    private utcTimeConversionPipe: UtcTimeConversionPipe,
    private localStorageService: LocalStorageService,
  ) {}

  /**
   * Handle trigger update.
   * @param triggerSelected Trigger Selected.
   * @param removeTrigger Remove Trigger.
   */
  emitHandleTrigger(
    triggerSelected: PowerTrigger | undefined,
    removeTrigger: boolean,
  ): void {
    this.handleTrigger.emit({
      trigger: triggerSelected,
      removeTrigger,
    });

    if (!removeTrigger) {
      this.showForm.emit();
    }
  }

  /**
   * Emit change trigger status.
   * @param triggerId Trigger id.
   * @param status Status.
   */
  emitChangeTriggerStatus(triggerId: string, status: boolean): void {
    this.changeTriggerStatus.emit({ triggerId, status });
  }

  /**
   * Get the readable schedule trigger.
   * @param trigger Trigger.
   * @param stringPart String part.
   * @returns Readable schedule trigger.
   */
  readableScheduleTrigger(trigger: PowerTrigger, stringPart?: number): string {
    return ScheduledTriggerHelper.readableScheduleTrigger(
      trigger,
      stringPart,
      this.utcTimeConversionPipe.transform,
    );
  }
}
