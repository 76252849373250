@if (actionToDisplay() && !isEditForm) {
  <app-rithm-ai-action-list
    [orderOfOperations]="orderOfOperations()"
    [action]="actionToDisplay()"
    [bucketQuestions]="bucketQuestions()"
    (eventAction)="handleEventAction($event)"
  />
} @else {
  <section class="pt-6 px-4 pb-4 pl-[30px]">
    @if (openedAiForm) {
      <app-rithm-ai-action-form
        [bucketQuestions]="bucketQuestions()"
        [actionToEdit]="action()"
        [selectedModelInput]="selectedModel()"
        (isFormValid)="isFormValid = $event"
        (formValue)="formValue = $event"
      />
    } @else {
      <app-select-ai-model (selectedModelOutput)="selectedModel.set($event)" />
    }
    <section class="flex space-x-2" [ngClass]="{ 'mt-6': !openedAiForm }">
      <button
        mat-button
        type="button"
        class="!font-semibold w-[84px] text-gray-800"
        (click)="emitCancelAction()"
      >
        CANCEL
      </button>
      @if (openedAiForm) {
        <button
          mat-stroked-button
          type="button"
          class="!bg-sky-500 !text-white !font-semibold"
          [ngClass]="{
            'pointer-events-none opacity-40': !isFormValid || isSavingAction()
          }"
          [disabled]="!isFormValid || isSavingAction()"
          (click)="emitSaveAction()"
        >
          @if (!isSavingAction()) {
            SAVE
          } @else {
            <app-loading-indicator inlineText="Saving..." />
          }
        </button>
      } @else {
        <button
          mat-stroked-button
          type="button"
          class="!bg-sky-500 !text-white !font-semibold"
          [ngClass]="{ 'pointer-events-none opacity-40': !selectedModel() }"
          [disabled]="!selectedModel()"
          (click)="openedAiForm = true"
        >
          <span> <i class="fal fa-plus text-lg"></i> ACTION </span>
        </button>
      }
    </section>
  </section>
}
