import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  DateTimeSetting,
  PowerTrigger,
  Question,
  QuestionFieldType,
  TriggerDuration,
} from 'src/models';
import { NumberFieldComponent } from 'src/app/shared/fields/number-field/number-field.component';
import { TextFieldComponent } from 'src/app/shared/fields/text-field/text-field.component';
import { SelectFieldComponent } from 'src/app/shared/fields/select-field/select-field.component';
import { DateFieldComponent } from 'src/app/shared/fields/date-field/date-field.component';
import { v4 as uuidv4 } from 'uuid';

interface eventSelectorInfo {
  /** Title of the field. */
  field: string;
  /** Value for the field. */
  value: string | Question;
  /** Selected value is custom or a bucket question. */
  isCustom: boolean;
}

interface resetFormInfo {
  /** Title of the field. */
  field: string;
  /** Selected value is custom or a bucket question. */
  isCustom: boolean;
}

/**
 * Component for custom and bucket fields.
 */
@Component({
  selector: 'app-custom-vs-bucket[poolOfQuestions]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    NumberFieldComponent,
    TextFieldComponent,
    SelectFieldComponent,
    DateFieldComponent,
  ],
  templateUrl: './custom-vs-bucket.component.html',
  styleUrls: ['./custom-vs-bucket.component.scss'],
})
export class CustomVsBucketQuestionComponent implements OnInit {
  /** The list of bucket questions for any station. */
  @Input() poolOfQuestions: Question[] = [];

  /** The filter to get respective question from bucket list. */
  @Input() filteredBy:
    | 'date'
    | 'number'
    | 'text'
    | 'select'
    | QuestionFieldType
    | 'all' = 'all';

  /** The label text for the question. */
  @Input() questionLabel!: string;

  /** The current action that is using the component. */
  @Input() currentAction: 'scheduleTrigger' | null = null;

  /** The current field title. */
  @Input() fieldTitle: 'startDate' | 'endRepeat' | null = null;

  /** The minimum date value to be set for date-picker. */
  @Input() minDate = new Date();

  /** The maximum date value to be set for date-picker. */
  @Input() maxDate = new Date();

  /** Should set max date. */
  @Input() setMaxDate = false;

  /** The selected question field. */
  @Input() selectedQuestion!: Question | undefined;

  /** The selected data for editing. */
  @Input() set editedData(value: PowerTrigger | undefined) {
    if (value) {
      this.setDataToEdit(value);
    }
  }

  /** It will emit an event to direct us to the Station triggers section. */
  @Output() dateEmitter = new EventEmitter<eventSelectorInfo>();

  /** It will emit an event to reset the trigger form. */
  @Output() resetFormEmitter = new EventEmitter<resetFormInfo>();

  /** If the current field is custom or not. */
  isCustomValues = true;

  /** Mock question value. */
  field: Question = {
    rithmId: uuidv4(),
    prompt: 'Field prompt',
    questionType: QuestionFieldType.DateTime,
    isReadOnly: false,
    isRequired: false,
    isPrivate: false,
    possibleAnswers: [],
    children: [],
    answer: {
      questionRithmId: '',
      referAttribute: '',
      value: '',
      asString: '',
      asArray: [],
      asInt: 0,
      asJson: undefined,
    },
  };

  /** The form to add to the template.*/
  inputFrameFieldForm = new FormGroup({
    dateFieldForm: new FormControl(''),
    selectFieldForm: new FormControl('', { validators: Validators.required }),
  });

  /**
   * Init life cycle.
   */
  ngOnInit(): void {
    this.field.prompt = this.questionLabel;
    if (this.filteredBy === 'date') {
      const settings: DateTimeSetting = {
        seconds: false,
        enableMeridian: true,
      };
      this.field.questionType = QuestionFieldType.Date;
      this.field.settings = JSON.stringify(settings);
      this.field.isRequired = true;
    }
  }

  /**
   * Sets data to edit mode.
   * @param dataToEdit Data to be edited.
   */
  setDataToEdit(dataToEdit: PowerTrigger): void {
    switch (this.currentAction) {
      case 'scheduleTrigger':
        this.editScheduleTrigger(dataToEdit);
        break;
      default:
        break;
    }
  }

  /**
   * Sets data to edit mode for schedule trigger.
   * @param scheduleTrigger Data to be edited.
   */
  editScheduleTrigger(scheduleTrigger: PowerTrigger): void {
    // let index = -1;
    const data = JSON.parse(scheduleTrigger.settings) as TriggerDuration;
    if (this.fieldTitle === 'startDate') {
      this.isCustomValues = data.startDate.isCustom;
      if (data.startDate.isCustom) {
        this.field.value = scheduleTrigger.startDateUTC;
      } else {
        this.inputFrameFieldForm.controls.selectFieldForm.setValue(
          data.startDate.question?.rithmId || '',
        );
      }
      this.emitDateEmitter(data.startDate.question?.rithmId || '');
    } else if (this.fieldTitle === 'endRepeat' && data.endDate) {
      this.isCustomValues = data.endDate.isCustom;
      if (data.endDate.isCustom) {
        this.field.value = scheduleTrigger.endDateUTC || '';
      } else {
        this.inputFrameFieldForm.controls.selectFieldForm.setValue(
          data.endDate.question?.rithmId || '',
        );
      }
      this.emitDateEmitter(data.endDate.question?.rithmId || '');
    }
  }

  /**
   * Gets the list of filtered questions.
   * @returns Returns the list of questions based on the filter type.
   */
  get questionList(): Question[] {
    return this.poolOfQuestions.filter(
      (questions) => questions.questionType === this.filteredBy,
    );
  }

  /**
   * Sets the selected value to form controls and pass it to emitter.
   * @param event Station trigger selected.
   */
  setEmittedDate(event: string): void {
    if (this.isCustomValues) {
      this.field.value = event as string;
    } else {
      this.inputFrameFieldForm.controls.selectFieldForm.setValue(event);
    }
    this.emitDateEmitter(event);
  }

  /**
   * Emits the event and goes to the station triggers section.
   * @param event Station trigger selected.
   */
  emitDateEmitter(event: string): void {
    const index = this.poolOfQuestions.findIndex(
      (que) => que.rithmId === event,
    );
    this.dateEmitter.emit({
      field: this.fieldTitle || '',
      value:
        index > -1 ? this.poolOfQuestions[index] : (this.field.value as string),
      isCustom: this.isCustomValues,
    });
  }

  /**
   * Remove selected question from the list.
   * @param rithmId - Selected question Id.
   * @returns Boolean value.
   */
  hideSelectedQuestion(rithmId: string): boolean {
    switch (this.currentAction) {
      case 'scheduleTrigger':
        return this.hideSelectedScheduleTriggerQuestion(rithmId);
      default:
        return this.questionList.length > 0;
    }
  }

  /**
   * Remove selected schedule trigger question from the list.
   * @param rithmId - Selected question Id.
   * @returns Boolean value.
   */
  hideSelectedScheduleTriggerQuestion(rithmId: string): boolean {
    if (this.fieldTitle === 'startDate') {
      return (
        this.questionList.filter(
          (question) =>
            question.rithmId === rithmId &&
            question.rithmId === this.selectedQuestion?.rithmId,
        ).length > 0
      );
    } else {
      return (
        this.questionList.filter(
          (question) =>
            question.rithmId === rithmId &&
            question.rithmId === this.selectedQuestion?.rithmId,
        ).length > 0
      );
    }
  }

  /**
   * Emit event to reset the station trigger form.
   *
   */
  resetForm(): void {
    this.isCustomValues = !this.isCustomValues;
    this.inputFrameFieldForm.controls.dateFieldForm.setValue('');
    this.inputFrameFieldForm.controls.selectFieldForm.setValue('');
    this.field.value = '';
    this.resetFormEmitter.emit({
      field: this.fieldTitle || '',
      isCustom: this.isCustomValues,
    });
  }
}
