<mat-form-field
  appearance="outline"
  subscriptSizing="dynamic"
  class="w-full max-w-lg"
>
  <mat-label>Action Type</mat-label>
  <mat-select
    (selectionChange)="emitSqlIntegrationType($event.value)"
    placeholder="Action type"
    id="integration-select"
    data-testid="integration-select"
  >
    @for (type of sqlIntegrationTypeOptions; track $index) {
      <mat-option
        [value]="type"
        [id]="'integration-' + type"
        [attr.data-testid]="'integration-' + type"
        >{{ type }}</mat-option
      >
    }
  </mat-select>
</mat-form-field>
