import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionType,
  FieldDataType,
  PowerAction,
  RelationshipAction,
  StationLibraryRelationship,
  UpdateRelationshipAction,
} from 'src/models';
import { TermsGeneric } from 'src/helpers';
import { MatSelectModule } from '@angular/material/select';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { MatButtonModule } from '@angular/material/button';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { MatDividerModule } from '@angular/material/divider';
import { NgxSearchComponent } from 'src/app/shared/ngx-search/ngx-search.component';

/** Represent the form to add/edit an action to update relationships. */
@Component({
  selector: 'app-update-relationship-form[stationRithmId]',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatChipsModule,
    LoadingIndicatorComponent,
    MatButtonModule,
    MatDividerModule,
    NgxSearchComponent,
  ],
  templateUrl: './update-relationship-form.component.html',
  styleUrls: ['./update-relationship-form.component.scss'],
})
export class UpdateRelationshipFormComponent {
  /** Contains the action of the power that will be edited. */
  private _actionToUpdate: PowerAction = {
    order: 1,
    rithmId: uuidv4(),
    type: ActionType.AddRelationship,
    target: '',
    data: '',
    resultMapping: '',
    header: '',
  };

  /** The station rithmId. */
  @Input() stationRithmId!: string;

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Whether you are saving the current action . */
  @Input() savingAction = false;

  /** Confirms whether the action is being edited. */
  @Input() editingAction = false;

  /** Contains the action of the power that will be edited. */
  @Input() set action(value: PowerAction | null) {
    if (value) {
      this._actionToUpdate = value;
      this.setFormInfoToUpdate();
    }
  }

  /** Station Libraries is Loading.*/
  @Input() librariesLoading = false;

  /** Station Libraries.*/
  @Input() libraryRelationships: StationLibraryRelationship[] = [];

  /** The question of ParentContainer Type. */
  @Input() parentId = '';

  /** Feature flag  for show children in containers. */
  @Input() showParentChildRelationship = false;

  /** Whether you are deleting the current action in parent. */
  @Input() deletingAction = false;

  /** Emit Cancel the new action. */
  @Output() cancelAction = new EventEmitter<void>();

  /** Emit the new action to add it into the list of actions in the power. */
  @Output() actionEmitter = new EventEmitter<PowerAction>();

  /** Emit the current action to delete it. */
  @Output() actionToRemove = new EventEmitter<PowerAction>();

  /** Project glossary. */
  termsGeneric = TermsGeneric;

  /** Type of actions. */
  actionTypesEnum = ActionType;

  /** Library data filter. */
  libraryDataFiltered!: FieldDataType;

  /**Relation selected in add relationship */
  relationsSelected: string[] = [];

  /** Action type selector value. */
  actionTypeValue: ActionType | '' = '';

  /** Index relation to edit. */
  indexRelationToEdit = -1;

  /**Parent container Id to send if applicable */
  parentContainerIdSelected = '';

  /** The form to update relationships to the template.*/
  relationshipsFieldForm: FormGroup = new FormGroup({
    updateRelationship: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    relationshipType: new FormControl<string[]>([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
    relatedTo: new FormControl<string>('', { nonNullable: true }),
  });

  /** Relations selected to work them.*/
  updateRelationshipAction: UpdateRelationshipAction = {
    relationships: [],
  };

  /** Update relationships enum. */
  updateRelationshipType:
    | ActionType.DeleteRelationship
    | ActionType.AddRelationship = ActionType.AddRelationship;

  /**
   * OnChanges Method.
   */
  setRelatedToInformation(): void {
    if (
      this.relationshipsFieldForm.value.updateRelationship ===
      ActionType.AddRelationship
    ) {
      this.relationshipsFieldForm.controls.relatedTo.setValue(this.parentId);
    } else {
      this.relationshipsFieldForm.controls.relatedTo.reset();
    }
  }

  /**
   * Add actions to save.
   */
  emitActionToSave(): void {
    const relationshipToUpdate: UpdateRelationshipAction = {
      relationships: this.filterRelationshipsFromSelection(),
    };

    if (
      this.relationshipsFieldForm.controls['updateRelationship'].value ===
      ActionType.AddRelationship
    ) {
      if (
        this.relationshipsFieldForm.controls['relatedTo'].value ===
        this.parentId
      ) {
        relationshipToUpdate.relatesTo = 'parent';
        relationshipToUpdate.rithmId = this.parentId;
      } else {
        relationshipToUpdate.relatesTo = 'children';
      }
    }

    this._actionToUpdate.data = JSON.stringify(relationshipToUpdate);
    this._actionToUpdate.type =
      this.relationshipsFieldForm.value.updateRelationship;
    this._actionToUpdate.target = this.parentId;

    this.actionEmitter.emit(this._actionToUpdate);
  }

  /**
   * Remove relationship type.
   * @param indexRelation Index relation to remove.
   */
  removeRelation(indexRelation: number): void {
    const relationshipsSelected =
      this.relationshipsFieldForm.value.relationshipType;
    relationshipsSelected.splice(indexRelation, 1);
    this.relationshipsFieldForm.controls['relationshipType'].setValue(
      relationshipsSelected,
    );
  }

  /**
   * Get a clean label.
   * @param relation Relation label.
   * @returns Clean relation.
   */
  cleanRelationLabel(relation: string): string {
    return relation.split('[')[0];
  }

  /**
   * Format all those relationships selected into proper info to be emitted.
   * @returns An array of relationships with inward and outward.
   */
  filterRelationshipsFromSelection(): RelationshipAction[] {
    const relationsToAdd: RelationshipAction[] = [];
    /**
     * Get all the ids of the relationships set in the action.
     */
    const relationshipIds: string[] = _.uniq(
      (this.relationshipsFieldForm.value.relationshipType as string[]).map(
        (rel) => {
          return rel.split('[')[1].split(']')[0];
        },
      ),
    );

    /**
     * Peer each ID we'll make a loop to catch its relationships.
     */
    relationshipIds.forEach((relId) => {
      //We'll take inward and outward names for this determined relationship in case was enabled.
      const relationshipsEnabled: string[] = (
        this.relationshipsFieldForm.value.relationshipType as string[]
      ).filter((relation) => {
        return relation.includes(relId);
      });

      //Se set the rithmID of the relationship and inward and outward as true as long as they exist as enabled items.
      const relAction: RelationshipAction = {
        relationshipRithmId: relId,
        inward: !!relationshipsEnabled.filter((rel) =>
          rel.includes('_inwardName'),
        ).length,
        outward: !!relationshipsEnabled.filter((rel) =>
          rel.includes('_outwardName'),
        ).length,
      };

      //We populate the array of relationships enabled.
      relationsToAdd.push(relAction);
    });

    return relationsToAdd;
  }

  /**
   * Set the form with the incoming object information.
   */
  setFormInfoToUpdate(): void {
    /** The array to populate with the built string from relationships selected. */
    const relationshipsChecked: string[] = [];

    /** Extract relationships array from library. */
    const stationRelationships = this.libraryRelationships.map((lib) => {
      return lib.relationships;
    });

    /** Extract info from object.Data. */
    const actionRelationshipsData = JSON.parse(
      this._actionToUpdate.data,
    ) as UpdateRelationshipAction;

    /** Loop through relationships extracted data. */
    actionRelationshipsData.relationships.forEach((relationData) => {
      /** Loop through each station relationship array. */
      stationRelationships.forEach((sRel) => {
        /** Look for matching relationships id and get their Inward/Outward Names. */
        const inwardName = sRel.find(
          (rel) => rel.rithmId === relationData.relationshipRithmId,
        )?.inwardName;
        const outwardName = sRel.find(
          (rel) => rel.rithmId === relationData.relationshipRithmId,
        )?.outwardName;

        /** If they were selected then we'll built the string. */
        if (relationData.inward && inwardName) {
          relationshipsChecked.push(
            inwardName +
              '[' +
              relationData.relationshipRithmId +
              ']_inwardName',
          );
        }

        if (relationData.outward && outwardName) {
          relationshipsChecked.push(
            outwardName +
              '[' +
              relationData.relationshipRithmId +
              ']_outwardName',
          );
        }
      });
    });

    /** Finally we'll set the info in the form to be edited after. */
    this.relationshipsFieldForm.setValue({
      updateRelationship: this._actionToUpdate.type,
      relationshipType: relationshipsChecked,
      relatedTo:
        actionRelationshipsData.relatesTo === 'children'
          ? 'children'
          : this.parentId,
    });
  }

  /**
   * Cancel the process of Append Field.
   */
  onCancelAction(): void {
    this.cancelAction.emit();
  }

  /**
   * Remove the action from the parent.
   */
  removeParentAction(): void {
    this._actionToUpdate && this.actionToRemove.emit(this._actionToUpdate);
  }
}
