<section class="space-y-4 notification-action-component py-4">
  <form
    class="notification-action-section"
    [formGroup]="notificationActionForm"
  >
    <section class="notification-add">
      <p class="text-sm">Type*</p>
      <section
        class="notification-type-selector flex justify-between min-h-[58px]"
      >
        <div class="notification-type-select">
          <mat-chip-listbox multiple>
            @for (type of notificationTypeList; track $index) {
              <mat-chip-option
                (click)="changeTypeSelected(type)"
                class="internal-notification-chips"
                [attr.data-testid]="'option-notification-' + type"
                [id]="'option-notification-' + type"
                [ngClass]="checkTypeSelected(type) ? 'white-text' : 'default'"
                [style.background-color]="
                  checkTypeSelected(type) ? '#1b4387' : '#e0e0e0'
                "
                >{{ type }}</mat-chip-option
              >
            }
          </mat-chip-listbox>
          @if (!noNotificationSelected) {
            <p class="text-xs text-error-500">
              Warning: At least one type is required in order to save.
            </p>
          }
        </div>
        <div class="notification-learn-more">
          <i
            class="fa-sharp fa-light fa-circle-question"
            [popper]="notificationHelper"
            [popperShowOnStart]="false"
            [popperTrigger]="enumPopperTrigger.hover"
            [popperHideOnClickOutside]="true"
            [popperPlacement]="enumPopperPlacement.TOP"
            [popperTimeout]="2000"
            [popperStyles]="{
              width: '283px',
              height: 'auto',
              zIndex: 1000,
              padding: '4px',
              backgroundColor: 'rgba(3, 17, 32, 0.87)'
            }"
          ></i>
          <popper-content #notificationHelper>
            <div class="flex flex-col justify-center text-white tooltip-text">
              <p class="text-xs">
                For time sensitive notifications, we recommend push & browser
                notifications.
              </p>
              <a
                id="open-notification-modal"
                data-testid="open-notification-modal"
                (click)="openNotificationTypeInfoModal()"
                class="ml-auto text-sm text-white text-decoration-none"
                >Learn more <i class="fa-regular fa-arrow-right"></i
              ></a>
            </div>
          </popper-content>
        </div>
      </section>
      <section class="flex items-center mb-4 notification-recipients">
        <mat-form-field
          class="w-full"
          appearance="outline"
          subscriptSizing="dynamic"
          [ngClass]="{ 'auto-complete-invalid': !selectedMembersValid }"
        >
          <span matPrefix>To:</span>
          <mat-chip-listbox>
            @for (member of selectedMembers; track $index) {
              <mat-chip-option
                [attr.data-testid]="'option-member-' + member.rithmId"
                [id]="'option-member-' + member.rithmId"
                [selectable]="false"
                [removable]="removable"
                (removed)="removeRecipient(member.rithmId)"
                class="mx-1 my-1"
              >
                <div class="flex items-center w-full h-full scrollbar">
                  @if (isUserType(member) || member.profileImageRithmId) {
                    <ng-template
                      [ngTemplateOutlet]="userChips"
                      [ngTemplateOutletContext]="{
                        member: member
                      }"
                    ></ng-template>
                  } @else {
                    <i
                      [ngClass]="componentHelper.originIcon(member.type)"
                      class="text-secondary-300"
                    ></i>
                    <span class="pl-2">{{ member.name }}</span>
                  }
                </div>

                <button
                  matChipRemove
                  type="button"
                  [attr.data-testid]="'remove-member-' + member.rithmId"
                  [attr.id]="'remove-member-' + member.rithmId"
                >
                  <i class="fa fa-times-circle-o"></i>
                </button>
              </mat-chip-option>
            }
          </mat-chip-listbox>

          <mat-select
            class="members-select scrollbar"
            formControlName="addMembers"
            (selectionChange)="addRecipient($event.value)"
            [multiple]="true"
            (click)="getRecipientMemberList()"
          >
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search..."
                noEntriesFoundLabel="Not match found..."
                [formControl]="stationMembersFilter"
              >
                <span aria-hidden="true" class="close" ngxMatSelectSearchClear>
                  <i class="fa-light fa-xmark"></i>
                </span>
              </ngx-mat-select-search>
            </mat-option>
            @if (members.length && !notificationLoading) {
              <mat-optgroup label="Members">
                @for (member of members; track $index) {
                  <mat-option
                    [attr.data-testid]="'members-selected' + member.rithmId"
                    [attr.id]="'members-selected' + member.rithmId"
                    [value]="member.rithmId"
                    [disabled]="isMemberAlreadySelected(member.rithmId)"
                  >
                    <div class="flex items-center space-x-1">
                      <app-user-avatar
                        [firstName]="member.firstName"
                        [lastName]="member.lastName"
                        [size]="'20'"
                        [profileImageRithmId]="member.profileImageRithmId || ''"
                      ></app-user-avatar>
                      <span>{{
                        member.firstName + ' ' + member.lastName
                      }}</span>
                    </div>
                  </mat-option>
                }
              </mat-optgroup>
            }
            @if (notificationLoading || !members.length || errorGetMembers) {
              <mat-optgroup class="pointer-events-none center-loading">
                @if (notificationLoading) {
                  <app-loading-indicator
                    id="loading-members-list"
                    data-testid="loading-members-list"
                    [inlineText]="'Loading..'"
                  >
                  </app-loading-indicator>
                }
                @if (
                  !members.length && !notificationLoading && !errorGetMembers
                ) {
                  <span class="text-sm text-primary-300"
                    >-- No member found --</span
                  >
                }
              </mat-optgroup>
            }
            @if (errorGetMembers) {
              <mat-optgroup>
                <span class="text-sm text-error-500">
                  -- Error getting members --
                </span>
              </mat-optgroup>
            }
          </mat-select>
        </mat-form-field>
        <a
          (click)="openAdvancedSearchModal()"
          class="flex-shrink-0 px-2 text-sm"
          >Advanced Search</a
        >
      </section>
      <section class="pb-3 notification-text">
        <mat-form-field
          class="w-full"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <textarea
            #inputNote
            matInput
            class="scrollbar non-resizable"
            rows="3"
            spellcheck="true"
            id="input-note"
            data-testid="input-note"
            autocomplete="off"
            maxlength="280"
            formControlName="inputNote"
          ></textarea>
          <mat-hint align="end" class="text-xs"
            >{{ inputNote.value.length }}/{{ '280' }}</mat-hint
          >
        </mat-form-field>
      </section>
      <section
        class="notification-controls-section"
        [ngClass]="{
          'relative flex justify-between items-center py-2 max-w-none':
            orderOfOperations
        }"
      >
        <div
          class="flex"
          [ngClass]="orderOfOperations ? 'space-x-4' : 'justify-end space-x-2'"
        >
          <button
            mat-button
            type="button"
            id="cancel-notification"
            data-testid="cancel-notification"
            class="!font-semibold !uppercase"
            [disabled]="
              (savingNotification || deletingNotification) && orderOfOperations
            "
            (click)="confirmCancel()"
          >
            Cancel
          </button>
          <button
            mat-raised-button
            [ngClass]="{
              '!bg-sky-500 !text-white': orderOfOperations,
              '!opacity-60':
                (!notificationActionForm.valid ||
                  !selectedMembersValid ||
                  !noNotificationSelected ||
                  savingNotification ||
                  deletingNotification) &&
                orderOfOperations
            }"
            type="button"
            color="accent"
            [disabled]="
              !notificationActionForm.valid ||
              !selectedMembersValid ||
              !noNotificationSelected ||
              ((savingNotification || deletingNotification) &&
                orderOfOperations)
            "
            id="save-notification"
            data-testid="save-notification"
            class="!font-semibold !uppercase"
            (click)="addNotificationAction()"
          >
            @if (orderOfOperations) {
              @if (savingNotification) {
                <ng-container
                  [ngTemplateOutlet]="loadingInlineText"
                  [ngTemplateOutletContext]="{ $implicit: 'Saving...' }"
                ></ng-container>
              } @else {
                Save
              }
            } @else {
              {{ editMode ? 'Update' : 'Add' }}
            }
          </button>
        </div>
        @if (orderOfOperations && editMode) {
          <div class="flex-grow text-right">
            <button
              mat-stroked-button
              class="!bg-red-500 !text-white"
              type="button"
              id="delete-action"
              data-testid="delete-action"
              (click)="removeParentNotification()"
            >
              @if (deletingNotification) {
                <ng-container
                  [ngTemplateOutlet]="loadingInlineText"
                  [ngTemplateOutletContext]="{ $implicit: 'Deleting...' }"
                ></ng-container>
              } @else {
                <i class="fa-light fa-trash text-lg"></i> DELETE ACTION
              }
            </button>
          </div>
        }
      </section>
    </section>
  </form>
</section>

<!-- Show users in chips.-->
<ng-template #userChips let-member="member">
  <app-user-avatar
    [firstName]="member.firstName || ''"
    [lastName]="member.lastName || ''"
    [size]="'20'"
    [profileImageRithmId]="member.profileImageRithmId || ''"
    class="relative right-1"
  ></app-user-avatar>
  <span>
    {{ member.name }}
  </span>
</ng-template>

<!-- In line text loading indicator -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator
    id="loading-inline-text"
    [inlineText]="message"
  ></app-loading-indicator>
</ng-template>
