import { CommonModule } from '@angular/common';
import { Component, Input, input, model, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { ManagementMembersActionFormComponent } from './management-members/management-members-action-form/management-members-action-form.component';
import { ManagementMembersActionItemComponent } from './management-members/management-members-action-item/management-members-action-item.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { ActionType, PowerAction, Question } from 'src/models';

/**
 * Component to render list of type actions of users action in station.
 */
@Component({
  selector: 'app-user-actions',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ManagementMembersActionFormComponent,
    ManagementMembersActionItemComponent,
  ],
  templateUrl: './user-actions.component.html',
  styleUrl: './user-actions.component.scss',
})
export class UserActionsComponent {
  /** Actions of the current power. */
  @Input() actions: PowerAction[] = [];

  /** Total actions. */
  totalActions = input.required<number>();

  /** Feature flag order of operations. */
  orderOfOperations = input.required<boolean>();

  /** The station id used to get shared values. */
  stationRithmId = input.required<string>();

  /** Current rithmId of the rule. */
  ruleRithmId = input.required<string>();

  /** Bucket questions. */
  bucketQuestions = input.required<Question[]>();

  /** Show form management members. */
  showManagementMembersForm = model<boolean>();

  /** Mode List of actions. */
  modeListOfActions = model<boolean>(false);

  /** Action emitter.  */
  actionsUpdEmitter = output<void>();

  /** Action to edit index. */
  editActionIndex = -1;

  /** List of users action types. */
  readonly userActions = [ActionType.AddMember, ActionType.RemoveMember];

  /** Add action. */
  addingAction = false;

  /** ActionType Value. */
  actionTypeValue = '';

  /**
   * Returns users-actions .
   * @returns List of actions.
   */
  get getUsersActions(): PowerAction[] {
    return this.actions.filter((action) =>
      this.userActions.includes(action.type),
    );
  }

  /**
   * Add action.
   * @param powerAction Action to add.
   */
  addAction(powerAction: PowerAction): void {
    this.actions.push(powerAction);
    this.actionsUpdEmitter.emit();
    this.showManagementMembersForm.set(false);
    this.addingAction = false;
  }

  /**
   * EditAction event emit edit event.
   * @param  action Action event.
   */
  editActionEvent(action: PowerAction): void {
    this.editActionIndex = this.actions.findIndex(
      (act) => act.rithmId === action.rithmId,
    );
    this.showManagementMembersForm.set(true);
  }

  /**
   * Close form.
   */
  closeForm(): void {
    this.showManagementMembersForm.set(false);
    this.editActionIndex = -1;
  }
}
