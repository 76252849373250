import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';
import { ComponentHelper, JsonValidator } from 'src/helpers';
import {
  ActionAlertInfo,
  MemberPermissionData,
  PowerAction,
  UserAssociateType,
} from 'src/models';
/**
 * Display the notification action item.
 */
@Component({
  selector: 'app-internal-notification-item[notification]',
  templateUrl: './internal-notification-item.component.html',
  styleUrls: ['./internal-notification-item.component.scss'],
  standalone: true,
  imports: [CommonModule, MatChipsModule, UserAvatarComponent],
})
export class InternalNotificationItemComponent {
  /** Internal Notification object. */
  private _notification!: PowerAction;

  /** Component Helper. */
  componentHelper = ComponentHelper;

  /** Set internal Notification object. */
  @Input() set notification(value: PowerAction) {
    if (value) {
      this._notification = value;
      if (this.notification.data?.length) {
        this.memberRecipients = this.getNotificationMembers();
      }
    }
  }

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /**
   * Get internal Notification object.
   * @returns Returns the internal Notification object.
   */
  get notification(): PowerAction {
    return this._notification;
  }

  /** The notification's recipients. */
  memberRecipients: MemberPermissionData[] = [];

  /**
   * Get the types of notifications from notification object.
   * @returns Returns the notification types.
   */
  getNotificationTypes(): string[] {
    const validatedJson = this.notification.data.replace(/'/g, ' ');
    const alertType =
      JsonValidator.getObjectFromString<ActionAlertInfo>(validatedJson);
    return alertType.types;
  }

  /**
   * Gets the types list of members.
   * @returns Returns the members list.
   */
  getNotificationMembers(): MemberPermissionData[] {
    const validatedJson = this.notification.data.replace(/'/g, ' ');
    const alertType =
      JsonValidator.getObjectFromString<ActionAlertInfo>(validatedJson);
    const recipientData = alertType.recipients;
    return [
      ...recipientData.organizations,
      ...recipientData.teams,
      ...recipientData.stationGroups,
      ...recipientData.stations,
      ...recipientData.users,
    ];
  }

  /**
   * Get the notification message.
   * @returns Returns notification message.
   */
  getNotificationMessage(): string {
    const validatedJson = this.notification.data.replace(/'/g, ' ');
    const alertType =
      JsonValidator.getObjectFromString<ActionAlertInfo>(validatedJson);
    return alertType.message || '';
  }

  /**
   * Check whether it should show an icon or not.
   * @param option Option selected.
   * @returns A boolean.
   */
  showIcon(option: MemberPermissionData): boolean {
    return (
      !option.profileImageRithmId && option.type !== UserAssociateType.User
    );
  }
}
