import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  ScriptActionData,
  ScriptHistory,
  ScriptHistoryType,
  ScriptLogs,
} from 'src/models';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/**
 * Component represents log section.
 */
@Component({
  selector:
    'app-scripts-logs-actions[typeLog][dataScriptTesting][historyScripts][isLoadingTesting][scriptName]',
  templateUrl: './scripts-logs-actions.component.html',
  styleUrls: ['./scripts-logs-actions.component.scss'],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class ScriptsLogsActionsComponent {
  /**
   * Type of log.
   * 0 => Testing.
   * 1 => Console.
   * 2 => History.
   */
  @Input() typeLog = 0;

  /** Data for show events in testing for all scripts. */
  @Input() dataScriptTesting!: ScriptActionData[];

  /** Data for accumulate and show history the scripts. */
  @Input() historyScripts!: ScriptHistory[];

  /** Loading scripts by each testing file. */
  @Input() isLoadingTesting!: boolean[];

  /** Input to search documents in history by script.  */
  @Input() inputSearchHistory = '';

  @Input() scriptName = '';

  /**
   * Get the historyScript with filter.
   * @returns The historyScript filtered.
   */
  get historyWithFilter(): ScriptHistory[] {
    return this.inputSearchHistory
      ? this.historyScripts.filter((script) => {
          return script.containerName
            .toLowerCase()
            .includes(this.inputSearchHistory.toLowerCase());
        })
      : this.historyScripts;
  }

  /** Enum ScriptHistoryType. */
  enumScriptHistoryType = ScriptHistoryType;

  /** Toggle arrows by history opened or closed. */
  expansionHistory: boolean[] = [];

  /**
   * Get script logs by interfaces ScriptTest, ScriptHistory.
   * @param script The script to extract ScriptLogs.
   * @returns Return ScriptLogs[].
   */
  getScriptLogs(script: ScriptActionData): ScriptLogs[] {
    const { scriptTestLogs, scriptConsoleLogs } = script?.scriptExecutions?.at(
      0,
    ) as ScriptHistory;
    return scriptTestLogs || scriptConsoleLogs || [];
  }
}
