<section class="space-x-2 flex justify-between">
  <section class="py-4">
    <div>
      <span class="action-title"> Manage Member </span>
    </div>

    <div class="pt-2">
      <span class="sub-section-title">Type</span>
      <mat-chip-listbox>
        <mat-chip>
          <span class="label-chip"
            >{{
              action.type === enumActionType.AddMember
                ? 'Add Member(s)'
                : 'Remove Member(s)'
            }}
          </span>
        </mat-chip>
      </mat-chip-listbox>
    </div>

    <div class="pt-2">
      <span class="sub-section-title">To</span>
      <mat-chip-listbox>
        @for (station of dataAction.target.stations; track $index) {
          @if (stationsAndGroups[station] || loadingStationsAndGroup) {
            <mat-chip>
              <i class="fak fa-station icon-chip"></i
              ><span class="label-chip">
                {{
                  stationsAndGroups[station]
                    ? stationsAndGroups[station].name
                    : 'loading...'
                }}
              </span>
            </mat-chip>
          }
        }

        @for (group of dataAction.target.groups; track $index) {
          @if (stationsAndGroups[group] || loadingStationsAndGroup) {
            <mat-chip>
              <i class="fal fa-grid-2 icon-chip"></i
              ><span class="label-chip">
                {{
                  stationsAndGroups[group]
                    ? stationsAndGroups[group].name
                    : 'loading...'
                }}
              </span>
            </mat-chip>
          }
        }
      </mat-chip-listbox>
    </div>

    <div class="pt-2">
      <span class="sub-section-title">Member(s)</span>
      <mat-chip-listbox>
        @if (usersMembers.length === 0) {
          <span class="pl-3">--</span>
        } @else {
          @for (element of usersMembers; track $index) {
            <mat-chip>
              <span class="label-chip flex items-center gap-1">
                @if (element.profileImageRithmId) {
                  <app-user-avatar
                    [firstName]="element['firstName'] || ''"
                    [lastName]="element['lastName'] || ''"
                    [profileImageRithmId]="element['profileImageRithmId']"
                  />
                } @else {
                  <i
                    class="icon-chip"
                    [ngClass]="
                      element['rithmId'] === 'info:assignedTo'
                        ? 'fa-kit fa-container-user'
                        : element['rithmId'] ===
                            'info:' + enumQuestionFieldType.CreatedBy
                          ? 'fa-light fa-user-pen'
                          : element.isQuestion
                            ? 'fak fa-user-select'
                            : 'fa-light fa-user'
                    "
                  ></i>
                }

                {{
                  element.firstName +
                    ' ' +
                    (element.lastName ? element.lastName : '')
                }}
              </span>
            </mat-chip>
          }
        }
      </mat-chip-listbox>
    </div>

    <div class="pt-2">
      <span class="sub-section-title">{{ termsGeneric.Worker.Single }}</span>
      <mat-chip-listbox>
        @if (usersWorkers.length === 0) {
          <span class="pl-3">--</span>
        } @else {
          @for (element of usersWorkers; track $index) {
            <mat-chip>
              <span class="label-chip flex items-center gap-1">
                @if (element.profileImageRithmId) {
                  <app-user-avatar
                    [firstName]="element['firstName'] || ''"
                    [lastName]="element['lastName'] || ''"
                    [profileImageRithmId]="element['profileImageRithmId']"
                  />
                } @else {
                  <i
                    class="icon-chip"
                    [ngClass]="
                      element['rithmId'] === 'info:assignedTo'
                        ? 'fa-kit fa-container-user'
                        : element['rithmId'] ===
                            'info:' + enumQuestionFieldType.CreatedBy
                          ? 'fa-light fa-user-pen'
                          : element.isQuestion
                            ? 'fak fa-user-select'
                            : 'fa-light fa-user'
                    "
                  ></i>
                }

                {{
                  element.firstName +
                    ' ' +
                    (element.lastName ? element.lastName : '')
                }}
              </span>
            </mat-chip>
          }
        }
      </mat-chip-listbox>
    </div>
  </section>

  <section>
    <button
      mat-icon-button
      type="button"
      [matMenuTriggerFor]="actionOptions"
      [disabled]="deletingAction"
      id="actions-container"
      data-testid="actions-container"
      class="text-lg"
    >
      <i class="fa-light fa-ellipsis-h"></i>
    </button>
    <mat-menu #actionOptions="matMenu" xPosition="before">
      <button
        mat-menu-item
        type="button"
        class="text-md"
        data-testid="edit-action"
        id="edit-action"
        (click)="editAction()"
      >
        <span class="p-4">Edit action</span>
      </button>
      @if (orderOfOperations()) {
        <button
          mat-menu-item
          type="button"
          class="text-md"
          data-testid="duplicate-container-action"
          id="duplicate-container-action"
          (click)="duplicateAction()"
        >
          <span class="p-4">Duplicate action</span>
        </button>
      }
      <button
        mat-menu-item
        type="button"
        class="text-md"
        data-testid="delete-container-action"
        id="delete-container-action"
        (click)="confirmDeleteAction()"
      >
        <span class="p-4 text-error-500">Delete action</span>
      </button>
    </mat-menu>
  </section>
</section>
