import {
  Component,
  effect,
  EventEmitter,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ConnectedStationInfo,
  FlowLogicRule,
  Question,
  Power,
  PowerTrigger,
  RuleEquation,
  RuleType,
  TriggerType,
  PowerAction,
  CustomIdSettings,
  GridsterItemWidget,
  TriggerButton,
  PowerTriggerToUpdate,
  StationBucketQuestion,
  StationLibraryRelationship,
  PowerEventType,
  OrderAction,
  QuestionFieldType,
  PowerEventAction,
} from 'src/models';
import { MatDialog } from '@angular/material/dialog';
import { ErrorService } from 'src/app/core/error.service';
import { PopupService } from 'src/app/core/popup.service';
import {
  first,
  map,
  Observable,
  startWith,
  Subject,
  of,
  catchError,
  ignoreElements,
  takeUntil,
} from 'rxjs';
import { ContainerService } from 'src/app/core/container.service';
import { StationService } from 'src/app/core/station.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { ActionHelper, ComponentHelper, TermsGeneric } from 'src/helpers';
import { ConditionsComponent } from 'src/app/station/rules/conditions/conditions.component';
import { ContainerActionsComponent } from 'src/app/station/rules/actions/container-actions/container-actions.component';
import { PowerService } from 'src/app/core/power.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { ComingSoonMessageComponent } from 'src/app/shared/coming-soon-message/coming-soon-message.component';
import { ActionsListComponent } from 'src/app/station/rules/actions/actions-list/actions-list.component';
import { FlowContainerFormActionComponent } from 'src/app/station/rules/actions/flow-container-action/flow-container-form-action/flow-container-form-action.component';
import { IntegrationsComponent } from 'src/app/station/rules/actions/integrations/integrations.component';
import { NotificationsActionComponent } from 'src/app/station/rules/actions/notifications/notifications-action.component';
import { ScriptsActionsComponent } from 'src/app/station/rules/actions/scripts-actions/scripts-actions.component';
import { TriggersComponent } from 'src/app/station/rules/triggers/triggers.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { RithmAiActionComponent } from 'src/app/station/rules/actions/rithm-ai-action/rithm-ai-action.component';
import { UserActionsComponent } from 'src/app/station/rules/actions/user-actions/user-actions.component';

/**
 * Component for the flow logic tab on a station.
 */
@Component({
  selector: 'app-rules[nextStations]',
  templateUrl: './rules.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatChipsModule,
    MatListModule,
    MatInputModule,
    MatTooltipModule,
    ScrollingModule,
    LoadingIndicatorComponent,
    ActionsListComponent,
    TriggersComponent,
    ConditionsComponent,
    FlowContainerFormActionComponent,
    ContainerActionsComponent,
    ComingSoonMessageComponent,
    NotificationsActionComponent,
    IntegrationsComponent,
    ScriptsActionsComponent,
    RithmAiActionComponent,
    UserActionsComponent,
  ],
  styleUrls: ['./rules.component.scss'],
})
export class RulesComponent implements OnInit, OnDestroy {
  /** Action helper. */
  actionHelper = ActionHelper;

  /** The component conditions to be updated. */
  @ViewChild('condition', { static: false })
  condition!: ConditionsComponent;

  /** The component container actions to be updated. */
  @ViewChild('containerAction', { static: false })
  containerAction!: ContainerActionsComponent;

  /** Feature flag for rithmAI action. */
  rithmAIActionFlag = input<boolean>(false);

  /** Feature flag order of operations. */
  @Input({ required: true }) orderOfOperations = false;

  /**
   * ? This block should be used to place @INPUT/@OUTPUT variables.
   */
  /** The list of stations to display in the pane. */
  @Input() nextStations: ConnectedStationInfo[] = [];

  /** Station Rithm id. */
  @Input() rithmId = '';

  /** Feature flag Sql Integration. */
  @Input() showOptionSqlIntegration = false;

  /** Flow Button Name. */
  @Input() flowButtonName = '';

  /** Feature flag Assigned to conditions. */
  @Input() assignedToConditions = false;

  /** Feature flag to show the rules for Number field in conditions. */
  @Input() showNumberFieldRules = false;

  /** Flag to show Relationships. */
  @Input() showRelationshipActions = false;

  /** Flag to show append field action. */
  @Input() showAppendFieldActions = false;

  /** Flag to show action move container. */
  @Input() showActionMoveContainer = false;

  /** Flag advancedUpdateAction. */
  @Input() showAdvancedUpdateAction = false;

  /** Flag parent child relationship action. */
  @Input() showParentChildRelationship = false;

  /** Show or not custom fields option. */
  @Input() showFlowedBy = false;

  /** If the update field trigger must be shown. */
  @Input() showUpdateFieldTrigger = false;

  /** Feature flag for attachment field conditions. */
  @Input() attachmentFieldLogicFlag = false;

  /** Feature flag for attachment conditions filters. */
  @Input({ required: true }) flagAttachmentsConditionsFilters = false;

  /** Input Frames of the station template. */
  @Input() set inputFrameWidgetItems(
    inputFrameWidgetItems: GridsterItemWidget[],
  ) {
    this._inputFrameWidgetItems = inputFrameWidgetItems;
  }

  /** The modified Flow Logic Rule to send back to station. */
  @Output() modifiedFlowRules = new EventEmitter<FlowLogicRule | null>();

  /** Move me to add custom Id. */
  @Output() standOutCustomId = new EventEmitter<void>();

  /** Input frame private. */
  private _inputFrameWidgetItems!: GridsterItemWidget[];

  /**
   * Get input frames.
   * @returns Input Frames.
   */
  get inputFrameWidgetItems(): GridsterItemWidget[] {
    return this._inputFrameWidgetItems;
  }

  /**
   * ? This block should be used to place SUBJECTS/OBSERVABLES Variables.
   */
  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /**Filtered form station List. */
  filteredStations$: Observable<ConnectedStationInfo[]> | undefined;

  /** Owner List. */
  stationTriggers$!: Observable<PowerTrigger[]>;

  /** Owner error List. */
  stationTriggersError$!: Observable<unknown>;

  /** Station buttons flags. */
  stationButtonsFlag$!: Observable<TriggerButton[]>;

  /** Station buttons error flags. */
  stationButtonsFlagError$!: Observable<unknown>;

  /**
   * ? This block should be used to place NOT INITIALIZED variables.
   */

  /** The form to add this field in the template. */
  flowFieldForm = new FormGroup({
    stations: new FormControl<string[]>([]),
  });

  /** Contains the new flow logic rule for saved . */
  newFlowLogic!: FlowLogicRule;

  /**
   * ? This block should be used to place variables initialized with CLASSES/MODELS/ENUM.
   */
  /** List trigger type. */
  triggerType = TriggerType;

  /** Get all existing rule types. */
  rulesType = RuleType;

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /**
   * ? This block should be used to place BOOLEAN variables.
   */
  /** Allow switch between new/old interface. */
  showRulesList = true;

  /** The date and time zone shown, if true. */
  showDateTimeZone = false;

  /** Display days field if container check is selected, else hide. */
  showDaysField = false;

  /** Display date interval repeat section if 'never' isn't selected, else hide. */
  showRepeatForever = false;

  /** The edit rule name info display if in edit mode, else hide . */
  editedRuleInfo = false;

  /** The power has been edited enable the save button, else disable . */
  somethingHasChanged = false;

  /** A little blink. */
  ticTime = false;

  /**
   * ? This block should be used to place ARRAY initialized variables.
   */

  /** The station Flow Logic Rule. */
  flowLogicRules: FlowLogicRule[] = [];

  /** The powers of current station. */
  stationPowers: Power[] = [];

  /** This will backup stationPowers to its initial value after cancelling changes. */
  _stationPowers_backup: Power[] = [];

  /** The list of all stations. */
  stations: ConnectedStationInfo[] = [];

  /** The list of selected stations in flow section. */
  flowStations: ConnectedStationInfo[] = [];

  /** Station Widgets type custom id array. */
  customIdQuestions: Question[] = [];

  /** Copy customId questions. */
  customIdQuestionsCopy: Question[] = [];

  /** Station Widgets with valid data. */
  customIdQuestionsData: Question[] = [];

  /** Station Widgets type custom id array. */
  customIdInputFrameWidgetItems: GridsterItemWidget[] = [];

  /** Station Widgets with valid data. */
  customIdInputFrameWidgetItemsData: GridsterItemWidget[] = [];

  /** Bucket questions. */
  bucketQuestions: Question[] = [];

  /** Station bucket questions. */
  poolQuestions: StationBucketQuestion[] = [];

  /** Tooltip. */
  manuallyTooltip = `Upon pressing the ${this.termsGeneric.Flow.Single.toLowerCase()} button, containers will be checked and\
     ${this.termsGeneric.Flow.Past} to their destination`;

  conditionTypeForm = new FormGroup({
    conditionType: new FormControl(RuleType.And, { nonNullable: true }),
  });

  /** Selected custom ID github trigger. */
  customIdSelected = '';

  /* Loading the rules list  by type  */
  flowLogicLoadingByRuleType: string | null = null;

  /** The end repeat start. */
  startDate = new Date();

  /* The selected index of the actions section toggle button. */
  selectedActionTab = '0';

  /* Indicates the change of option with the id in the list of powers. */
  refreshIdContainer = '';

  /** Power Name temporally. */
  powerNameTemporal = 'Untitled rule';

  /**
   * ? This block should be used to place OBJECT/JSON initialized variables.
   */
  /** The powers that are currently being edited. */
  editedPower: Power = {
    rithmId: uuidv4(),
    triggers: [],
    actions: [],
    stationRithmId: '',
    flowToStationRithmIds: [],
    ruleName: 'Untitled rule',
    conditions: [],
    ruleType: RuleType.And,
  };

  /** The action being saved. */
  savingAction: {
    /** The action id being saved. */
    rithmId: string;
    /** If the action is being saved. */
    isSaving: boolean;
  } = {
    rithmId: '',
    isSaving: false,
  };
  /**
   * ? This block should be used to place NUMBERS initialized variables.
   */

  /** The index of the power selected to edit/delete. */
  editedPowerIndex = 0;

  /** Height edit code. */
  heightScript = 500;

  /**
   * ? This block should be used for LOADING/ERROR variables.
   */
  /* Loading the list of rules of flow logic*/
  ruleLoading = false;

  /* Loading the list of stations flow logic*/
  flowLogicLoading = true;

  /* Loading the powers on the station*/
  powersLoading = false;

  /* Powers view loading indicator.*/
  powerViewLoading = false;

  /* Rules list loading indicator.*/
  rulesLoading = false;

  /** The error if rules fails . */
  ruleError = false;

  /** The error if rules fails . */
  flowRuleError = false;

  /* Loading in input auto-complete the list of all stations. */
  stationLoading = false;

  /** Loading station triggers. */
  stationTriggersLoading = false;

  /** Station Libraries is Loading.*/
  stationLibrariesLoading = false;

  /** Station Libraries.*/
  stationLibraryRelationships: StationLibraryRelationship[] = [];

  /** Variable to create new action. */
  newAction = false;

  /** Contains if trigger is valid or not. */
  isValidTrigger = false;

  /** Show management form or not. */
  showManagementMembersForm = false;

  constructor(
    public dialog: MatDialog,
    private popupService: PopupService,
    private errorService: ErrorService,
    private containerService: ContainerService,
    private stationService: StationService,
    private powerService: PowerService,
  ) {
    effect(() => {
      if (
        this.rithmAIActionFlag() &&
        !this.actionHelper.availableActions.some(
          ({ label }) => label === 'RithmAI',
        )
      ) {
        this.actionHelper.availableActions.push({
          id: 'toggle-button-rithm-ai',
          value: '6',
          label: 'RithmAI',
        });
      }
    });
  }

  /**
   * Life cycle init the component.
   */
  ngOnInit(): void {
    this.getStationBucketQuestions();
    this.getStationPowers();
    this.getStationButtons();
    this.getPreviousAndNextStations();
    this.getStationLibrariesRelationships();
    this.updateRelationshipConditions$();
    this.setPowerAction$();
    this.editedPower.stationRithmId = this.rithmId;
  }

  /**
   * Update relationship action.
   */
  updateRelationshipConditions$(): void {
    this.stationService.updateRelationshipConditions$
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.getStationLibrariesRelationships();
        },
      });
  }

  /**
   * Set action according to an event type.
   */
  private setPowerAction$(): void {
    this.powerService.setPowerAction$
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (powerEventAction) => {
          const action = powerEventAction.action;
          const powerIndex = this.stationPowers.findIndex(
            (x) => x.rithmId === powerEventAction.powerRithmId,
          );
          if (powerIndex > -1) {
            //  Add and duplicate functionality.
            if (
              powerEventAction.eventAction === PowerEventType.Add ||
              powerEventAction.eventAction === PowerEventType.Duplicate
            ) {
              this.stationPowers[powerIndex].actions.push(action);
            } else {
              const actionIndex = this.stationPowers[
                powerIndex
              ].actions.findIndex((x) => x.rithmId === action.rithmId);
              // Functionality to remove an action.
              if (powerEventAction.eventAction === PowerEventType.Delete) {
                actionIndex > -1 &&
                  this.stationPowers[powerIndex].actions.splice(actionIndex, 1);
              } else {
                // Functionality to update an action.
                this.stationPowers[powerIndex].actions[actionIndex] = action;
              }
            }
            this._stationPowers_backup = _.cloneDeep(this.stationPowers);
          }
          switch (powerEventAction.eventAction) {
            case PowerEventType.Add:
              this.popupService.notify('Action saved successfully');
              break;
            case PowerEventType.Duplicate:
              this.popupService.notify('Action duplicated successfully');
              break;
            case PowerEventType.Delete:
              this.reorderListActionsContainer();
              this.popupService.notify('Action removed successfully');
              break;
            case PowerEventType.Update:
              this.popupService.notify('Action updated successfully');
              break;
          }
          this.newAction = false;
        },
      });
  }

  /**
   * When missing saved on customId or is invalid displayed alert icon.
   * @returns True or false.
   */
  get alertOnCustomId(): boolean {
    return this.customIdQuestionsCopy.length < this.customIdQuestions.length;
  }

  /**
   * Return if selectedRule is not saved yet.
   * @returns Is Rule selected saved.
   */
  get isRuleSelectedSaved(): boolean {
    return !!this.stationPowers.find(
      (power) => power.rithmId === this.editedPower.rithmId,
    )?.rithmId;
  }

  /**
   * Returns true if the field matches the powers edited, enable the save button.
   */
  hasBeenEdited(): void {
    this.editedPower.ruleType =
      this.conditionTypeForm.value.conditionType || RuleType.And;

    this.somethingHasChanged =
      JSON.stringify(this.stationPowers) !==
      JSON.stringify(this._stationPowers_backup);
  }

  /**
   * Add new condition when flag its true to work independent condition.
   * @param condition Condition to add in array of conditions.
   */
  addCondition(condition: RuleEquation): void {
    const index = this.editedPower.conditions.findIndex(
      (e) => e.rithmId === condition.rithmId,
    );
    if (index > -1) {
      this.editedPower.conditions[index] = condition;
    } else {
      this.editedPower.conditions.push(condition);
    }
    this.conversionTextOfConditionsTypeCheckList(this.editedPower);
  }

  /**
   * Delete specific condition by id.
   * @param conditionId Condition id.
   */
  deleteConditionById(conditionId: string): void {
    const index = this.editedPower.conditions.findIndex(
      (e) => e.rithmId === conditionId,
    );
    index > -1 && this.editedPower.conditions.splice(index, 1);
  }

  /**
   * Get's list of station bucket questions.
   */
  private getStationBucketQuestions(): void {
    this.powersLoading = true;
    this.stationService
      .getStationBucketQuestions(this.rithmId)
      .pipe(first())
      .subscribe({
        next: (bucketQuestions) => {
          this.bucketQuestions =
            ComponentHelper.parseBucketQuestionsToQuestions(bucketQuestions);
          this.poolQuestions = bucketQuestions;
          this.powersLoading = false;
        },
        error: (error: unknown) => {
          this.powersLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Verify if the current connected station has subrules added or not.
   * @param connectedStationId Current connected station.
   * @returns True if so, false if not.
   */
  hasSubRulesAdded(connectedStationId: string): boolean {
    const currentFlow = this.flowLogicRules.find(
      (station) => station.destinationStationRithmID === connectedStationId,
    );
    if (currentFlow) {
      return !!currentFlow.flowRule.subRules.length;
    }
    return false;
  }

  /**
   * Verify if the current connected station has subrules added or not.
   * @param connectedStationId Current connected station.
   * @param type The type for current rules.
   * @returns True if so, false if not.
   */
  hasSubRulesByType(connectedStationId: string, type: 'all' | 'any'): boolean {
    const currentFlow = this.flowLogicRules.find(
      (station) => station.destinationStationRithmID === connectedStationId,
    );
    if (currentFlow) {
      if (type === 'all') {
        const equationsAND = currentFlow.flowRule.subRules.find(
          (sb) => sb.ruleType === RuleType.And,
        );
        return !!equationsAND;
      } else {
        const equationsOR = currentFlow.flowRule.subRules.find(
          (sb) => sb.ruleType === RuleType.Or,
        );
        return !!equationsOR;
      }
    }
    return false;
  }

  /**
   * Return the flowRule Object of the currentStation if exist.
   * @param connectedStationId The id of each station connected to the FlowLogicRule.
   * @param type The type of rule to return.
   * @returns And emptyByDefault or existing RuleObject.
   */
  getEquationsByRuleType(
    connectedStationId: string,
    type: 'all' | 'any',
  ): RuleEquation[] {
    const defaultRule: RuleEquation[] = [];
    if (this.hasSubRulesByType(connectedStationId, type)) {
      const currentFlow = this.flowLogicRules.find(
        (station) => station.destinationStationRithmID === connectedStationId,
      );
      if (currentFlow) {
        if (type === 'all') {
          const equations = currentFlow.flowRule.subRules.find(
            (sr) => sr.ruleType === RuleType.And,
          )?.equations;
          return equations ? equations : [];
        }

        if (type === 'any') {
          const equations = currentFlow.flowRule.subRules.find(
            (sr) => sr.ruleType === RuleType.Or,
          )?.equations;
          return equations ? equations : [];
        }
      }
    }
    return defaultRule;
  }

  /**
   * Get the list of all stations.
   */
  getPreviousAndNextStations(): void {
    this.stationLoading = true;
    this.stationService
      .getPreviousAndNextStations(this.rithmId)
      .pipe(first())
      .subscribe({
        next: (stations) => {
          this.stations = [];
          this.stations = this.stations.concat(stations.nextStations);
          this.filterStations();
          this.stationLoading = false;
        },
        error: (error: unknown) => {
          this.stationLoading = false;
          this.errorService.displayError(
            `Failed to get all ${TermsGeneric.Station.Plural.toLowerCase()} for this data link field.`,
            error,
            false,
          );
        },
      });
  }

  /**
   * Get the powers (triggers, actions, flow) of current station.
   *
   */
  private getStationPowers(): void {
    this.rulesLoading = true;
    this.powerService
      .getStationPowers(this.rithmId)
      .pipe(first())
      .subscribe({
        next: (powers) => {
          this.stationPowers = powers;
          this.stationPowers.forEach((power) => {
            if (!power.conditions) {
              power.ruleType = RuleType.And;
              power.conditions = [];
            } else {
              this.conversionTextOfConditionsTypeCheckList(power);
            }
            if (!power.flowToStationRithmIds) {
              power.flowToStationRithmIds = [];
            }
          });
          this.powerViewLoading = false;
          this.rulesLoading = false;
          this._stationPowers_backup = _.cloneDeep(this.stationPowers);
          if (powers.length) {
            this.rulesHandler(0);
          }
        },
        error: (error: unknown) => {
          this.powersLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Filter the list of all stations.
   */
  private filterStations(): void {
    /** Set the filter List for auto searching. */
    this.filteredStations$ =
      this.flowFieldForm.controls.stations.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value as string)),
        takeUntil(this.destroyed$),
      );
  }

  /**
   * Filtered Values.
   * @param value Current String in Field Forms.
   * @returns Filtered value.
   */
  private _filter(value: string): ConnectedStationInfo[] {
    const filterValue =
      value && typeof value !== 'object' ? value.toLowerCase() : '';
    return this.stations.filter((option) =>
      option.name.toLowerCase().includes(filterValue),
    );
  }

  /**
   * Toggle the responsive view to hide/show rules list.
   * @param powerIndex The parameter optional, the power selected.
   */
  rulesHandler(powerIndex = 0): void {
    this.newAction = false;
    this.editedRuleInfo = false;
    this.editedPowerIndex = powerIndex;
    this.powerViewLoading = true;
    setTimeout(() => {
      this.powerViewLoading = false;
      if (this.showRulesList) {
        this.showRulesList = false;
      }
      this.resetEditPowerInfo();
      this.resetActionsSection();
      if (this.stationPowers[powerIndex]) {
        this.powerSelected(this.stationPowers[powerIndex]);
      }
    }, 300);
  }

  /**
   * Run several functions to create a default state in the action section.
   */
  resetActionsSection(): void {
    this.selectedActionTab = '0';
    this.refreshIdContainer = this.editedPower.rithmId;
    this.flowStations = [];
    this.flowFieldForm.controls.stations.setValue([]);
  }

  /**
   * Update's the selected station array data which is displayed as chips.
   *
   */
  flowStationSelect(): void {
    this.flowStations = [];
    const flowToStations = this.flowFieldForm.value.stations || [];
    flowToStations.map((stationId: string) => {
      const station = this.stations.find((e) => e.rithmId === stationId);
      if (station) {
        this.flowStations.push(station);
      }
    });
    this.editedPower.flowToStationRithmIds = flowToStations;
    this.hasBeenEdited();
  }

  /**
   * Delete the power of current station.
   * @param powerRithmId The id of the power to be removed.
   */
  async deleteStationPowers(powerRithmId: string): Promise<void> {
    const confirm = await this.popupService.confirm({
      title: 'Delete Rule',
      message: 'Are you sure you want to remove this rule?',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (confirm) {
      this.powersLoading = true;
      this.powerService
        .deleteStationPowers(powerRithmId, this.rithmId)
        .pipe(first())
        .subscribe({
          next: () => {
            const powerIndex = this.stationPowers.findIndex(
              (power) => powerRithmId === power.rithmId,
            );
            this.stationPowers.splice(powerIndex, 1);
            //This will set as selected the power previous to the deleted one if is the case.
            this.editedPowerIndex =
              this.stationPowers.length && powerIndex - 1 > 0
                ? powerIndex - 1
                : 0;
            this.rulesHandler(this.editedPowerIndex);
            this.powersLoading = false;
            this._stationPowers_backup = _.cloneDeep(this.stationPowers);
            this.somethingHasChanged = false;
          },
          error: (error: unknown) => {
            this.powersLoading = false;
            this.errorService.displayError(
              "Something went wrong on our end and we're looking into it. Please try again in a little while.",
              error,
            );
          },
        });
    }
  }

  /**
   * Delete rule from station flow logic.
   * @param index The index of each rule.
   * @param type If the rule to add is AND/OR type.
   * @param connectedStationId The connected station to create the rule.
   */
  async deleteEquationFromFlowLogic(
    index: number,
    type: 'all' | 'any',
    connectedStationId: string,
  ): Promise<void> {
    const confirm: boolean = await this.popupService.confirm({
      title: 'Remove Rule',
      message: `Are you sure to remove the selected rule from this ${TermsGeneric.Station.Single.toLocaleLowerCase()}?`,
      okButtonText: 'Remove',
      important: true,
    });
    if (confirm) {
      const flowLogicRules = this.flowLogicRules.find(
        (station) => station.destinationStationRithmID === connectedStationId,
      );
      if (flowLogicRules) {
        this.flowLogicLoadingByRuleType = `${connectedStationId}-${type}`;

        // cloning variable without memory
        const flowLogicRulesCopy: FlowLogicRule = _.clone(flowLogicRules);

        // remove the rule from the  array  flowLogicRulesCopy when is 'ANY' or 'all'
        this.removeEquationFromSubRule(flowLogicRulesCopy, type, index);

        this.containerService
          .deleteEquationFromFlowLogic([flowLogicRulesCopy])
          .pipe(first())
          .subscribe({
            next: () => {
              this.flowLogicLoadingByRuleType = null;
              this.modifiedFlowRules.emit(null);
            },
            error: (error: unknown) => {
              this.flowRuleError = true;
              this.flowLogicLoading = false;
              this.flowLogicLoadingByRuleType = null;
              this.errorService.displayError(
                "Something went wrong on our end and we're looking into it. Please try again in a little while.",
                error,
              );
            },
          });
      }
    }
  }

  /**
   * Should remove one item from the current flowLogic in the current station.
   * @param _flowLogicRule The current Flow logic.
   * @param type The type of subrules to filter equations.
   * @param index The index of the equation to be removed.
   */
  private removeEquationFromSubRule(
    _flowLogicRule: FlowLogicRule,
    type: 'all' | 'any',
    index: number,
  ): void {
    const subRuleObjectAND = _flowLogicRule.flowRule.subRules.find(
      (sr) => sr.ruleType === RuleType.And,
    );
    const subRuleObjectOR = _flowLogicRule.flowRule.subRules.find(
      (sr) => sr.ruleType === RuleType.Or,
    );
    if (type === 'all') {
      if (subRuleObjectAND) {
        const subRuleIndex =
          _flowLogicRule.flowRule.subRules.indexOf(subRuleObjectAND);
        subRuleObjectAND.equations.splice(index, 1);
        if (!subRuleObjectAND.equations.length) {
          _flowLogicRule.flowRule.subRules.splice(subRuleIndex, 1);
        }
      }
    }

    if (type === 'any') {
      if (subRuleObjectOR) {
        const subRuleIndex =
          _flowLogicRule.flowRule.subRules.indexOf(subRuleObjectOR);
        subRuleObjectOR.equations.splice(index, 1);
        if (!subRuleObjectOR.equations.length) {
          _flowLogicRule.flowRule.subRules.splice(subRuleIndex, 1);
        }
      }
    }

    if (
      !subRuleObjectAND?.equations.length &&
      !subRuleObjectOR?.equations.length
    ) {
      _flowLogicRule.flowRule.equations = [];
      _flowLogicRule.flowRule.subRules = [];
    }
  }

  /**
   * Receives a action type.
   * @param containerActions Contains data of action type.
   */
  addUpdateActionTypeContainer(containerActions: PowerAction[]): void {
    if (containerActions.length === 1 && this.editedPower.actions.length > 1) {
      containerActions = [...this.editedPower.actions, ...containerActions];
    }
    containerActions.map((action, index) => {
      const order = this.orderOfOperations ? index + 1 : 0;
      action.order = order;
    });
    this.editedPower.actions = containerActions;
    this.newAction = false;
    this.hasBeenEdited();
  }

  /**
   * Handle the event action.
   * @param event The event action.
   */
  handleEventAction(event: Omit<PowerEventAction, 'powerRithmId'>): void {
    switch (event.eventAction) {
      case PowerEventType.Add:
      case PowerEventType.Duplicate:
        this.addOrUpdateSpecificAction(event.action, event.eventAction);
        break;
      case PowerEventType.Delete:
        this.confirmRemoveAction(event.action);
        break;
    }
  }

  /**
   * Add or update action.
   * @param powerAction The current action to be created or updated.
   * @param powerEvent The event type to be executed.
   */
  addOrUpdateSpecificAction(
    powerAction: PowerAction,
    powerEvent: PowerEventType,
  ): void {
    if (powerEvent === PowerEventType.Duplicate) {
      powerAction.order = this.editedPower.actions.length + 1;
      powerAction = {
        ...powerAction,
        rithmId: uuidv4(),
      };
    }

    this.savingAction = {
      rithmId: powerAction.rithmId,
      isSaving: true,
    };

    this.powerService
      .addOrUpdateSpecificAction(this.editedPower.rithmId, powerAction)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: powerEvent,
            action: powerAction,
            powerRithmId: this.editedPower.rithmId,
          });
          this.newAction = false;
          this.savingAction = {
            rithmId: powerAction.rithmId,
            isSaving: false,
          };
        },
        error: () => {
          this.savingAction = {
            rithmId: powerAction.rithmId,
            isSaving: false,
          };
          this.popupService.notify('Error saving action', true);
        },
      });
  }

  /**
   * Popup confirmation delete/update action.
   * @param action Action of the action to be deleted.
   */
  async confirmRemoveAction(action: PowerAction): Promise<void> {
    const confirm = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'This Action will be deleted',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (!confirm) return;

    if (this.orderOfOperations) {
      this.deleteSpecificAction(action);
      return;
    }

    const actionIndex = this.editedPower.actions.findIndex(
      (currentAction) => currentAction.rithmId === action.rithmId,
    );
    this.editedPower.actions.splice(actionIndex, 1);
  }

  /**
   * Delete a specific action.
   * @param action  Delete the current action.
   */
  deleteSpecificAction(action: PowerAction): void {
    this.powerService
      .deleteAction(this.editedPower.rithmId, action.rithmId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: PowerEventType.Delete,
            action,
            powerRithmId: this.editedPower.rithmId,
          });
        },
        error: () => {
          this.popupService.notify('Error to delete action', true);
        },
      });
  }

  /**
   * Confirm remove rule .
   * @param powerRithmId The id of the power to be removed.
   */
  async confirmRemoveRule(powerRithmId: string): Promise<void> {
    const confirm = await this.popupService.confirm({
      title: 'Delete Rule',
      message: 'Are you sure you want to remove this rule?',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (confirm) {
      this.deleteStationPowers(powerRithmId);
    }
  }

  /**
   * Sets the selected power as the current power.
   * @param powerSelected This power pre-load selected.
   */
  private powerSelected(powerSelected: Power): void {
    if (!powerSelected.conditions) {
      powerSelected.conditions = [];
      powerSelected.ruleType = RuleType.And;
    }
    this.powerNameTemporal = powerSelected.ruleName || '';
    if (powerSelected.flowToStationRithmIds.length) {
      this.flowFieldForm.controls.stations.setValue(
        powerSelected.flowToStationRithmIds,
      );
      this.flowStationSelect();
    }
    this.editedPowerIndex = this.stationPowers.indexOf(powerSelected);
    this.editedPower = powerSelected;

    /** Assign to the form the type of condition according to the selected rule. */
    this.conditionTypeForm.controls.conditionType.setValue(
      this.editedPower.ruleType || RuleType.And,
    );
  }

  /**
   * It restores the editedPower object to its default state.
   */
  resetEditPowerInfo(): void {
    this.editedPower = {
      rithmId: uuidv4(),
      triggers: [],
      actions: [],
      stationRithmId: this.rithmId,
      flowToStationRithmIds: [],
      ruleName:
        'Default Rule: Flow to all ' +
        this.termsGeneric.Station.Plural.toLowerCase(),
      conditions: [],
      ruleType: RuleType.And,
    };
    this.powerNameTemporal = this.editedPower.ruleName || '';
  }

  /**
   * Add a new power to the array of powers for this station.
   */
  addPowerToStation(): void {
    this.stationPowers = _.cloneDeep(this._stationPowers_backup);
    const newPower: Power = {
      rithmId: uuidv4(),
      triggers: [
        {
          rithmId: uuidv4(),
          type: this.triggerType.ManualFlow,
          source: '',
          value: '',
          startDateUTC: new Date().toJSON(),
          endDateUTC: null,
          settings: '',
          isDisabled: false,
        },
      ],
      actions: [],
      stationRithmId: this.rithmId,
      flowToStationRithmIds: [],
      ruleName: 'Untitled rule',
      ruleType: RuleType.And,
      conditions: [],
    };
    this.stationPowers.push(newPower);
    this.resetEditPowerInfo();
    this.updateStationPowers(true);
  }

  /**
   * Update the station powers array.
   * @param singleUpd Variable to know if the request is made to add a new power.
   */
  updateStationPowers(singleUpd = false): void {
    this.newAction = false;
    this.powerViewLoading = true;
    const parameter = singleUpd
      ? [this.stationPowers[this.stationPowers.length - 1]]
      : this.stationPowers;

    // WHen flag its false send to backend order in 0 to works fine signalR.
    if (!this.orderOfOperations) {
      parameter.forEach((power) => {
        power.actions.forEach((action) => {
          action.order = 0;
        });
      });
    }
    this.powerService
      .saveStationPowers(parameter)
      .pipe(first())
      .subscribe({
        next: (powers) => {
          let message = `Rule Updated Successfully`;
          if (powers) {
            if (singleUpd) {
              this.stationPowers[this.stationPowers.length - 1] = powers[0];
              this.rulesHandler(this.stationPowers.length - 1);
              message = `${powers[0].ruleName} has been successfully created`;
            } else {
              //If the user is currently editing some power, then we will set the edited power as selected again.
              this.stationPowers = powers;
              this.rulesHandler(this.editedPowerIndex);
            }
            this._stationPowers_backup = _.cloneDeep(this.stationPowers);
            this.notify(message, false, 5000);
          }

          this.powerViewLoading = false;
          this.somethingHasChanged = false;
          this.isValidTrigger = false;
        },
        error: () => {
          this.stationPowers = _.cloneDeep(this._stationPowers_backup);
          this.powersLoading = false;
          this.powerViewLoading = false;
          this.rulesLoading = false;
          this.notify('Unable to save rule', true, 5000);
        },
      });
  }

  /**
   * Returns all non-archived question of type button.
   */
  private getStationButtons(): void {
    this.stationButtonsFlag$ = this.stationService.getStationTriggerButtons(
      this.rithmId,
    );

    this.stationButtonsFlagError$ = this.stationButtonsFlag$.pipe(
      ignoreElements(),
      catchError((err: unknown) => {
        return of(err);
      }),
    );
  }

  /** This cancel button clicked show alert. */
  async cancelRulesChanges(): Promise<void> {
    const confirm = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'Changes that have not been saved will be lost.',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (confirm) {
      this.stationPowers = _.cloneDeep(this._stationPowers_backup);

      this.somethingHasChanged = false;
      this.rulesHandler(this.editedPowerIndex);
      this.resetForm();
    }
  }

  /**
   * Reset forms of conditions and container actions.
   */
  resetForm(): void {
    this.condition && this.condition.closeForm();
    this.containerAction && this.containerAction.cancelAction();
  }

  /**
   * Add custom id if there are not id.
   */
  addCustomId(): void {
    this.standOutCustomId.emit();
  }

  /**
   * Return custom id label.
   * @param data Data stringJson.
   * @returns Custom id label.
   */
  getCustomIdLabel(data: string | undefined): string {
    try {
      const customId = JSON.parse(data as string);
      return 'label' in customId
        ? (customId as CustomIdSettings).label
        : 'Custom ID';
    } catch (error) {
      return '';
    }
  }

  /**
   * Return custom id label.
   * @param newHeight Height Script code.
   */
  getHeightScript(newHeight: number): void {
    this.heightScript = newHeight;
  }

  /** After clicking the pencil icon, save to the name of the power.*/
  changePowerName(): void {
    this.editedPower.ruleName = this.powerNameTemporal;
    this.editedRuleInfo = !this.editedRuleInfo;
    this.hasBeenEdited();
  }

  /**
   * Add scheduled trigger.
   * @param triggerScheduled New type scheduled trigger.
   */
  handleScheduleTrigger(triggerScheduled: PowerTriggerToUpdate): void {
    const triggers = this.stationPowers[this.editedPowerIndex].triggers;

    const triggerIndex = triggers.findIndex(
      (trigger) => trigger.rithmId === triggerScheduled.trigger.rithmId,
    );

    if (triggerIndex > -1) {
      triggers[triggerIndex] = triggerScheduled.trigger;
      triggerScheduled.removeTrigger && triggers.splice(triggerIndex, 1);
    } else {
      triggers.push(triggerScheduled.trigger);
    }
    this.hasBeenEdited();
  }

  /**
   * Retrieve all the station available libraries with relationships.
   */
  private getStationLibrariesRelationships(): void {
    this.stationLibrariesLoading = true;
    this.stationService
      .getStationLibrariesRelationships(this.rithmId)
      .pipe(first())
      .subscribe({
        next: (libraries) => {
          this.stationLibraryRelationships = libraries;
          this.stationLibrariesLoading = false;
        },
        error: () => {
          this.notify('Error getting relationships', true);
          this.stationLibrariesLoading = false;
        },
      });
  }

  /**
   * Notify and show pop-up for actions the admin.
   * @param message Message for show in popup.
   * @param isError If the popup is an error message.
   * @param duration Whether the snackbar is for an error message. Optional; defaults to `false`, non-error.
   * @param icon Icon as Font Awesome, example: 'fa-light fa-circle-exclamation'.
   */
  private notify(
    message: string,
    isError = false,
    duration?: number,
    icon?: string,
  ): void {
    this.popupService.notify(message, isError, duration, icon);
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Reorder actions list.
   */
  reorderListActionsContainer(): void {
    const orderActions: OrderAction[] = [];
    this.editedPower.actions.forEach((action, index) => {
      action.order = index + 1;
      const orderAction: OrderAction = {
        actionRithmId: action.rithmId,
        order: action.order,
      };
      orderActions.push(orderAction);
    });
    this.powerService
      .updateOrderActions(this.editedPower.rithmId, orderActions)
      .pipe(first())
      .subscribe();
  }

  /**
   * Update type of rule current.
   */
  updateRuleType(): void {
    if (this.orderOfOperations) {
      const valueRuleType: RuleType =
        this.conditionTypeForm.value.conditionType ?? this.editedPower.ruleType;
      this.powerService
        .updateRuleType(this.editedPower.rithmId, valueRuleType)
        .pipe(first())
        .subscribe({
          next: (response) => {
            this.somethingHasChanged = false;
            this.editedPower.ruleType = response;
            this.popupService.notify('The rule type has changed successfully');
          },
          error: () => {
            this.popupService.notify(
              "Something went wrong on our end and we're looking into it. Please try again in a little while.",
              true,
            );
          },
        });
    } else {
      this.hasBeenEdited();
    }
  }

  /**
   * Conversion text of conditions type check list.
   * @param power Power that needs validation to convert '~' to ','.
   */
  private conversionTextOfConditionsTypeCheckList(power: Power) {
    const listFiltered = power.conditions.filter(
      (condition) =>
        condition.rightOperand.questionType === QuestionFieldType.CheckList ||
        condition.rightOperand.questionType === QuestionFieldType.MultiSelect ||
        condition.rightOperand.questionType === QuestionFieldType.RadioList ||
        condition.rightOperand.questionType === QuestionFieldType.Select,
    );
    if (listFiltered !== undefined) {
      listFiltered.forEach((conditionCheckList) => {
        conditionCheckList.rightOperand.text =
          conditionCheckList.rightOperand.text.toString().replace(/~/g, ',');
      });
    }
  }
}
