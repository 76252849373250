<section
  class="notification-type-modal flex justify-between h-full w-full flex-col p-4"
>
  <!-- Modal header -->
  <section class="modal-title">
    <section class="flex flex-row justify-between items-start h-full">
      <div class="flex flex-col items-start pb-2">
        <span class="text-xl font-semibold tracking-wide pb-3"
          >Notification Types</span
        >
        <span class="text-primary-300 text-sm">
          <span class="text-primary-500">Silent:</span> Quietly receive
          notifications in the background
        </span>
        <span class="text-primary-300 text-sm">
          <span class="text-primary-500">Push:</span> In app only, push
          notifications can always be viewed later
        </span>
        <span class="text-primary-300 text-sm">
          <span class="text-primary-500">Browser:</span> The browser will notify
          you regardless if Rithm is open
        </span>
      </div>
    </section>
  </section>
  <!-- Modal content area -->
  <section mat-dialog-content class="flex modal-content h-full">
    <div class="px-4">
      <div class="w-full flex justify-between space-x-2">
        <div class="flex flex-col w-1/3 rounded mt-2 relative">
          <img
            class="h-[250px]"
            src="assets/images/notification-type/silent-notification.png"
            priority
            alt="Silent notification"
          />
          <div class="inline-block text-center my-2 text-primary-500">
            Silent
          </div>
        </div>
        <div class="flex flex-col w-1/3 rounded mt-2 relative">
          <img
            class="h-[250px]"
            src="assets/images/notification-type/push-notification.png"
            priority
            alt="Push notification"
          />
          <div class="inline-block text-center my-2 text-primary-500">Push</div>
        </div>
        <div class="flex flex-col w-1/3 rounded mt-2 relative">
          <img
            class="h-[250px]"
            src="assets/images/notification-type/browser-notification.png"
            priority
            alt="Browser notification"
          />
          <div class="inline-block text-center my-2 text-primary-500">
            Browser
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Modal footer area -->
  <mat-dialog-actions align="end">
    <button
      type="button"
      mat-button
      class="font-semibold uppercase text-accent-500"
      data-testid="close-modal-btn"
      id="close-modal-btn"
      (click)="closeModal()"
    >
      Close
    </button>
  </mat-dialog-actions>
</section>
