import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { v4 as uuidv4 } from 'uuid';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ErrorService } from 'src/app/core/error.service';
import { ScriptActionData, ScriptHistory, ScriptHistoryType } from 'src/models';
import { first, fromEvent, Subject, takeUntil } from 'rxjs';
import { ScriptsLogsActionsComponent } from 'src/app/station/rules/actions/scripts-actions/scripts-logs-actions/scripts-logs-actions.component';
import { ResizeElementDirective } from 'src/helpers/directives/resize/resizeElement.directive';

import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { MobileBrowserChecker, TermsGeneric } from 'src/helpers';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { CodeEditorModule } from '@ngstack/code-editor';
import { PopupService } from 'src/app/core/popup.service';
import { FormsModule } from '@angular/forms';
import { PowerService } from 'src/app/core/power.service';
/**
 * Component to represent script actions.
 *
 */
@Component({
  selector: 'app-scripts-actions[ruleRithmId]',
  templateUrl: './scripts-actions.component.html',
  styleUrls: ['./scripts-actions.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    LoadingIndicatorComponent,
    ScriptsLogsActionsComponent,
    ResizeElementDirective,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    CodeEditorModule,
    FormsModule,
    NgOptimizedImage,
  ],
})
export class ScriptsActionsComponent implements OnDestroy {
  @ViewChild(MatMenuTrigger)
  matMenuOptions!: MatMenuTrigger | undefined;

  /** Destroyed. */
  private destroyed$ = new Subject<void>();

  /** Rule id. */
  private _ruleRithmId = '';

  /**
   * Get ruleRithmId.
   * @returns String of the _ruleRithmId.
   */
  get ruleRithmId(): string {
    return this._ruleRithmId;
  }

  /** The rule rithm id. */
  @Input() set ruleRithmId(value: string) {
    this._ruleRithmId = value;
    if (this._ruleRithmId) {
      this.getScriptActions();
    }
  }

  /** Height edit code. */
  private _height = 500;

  /** Height edit code. */
  @Input() set height(newHeight: number) {
    this._height = newHeight;
    this.getHeight.emit(this._height);
  }

  /**
   * Height edit code.
   * @returns Height edit code.
   */
  get height(): number {
    return this._height;
  }

  /** Height edit code. */
  @Output() getHeight = new EventEmitter<number>();

  /**
   * If it has selected container changes with changes unsaved or new container.
   * @returns If the container have changes.
   */
  get isEditingDocumentSelected(): boolean {
    if (
      this.scriptsToEdit[this.indexTabScriptEdit] &&
      this.valueEditScript !== null
    ) {
      const indexOfDataAction = this.dataScriptActions.findIndex(
        (script) =>
          script.rithmId ===
          this.scriptsToEdit[this.indexTabScriptEdit]?.rithmId,
      );
      return this.scriptsToEdit[this.indexTabScriptEdit]?.contents ===
        this.valueEditScript
        ? this.dataScriptActions[indexOfDataAction]?.contents !==
            this.scriptsToEdit[this.indexTabScriptEdit]?.contents
        : this.dataScriptActions[indexOfDataAction]?.contents !==
            this.valueEditScript;
    }
    return false;
  }

  /** Data for show events in testing for all scripts. */
  dataScriptTesting: ScriptActionData[] = [];

  /** Contain all scripts. */
  dataScriptActions: ScriptActionData[] = [];

  /** List of scripts opened. */
  scriptsToEdit: ScriptActionData[] = [];

  /** List executes by scriptToEdit. */
  scriptsToEditWarning: number[] = [];

  /** Data for accumulate and show history the scripts. */
  historyScripts: ScriptHistory[] = [];

  /** Copy save the last logs in console. */
  copyLastConsoleLog: ScriptActionData[] = [];

  /** Value when edit in editor-code. */
  valueEditScript: string | null = null;

  /** Index tab to testing. */
  indexTabTesting = 0;

  /** Index of tab to edit script. */
  indexTabScriptEdit = 0;

  /** If it's loading the script list. */
  isLoadingScriptList = false;

  /** If it's loading the code editor. */
  isLoadingCodeEditor = false;

  /** Loading scripts by each testing file. */
  isLoadingScriptsTesting: boolean[] = [];

  /** Is loading history. */
  isLoadingHistory = false;

  /** If it has occurred an error for get script list. */
  isErrorLoadScriptList = false;

  /** Disable code edit. */
  isDisabledCodeEdit = false;

  /** Index of option that has been selected when clicked with right click. */
  indexOptionScriptList = -1;

  /** Is mode rename script is enabled. */
  enabledRenameScript = false;

  /** Set coordinates in mat-menu when is click right button on mouse. */
  menuPosition = { x: '0', y: '0' };

  /** If upload/ saving file. */
  isSavingFile = false;

  /** If deleting a file. */
  isDeletingFile = false;

  /** If rename a file. */
  isRenamingFile = false;

  /** If is creating a file. */
  isCreatingFile = false;

  /** If rename if called for key enter. */
  enterRename = false;

  /** Index Script Selected. */
  indexScriptSelected = -1;

  /** Search History. */
  searchHistory = '';

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  constructor(
    private errorService: ErrorService,
    private popupService: PopupService,
    public mobileBrowserChecker: MobileBrowserChecker,
    private powerService: PowerService,
  ) {
    this.CTRL_ALT_N();
    this.CTRL_N();
    this.CTRL_ALT_T();
    this.CTRL_T();
  }

  /**
   * Key event for Windows.
   */
  CTRL_ALT_N(): void {
    fromEvent(window, 'keydown')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((ev) => {
        const event = ev as KeyboardEvent;
        if (
          !this.isCreatingFile &&
          event.ctrlKey &&
          event.altKey &&
          event.key === 'n'
        ) {
          this.newScript();
        }
      });
  }

  /**
   * Key event for Mac.
   */
  CTRL_N(): void {
    fromEvent(window, 'keydown')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((ev) => {
        const event = ev as KeyboardEvent;
        if (!this.isCreatingFile && event.ctrlKey && event.key === 'n') {
          this.newScript();
        }
      });
  }

  /**
   * Key event for Windows.
   */
  CTRL_ALT_T(): void {
    fromEvent(window, 'keydown')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((ev) => {
        const event = ev as KeyboardEvent;
        if (
          !this.isSavingFile &&
          this.indexScriptSelected !== -1 &&
          event.ctrlKey &&
          event.altKey &&
          event.key === 't'
        ) {
          this.executedScriptTesting();
        }
      });
  }

  /**
   * Key event for Mac.
   */
  CTRL_T(): void {
    fromEvent(window, 'keydown')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((ev) => {
        const event = ev as KeyboardEvent;
        if (
          !this.isSavingFile &&
          this.indexScriptSelected !== -1 &&
          event.ctrlKey &&
          event.key === 't'
        ) {
          this.executedScriptTesting();
        }
      });
  }

  /**
   * This function is used to get the data of the executed script testing.
   */
  executedScriptTesting(): void {
    if (
      !this.isEditingDocumentSelected &&
      this.scriptsToEdit[this.indexTabScriptEdit] &&
      this.scriptsToEdit[this.indexTabScriptEdit].contents.trim() &&
      !this.isSavingFile
    ) {
      this.dataScriptTesting.push({
        ...this.scriptsToEdit[this.indexTabScriptEdit],
        scriptExecutions: [],
      });
      this.isLoadingScriptsTesting.push(true);
      const indexOfScriptTesting = this.dataScriptTesting.length - 1;
      this.powerService
        .executedScriptTesting(
          this.scriptsToEdit[this.indexTabScriptEdit].rithmId,
        )
        .pipe(first())
        .subscribe({
          next: (eventsScript) => {
            this.notify(
              `RUN ${
                this.scriptsToEdit[this.indexTabScriptEdit]?.fileName
              } was completed.`,
            );
            const warning = eventsScript?.scriptTestLogs?.filter(
              (scriptLog) =>
                scriptLog.historyType === ScriptHistoryType.Warning,
            ).length;

            this.dataScriptTesting[indexOfScriptTesting] = {
              ...this.dataScriptTesting[indexOfScriptTesting],
              scriptExecutions: [eventsScript],
            };

            this.scriptsToEditWarning[this.indexTabScriptEdit] = warning || 0;

            const index = this.dataScriptActions.findIndex(
              (script) =>
                script.rithmId ===
                this.scriptsToEdit[this.indexTabScriptEdit].rithmId,
            );

            this.dataScriptActions[index].scriptEnabled = eventsScript.success;
            this.isLoadingScriptsTesting[indexOfScriptTesting] = false;
          },
          error: (error: unknown) => {
            this.isLoadingScriptsTesting[indexOfScriptTesting] = false;
            this.errorService.displayError(
              "Something went wrong on our end and we're looking into it. Please try again in a little while.",
              error,
            );
          },
        });
    }
  }

  /**
   * This function is called when the user clicks on the "History" button in the "Scripts" tab.
   * It calls the historyScript function in the containerService, which makes a GET request to the backend.
   * To retrieve the history of the script. The history of the script is then pushed to the.
   * HistoryScripts array.
   */
  historyScript(): void {
    this.isLoadingHistory = true;
    this.historyScripts = [];
    this.powerService
      .historyTest(this.scriptsToEdit[this.indexTabScriptEdit].rithmId)
      .pipe(first())
      .subscribe({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        next: (historyScripts) => {
          this.isLoadingHistory = false;
          this.historyScripts = historyScripts;
        },
        error: (error: unknown) => {
          this.isLoadingHistory = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /** This function get the data of the script actions. */
  getScriptActions(): void {
    this.isLoadingScriptList = true;
    this.isErrorLoadScriptList = false;
    this.powerService
      .getScriptActions(this.ruleRithmId)
      .pipe(first())
      .subscribe({
        next: (scriptActions) => {
          scriptActions.forEach((script) => {
            if (!script.fileName.includes('.js')) {
              script.fileName = `${script.fileName}.js`;
            }
          });
          this.dataScriptActions = scriptActions;
          this.isLoadingScriptList = false;
          this.isErrorLoadScriptList = false;
          this.indexScriptSelected = -1;
        },
        error: (error: unknown) => {
          this.isLoadingScriptList = false;
          this.isErrorLoadScriptList = true;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * This function save the data of the script actions.
   * @param dataScripts The modified for save.
   */
  saveScriptActions(dataScripts = this.dataScriptActions): Promise<void> {
    this.setValueOfScriptEdited();
    this.setValueOfScriptList();
    this.isSavingFile = true;
    const copyScriptData = dataScripts;
    copyScriptData.forEach((script) =>
      script.rithmId.includes('DEV') ? (script.rithmId = '') : null,
    );

    return new Promise((resolve, reject) => {
      this.powerService
        .saveScriptActions(this.ruleRithmId, copyScriptData)
        .pipe(first())
        .subscribe({
          next: (scriptActions) => {
            this.dataScriptActions = scriptActions;
            this.isSavingFile = false;
            this.setIndexOfTabSelected();
            this.notify('Files has been saved successfully');
            resolve();
          },
          error: (error: unknown) => {
            this.isSavingFile = false;
            this.errorService.displayError(
              "Something went wrong on our end and we're looking into it. Please try again in a little while.",
              error,
            );
            reject();
          },
        });
    });
  }

  /**
   * Save property  boolean for identify enabled scripts.
   * @param rithmId Rithm id.
   * @param enabled Boolean - true or false.
   */
  enabledScriptActions(rithmId: string, enabled: boolean): void {
    this.dataScriptActions[this.indexScriptSelected].scriptEnabled = enabled;
    this.isSavingFile = true;
    if (rithmId) {
      this.powerService
        .enabledScriptActions(rithmId, enabled)
        .pipe(first())
        .subscribe({
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          next: () => {
            this.notify('Turned to ' + enabled);
            this.isSavingFile = false;
            const script = this.dataScriptActions.find(
              (element) => element.rithmId === rithmId,
            );
            script ? (script.scriptEnabled = enabled) : null;
          },
          error: (error: unknown) => {
            this.isSavingFile = false;
            this.errorService.displayError(
              "Something went wrong on our end and we're looking into it. Please try again in a little while.",
              error,
            );
          },
        });
    }
  }

  /**
   * Create a new script.
   */
  newScript(): void {
    this.isCreatingFile = true;
    let name = 'untitled_file.js';
    let indexScriptCreated = 1;
    if (this.searchScriptByName(name)) {
      do {
        name = `untitled_file(${indexScriptCreated++}).js`;
      } while (this.searchScriptByName(name));
    }

    const newScript = {
      rithmId: 'DEV-' + uuidv4(),
      contents: '',
      fileName: name,
      scriptEnabled: false,
      scriptExecutions: [],
    };

    this.saveScriptActions([...this.dataScriptActions, newScript])
      .then(() => {
        this.isCreatingFile = false;
        const index = this.dataScriptActions.length - 1;
        this.openScript(this.dataScriptActions[index], index);
      })
      .catch(() => {
        this.isCreatingFile = false;
      });
  }

  /**
   * Search script by name.
   * @param scriptName Name to find.
   * @returns Script if exist.
   */
  private searchScriptByName(scriptName: string): ScriptActionData | undefined {
    return this.dataScriptActions.find(
      (script) => script.fileName === scriptName,
    );
  }

  /**
   * This function save new index tab.
   * @param newIndexTab New index tab to save.
   */
  setIndexTab(newIndexTab: MatTabChangeEvent): void {
    this.indexTabTesting = newIndexTab.index;
    if (
      this.indexTabTesting === 2 &&
      this.scriptsToEdit[this.indexTabScriptEdit]
    ) {
      this.historyScript();
    }
  }

  /**
   * Open script of list scripts.
   * @param script Script to open.
   * @param index Script selected.
   */
  openScript(script: ScriptActionData, index: number): void {
    this.indexScriptSelected = index;
    this.setValueOfScriptEdited();
    if (
      !this.scriptsToEdit.some((file) => file.rithmId.includes(script.rithmId))
    ) {
      this.scriptsToEdit.push(script);
      this.scriptsToEditWarning.push(0);
      this.indexTabScriptEdit = this.scriptsToEdit.length - 1;
    } else {
      this.indexTabScriptEdit = this.scriptsToEdit.findIndex((file) =>
        file.rithmId.includes(script.rithmId),
      );
    }
    this.setIndexOfTabSelected();
    if (this.indexTabTesting === 2) {
      this.historyScript();
    }
  }

  /**
   * Close scripts edit.
   */
  closeScript(): void {
    this.scriptsToEdit.splice(this.indexTabScriptEdit, 1);
    this.scriptsToEditWarning.splice(this.indexTabScriptEdit, 1);
    this.valueEditScript = null;
    this.indexTabScriptEdit = 0;
    this.setIndexOfTabSelected();
  }

  /**
   * Set value of scriptEdit to specific scriptList.
   */
  setValueOfScriptList(): void {
    if (this.scriptsToEdit[this.indexTabScriptEdit]) {
      this.dataScriptActions.forEach((script) => {
        if (
          script.rithmId ===
            this.scriptsToEdit[this.indexTabScriptEdit].rithmId ||
          script.fileName ===
            this.scriptsToEdit[this.indexTabScriptEdit].fileName
        ) {
          script.contents =
            this.scriptsToEdit[this.indexTabScriptEdit].contents;
        }
      });
    }
  }

  /**
   * Set value of edit code only in scriptEdit.
   */
  setValueOfScriptEdited(): void {
    if (
      this.valueEditScript !== null &&
      this.scriptsToEdit[this.indexTabScriptEdit]
    ) {
      this.scriptsToEdit[this.indexTabScriptEdit] = {
        ...this.scriptsToEdit[this.indexTabScriptEdit],
        contents: this.valueEditScript,
      };
      this.valueEditScript = null;
    }
  }

  /**
   * Show alert confirm close script.
   */
  async confirmCloseScript(): Promise<void> {
    if (this.isEditingDocumentSelected) {
      const response = await this.popupService.confirm({
        title: `Do you want to save the changes you made to ${
          this.scriptsToEdit[this.indexTabScriptEdit].fileName
        }?`,
        message: "Your changes will be lost if don't save them",
        okButtonText: 'Yes',
        cancelButtonText: 'No',
        important: true,
      });
      if (response) {
        this.setValueOfScriptEdited();
        this.saveScriptActions().then(() => {
          this.closeScript();
        });
      } else {
        this.closeScript();
      }
    } else {
      this.closeScript();
    }
  }

  /**
   * Change script tabs in code-editor.
   * @param index Number of tab.
   */
  changeTabScriptOpened(index: number): void {
    this.setValueOfScriptEdited();
    this.indexTabScriptEdit = index;
    this.setIndexOfTabSelected();
  }

  /**
   * Set index to indexScriptSelected by scrip tab selected.
   */
  setIndexOfTabSelected(): void {
    this.indexScriptSelected = this.scriptsToEdit.length
      ? this.dataScriptActions.findIndex(
          (script) =>
            script.rithmId ===
            this.scriptsToEdit[this.indexTabScriptEdit].rithmId,
        )
      : -1;
  }

  /**
   * Set values when resize edit code.
   * @param isActive A boolean.
   */
  activeResize(isActive: boolean): void {
    if (isActive) {
      this.setValueOfScriptEdited();
    }
    this.isLoadingCodeEditor = isActive;
  }

  /** Clear logs of tab console. */
  clearConsoleLogs(): void {
    if (this.dataScriptTesting.length) {
      if (this.copyLastConsoleLog.length !== this.dataScriptTesting.length) {
        this.copyLastConsoleLog = JSON.parse(
          JSON.stringify(this.dataScriptTesting),
        );
      }
      this.dataScriptTesting.map((script, index) => {
        const dataScript = {
          ...script,
          scriptExecutions: [
            {
              ...script?.scriptExecutions?.at(0),
              scriptTestLogs: [],
            },
          ],
        };
        this.dataScriptTesting[index] = dataScript as ScriptActionData;
      });
    }
  }

  /** Set last console log. */
  setLastConsoleLog(): void {
    if (this.copyLastConsoleLog.length === this.dataScriptTesting.length) {
      this.dataScriptTesting = JSON.parse(
        JSON.stringify(this.copyLastConsoleLog),
      );
    }
  }

  /**
   * Click right in file list.
   * @param event Mouse event.
   * @param index Index file.
   */
  openMatMenu(event: MouseEvent, index: number): void {
    event.preventDefault();
    if (!this.isSavingFile) {
      if (
        this.indexOptionScriptList >= 0 &&
        !this.dataScriptActions[this.indexOptionScriptList].fileName.includes(
          '.',
        )
      ) {
        this.dataScriptActions[this.indexOptionScriptList].fileName += '.js';
      }

      this.indexOptionScriptList = index;
      this.enabledRenameScript = false;

      this.menuPosition.x = event.clientX + 5 + 'px';
      this.menuPosition.y = event.clientY + 5 + 'px';

      if (this.matMenuOptions) {
        this.matMenuOptions.menuData = index;
        this.matMenuOptions.openMenu();
      }
    }
  }

  /**
   * Enable mode rename script file.
   */
  enableRenameScriptFile(): void {
    this.enabledRenameScript = true;
    this.enterRename = true;
    if (this.indexOptionScriptList >= 0) {
      const name = this.dataScriptActions[this.indexOptionScriptList]?.fileName;
      if (name) {
        this.dataScriptActions[this.indexOptionScriptList].fileName =
          name.split('.')[0];
      }
    }
  }

  /**
   * Rename file.
   * @param index Index file.
   * @param value New file name.
   * @param script Script to rename.
   */
  renameScriptFile(
    index: number,
    value: string,
    script: ScriptActionData,
  ): void {
    if (this.enterRename) {
      this.enterRename = false;
      const oldName = this.dataScriptActions[index].fileName + '.js';
      const newName =
        value
          .toLowerCase()
          .replace(/\s/g, '-')
          .replace(/[^a-z0-9\-_()]/g, '') + '.js';

      if (oldName !== newName) {
        if (!this.searchScriptByName(newName)) {
          this.isRenamingFile = true;
          this.enabledRenameScript = false;

          const dataCopy = JSON.parse(JSON.stringify(this.dataScriptActions));
          dataCopy[index].fileName = newName;
          this.saveScriptActions(dataCopy)
            .then(() => {
              this.isRenamingFile = false;
              this.updateNameScriptTab(script.rithmId, newName);
              this.indexScriptSelected = index;
              this.openScript(this.dataScriptActions[index], index);
            })
            .catch(() => {
              this.isRenamingFile = false;
              this.dataScriptActions[index].fileName = oldName;
            });
        } else {
          this.dataScriptActions[index].fileName = oldName;
          this.notify('Duplicate name', true);
        }
      } else {
        this.dataScriptActions[index].fileName = oldName;
        this.enabledRenameScript = false;
      }
      this.indexScriptSelected = index;
    }
  }

  /**
   * Update name in tab when rename a file.
   * @param rithmId RithmId script.
   * @param newName New name for tab.
   */
  private updateNameScriptTab(rithmId: string, newName: string): void {
    const indexTab = this.scriptsToEdit.findIndex(
      (script) => script.rithmId === rithmId,
    );

    if (indexTab >= 0) {
      this.scriptsToEdit[indexTab].fileName = newName;
    }
  }

  /**
   * Confirm remove member.
   *
   */
  async confirmRemoveScript(): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Remove script?',
      message: 'This cannot be undone!',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });

    if (response) {
      this.deleteScript();
    }
  }

  /**
   * Delete script from list.
   */
  deleteScript(): void {
    if (this.indexOptionScriptList >= 0) {
      this.isDeletingFile = true;
      const scriptFile = this.dataScriptActions[this.indexOptionScriptList];
      const dataCopy = JSON.parse(JSON.stringify(this.dataScriptActions));
      dataCopy.splice(this.indexOptionScriptList, 1);
      this.saveScriptActions(dataCopy)
        .then(() => {
          this.closeTabDeleted();
          this.indexOptionScriptList = -1;
          this.notify(`The file ${scriptFile.fileName} has been deleted.`);
          this.isDeletingFile = false;
        })
        .catch(() => {
          this.isDeletingFile = false;
        });
    }
  }

  /**
   * This function to show toast message.
   * @param message Message to show as toast.
   * @param error Error petition.
   */
  private notify(message: string, error = false): void {
    this.popupService.notify(message, error);
  }

  /**
   * Close tab when the script has been deleted.
   *
   */
  private closeTabDeleted(): void {
    this.scriptsToEdit.forEach((tabScript, index) => {
      if (
        !this.dataScriptActions.some(
          (script) => script.rithmId === tabScript.rithmId,
        )
      ) {
        this.indexTabScriptEdit = index;
        this.closeScript();
      }
    });
  }

  /** Destroy life cycle. */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
