<div class="flex items-start justify-between space-x-2">
  @if (isCustomValues) {
    <!-- Select custom values -->
    <section class="custom-values w-full" [formGroup]="inputFrameFieldForm">
      @switch (filteredBy) {
        @case ('number') {
          <app-number-field [field]="field" [isAction]="true" />
        }
        @case ('date') {
          <app-date-field
            [field]="field"
            [isAction]="true"
            [setTimeSettings]="true"
            [setMinDate]="true"
            [setMaxDate]="setMaxDate"
            [minDate]="minDate"
            [maxDate]="maxDate"
            formControlName="dateFieldForm"
            (dateEmitted)="setEmittedDate($event)"
          />
        }
        @case ('select') {
          <app-select-field
            [field]="field"
            [isAction]="true"
            [stationId]="''"
          />
        }
        @default {
          <app-text-field
            [field]="field"
            [isAction]="true"
            [isStation]="false"
            [labelTag]="field.prompt"
          />
        }
      }
    </section>
  } @else {
    <!-- Updates to field values -->
    <section class="w-full" [formGroup]="inputFrameFieldForm">
      <mat-form-field
        class="w-full"
        appearance="outline"
        subscriptSizing="dynamic"
      >
        <mat-label class="text-base">{{ questionLabel }}</mat-label>
        <mat-select
          (selectionChange)="setEmittedDate($event.value)"
          formControlName="selectFieldForm"
          class="select"
          [id]="'field-' + fieldTitle"
          [attr.data-testid]="'field-' + fieldTitle"
        >
          @for (field of questionList; track $index) {
            @if (!hideSelectedQuestion(field.rithmId)) {
              <mat-option
                [id]="'field-' + field.rithmId"
                [attr.data-testid]="'field-' + field.rithmId"
                [value]="field.rithmId"
                >{{ field.prompt }}
              </mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    </section>
  }
  <button
    mat-stroked-button
    type="button"
    color="accent"
    id="switch-mode-button"
    data-testid="switch-mode-button"
    class="value-switching-button"
    [ngClass]="{ '!h-14 !w-14': !isCustomValues }"
    (click)="resetForm()"
  >
    <i
      class="fa-light fa-xl"
      [ngClass]="isCustomValues ? 'fa-keyboard' : 'fa-chevron-down'"
    ></i>
  </button>
</div>
