<section class="rules-right-side-triggers w-full">
  <form class="scheduled-content-section" [formGroup]="scheduleTriggerForm">
    <section class="trigger-interval-time-section">
      <section class="trigger-datepicker-section">
        <app-custom-vs-bucket
          [poolOfQuestions]="bucketQuestions()"
          [filteredBy]="'date'"
          [questionLabel]="'Start Date'"
          [currentAction]="'scheduleTrigger'"
          [fieldTitle]="'startDate'"
          [minDate]="startDate"
          [maxDate]="setMaxDate()"
          [setMaxDate]="isMaxDateSet"
          [selectedQuestion]="selectedEndDateQuestion"
          [editedData]="scheduledTriggerOnEdit"
          (dateEmitter)="
            setDateValue($event.field, $event.value, $event.isCustom)
          "
          (resetFormEmitter)="resetTriggerForm($event.field, $event.isCustom)"
        />
      </section>

      <section class="trigger-repeat-section">
        <mat-form-field
          appearance="outline"
          class="w-full"
          subscriptSizing="dynamic"
        >
          <mat-label>Repeat</mat-label>
          <mat-select
            formControlName="frequency"
            (selectionChange)="repeatChange()"
          >
            @for (repeat of dateIntervalRepeatOptions; track $index) {
              <mat-option [value]="repeat" [disabled]="$index === 1">
                {{ repeat }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </section>

      @if (scheduleTriggerForm.value.frequency !== 'Never') {
        <section class="trigger-repeat-forever-section">
          Repeat Forever
          <mat-slide-toggle
            formControlName="repeatForever"
            (change)="enableRepeatForever()"
          />
        </section>
      }

      @if (
        !scheduleTriggerForm.value.repeatForever &&
        scheduleTriggerForm.value.frequency !== 'Never'
      ) {
        <section class="trigger-end-repeat-section over">
          <app-custom-vs-bucket
            [poolOfQuestions]="bucketQuestions()"
            [filteredBy]="'date'"
            [questionLabel]="'End Repeat'"
            [currentAction]="'scheduleTrigger'"
            [fieldTitle]="'endRepeat'"
            [minDate]="endDate"
            [selectedQuestion]="selectedStartDateQuestion"
            [editedData]="scheduledTriggerOnEdit"
            (dateEmitter)="
              setDateValue($event.field, $event.value, $event.isCustom)
            "
            (resetFormEmitter)="resetTriggerForm($event.field, $event.isCustom)"
          />
        </section>
      }
    </section>

    <section class="schedule-control-buttons flex space-x-2">
      <button
        mat-button
        type="button"
        data-testid="button-schedule-cancel"
        id="button-schedule-cancel"
        (click)="hideForm.emit(); resetScheduleTriggerSection()"
      >
        Cancel
      </button>
      <button
        mat-button
        type="button"
        color="accent"
        id="button-schedule-save"
        data-testid="button-schedule-save"
        [disabled]="!scheduleTriggerForm.valid || isTriggerFormInValid"
        (click)="addUpdateScheduledTriggers()"
      >
        Done
      </button>
    </section>
  </form>
</section>
