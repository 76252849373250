import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { first } from 'rxjs';
import { PopupService } from 'src/app/core/popup.service';
import { PowerService } from 'src/app/core/power.service';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { TermsGeneric } from 'src/helpers';
import {
  ActionType,
  ConnectedStationInfo,
  PowerAction,
  PowerEventType,
} from 'src/models';
import { v4 as uuidv4 } from 'uuid';

/** Flow Container Form Action. */
@Component({
  selector: 'app-flow-container-form-action',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatChipsModule,
    LoadingIndicatorComponent,
  ],
  templateUrl: './flow-container-form-action.component.html',
  styleUrl: './flow-container-form-action.component.scss',
})
export class FlowContainerFormActionComponent implements OnInit {
  /** Total actions. */
  @Input({ required: true }) totalActions = 0;

  /** Stations next connected . */
  @Input({ required: true }) nextStations: ConnectedStationInfo[] = [];

  /** Power action. */
  @Input() action!: PowerAction;

  /** Is edit power action. */
  @Input() isEditing = false;

  /** Rule rithmId. */
  @Input({ required: true }) ruleRithmId = '';

  /** Emit event for close form. */
  @Output() closeForm = new EventEmitter<void>();

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** The form to add this field in the template. */
  flowFieldForm = new FormGroup({
    stations: new FormControl<string[]>([]),
  });

  /** The list of selected stations in flow section. */
  flowStations: ConnectedStationInfo[] = [];

  /** Whether you are saving the current integration action . */
  savingAction = false;

  /** Whether you are deleting action. */
  deletingAction = false;

  constructor(
    private powerService: PowerService,
    private popupService: PopupService,
  ) {}

  /**
   * Initial method life cycle.
   */
  ngOnInit(): void {
    if (this.isEditing) {
      this.flowFieldForm.controls.stations.setValue(
        this.action.target.split(','),
      );
      this.flowStationSelect();
    }
  }

  /**
   * Update's the selected station array data which is displayed as chips.
   *
   */
  flowStationSelect(): void {
    this.flowStations = [];
    const flowToStations = this.flowFieldForm.value.stations || [];
    flowToStations.map((stationId: string) => {
      const station = this.nextStations.find((e) => e.rithmId === stationId);
      if (station) {
        this.flowStations.push(station);
      }
    });
  }

  /**
   * Cancel action create/edit action.
   */
  closeFormAction(): void {
    this.closeForm.emit();
  }

  /**
   * Add or update action.
   * @param powerAction The current action to be created or updated.
   * @param isUpdate Whether should update action.
   */
  addOrUpdateSpecificAction(powerAction: PowerAction, isUpdate = false): void {
    const eventAction = isUpdate ? PowerEventType.Update : PowerEventType.Add;
    this.savingAction = true;

    if (isUpdate) {
      powerAction = {
        ...powerAction,
        target: this.flowStations.map((x) => x.rithmId).join(','),
      };
    }

    this.powerService
      .addOrUpdateSpecificAction(this.ruleRithmId, powerAction)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: eventAction,
            action: powerAction,
            powerRithmId: this.ruleRithmId,
          });
          this.savingAction = false;
          this.closeFormAction();
        },
        error: () => {
          this.savingAction = false;
          this.popupService.notify('Error to save action', true);
        },
      });
  }

  /**
   * Create new action container flow.
   */
  createNewAction(): void {
    const createdAction: PowerAction = {
      order: this.totalActions + 1,
      rithmId: uuidv4(),
      type: ActionType.FlowContainer,
      target: this.flowStations.map((x) => x.rithmId).join(','),
      data: '',
      resultMapping: '',
      header: '',
    };
    this.addOrUpdateSpecificAction(createdAction);
  }

  /**
   * Popup confirmation delete/update integration.
   */
  async confirmRemoveIntegration(): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'This action will be deleted',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (response) this.deleteAction(this.action);
  }

  /**
   * Delete action.
   * @param action  Delete the current action.
   */
  deleteAction(action: PowerAction): void {
    this.deletingAction = true;
    this.powerService
      .deleteAction(this.ruleRithmId, action.rithmId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: PowerEventType.Delete,
            action,
            powerRithmId: this.ruleRithmId,
          });
          this.deletingAction = false;
        },
        error: () => {
          this.deletingAction = false;
          this.popupService.notify('Error to delete action', true);
        },
      });
  }
}
