import { CommonModule } from '@angular/common';
import { Component, effect, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ActionType, AiAction, AvailableModel } from 'src/models';

/** The selected ai model component. */
@Component({
  selector: 'app-select-ai-model',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatButtonModule, MatFormFieldModule],
  templateUrl: './select-ai-model.component.html',
  styleUrl: './select-ai-model.component.scss',
})
export class SelectAiModelComponent {
  /** The selected model input. */
  selectedModelInput = input<AiAction>();

  /** The selected model output. */
  selectedModelOutput = output<AiAction>();

  /** The available models. */
  readonly availableModels: AvailableModel[] = [
    {
      name: 'Text Generator',
      value: ActionType.TextGenerator,
    },
    {
      name: 'Image Generator',
      value: ActionType.ImageGenerator,
    },
  ];

  /** The selected model. */
  selectedModel!: ActionType.TextGenerator | ActionType.ImageGenerator;

  constructor() {
    effect(() => {
      if (this.selectedModelInput()) {
        this.selectedModel = this.selectedModelInput() as
          | ActionType.TextGenerator
          | ActionType.ImageGenerator;
      }
    });
  }
}
