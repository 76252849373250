import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  Subject,
  takeUntil,
} from 'rxjs';
import { ErrorService } from 'src/app/core/error.service';
import { StationService } from 'src/app/core/station.service';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { NgxSearchComponent } from 'src/app/shared/ngx-search/ngx-search.component';
import { ActionHelper, TermsGeneric } from 'src/helpers';
import {
  ActionType,
  PageElement,
  PowerAction,
  QuestionFieldType,
  RelationshipAction,
  Station,
  StationBucketQuestion,
  StationLibraryRelationship,
  UpdateCreateContainerAction,
  UpdateRelationshipAction,
} from 'src/models';
import { v4 as uuidv4 } from 'uuid';
import { MatSelectInfinityScrollDirective } from 'src/helpers/directives/mat-select-infinite-scroll/mat-select-infinite-scroll.directive';
import _ from 'lodash';

/** Create Container Action Form. */
@Component({
  selector: 'app-create-container-action',
  templateUrl: './create-container-action.component.html',
  styleUrls: ['./create-container-action.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LoadingIndicatorComponent,
    NgxSearchComponent,
    MatButtonModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDividerModule,
    LoadingIndicatorComponent,
    MatSelectInfinityScrollDirective,
  ],
})
export class CreateContainerActionComponent implements OnInit, OnDestroy {
  /** Subject for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Subject for when the get of stations is consumed. */
  private destroyedPetition$ = new Subject<void>();

  /** Contains the action of the power that will be edited. */
  private _actionToUpdate: PowerAction = {
    order: 1,
    rithmId: uuidv4(),
    type: ActionType.AddRelationship,
    target: '',
    data: '',
    resultMapping: '',
    header: '',
  };

  /** Contains the action of the power that will be edited. */
  @Input() set action(value: PowerAction | null) {
    if (value) {
      this._actionToUpdate = value;
      this.setFormInfoToUpdate();
    }
  }

  /** Data filtered to show autocomplete. */
  filteredStationsAutocomplete: Station[] = [];

  /** List of stations to display with the autocomplete. */
  @Input({ required: true }) stations: Station[] = [];

  /** Current Station . */
  @Input({ required: true }) currentStation: Station | undefined;

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Whether you are saving the current action . */
  @Input() savingAction = false;

  /** The station rithmId. */
  @Input({ required: true }) stationRithmId = '';

  /** Confirms whether the action is being edited. */
  @Input() editingAction = false;

  /** Whether you are deleting the current action in parent. */
  @Input() deletingAction = false;

  /** Relationship flag. */
  @Input({ required: true }) showRelationshipActions = false;

  /** Contains the action of the power that will be edited. */
  @Input() set actionToUpdate(action: PowerAction | null) {
    if (action) {
      this._actionToUpdate = action;
      this.setInformationToEdit();
    } else {
      this.actionForm.controls.assignedRelationship.reset();
      this.actionForm.controls.formSharedValues.reset();
      this.relationsSelected = [];
    }
  }

  private _relationsSelected: string[] = [];

  /** Relationship flag. */
  @Input() set relationsSelected(values: string[]) {
    this.actionForm &&
      this.actionForm.controls.assignedRelationship.setValue(values);
    this._relationsSelected = values;
  }

  /**
   * Get relations Selected.
   * @returns Relations Selected.
   */
  get relationsSelected(): string[] {
    return this._relationsSelected;
  }

  /** Relations selected to work them.*/
  @Input({ required: true })
  updateRelationshipAction: UpdateRelationshipAction = {
    relationships: [],
  };

  /** Station Libraries is Loading.*/
  @Input({ required: true }) stationLibrariesLoading = false;

  /** Action type selector value. */
  @Input({ required: true }) actionTypeValue: ActionType | '' = '';

  /** Station Libraries.*/
  @Input() stationLibraryRelationships: StationLibraryRelationship[] = [];

  /** Filtered libraries of relationship stations. */
  filteredStationLibraryRelationships: StationLibraryRelationship[] = [];

  /** Emit the new action to add. */
  @Output() containerPowerEmitter = new EventEmitter<PowerAction>();

  /** Emit the new action to add. */
  @Output() cancelForm = new EventEmitter<boolean>();

  /** Emit the new action to add. */
  @Output() emptyRelationships = new EventEmitter();

  /** Emit the new action to add. */
  @Output() addActionsToSave = new EventEmitter();

  /** Emit the current action to delete it. */
  @Output() actionToRemove = new EventEmitter<PowerAction>();

  /** Emit the new action to add. */
  @Output() addUpdateRelationshipAction = new EventEmitter<{
    /** */
    relationshipAction: RelationshipAction;
    /** */
    isInwardName: boolean;
    /** */
    isOutwardName: boolean;
    /** */
    deleting: boolean;
  }>();

  /** Static Project terms. */
  termsGeneric = TermsGeneric;

  /** Current station questions deployed in shared values. */
  currentStationFields: StationBucketQuestion[] = [];

  /** Filtered data from the station fields for the autocomplete options. */
  filteredStationFields: StationBucketQuestion[] = [];

  /** Flag for show shared values field when available stations has selection. */
  showSharedValueSelection = false;

  /** Component form. */
  actionForm = new FormGroup({
    formOverlay: new FormControl<string>({ value: '', disabled: true }),
    formStations: new FormControl<string>('', {
      nonNullable: true,
    }),
    formStationsFilter: new FormControl<string>(''),
    formSharedValues: new FormControl<string[]>([], {
      nonNullable: true,
      validators: [],
    }),
    assignedRelationship: new FormControl<string[]>([]),
    stationsSelection: new FormControl<Station[]>([]),
    updateRelationship: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    relationshipType: new FormControl<string[]>([], {
      nonNullable: true,
    }),
  });

  /** Indicator when receiving questions from the selected station.*/
  sharedValuesLoading = false;

  /** Type of actions. */
  actionTypesEnum = ActionType;

  /** Selected stations. */
  selectedStationsToShowInChips: Station[] = [];

  /** The paging for the request when getting the stations. */
  currentPageElement: PageElement = {
    pageNumber: 1,
    pageSize: 30,
    searchValue: '',
  };

  /** Whether all stations are loading or not. */
  loadingStations = false;

  /** The station ids backups to keep in the options. */
  stationIdsBackup: string[] = [];

  constructor(
    private stationService: StationService,
    private errorService: ErrorService,
  ) {}

  /**
   * Init Method.
   */
  ngOnInit(): void {
    this.listenAutocomplete$();
    this.getBucketQuestions();
    this.currentStationFirstPosition();
    this.getOptimizedStations();
    this.actionForm.controls.updateRelationship.setValue(this.actionTypeValue);
    this.actionForm.controls['relationshipType'].setValue(
      this.getRelationshipTypes(this._actionToUpdate),
    );
    if (!this.showRelationshipActions) {
      this.actionForm.controls.formStations.setValidators([
        Validators.required,
      ]);
    }
  }

  /**
   * Place the current station to the first position of the array of stations.
   */
  currentStationFirstPosition(): void {
    if (this.currentStation) {
      const index = this.stations.findIndex(
        (station) => station.rithmId === this.currentStation?.rithmId,
      );
      if (index === -1) {
        this.stations.unshift(this.currentStation);
      } else if (index > 0) {
        this.stations.splice(index, 1);
        this.stations.unshift(this.currentStation);
      }
    }
  }

  /**
   * Returns array of selected stations.
   * @returns Array of stations selected.
   */
  get selectedStations(): Station[] {
    const stationsMatch: Station[] = this.stations.filter(
      (station) => station.rithmId === this.actionForm.value.formStations,
    );
    return stationsMatch;
  }

  /**
   * Returns array of selected for CurrentStationFields.
   * @returns Array of CurrentStationFields selected.
   */
  get selectedCurrentStationFields(): StationBucketQuestion[] {
    return (
      this.currentStationFields.filter((field) =>
        this.actionForm.controls.formSharedValues.value.some(
          (rithmId: string) => rithmId === field.rithmId,
        ),
      ) || []
    );
  }

  /** Listen changes in autocomplete. */
  private listenAutocomplete$(): void {
    this.actionForm.controls.formStationsFilter.valueChanges
      .pipe(
        debounceTime(750),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe((search) => {
        this.currentPageElement.searchValue = search || '';
        this.currentPageElement.pageNumber = 1;
        this.getOptimizedStations();
      });
  }

  /**
   * Saving action type changes.
   *
   */
  public addOrEditAction(): void {
    const target = this.showRelationshipActions
      ? this.selectedStationsToShowInChips?.map((e) => e.rithmId).join(',') ||
        ''
      : this.actionForm.value.formStations || '';

    let data = this.actionForm.value.formSharedValues?.toString() || '';

    const isFirstFieldSelected =
      this.actionForm.value.formStations ||
      this.actionForm.value.stationsSelection;

    if (isFirstFieldSelected) {
      data = JSON.stringify({
        questions: this.actionForm.value.formSharedValues,
        relationships: this.relationShipAction(this.relationsSelected),
      });
    }

    const createdAction: PowerAction = {
      order: this._actionToUpdate ? this._actionToUpdate.order : 1,
      rithmId: this._actionToUpdate ? this._actionToUpdate.rithmId : uuidv4(),
      type: ActionType.CreateContainer,
      target,
      data,
      resultMapping: this._actionToUpdate
        ? this._actionToUpdate.resultMapping
        : '',
      header: this._actionToUpdate ? this._actionToUpdate.header : '',
    };
    this.containerPowerEmitter.emit(createdAction);
  }

  /**
   * Get relationship.
   * @param data Array with relations.
   * @returns Relationship array.
   */
  relationShipAction(data: string[]): RelationshipAction[] {
    const relationshipActions: RelationshipAction[] = [];
    data.forEach((e) => {
      const relationshipRithmId = e.split('[')[1].split(']')[0];
      const inward = e.split('_')[1] === 'inwardName';
      const outward = e.split('_')[1] === 'outwardName';
      const element = relationshipActions.find(
        (relation) => relation.relationshipRithmId === relationshipRithmId,
      );
      if (element) {
        element.inward = element.inward || inward;
        element.outward = element.outward || outward;
      } else {
        relationshipActions.push({
          relationshipRithmId,
          inward,
          outward,
        });
      }
    });
    return relationshipActions;
  }

  /**
   * Remove the selected station form the array of stations selected.
   * @param stationId The id of the station to remove.
   */
  public removeStation(stationId: string): void {
    const station: Station | undefined = this.stations.find(
      (s) => s.rithmId === stationId,
    );
    if (station) {
      this.actionForm.controls.formStations.reset();
      this.actionForm.controls.formSharedValues.reset();
    }
  }

  /**
   * Remove the selected current station field form the array of currentStationFields selected.
   * @param rithmId The id of the current station field to remove.
   */
  public removeCurrentStationFields(rithmId: string): void {
    const stationBucketQuestion: StationBucketQuestion | undefined =
      this.currentStationFields.find((f) => f.rithmId === rithmId);
    if (stationBucketQuestion) {
      this.actionForm.controls.formSharedValues.setValue(
        (this.actionForm.controls.formSharedValues.value as string[]).filter(
          (id) => id !== rithmId,
        ),
      );
    }
  }

  /**
   * Request for all the bucket questions.
   */
  getBucketQuestions(): void {
    this.sharedValuesLoading = true;
    this.stationService
      .getStationBucketQuestions(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (question) => {
          const questionsBucket =
            ActionHelper.removeContainerInfoBucketDuplicate(question).filter(
              ({ questionType }) =>
                ![
                  QuestionFieldType.ParentContainerRithmId,
                  QuestionFieldType.ParentStationRithmId,
                  QuestionFieldType.CreatedOn,
                  QuestionFieldType.CreatedBy,
                  QuestionFieldType.TimeInStation,
                  QuestionFieldType.StationName,
                  QuestionFieldType.ContainerId,
                  QuestionFieldType.StationId,
                  QuestionFieldType.PresentTime,
                  QuestionFieldType.SelfAssign,
                ].includes(questionType as QuestionFieldType),
            );

          this.currentStationFields = questionsBucket;
          this.sharedValuesLoading = false;
        },
        error: (error: unknown) => {
          this.sharedValuesLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Cancel the process of creating a new action.
   */
  cancelAction(): void {
    this.cancelForm.emit();
  }

  /**
   * Add container action.
   * @param stations Represents station pressed.
   */
  createContainerAction(stations: Station[]): void {
    this.setEmptyForm();
    this.showSharedValueSelection = true;
    // Create a Set of filteredStationsAutocomplete ids for quick search.
    const filteredIds = new Set(
      this.filteredStationsAutocomplete.map((station) => station.rithmId),
    );
    // Filter stations that are in stationIdsBackup but not in filteredStationsAutocomplete.
    const result = _.filter(
      this.stations,
      (station) =>
        _.includes(this.stationIdsBackup, station.rithmId) &&
        !filteredIds.has(station.rithmId),
    );
    // Concatenate the filtered stations with the current ones if stationIdsBackup is not empty.
    this.selectedStationsToShowInChips = this.stationIdsBackup.length
      ? _.concat(stations, result)
      : stations;
    // Update the value of the form.
    this.actionForm.controls.stationsSelection.setValue(stations);
  }

  /**
   * Remove station from ids backup.
   * @param stationId The id of the clicked station.
   */
  removeStationBackup(stationId: string): void {
    if (this.stationIdsBackup.includes(stationId)) {
      // Remove the id of stationIdsBackup that is in this station.
      this.stationIdsBackup = _.difference(this.stationIdsBackup, [stationId]);
    }
  }

  /**
   * Add container action.
   */
  setEmptyForm(): void {
    if (
      !(this.actionForm.controls.stationsSelection.value as []).length &&
      !this.selectedStationsToShowInChips.length
    ) {
      this.actionForm.controls['formSharedValues'].setValue([]);
      this.selectedStationsToShowInChips = [];
      this.emptyRelationships.emit();
    }
  }

  /**
   * Remove container action.
   * @param station Represents station pressed.
   */
  removeCurrentStation(station: Station): void {
    this.selectedStationsToShowInChips =
      this.selectedStationsToShowInChips.filter(
        (currentStation) => currentStation.rithmId !== station.rithmId,
      );
    this.actionForm.controls.stationsSelection.setValue(
      (this.actionForm.controls.stationsSelection.value as Station[]).filter(
        (currentStation) => currentStation.rithmId !== station.rithmId,
      ),
    );
    if (!this.selectedStationsToShowInChips.length) {
      this.showSharedValueSelection = false;
    }
    if (this.stationIdsBackup.includes(station.rithmId)) {
      // Remove the id of stationIdsBackup that is in this station.
      this.stationIdsBackup = _.difference(this.stationIdsBackup, [
        station.rithmId,
      ]);
    }

    this.setEmptyForm();
  }

  /**
   * This method will set the information to edit an already created action.
   */
  private setInformationToEdit(): void {
    if (this._actionToUpdate && this._actionToUpdate.data) {
      setTimeout(() => {
        const stationsRithmId = this._actionToUpdate.target.split(',');

        this.stationIdsBackup = stationsRithmId;

        const data = JSON.parse(
          this._actionToUpdate.data,
        ) as unknown as UpdateCreateContainerAction;

        this.selectedStationsToShowInChips = this.stations.filter((station) =>
          stationsRithmId.some((id) => id === station.rithmId),
        );

        this.actionForm.controls.formSharedValues.setValue(data.questions);
        this.showSharedValueSelection = true;
      }, 0);
    }
  }

  /**
   * Remove relationship type.
   * @param indexRelation Index relation to remove.
   */
  removeIndexRelation(indexRelation: number): void {
    this.relationsSelected.splice(indexRelation, 1);
    this.actionForm.controls.assignedRelationship.setValue(
      this.relationsSelected,
    );
    this.relationsSelected[indexRelation] &&
      this.updateActions(this.relationsSelected[indexRelation], true);
  }

  /**
   * Update Actions.
   * @param relation Relation label.
   * @param deleting Relation label.
   */
  updateActions(relation: string, deleting = false): void {
    let relationRithmId = '';
    relationRithmId = relation.split('[')[1].split(']')[0];
    const isOutwardName = relation.includes('outwardName');
    const isInwardName = relation.includes('inwardName');
    const newUpdateRelationshipAction = {
      inward: isInwardName,
      outward: isOutwardName,
      relationshipRithmId: relationRithmId,
    };

    const updateRelationship = {
      relationshipAction: newUpdateRelationshipAction,
      isInwardName,
      isOutwardName,
      deleting,
    };
    this.addUpdateRelationshipAction.emit(updateRelationship);
    this.addActionsToSave.emit();
  }

  /**
   * Set chip.
   * @param option Option to add or delete.
   */
  setChips(option: MatSelectChange): void {
    const newSelections = option.value;
    const deselectedValues = this.relationsSelected.filter(
      (value) => !newSelections.includes(value),
    );
    const index = this.relationsSelected.findIndex(
      (value) => !newSelections.includes(value),
    );
    if (deselectedValues.length > 0) {
      this.removeIndexRelation(index);
    }

    const controls = newSelections;
    this.relationsSelected = newSelections;
    this.updateRelationshipAction.relationships = [];
    controls.forEach((relation: string) => {
      this.updateActions(relation);
    });
  }

  /**
   * Get a clean label.
   * @param relation Relation label.
   * @returns Clean relation.
   */
  cleanRelationLabel(relation: string): string {
    return relation.split('[')[0];
  }

  /**
   * Set the form with the incoming object information.
   */
  setFormInfoToUpdate(): void {
    /** The array to populate with the built string from relationships selected. */
    const relationshipsChecked: string[] = [];

    /** Extract relationships array from library. */
    const stationRelationships = this.stationLibraryRelationships.map((lib) => {
      return lib.relationships;
    });

    /** Extract info from object.Data. */
    const actionRelationshipsData = (
      JSON.parse(this._actionToUpdate.data) as UpdateRelationshipAction
    ).relationships;

    /** Loop through relationships extracted data. */
    actionRelationshipsData.forEach((relationData) => {
      /** Loop through each station relationship array. */
      stationRelationships.forEach((sRel) => {
        /** Look for matching relationships id and get their Inward/Outward Names. */
        const inwardName = sRel.find(
          (rel) => rel.rithmId === relationData.relationshipRithmId,
        )?.inwardName;
        const outwardName = sRel.find(
          (rel) => rel.rithmId === relationData.relationshipRithmId,
        )?.outwardName;

        /** If they were selected then we'll built the string. */
        if (relationData.inward && inwardName) {
          relationshipsChecked.push(
            inwardName +
              '[' +
              relationData.relationshipRithmId +
              ']_inwardName',
          );
        }

        if (relationData.outward && outwardName) {
          relationshipsChecked.push(
            outwardName +
              '[' +
              relationData.relationshipRithmId +
              ']_outwardName',
          );
        }
      });
    });
  }

  /**
   * Get relations to show.
   * @param action Update Relationships.
   * @returns Relations as array to show.
   */
  getRelationshipTypes(action: PowerAction): string[] {
    if (action.data) {
      const loadedRelations: string[] = [];
      const updateRelationshipAction = JSON.parse(
        action.data,
      ) as UpdateRelationshipAction;
      updateRelationshipAction.relationships.forEach((relation) => {
        const relationshipRithmId = relation.relationshipRithmId;
        this.stationLibraryRelationships.forEach((libraryRelation) => {
          libraryRelation.relationships.forEach((rela) => {
            if (rela.rithmId === relationshipRithmId) {
              if (relation.inward) {
                const relaTionToAdd =
                  rela.inwardName + '[' + rela.rithmId + ']_inwardName';
                loadedRelations.push(relaTionToAdd);
              }
              if (relation.outward) {
                const relaTionToAdd =
                  rela.outwardName + '[' + rela.rithmId + ']_outwardName';
                loadedRelations.push(relaTionToAdd);
              }
            }
          });
        });
      });
      return loadedRelations;
    } else {
      return [];
    }
  }

  /**
   * Event when select action.
   */
  selectTypeActionEvent(): void {
    this.actionForm.reset();
    this.relationsSelected = [];
    this.actionTypeValue === ActionType.CreateContainer
      ? this.actionForm.controls.relationshipType.setValidators([])
      : this.actionForm.controls.relationshipType.setValidators([
          Validators.required,
        ]);
  }

  /**
   * Get the stations for the autocomplete options in an efficient way.
   * @param allStations Whether it is an all-station load or not.
   */
  getOptimizedStations(allStations = true): void {
    if (allStations) {
      this.loadingStations = true;
    } else {
      this.currentPageElement.loading = true;
    }
    this.destroyedPetition$.next();
    this.stationService
      .getAllStationsOptimized(
        this.currentPageElement.searchValue,
        this.currentPageElement.pageNumber,
        this.currentPageElement.pageSize,
      )
      .pipe(takeUntil(this.destroyedPetition$.asObservable()))
      .subscribe({
        next: (stations: Station[]) => {
          this.filteredStationsAutocomplete = (
            this.currentPageElement.pageNumber > 1
              ? // We keep a single list in case it has been added before.
                _.uniqBy(
                  this.filteredStationsAutocomplete.concat(stations),
                  'rithmId',
                )
              : stations
          ).filter((e) => e.rithmId !== this.stationRithmId);
          this.currentPageElement.loadMoreElements =
            stations.length >= this.currentPageElement.pageSize;
          // If currently has id's in the backups.
          if (this.stationIdsBackup.length) {
            // Filter the stations and update the value in the form.
            const filteredStations = _.filter(
              this.filteredStationsAutocomplete,
              (station) => _.includes(this.stationIdsBackup, station.rithmId),
            );
            // Set the filtered stations.
            this.actionForm.controls.stationsSelection.setValue(
              filteredStations,
            );
          } else {
            // We set up the chip stations for you.
            this.actionForm.controls.stationsSelection.setValue(
              _.uniqBy(this.selectedStationsToShowInChips, 'rithmId'),
            );
          }
          if (allStations) {
            this.loadingStations = false;
          } else {
            this.currentPageElement.loading = false;
          }
        },
        error: (error: unknown) => {
          if (allStations) {
            this.loadingStations = false;
          } else {
            this.currentPageElement.loading = false;
          }
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Remove the action from the parent.
   */
  removeParentAction(): void {
    this._actionToUpdate && this.actionToRemove.emit(this._actionToUpdate);
  }

  /** Infinity scroll for get more elements. */
  getMoreElements(): void {
    if (
      !this.currentPageElement.loading &&
      this.currentPageElement.loadMoreElements
    ) {
      this.currentPageElement.pageNumber++;
      this.getOptimizedStations(false);
    }
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyedPetition$.next();
    this.destroyedPetition$.complete();
  }
}
