<section class="notification-action-component py-4">
  <form
    class="notification-action-section"
    [formGroup]="notificationActionForm"
  >
    <section class="notification-add flex flex-col space-y-4">
      <p class="text-sm font-medium">Email</p>
      <section class="notification-recipients flex items-center">
        <div class="flex space-x-2 items-center w-full">
          <mat-form-field
            class="w-full"
            appearance="outline"
            subscriptSizing="dynamic"
            [ngClass]="{
              'auto-complete-invalid': isRecipientsFieldInvalid
            }"
          >
            <mat-label>To:</mat-label>
            <mat-chip-grid #chipGrid aria-label="Recipient selection">
              @for (
                recipient of notificationActionForm.value.recipients;
                track $index
              ) {
                <mat-chip-row>
                  <div class="flex gap-x-2 items-center">
                    <ng-container
                      *ngTemplateOutlet="
                        recipientOption;
                        context: { $implicit: recipient }
                      "
                    ></ng-container>
                    <button type="button" (click)="removeRecipient(recipient)">
                      <i
                        class="fas fa-xmark-circle text-gray-400 hover:text-gray-500"
                      ></i>
                    </button>
                  </div>
                </mat-chip-row>
              }
            </mat-chip-grid>
            <input
              placeholder="New Recipients..."
              #recipientInput
              [formControl]="recipientControl"
              [matChipInputFor]="chipGrid"
              [matAutocomplete]="autoGroup"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="
                recipientControl.valid && selected($event.value)
              "
              (focus)="loadMembers()"
            />
            <mat-autocomplete
              #autoGroup="matAutocomplete"
              (optionSelected)="selected($event.option.value)"
              [hideSingleSelectionIndicator]="true"
            >
              @if (
                (filteredMemberRecipients$ | async)?.length ||
                memberListLoading ||
                memberListError
              ) {
                <mat-optgroup label="Member">
                  @for (
                    recipient of filteredMemberRecipients$ | async;
                    track $index
                  ) {
                    <mat-option
                      [disabled]="alreadySelected(recipient)"
                      [value]="recipient"
                    >
                      <div class="flex gap-x-2 items-center pl-2">
                        <ng-template
                          *ngTemplateOutlet="
                            avatarImage;
                            context: { data: recipient }
                          "
                        ></ng-template>

                        <div class="w-full truncate">
                          <p class="text-base text-black">
                            {{ getRecipientName(recipient) }}
                          </p>
                        </div>
                      </div>
                    </mat-option>
                  }

                  @if (memberListLoading) {
                    <mat-option [disabled]="true">
                      <ng-template
                        *ngTemplateOutlet="loadingTemplate"
                      ></ng-template>
                    </mat-option>
                  }
                  @if (memberListError) {
                    <mat-option [disabled]="true">
                      <ng-template
                        *ngTemplateOutlet="errorTemplate"
                      ></ng-template>
                    </mat-option>
                  }
                </mat-optgroup>
              }

              @if (
                (filteredRecipientsTeams$ | async)?.length ||
                teamListLoading ||
                teamListError
              ) {
                <mat-optgroup label="Team">
                  @for (
                    recipient of filteredRecipientsTeams$ | async;
                    track $index
                  ) {
                    <mat-option
                      [disabled]="alreadySelected(recipient)"
                      [value]="recipient"
                    >
                      <div class="flex items-center space-x-2 pl-2">
                        <ng-container
                          *ngTemplateOutlet="
                            recipientOption;
                            context: { $implicit: recipient }
                          "
                        ></ng-container>
                      </div>
                    </mat-option>
                  }

                  @if (teamListLoading) {
                    <mat-option [disabled]="true">
                      <ng-template
                        *ngTemplateOutlet="loadingTemplate"
                      ></ng-template>
                    </mat-option>
                  }
                  @if (teamListError) {
                    <mat-option [disabled]="true">
                      <ng-template
                        *ngTemplateOutlet="errorTemplate"
                      ></ng-template>
                    </mat-option>
                  }
                </mat-optgroup>
              }

              @if (
                (filteredRecipientsCustomField$ | async)?.length ||
                customFieldsLoading ||
                customFieldsError
              ) {
                <mat-optgroup label="Custom Field">
                  @for (
                    recipient of filteredRecipientsCustomField$ | async;
                    track $index
                  ) {
                    <mat-option
                      [disabled]="alreadySelected(recipient)"
                      [value]="recipient"
                    >
                      <div class="flex items-center space-x-2">
                        <i class="fa-light fa-at text-sky-500 text-sm"></i>
                        <span>{{ recipient.prompt }}</span>
                      </div>
                    </mat-option>
                  }

                  @if (customFieldsLoading) {
                    <mat-option [disabled]="true">
                      <ng-template
                        *ngTemplateOutlet="loadingTemplate"
                      ></ng-template>
                    </mat-option>
                  }
                  @if (customFieldsError) {
                    <mat-option [disabled]="true">
                      <ng-template
                        *ngTemplateOutlet="errorTemplate"
                      ></ng-template>
                    </mat-option>
                  }
                </mat-optgroup>
              }
            </mat-autocomplete>
          </mat-form-field>
          <a
            (click)="openAdvancedSearchModal()"
            (keypress)="openAdvancedSearchModal()"
            tabindex="0"
            class="flex-shrink-0 text-sm"
            >Advanced Search</a
          >
        </div>
      </section>
      <section class="notification-subject">
        <mat-form-field
          class="w-full"
          appearance="outline"
          subscriptSizing="dynamic"
          [ngClass]="{
            'auto-complete-invalid': isSubjectFieldInvalid
          }"
        >
          <mat-label>Subject:</mat-label>

          <mat-chip-grid #chipSubject aria-label="Subject selection">
            @for (
              subject of notificationActionForm.value.subject;
              track $index
            ) {
              <mat-chip-row>
                <i [ngClass]="getSubjectIcon(subject)"></i>
                {{ getRecipientName(subject) }}
                <button type="button" (click)="removeSubject(subject)">
                  <i
                    class="fas fa-xmark-circle text-gray-400 hover:text-gray-500"
                  ></i>
                </button>
              </mat-chip-row>
            }
          </mat-chip-grid>
          <input
            placeholder="Subject"
            [formControl]="subjectControl"
            [matChipInputFor]="chipSubject"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="subjectControl.valid && addSubject($event)"
          />

          <button
            type="button"
            matSuffix
            matButton
            (click)="openEmailSubjectModal()"
          >
            <i class="fa-light fa-plus-circle text-primary-400"></i>
          </button>
        </mat-form-field>
      </section>
      <section class="notification-message">
        <mat-form-field
          class="w-full"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <textarea
            matInput
            class="scrollbar non-resizable"
            rows="5"
            id="email-message"
            data-testid="email-message"
            autocomplete="off"
            formControlName="message"
          ></textarea>
        </mat-form-field>
      </section>
      <section
        class="notification-controls-section"
        [ngClass]="{
          'relative flex justify-between items-center py-2 max-w-none':
            orderOfOperations
        }"
      >
        <div
          class="flex"
          [ngClass]="orderOfOperations ? 'space-x-4' : 'justify-end space-x-2'"
        >
          <button
            mat-button
            type="button"
            id="cancel-notification"
            data-testid="cancel-notification"
            class="!font-semibold !uppercase"
            [disabled]="
              (savingNotification || deletingNotification) && orderOfOperations
            "
            (click)="confirmCancel()"
          >
            Cancel
          </button>
          <button
            mat-raised-button
            [ngClass]="{
              '!bg-sky-500 !text-white': orderOfOperations,
              '!opacity-60':
                (disableAddNotification() ||
                  savingNotification ||
                  deletingNotification) &&
                orderOfOperations
            }"
            type="button"
            color="accent"
            id="save-notification"
            data-testid="save-notification"
            class="!font-semibold !uppercase"
            [disabled]="
              disableAddNotification() ||
              ((savingNotification || deletingNotification) &&
                orderOfOperations)
            "
            (click)="addNotificationAction()"
          >
            @if (orderOfOperations) {
              @if (savingNotification) {
                <ng-container
                  [ngTemplateOutlet]="loadingInlineText"
                  [ngTemplateOutletContext]="{ $implicit: 'Saving...' }"
                ></ng-container>
              } @else {
                Save
              }
            } @else {
              {{ editMode ? 'Update' : 'Add' }}
            }
          </button>
        </div>
        @if (orderOfOperations && editMode) {
          <div class="flex-grow text-right">
            <button
              mat-stroked-button
              class="!bg-red-500 !text-white"
              type="button"
              id="delete-action"
              data-testid="delete-action"
              (click)="removeParentNotification()"
            >
              @if (deletingNotification) {
                <ng-container
                  [ngTemplateOutlet]="loadingInlineText"
                  [ngTemplateOutletContext]="{ $implicit: 'Deleting...' }"
                ></ng-container>
              } @else {
                <i class="fa-light fa-trash text-lg"></i> DELETE ACTION
              }
            </button>
          </div>
        }
      </section>
    </section>
  </form>
</section>

<!-- Recipient Option.-->
<ng-template #recipientOption let-member>
  @if (showIcon(member)) {
    <i [ngClass]="getRecipientIcon(member)" class="text-secondary-300"></i>
  } @else {
    <ng-container
      [ngTemplateOutletContext]="{
        data: member
      }"
      [ngTemplateOutlet]="avatarImage"
    ></ng-container>
  }
  <p>{{ getRecipientName(member) }}</p>
</ng-template>

<!-- Avatar profile image field-->
<ng-template #avatarImage let-data="data">
  <app-user-avatar
    [firstName]="data.firstName || ''"
    [lastName]="data.lastName || ''"
    [size]="'20'"
    [profileImageRithmId]="data.profileImageRithmId || ''"
    class="relative right-1"
  ></app-user-avatar>
</ng-template>

<!-- Loading template-->
<ng-template #loadingTemplate>
  <div class="w-full flex justify-center pt-2">
    <app-loading-indicator inlineText="Loading..."></app-loading-indicator>
  </div>
</ng-template>

<!-- Error template-->
<ng-template #errorTemplate>
  <p class="text-error-500">Error getting list.</p>
</ng-template>

<!-- In line text loading indicator -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator
    id="loading-inline-text"
    [inlineText]="message"
  ></app-loading-indicator>
</ng-template>
