import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

/** Notification types modal. */
@Component({
  selector: 'app-notification-type-modal',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  templateUrl: './notification-type-modal.component.html',
  styleUrls: ['./notification-type-modal.component.scss'],
})
export class NotificationTypeModalComponent {
  constructor(
    private dialogRef: MatDialogRef<NotificationTypeModalComponent>,
  ) {}

  /**
   * Close the modal.
   */
  closeModal(): void {
    this.dialogRef.close();
  }
}
