/* eslint-disable jsdoc/require-jsdoc */
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ComponentHelper, TermsGeneric } from 'src/helpers';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {
  MemberPermissionData,
  UserAssociateType,
  UserSearchReturnViewModel,
} from 'src/models';
import { Subject, first, map } from 'rxjs';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { MatButtonModule } from '@angular/material/button';
import { NotificationService } from 'src/app/core/notification.service';
import { MatOption } from '@angular/material/core';
import { InfinityScrollAutocompleteDirective } from 'src/helpers/directives/infinity-scroll-autocomplete/infinity-scroll-autocomplete.directive';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';

/** Advanced search modal. */
@Component({
  selector: 'app-advanced-search',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    UserAvatarComponent,
    LoadingIndicatorComponent,
    InfinityScrollAutocompleteDirective,
  ],
  templateUrl: './advanced-search-modal.component.html',
  styleUrls: ['./advanced-search-modal.component.scss'],
})
export class AdvancedSearchModalComponent implements OnInit, OnDestroy {
  /** Search filter input. */
  @ViewChild('searchInput') searchInput!: ElementRef;

  /** Members select input. */
  @ViewChild('memberInput') memberInput!: ElementRef;

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /* System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Component Helper. */
  componentHelper = ComponentHelper;

  /** Reactive form control for filter type. */
  primarySearchControl = new FormControl('');

  /** Reactive form control for members dropdown. */
  memberSearchControl = new FormControl('');

  /** Filter to display members. */
  selectedMembers: MemberPermissionData[] = [];

  /** List of members based on filter criteria. */
  membersList: MemberPermissionData[] = [];

  /** Selected members list. */
  selectedFilterType: MemberPermissionData[] = [];

  /** List of selected members. */
  recipientLists: UserSearchReturnViewModel = {
    totalMembers: 0,
    users: [],
    teams: [],
  };

  /** List of selected members copy for search filter. */
  private _recipientLists: UserSearchReturnViewModel = {
    totalMembers: 0,
    users: [],
    teams: [],
  };

  /* Associate user search list. */
  userAssociateSearch: MemberPermissionData[] = [];

  /** Make close button visible/hidden in the chip-set. */
  removable = true;

  /** Display loading when get user API. */
  userLoading = false;

  /** Display error when API fails get user. */
  isErrorGetUser = false;

  /** Whether the request to get the search result is currently underway. */
  searchLoading = false;

  /** Show/hide member list dropdown. */
  showMembersList = false;

  /** While request get user associate for team selected in member search. */
  selectPrimarySearch = false;

  /** Whether can load more members.*/
  isLoadScroll = true;

  /** Maximum number of members to be shown in the list. */
  pageSize = 15;

  /** Number of pages to be shown in the list. */
  pageNumber = 1;

  /** Init a timeout variable to be used in method get search results . */
  timeout = setTimeout(() => '', 1000);

  /** Selected team rithmId is in member search. */
  selectFilterRithmId = '';

  /** Associate types except user.*/
  readonly associateTypes = [
    UserAssociateType.Organization,
    UserAssociateType.Team,
    UserAssociateType.StationGroup,
    UserAssociateType.Station,
  ];

  constructor(
    private dialogRef: MatDialogRef<AdvancedSearchModalComponent>,
    private notificationService: NotificationService,
  ) {}

  /** Init method. */
  ngOnInit(): void {
    this.getUserAssociationSearch();
  }

  /**
   * Selected filter type.
   * @param type Selected search filter.
   */
  selectFilterType(type: MemberPermissionData): void {
    this.selectedFilterType = [];
    this.selectedFilterType.push(type);
    this.searchInput.nativeElement.value = '';
    this.primarySearchControl.setValue(null);
    this.showMembersList = true;
    this.pageNumber = 1;
    this.isLoadScroll = true;
    const search = type.type !== UserAssociateType.Team ? type.name : '';
    this.getOrganizationMembers(search, type.type, type.rithmId);
    this.getUserAssociationSearch();
  }

  /**
   * Remove selected search type from the chip-set.
   * @param search Selected search rithmId.
   */
  removeSearchFilter(search: string): void {
    const index = this.selectedFilterType.findIndex(
      (member) => member.rithmId === search,
    );
    if (index !== -1) {
      this.selectedFilterType.splice(index, 1);
    }
    this.showMembersList = false;
    this.selectedMembers = [];
    this.memberSearchControl.setValue(null);
    if (this.memberInput?.nativeElement) {
      this.memberInput.nativeElement.value = '';
    }
  }

  /**
   * Search for matching filter from the list.
   */
  searchFilter(): void {
    this.userLoading = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const searchValue = this.primarySearchControl.value;
      if (searchValue && searchValue?.length) {
        if (!this.selectedFilterType.length) {
          this.pageNumber = 1;
          this.recipientLists.teams = [];
          this.recipientLists.users = [];
          this.recipientLists.totalMembers = 0;
        }
        this.getUserAssociationSearch(searchValue.toLowerCase());
      } else {
        this.userAssociateSearch = [];
        this.getUserAssociationSearch();
      }
    }, 750);
  }

  /**
   * Avoid selecting duplication search filter in the list.
   * @param rithmId - Search type filter.
   * @returns Boolean value.
   */
  isDisabledFilter(rithmId: string): boolean {
    return (
      this.selectedFilterType.filter((search) => search.rithmId === rithmId)
        .length > 0
    );
  }

  /**
   * Select a member from the autocomplete dropdown.
   * @param event SelectionChange event.
   */
  selectMembers(event: MatOption): void {
    const selected = event;
    if (!selected.group) {
      const isExist = this.selectedMembers.some(
        (option) => option.rithmId === this.selectedFilterType[0].rithmId,
      );
      if (!isExist) {
        this.selectedMembers.push(this.selectedFilterType[0]);
      }
    } else if (selected.group.label.includes('Member')) {
      this.selectedMembers.push(selected.value);
      if (this.memberInput?.nativeElement) {
        this.memberInput.nativeElement.value = '';
      }
    } else if (selected.group.label.includes('Team')) {
      this.getUserAssociationSearch(selected.value.name);
      this.selectFilterRithmId = selected.value.rithmId;
      this.selectPrimarySearch = true;
    }
    this.memberSearchControl.setValue(null);
  }

  /**
   * Remove selected member from the chip-set.
   * @param rithmId Selected member rithm-Id.
   */
  removeMember(rithmId: string): void {
    const index = this.selectedMembers.findIndex(
      (member) => member.rithmId === rithmId,
    );
    if (index !== -1) {
      this.selectedMembers.splice(index, 1);
    }
  }

  /**
   * Avoid selecting duplication members in the list.
   * @param rithmId Selected member rithm-Id.
   * @returns Boolean value.
   */
  isDisabledMember(rithmId: string): boolean {
    return (
      this.selectedMembers.filter((member) => member.rithmId === rithmId)
        .length > 0
    );
  }

  /**
   * Check whether it should show an icon or not.
   * @param option Option selected.
   * @returns A boolean.
   */
  showIcon(option: MemberPermissionData): boolean {
    return (
      !option.profileImageRithmId && option.type !== UserAssociateType.User
    );
  }

  /**
   * Get data about the user association data.
   * @param searchText Search filter.
   */

  private getUserAssociationSearch(searchText = ''): void {
    this.userLoading = true;
    this.isErrorGetUser = false;
    this.notificationService
      .getUserAssociationSearch(searchText)
      .pipe(
        first(),
        map((results) => {
          return results.filter(
            ({ type, totalMembers }) =>
              type !== UserAssociateType.Team ||
              (totalMembers && totalMembers > 0),
          );
        }),
      )
      .subscribe({
        next: (userAssociate) => {
          this.userAssociateSearch = userAssociate;
          if (this.selectPrimarySearch) {
            const selectAssociate = this.userAssociateSearch.find(
              (associate) => associate.rithmId === this.selectFilterRithmId,
            );
            if (selectAssociate) {
              this.selectFilterType(selectAssociate);
            }
            this.selectPrimarySearch = false;
            this.selectFilterRithmId = '';
          }
          this.userLoading = false;
          this.isErrorGetUser = false;
        },
        error: () => {
          this.isErrorGetUser = true;
          this.userLoading = false;
        },
      });
  }

  /**
   * Get the list of organization members.
   * @param searchText Search filter.
   * @param type Search type of origin associate.
   * @param rithmId Search rithmId of the associate.
   */
  private getOrganizationMembers(
    searchText: string,
    type = '',
    rithmId = '',
  ): void {
    this.searchLoading = true;
    this.isErrorGetUser = false;
    this.notificationService
      .getOrganizationMembers(
        this.pageSize,
        this.pageNumber,
        searchText,
        type,
        rithmId,
      )
      .pipe(
        first(),
        map((data) => {
          return {
            ...data,
            teams: data.teams?.filter(({ totalMembers }) => totalMembers > 0),
          };
        }),
      )
      .subscribe({
        next: (members) => {
          const searchFilter = this.memberSearchControl.value;
          if (searchFilter && searchFilter.length) {
            this._recipientLists = members;
          } else {
            if (members && this.pageNumber === 1) {
              this.recipientLists = members;
            } else {
              this.recipientLists.users = this.recipientLists.users.concat(
                members.users,
              );
              this.isLoadScroll = members.users.length >= this.pageSize;
            }
          }
          this.searchLoading = false;
        },
        error: () => {
          this.isErrorGetUser = true;
          this.searchLoading = false;
        },
      });
  }

  /**
   * Validate scroll to get member list.
   *
   */
  getMemberList(): void {
    const searchInput = this.selectedFilterType;

    if (
      !this.userLoading &&
      this.isLoadScroll &&
      searchInput &&
      searchInput.length
    ) {
      this.pageNumber++;
      this.getOrganizationMembers(
        searchInput[0].name,
        searchInput[0].type,
        searchInput[0].rithmId,
      );
    }
  }

  /**
   * Determine the selected user type.
   * @param data Selected option value.
   * @returns Returns the selected user type..
   */
  getUserAssociateType(data: MemberPermissionData): boolean {
    return this.associateTypes.includes(data.type);
  }

  /**
   * Close the modal and pass the selected data to parent component.
   */
  closeModal(): void {
    this.dialogRef.close(this.selectedMembers);
  }

  /**
   * Search member for matching filter value from the list.
   */
  memberSearchFilter(): void {
    this.pageSize = 50;
    this.selectFilterType(this.selectedFilterType[0]);
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const searchValue = this.memberSearchControl.value;
      if (searchValue && searchValue.length) {
        searchValue.trim().toLocaleLowerCase();
        this.recipientLists.totalMembers = 0;
        this.recipientLists.teams = [];
        this.recipientLists.users = [];
        this.recipientLists.teams = this._recipientLists.teams?.filter(
          (question) => question.name.toLowerCase().includes(searchValue),
        );
        this.recipientLists.users = this._recipientLists.users?.filter(
          (question) => question.name.toLowerCase().includes(searchValue),
        );
        this.recipientLists.totalMembers = this._recipientLists.totalMembers;
      } else {
        this.pageSize = 4;
        this.selectFilterType(this.selectedFilterType[0]);
      }
    }, 750);
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
