<section class="mb-2">
  <section class="flex w-full lg:w-1/2">
    <form [formGroup]="memberActionForm" class="w-full flex flex-col">
      <div class="text-gray-800 text-lg font-semibold leading-relaxed">
        Manage Members
      </div>

      <div class="flex-col justify-center items-center inline-flex pt-3">
        <div class="left-0 top-0 absolute"></div>
        <div
          class="self-stretch grow shrink basis-0 py-2 justify-start items-center gap-4 inline-flex"
        >
          <div
            class="grow shrink basis-0 self-stretch flex-col justify-center items-start inline-flex"
          >
            <div
              class="self-stretch text-gray-800 text-base font-medium leading-normal"
            >
              1. Add or remove members
            </div>
            <div
              class="self-stretch text-gray-600 text-base font-normal leading-normal"
            >
              Add users & teams to
              {{ termsGeneric.Station.Plural.toLowerCase() }} and
              {{ termsGeneric.Group.Plural.toLowerCase() }}.
            </div>
          </div>
        </div>
      </div>

      <mat-form-field
        appearance="outline"
        class="w-full max-w-2xl"
        subscriptSizing="dynamic"
      >
        <mat-label class="text-base">Type</mat-label>
        <mat-select formControlName="elementMemberAction">
          <mat-option [value]="actionsType.AddMember">
            Add member(s)
          </mat-option>
          <mat-option [value]="actionsType.RemoveMember">
            Remove member(s)
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div
        class="bg-white flex-col justify-center items-center inline-flex pt-4"
      >
        <div class="left-0 top-0 absolute"></div>
        <div
          class="self-stretch pt-3 pb-2 justify-start items-center gap-4 inline-flex"
        >
          <div
            class="grow shrink basis-0 self-stretch flex-col justify-center items-start inline-flex"
          >
            <div
              class="self-stretch text-gray-800 text-base font-medium leading-normal"
            >
              2. Select which
              {{ termsGeneric.Station.Single.toLowerCase() }}(s) or
              {{ termsGeneric.Group.Single.toLowerCase() }}(s) you’d like to
              {{
                memberActionForm.controls['elementMemberAction'].value ===
                actionsType.RemoveMember
                  ? 'remove'
                  : 'add'
              }}
              users to
            </div>
            <div
              class="self-stretch text-gray-600 text-base font-normal leading-normal lg:!w-[685.5px]"
            >
              If a user has already been added or removed from the selected
              {{ termsGeneric.Station.Single.toLowerCase() }} or
              {{ termsGeneric.Group.Single.toLowerCase() }} member list that
              user will be skipped.
            </div>
          </div>
        </div>
      </div>

      <!-- Select to get list of stations and groups of organization -->
      <mat-form-field
        appearance="outline"
        class="w-full max-w-2xl"
        subscriptSizing="dynamic"
        [ngClass]="{
          'animate-pulse': isLoadingStationsAndGroups && !!action()
        }"
      >
        <mat-label class="text-base">To: </mat-label>
        <mat-select
          [multiple]="true"
          formControlName="elementSelectControl"
          appMatSelectInfinityScroll
          [classStyleNameScroll]="'scroll-when-opened-select'"
          (onScrollDown)="getMoreElements()"
          [ngClass]="{
            'animate-pulse': isLoadingStationsAndGroups && !!action()
          }"
        >
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search..."
              formControlName="elementSearchControl"
              [clearSearchInput]="false"
              noEntriesFoundLabel="Not match found..."
              [attr.data-testid]="'element-search'"
              [attr.id]="'element-search'"
            >
              <span aria-hidden="true" class="close" ngxMatSelectSearchClear>
                <i class="fa-solid fa-xmark"></i>
              </span>
            </ngx-mat-select-search>
          </mat-option>
          @for (stationOrGroup of listStationsAndGroups; track $index) {
            <mat-option
              [value]="stationOrGroup.rithmId"
              (click)="selectStationOrGroupBackup(stationOrGroup.rithmId)"
              class="mat-option-list"
            >
              <i
                [ngClass]="
                  stationOrGroup.type === 'station'
                    ? 'fak fa-station'
                    : 'fal fa-grid-2'
                "
                class="text-secondary-200"
              ></i>
              <div class="w-full flex flex-col overflow-hidden px-4">
                <p class="w-full truncate">
                  <span class="font-medium text-base">
                    {{ stationOrGroup.name }}</span
                  >
                  @if (stationRithmId() === stationOrGroup.rithmId) {
                    <span class="text-sm text-secondary-400 pl-1">
                      (current station)
                    </span>
                  }
                </p>
                <span
                  class="flex flex-col text-xs text-secondary-400 !line-clamp-[2]"
                >
                  {{ stationOrGroup.description }}
                </span>
              </div>
            </mat-option>
          }
          @if (
            isLoadingStationsAndGroups ||
            !listStationsAndGroups.length ||
            isErrorGetStationsAndGroups
          ) {
            <mat-option class="pointer-events-none check-center-loading">
              @if (isLoadingStationsAndGroups) {
                <app-loading-indicator
                  id="loading-elements"
                  data-testid="loading-elements"
                  [inlineText]="'Loading..'"
                />
              }
              @if (
                !listStationsAndGroups.length &&
                !isLoadingStationsAndGroups &&
                !isErrorGetStationsAndGroups
              ) {
                <span class="text-secondary-400 text-sm"
                  >-- No member found --</span
                >
              }
              @if (isErrorGetStationsAndGroups) {
                <span class="text-sm text-error-500">
                  -- Error getting members --
                </span>
              }
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <!-- List of checks to stations and groups -->
      <div class="element-chips w-full lg:w-11/12 pt-2.5">
        @if (isLoadedStationAndGroup()) {
          <mat-chip-listbox>
            @for (
              stationOrGroup of memberActionForm.controls[
                'elementSelectControl'
              ].value;
              track $index
            ) {
              @if (stationsAndGroups[stationOrGroup]) {
                <mat-chip>
                  <i
                    [ngClass]="
                      stationsAndGroups[stationOrGroup].type === 'station'
                        ? 'fak fa-station'
                        : 'fal fa-grid-2'
                    "
                    class="text-gray-600 pr-1"
                  ></i>
                  {{ stationsAndGroups[stationOrGroup].name }}
                  @if (
                    stationRithmId() ===
                    stationsAndGroups[stationOrGroup].rithmId
                  ) {
                    (current station)
                  }
                  <button
                    matChipRemove
                    type="button"
                    [id]="$index + 'element-remove'"
                    [attr.data-testid]="$index + 'element-remove'"
                    (click)="removeSelectedElement($index)"
                  >
                    <i
                      class="fa-light fa-xmark text-gray-600 pl-1 cursor-pointer"
                    ></i>
                  </button>
                </mat-chip>
              }
            }
          </mat-chip-listbox>
        }
      </div>
      <div
        class="bg-white flex-col justify-center items-center inline-flex pt-3"
      >
        <div class="left-0 top-0 absolute"></div>
        <div
          class="self-stretch pt-3 pb-2 justify-start items-center gap-4 inline-flex"
        >
          <div
            class="grow shrink basis-0 self-stretch flex-col justify-center items-start inline-flex"
          >
            <div
              class="self-stretch text-gray-800 text-base font-medium leading-normal"
            >
              3. Select members
            </div>
            <div
              class="self-stretch text-gray-600 text-base font-normal leading-normal lg:!w-[685.5px]"
            >
              If removing users or teams, removing them as a member will also
              remove them as a worker.
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>

  <!-- Table members -->
  <section class="overflow-hidden">
    <section
      class="table-search border-gray-300 flex justify-between items-center"
    >
      <mat-form-field class="w-full">
        <input
          matInput
          type="text"
          placeholder="search"
          id="input-search-members"
          data-testid="input-search-members"
          [(ngModel)]="searchMembers"
          autocomplete="off"
          (ngModelChange)="onSearchChange($event)"
        />
        <span matPrefix
          ><i class="fa-light fa-search fa-sm text-primary-200"></i
        ></span>
      </mat-form-field>
    </section>
    @if (!isMembersLoading) {
      <section
        class="w-full h-auto max-h-[500px] overflow-scroll scrollbar"
        appInfinityScroll
        (onScrollDown)="nextPageInfinityScrollDown($event)"
      >
        <table
          mat-table
          [dataSource]="dataSourceTable"
          [trackBy]="trackBy"
          class="w-full"
        >
          <!-- check column -->
          <ng-container matColumnDef="check">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="!w-1 whitespace-nowrap !pr-0"
            >
              <mat-checkbox
                class="check-sky"
                id="check-all"
                data-testid="check-all"
                [checked]="allMemberSelected"
                (change)="setAllMembers($event.checked)"
              ></mat-checkbox>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="!w-1 whitespace-nowrap !pr-0"
            >
              <mat-checkbox
                class="check-sky"
                [id]="'checkbox-' + element['rithmId']"
                [attr.data-testid]="'checkbox-' + element['rithmId']"
                [checked]="
                  selectionsTable[element.rithmId] &&
                  selectionsTable[element.rithmId].isMember
                "
                (change)="selectAsMember(element, $event.checked)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <!-- Member name -->
          <ng-container matColumnDef="member">
            <th mat-header-cell *matHeaderCellDef class="!max-w-[50%]">
              <p class="font-semibold text-xs text-secondary-300">MEMBER</p>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="text-xs text-secondary-500 !max-w-[50%]"
            >
              <div class="flex gap-x-2 items-center">
                @if (element['icon']) {
                  <i class="text-gray-500" [ngClass]="element['icon']"></i>
                } @else {
                  <app-user-avatar
                    [firstName]="element['firstName'] || element['teamName']"
                    [lastName]="element['lastName']"
                    [profileImageRithmId]="element['profileImageRithmId']"
                  ></app-user-avatar>
                }

                <div class="w-full truncate">
                  <p class="text-base font-medium leading-6 text-gray-800">
                    {{
                      element['teamName']
                        ? element['teamName']
                        : element['firstName'] +
                          ' ' +
                          (element['lastName'] ? element['lastName'] : '')
                    }}
                  </p>
                  <p class="text-xs text-gray-600">
                    {{ element['email'] }}
                  </p>
                </div>
              </div>
            </td>
          </ng-container>

          <!-- Worker. -->
          <ng-container matColumnDef="worker">
            <th mat-header-cell *matHeaderCellDef class="min-w-[150px]">
              <p class="font-semibold text-xs truncate text-secondary-300">
                WORKER
              </p>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="min-w-[150px] text-xs text-secondary-500">
                <mat-checkbox
                  class="check-sky"
                  id="check-all"
                  data-testid="check-all"
                  [checked]="
                    selectionsTable[element.rithmId] &&
                    selectionsTable[element.rithmId].isWorker
                  "
                  (change)="selectAsWorker(element, $event.checked)"
                ></mat-checkbox>
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            class="cursor-pointer hover:bg-secondary-50"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
        @if (isLoadingScroll) {
          <div class="w-full flex justify-center pt-2">
            <app-loading-indicator inlineText="Loading..." />
          </div>
        }
      </section>
    } @else {
      <ng-container *ngTemplateOutlet="loadingIndicator" />
    }
    <ng-container *ngTemplateOutlet="controlButtons" />
  </section>
</section>

<ng-template #loadingIndicator>
  <div
    class="w-full h-full md:h-[600px] flex flex-col justify-center items-center space-y-4"
  >
    <app-loading-indicator />
  </div>
</ng-template>

<ng-template #controlButtons>
  <section
    class="container-action-control-buttons flex flex-col md:flex-row pt-3 justify-between items-center space-y-2 md:space-y-0"
  >
    <div class="space-x-4">
      <button
        mat-button
        type="button"
        data-testid="action-cancel"
        id="action-cancel"
        (click)="closeForm()"
      >
        <span class="font-semibold">CANCEL</span>
      </button>
      <button
        mat-raised-button
        [ngClass]="{
          '!bg-sky-500 !text-white !font-semibold': orderOfOperations,
          '!opacity-60': !memberActionForm.valid
        }"
        type="button"
        color="accent"
        id="action-save"
        data-testid="action-save"
        [disabled]="!memberActionForm.valid"
        (click)="saveAction()"
      >
        @if (orderOfOperations) {
          @if (loadingUpdatingAction) {
            <app-loading-indicator
              id="loading-account-setting"
              data-testid="loading-account-setting"
              [inlineText]="'Saving...'"
            />
          } @else {
            SAVE
          }
        } @else {
          DONE
        }
      </button>
    </div>

    @if (orderOfOperations && action()) {
      <div class="flex-grow text-right">
        <button
          mat-stroked-button
          class="!bg-red-500 !text-white"
          type="button"
          id="delete-action"
          data-testid="delete-action"
          (click)="confirmDeleteAction()"
        >
          @if (deletingAction) {
            <ng-container
              [ngTemplateOutlet]="loadingInlineText"
              [ngTemplateOutletContext]="{ $implicit: 'Deleting...' }"
            ></ng-container>
          } @else {
            <i class="fa-light fa-trash text-lg"></i> DELETE ACTION
          }
        </button>
      </div>
    }
  </section>
</ng-template>

<!-- In line text loading indicator -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator id="loading-inline-text" [inlineText]="message" />
</ng-template>
