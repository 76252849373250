import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionType,
  PowerAction,
  Question,
  QuestionFieldType,
  SqlActionData,
  SqlIntegrationType,
} from 'src/models';
import { MatChipsModule } from '@angular/material/chips';
import { UtcTimeConversion } from 'src/helpers';
import { UtcDateFormatPipe } from 'src/helpers/pipes/utc-date-format/utc-date-format.pipe';

/**
 * Represent the sql integration added to actions.
 */
@Component({
  selector: 'app-sql-item',
  standalone: true,
  imports: [CommonModule, MatChipsModule, UtcDateFormatPipe],
  providers: [UtcTimeConversion],
  templateUrl: './sql-item.component.html',
  styleUrls: ['./sql-item.component.scss'],
})
export class SqlItemComponent implements OnInit {
  /** SQL integration object. */
  @Input() integration!: PowerAction;

  /** Bucket questions. */
  @Input() bucketQuestions!: Question[];

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** The integration contained as JSONstringify in the power.data. */
  sqlIntegrationData!: SqlActionData;

  /** The type of integration of the item 'PUSH/PULL'. */
  sqlIntegrationType!: SqlIntegrationType;

  /** The current field setting for date handling. */
  questionSettings = '';

  constructor(public timeConversion: UtcTimeConversion) {}

  /**
   * Get question name/label the question.
   * @param field Current field to extract value.
   * @param isKey Whether it is a key field or not.
   * @returns The question according to the format of the field entered.
   */
  getQuestionName(field: string, isKey = false): string {
    const prefix = 'rid:';
    const index = field.indexOf(prefix);
    if (index !== -1) {
      // If 'rid:' is in the string, we extract the value after the colon.
      const newId = field.substring(index + prefix.length);
      const currentQuestion = this.bucketQuestions.find(
        (question) => question.rithmId === newId,
      );
      // If it is a key field and has a question type date.
      if (isKey && currentQuestion?.questionType === QuestionFieldType.Date) {
        this.questionSettings = currentQuestion?.settings || '';
      }
      return currentQuestion?.prompt || '';
    } else {
      // If 'rid:' is not in the string, we return the full string.
      return field;
    }
  }

  /**
   * Init Method.
   */
  ngOnInit(): void {
    this.setInitialData();
  }

  /**
   * Set initial data for sql integration.
   */
  setInitialData(): void {
    this.sqlIntegrationData = JSON.parse(
      this.integration.data,
    ) as SqlActionData;
    // Formatting key field questions.
    this.sqlIntegrationData.keyField.field = this.getQuestionName(
      this.sqlIntegrationData.keyField.field,
      true,
    );
    if (this.sqlIntegrationData.columnValues) {
      this.sqlIntegrationData.columnValues.forEach(
        (column) => (column.field = this.getQuestionName(column.field)),
      );
    }
    // We set it according to the type of action.
    this.sqlIntegrationType =
      this.integration.type === ActionType.SqlPull
        ? SqlIntegrationType.Pull
        : SqlIntegrationType.Push;
  }
}
