import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  ActionAlertInfo,
  ActionType,
  NotificationsType,
  PowerAction,
  PowerEventType,
  StationBucketQuestion,
} from 'src/models';
import { InternalNotificationFormComponent } from 'src/app/station/rules/actions/notifications/internal-notifications/internal-notification-form/internal-notification-form.component';
import { PopupService } from 'src/app/core/popup.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { InternalNotificationItemComponent } from './internal-notifications/internal-notification-item/internal-notification-item.component';
import { EmailNotificationsItemComponent } from './email-notifications-item/email-notifications-item.component';
import { StationService } from 'src/app/core/station.service';
import { first } from 'rxjs';
import { EmailNotificationsFormComponent } from './email-notifications-form/email-notifications-form.component';
import { PowerService } from 'src/app/core/power.service';
import { v4 as uuidv4 } from 'uuid';

/**
 * Component to add/edit/delete Push/Silent/Browser notification.
 */
@Component({
  selector: 'app-notifications-action[actions][stationRithmId]',
  templateUrl: './notifications-action.component.html',
  styleUrls: ['./notifications-action.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    InternalNotificationFormComponent,
    EmailNotificationsFormComponent,
    MatMenuModule,
    MatOptionModule,
    MatFormFieldModule,
    InternalNotificationItemComponent,
    EmailNotificationsItemComponent,
  ],
})
export class NotificationsActionComponent implements OnInit {
  /** The station id used to get shared values. */
  @Input() stationRithmId!: string;

  /** Set the actions of the current power. */
  @Input() set actions(value: PowerAction[]) {
    this._actions = value;
    this.updateAlertList();
  }

  /** Station Bucket Questions. */
  @Input() bucketQuestions: StationBucketQuestion[] = [];

  /** This is a setter. It is called when the value of the input property changes. */
  @Input() set refreshIdContainer(value: string) {
    this._refreshIdContainer = value;
    this.showNotification = false;
  }

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Whether to display only new creation action or not. */
  @Input() newCreationAction = false;

  /** Current rithmId of the rule. */
  @Input() ruleRithmId = '';

  /** The modified/added action type to send back to flow. */
  @Output() actionsUpdEmitter = new EventEmitter<PowerAction[]>();

  /** All the station actions. */
  private _actions: PowerAction[] = [];

  /**
   * Get the station actions.
   * @returns The list of actions.
   */
  get actions(): PowerAction[] {
    return this._actions;
  }

  /* A private variable that is used to store the value of the input property. */
  private _refreshIdContainer = '';

  /**
   * It returns the value of the private variable _refreshIdContainer.
   * @returns The value of the new id container.
   */
  get refreshIdContainer(): string {
    return this._refreshIdContainer;
  }

  showNotification = false;

  /** Dropdown options for notification types. */
  notificationsTypeList = [
    NotificationsType.Internal,
    NotificationsType.Email,
    NotificationsType.Sms,
  ];

  /** Dropdown options for notification types. */
  notificationsType = NotificationsType;

  /** Enable Notification create section. */
  enableNotificationType = false;

  /** Action of type UpdateField to be edited. */
  actionToBeUpdated: PowerAction | null = null;

  /** Selected notification action type. */
  selectedActionType: NotificationsType | null = null;

  /** Loading the powers on the station. */
  customFieldsLoading = false;

  /** Error the powers on the station. */
  customFieldsError = false;

  /** All custom fields. */
  allRecipientsCustomFields: StationBucketQuestion[] = [];

  /** Alert notifications. */
  alerts: PowerAction[] = [];

  /** Whether you are saving the current integration action . */
  savingNotification = false;

  /** Whether is loading petition delete action. */
  deletingNotification = false;

  /** The event type of the powerAction. */
  powerEventType = PowerEventType;

  constructor(
    private popupService: PopupService,
    private stationService: StationService,
    private powerService: PowerService,
  ) {}

  /**
   * Initialization of the component.
   */
  ngOnInit(): void {
    this.getStationBucketQuestions();
  }

  /**
   * Get's list of station bucket questions.
   */
  private getStationBucketQuestions(): void {
    this.customFieldsLoading = true;
    this.stationService
      .getStationBucketQuestions(this.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (bucketQuestions) => {
          this.allRecipientsCustomFields = bucketQuestions;
          this.customFieldsLoading = false;
          this.customFieldsError = false;
        },
        error: () => {
          this.customFieldsLoading = false;
          this.customFieldsError = true;
        },
      });
  }

  /**
   * Opens notification selection list.
   *
   */
  createNewAction(): void {
    this.showNotification = true;
    this.actionToBeUpdated = null;
    this.selectedActionType = null;
  }

  /**
   * Only for Internal Notification.
   * @param type Notification Type.
   */
  notificationSelected(type: NotificationsType): void {
    this.createNewAction();
    this.selectedActionType = type;
  }

  /**
   * Saving notification action changes.
   * @param powerToAdd The received power to add/edit into the action object.
   */
  public saveNotificationAction(powerToAdd: PowerAction): void {
    const actionIndex = this.actions.findIndex(
      (action) => action.rithmId === powerToAdd.rithmId,
    );
    if (this.orderOfOperations) {
      let eventAction: PowerEventType;
      // Notification has not been saved.
      if (actionIndex < 0) {
        const newOrder = this.actions.length + 1;
        // We update the order of the current action.
        powerToAdd.order = newOrder;
        eventAction = PowerEventType.Add;
      } else {
        // Otherwise it is only updated.
        eventAction = PowerEventType.Update;
      }
      this.addOrUpdateSpecificAction(powerToAdd, eventAction);
    } else {
      if (actionIndex >= 0) {
        this.actions[actionIndex] = powerToAdd as PowerAction;
      } else {
        this.actions.push(powerToAdd);
      }
      this.updateAlertList();
      this.actionsUpdEmitter.emit(this.actions);
      this.actionToBeUpdated = null;
      this.showNotification = false;
    }
  }

  /**
   * Popup confirmation delete/update notification.
   * @param notification Action of the integration to be deleted.
   */
  async confirmRemoveNotification(notification: PowerAction): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'This Notification will be deleted',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (response) {
      if (this.orderOfOperations) {
        this.deleteSpecificAction(notification);
      } else {
        const actionIndex = this.actions.findIndex(
          (action) => action.rithmId === notification.rithmId,
        );
        this.actions.splice(actionIndex, 1);
        this.updateAlertList();
        this.actionsUpdEmitter.emit(this.actions);
      }
    }
  }

  /** Update alert list. */
  updateAlertList(): void {
    this.alerts = this._actions.filter(({ type }) =>
      [ActionType.AlertMessage, ActionType.SendEmail].includes(type),
    );
  }

  /**
   * Pass the edit notification object to form.
   * @param action Action to be edited.
   */
  editNotification(action: PowerAction): void {
    this.actionToBeUpdated = action;
    const alertType = JSON.parse(action.data) as ActionAlertInfo;
    this.selectedActionType = this.isInternalNotification(alertType.types[0])
      ? NotificationsType.Internal
      : NotificationsType.Email;
    this.showNotification = true;
  }

  /**
   * Reset the notification action to initial view.
   *
   */
  cancelNotificationForm(): void {
    this.showNotification = false;
  }

  /**
   * Checks if the notification type is internal.
   * @param data The current notification data listed.
   * @returns A Boolean.
   */
  isInternalNotification(data: string): boolean {
    const internalRegex = new RegExp(/(Push|Silent|Browser|Internal)/g);
    // If notification data string contains Push/Silent/Browser it means is an internal Notification.
    return internalRegex.test(data);
  }

  /**
   * Checks if the notification type is email.
   * @param data The current notification data listed - stringify.
   * @param plainType Whether we need to compare the type directly from the variable.
   * @returns A Boolean.
   */
  isEmailNotification(data: string, plainType?: NotificationsType): boolean {
    if (plainType) {
      return plainType === this.notificationsType.Email;
    }
    return (JSON.parse(data) as ActionAlertInfo).types.includes(
      this.notificationsType.Email,
    );
  }

  /**
   * Checks if the notification type is SMS.
   * @param data The current notification data listed - stringify.
   * @param plainType Whether we need to compare the type directly from the variable.
   * @returns A Boolean.
   */
  isSmsNotification(data: string, plainType?: NotificationsType): boolean {
    if (plainType) {
      return plainType === this.notificationsType.Sms;
    }
    return (JSON.parse(data) as ActionAlertInfo).types.includes(
      this.notificationsType.Sms,
    );
  }

  /**
   * Add or update action.
   * @param powerAction The current action to be created or updated.
   * @param powerEvent The current action to be created or updated.
   */
  addOrUpdateSpecificAction(
    powerAction: PowerAction,
    powerEvent: PowerEventType,
  ): void {
    if (powerEvent === PowerEventType.Duplicate) {
      powerAction = {
        ...powerAction,
        rithmId: uuidv4(),
      };
    }
    this.savingNotification = true;
    this.powerService
      .addOrUpdateSpecificAction(this.ruleRithmId, powerAction)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: powerEvent,
            action: powerAction,
            powerRithmId: this.ruleRithmId,
          });
          this.savingNotification = false;
          this.cancelNotificationForm();
        },
        error: () => {
          this.savingNotification = false;
          this.popupService.notify('Error saving notification action', true);
        },
      });
  }

  /**
   * Delete a specific action.
   * @param action  Delete the current action.
   */
  deleteSpecificAction(action: PowerAction): void {
    this.deletingNotification = true;
    this.powerService
      .deleteAction(this.ruleRithmId, action.rithmId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: PowerEventType.Delete,
            action,
            powerRithmId: this.ruleRithmId,
          });
          this.deletingNotification = false;
          this.cancelNotificationForm();
        },
        error: () => {
          this.deletingNotification = false;
          this.popupService.notify('Error to delete action', true);
        },
      });
  }
}
