import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RithmAiActionFormComponent } from 'src/app/station/rules/actions/rithm-ai-action/rithm-ai-action-form/rithm-ai-action-form.component';
import { SelectAiModelComponent } from 'src/app/station/rules/actions/rithm-ai-action/select-ai-model/select-ai-model.component';
import {
  ActionType,
  AiAction,
  AiActionFormData,
  PowerAction,
  PowerEventAction,
  PowerEventType,
  Question,
} from 'src/models';
import { v4 as uuidv4 } from 'uuid';
import { RithmAiActionListComponent } from 'src/app/station/rules/actions/rithm-ai-action/rithm-ai-action-list/rithm-ai-action-list.component';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';

/**
 * Rithm AI Action Component.
 */
@Component({
  selector: 'app-rithm-ai-action',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    RithmAiActionFormComponent,
    SelectAiModelComponent,
    RithmAiActionListComponent,
    LoadingIndicatorComponent,
  ],
  templateUrl: './rithm-ai-action.component.html',
  styleUrl: './rithm-ai-action.component.scss',
})
export class RithmAiActionComponent {
  /** All the bucket questions. */
  bucketQuestions = input.required<Question[]>();

  /** The action to edit. */
  action = input<PowerAction | null>(null);

  /** The total actions. */
  totalActions = input.required<number>();

  /** The order of operations flag. */
  orderOfOperations = input<boolean>(false);

  /** The action being saved. */
  savingAction = input<{
    /** The action id being saved. */
    rithmId: string;
    /** If the action is being saved. */
    isSaving: boolean;
  } | null>(null);

  /** If the action is being saved. */
  isSavingAction = computed<boolean>(
    () => this.savingAction()?.isSaving ?? false,
  );

  /** The cancel action event. */
  cancelAction = output<void>();

  /** The save action event. */
  saveAction = output<Omit<PowerEventAction, 'powerRithmId'>>();

  /** The event action. */
  eventAction = output<Omit<PowerEventAction, 'powerRithmId'>>();

  /** The selected model. */
  selectedModel = signal<AiAction>('' as AiAction);

  /** The action to display. */
  actionToDisplay = computed<PowerAction>(() => this.action() as PowerAction);

  /** If AI form is open. */
  openedAiForm: boolean = false;

  /** If the form is valid. */
  isFormValid = false;

  /** The form value. */
  formValue: AiActionFormData = {} as AiActionFormData;

  /** If the form is in edit mode. */
  isEditForm = false;

  constructor() {
    effect(() => {
      if (
        this.savingAction() &&
        this.isEditForm !== this.savingAction()?.isSaving &&
        this.savingAction()?.rithmId === this.action()?.rithmId
      ) {
        this.isEditForm = this.savingAction()?.isSaving as boolean;
      }
    });
  }

  /**
   * Emit the cancel action event.
   */
  emitCancelAction(): void {
    this.cancelAction.emit();
    this.openedAiForm = false;
    this.isEditForm = false;
    this.formValue = {} as AiActionFormData;
  }

  /**
   * Emit the save action event.
   */
  emitSaveAction(): void {
    const aiActionData = <Omit<AiActionFormData, 'model'>>{
      prompt: this.formValue.prompt,
      formattedSpacing: this.formValue.formattedSpacing,
      responseField: this.formValue.responseField,
    };

    if (this.formValue.model === ActionType.ImageGenerator) {
      aiActionData.imageSettings = this.formValue.imageSettings;
    }

    const action: PowerAction = {
      rithmId: this.action()?.rithmId ?? uuidv4(),
      type: this.formValue.model,
      target: '',
      data: JSON.stringify(aiActionData),
      resultMapping: '',
      header: '',
      order: this.action()?.order ?? this.totalActions() + 1,
    };

    this.saveAction.emit({
      action,
      eventAction: this.action()?.rithmId
        ? PowerEventType.Update
        : PowerEventType.Add,
    });

    this.formValue = {} as AiActionFormData;
  }

  /**
   * Handle the event action.
   * @param event The event action.
   */
  handleEventAction(event: Omit<PowerEventAction, 'powerRithmId'>): void {
    if (event.eventAction === PowerEventType.Update) {
      this.isEditForm = true;
      this.openedAiForm = true;
      return;
    }

    this.eventAction.emit(event);
  }
}
