<div
  class="w-full max-w-md flex flex-col pb-6 gap-4"
  [ngClass]="{
    'animate-pulse pointer-events-none ':
      isEditAdvanceUpdateAction && isEditingLoading,
    'px-8': !orderOfOperations
  }"
>
  <section class="w-full">
    <p class="text-lg font-semibold tracking-wide">Advanced Update Field</p>
  </section>
  <form
    class="flex flex-col gap-5"
    [formGroup]="form"
    (ngSubmit)="addActionToPower()"
  >
    <section class="updating-section flex flex-col gap-4">
      <div class="flex justify-between">
        <p class="text-sm uppercase tracking-wide">UPDATING:</p>
        <i
          class="fa-light fa-circle-question text-secondary-500"
          matTooltip="What field you'd like to update and where"
        ></i>
      </div>

      <ng-container
        *ngTemplateOutlet="
          stationChipsSelected;
          context: {
            controlName: 'stations',
            placeholder: termsGeneric.Station.Plural
          }
        "
      />

      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        class="w-full"
      >
        <mat-label class="text-base">Field</mat-label>
        <span matPrefix class="fal fa-search"></span>
        <mat-select formControlName="fieldFilterCtrl">
          <app-ngx-search
            [customField]="containerInfoFiltered"
            [questions]="matchingQuestions"
            (getFilteredArrays)="fieldFilterCtrlFiltered = $event"
          />
          <mat-optgroup
            class="opt-group-info"
            [label]="termsGeneric.Container.Single + ' info'"
          >
            @for (
              option of fieldFilterCtrlFiltered.customField;
              track option.prompt + '-' + $index
            ) {
              <mat-option
                [value]="option"
                (click)="setField(option)"
                class="option-padding"
              >
                <div class="flex flex-row items-center space-x-2">
                  <i
                    class="flex justify-center items-center text-accent-500 fa-lg h-10 min-w-[40px]"
                    [ngClass]="
                      option.questionType &&
                      componentHelper.componentIcon(option.questionType)
                    "
                  >
                  </i>
                  <div>
                    <p class="text-sm text-primary-500 truncate w-64 cell-text">
                      {{ option.prompt }}
                    </p>
                  </div>
                </div>
              </mat-option>
            }
          </mat-optgroup>
          <mat-optgroup
            class="opt-group-info"
            [label]="termsGeneric.Station.Single + ' components'"
          >
            @for (
              option of fieldFilterCtrlFiltered.questions;
              track option.rithmId
            ) {
              <mat-option [value]="option" (click)="setField(option)">
                <div class="flex flex-row items-center space-x-2">
                  <i
                    class="flex justify-center items-center text-accent-500 fa-lg h-10 min-w-[40px]"
                    [ngClass]="
                      option.questionType &&
                      componentHelper.componentIcon(option.questionType)
                    "
                  >
                  </i>
                  <div>
                    <p class="text-sm text-primary-500 tracking-wide cell-text">
                      {{ option.prompt }}
                    </p>
                  </div>
                </div>
              </mat-option>
            }

            @if (!matchingQuestions.length) {
              <mat-option disabled>
                <ng-container
                  *ngTemplateOutlet="
                    noResults;
                    context: {
                      field: 'Questions'
                    }
                  "
                />
              </mat-option>
            }
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </section>

    <section class="updates-to-section flex flex-col gap-4">
      <div class="flex justify-between">
        <p class="text-sm uppercase tracking-wide">Updates to (source):</p>
        <i
          class="fa-light fa-circle-question text-secondary-500"
          matTooltip="What value you’d like to update the above field with"
        ></i>
      </div>
      <ng-container
        *ngTemplateOutlet="
          stationChipsSelected;
          context: {
            controlName: 'source',
            placeholder: 'Field Location'
          }
        "
      />

      <div class="updates-to-stations flex w-full flex-col gap-4">
        <div class="w-full flex gap-2">
          @if (showBucket) {
            <mat-form-field
              appearance="outline"
              class="w-full"
              subscriptSizing="dynamic"
            >
              <mat-select formControlName="stationBucketCtrl">
                <app-ngx-search
                  [customField]="containerInfoFilteredField"
                  [questions]="stationBucketsFiltered"
                  (getFilteredArrays)="
                    containerInfoFilteredDataFieldType = $event
                  "
                />
                <mat-optgroup
                  class="opt-group-info"
                  [label]="termsGeneric.Container.Single + ' info'"
                >
                  @for (
                    option of containerInfoFilteredDataFieldType.customField;
                    track option.prompt + '-' + $index
                  ) {
                    <mat-option [value]="option" class="option-padding">
                      <div class="flex flex-row items-center space-x-2">
                        <i
                          class="flex justify-center items-center text-accent-500 fa-lg h-10 min-w-[40px]"
                          [ngClass]="
                            option.questionType &&
                            componentHelper.componentIcon(option.questionType)
                          "
                        >
                        </i>
                        <div>
                          <p
                            class="text-sm text-primary-500 truncate w-64 cell-text"
                          >
                            {{ option.prompt }}
                          </p>
                        </div>
                      </div>
                    </mat-option>
                  }
                </mat-optgroup>

                <mat-optgroup
                  class="opt-group-info-field"
                  [label]="termsGeneric.Station.Single + ' components'"
                >
                  @for (
                    option of containerInfoFilteredDataFieldType.questions;
                    track option.rithmId
                  ) {
                    <mat-option [value]="option">
                      <div class="flex flex-row items-center space-x-2">
                        <i
                          class="flex justify-center items-center text-accent-500 fa-lg h-10 min-w-[40px]"
                          [ngClass]="
                            option.questionType &&
                            componentHelper.componentIcon(option.questionType)
                          "
                        >
                        </i>
                        <div>
                          <p
                            class="text-sm text-primary-500 truncate w-64 cell-text"
                          >
                            {{ option.prompt }}
                          </p>
                        </div>
                      </div>
                    </mat-option>
                  }
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          } @else {
            <mat-form-field
              appearance="outline"
              class="w-full"
              subscriptSizing="dynamic"
            >
              <mat-label
                >{{
                  showBucket
                    ? termsGeneric.Station.Single + ' Bucket'
                    : 'Custom Value'
                }}
              </mat-label>
              <input
                type="text"
                [placeholder]="
                  showBucket
                    ? termsGeneric.Station.Single + 'Bucket'
                    : 'User Input'
                "
                matInput
                formControlName="stationBucketCtrl"
              />
            </mat-form-field>
          }

          <button
            mat-stroked-button
            class="!h-14 !w-14"
            type="button"
            id="station-bucket-action"
            data-testid="station-bucket-action"
            (click)="setShowBucket()"
          >
            <i
              class="fa-solid text-secondary-500 fa-xl"
              [ngClass]="showBucket ? 'fa-keyboard' : 'fa-chevron-down'"
            ></i>
          </button>
        </div>

        <div class="flex flex-col gap-4">
          <div class="flex justify-between">
            <p class="text-sm uppercase tracking-wide">
              When (matching fields):
            </p>
            <i
              class="fa-light fa-circle-question text-secondary-500"
              [matTooltip]="
                'Specify what ' +
                termsGeneric.Container.Single +
                ' to update by selecting matching fields.'
              "
            ></i>
          </div>
          <ng-container
            *ngTemplateOutlet="
              stationChipsSelected;
              context: {
                controlName: 'matching',
                placeholder: 'Field(s)'
              }
            "
          />
        </div>
      </div>
    </section>
  </form>
</div>

<section class="flex justify-between items-center gap-2 py-2 mb-7">
  <div class="flex space-x-4">
    <button
      mat-button
      type="button"
      [disabled]="(savingAction || deletingAction) && orderOfOperations"
      (click)="cancelAction()"
      id="cancel-action"
      data-testid="cancel-action"
    >
      <span class="font-semibold">CANCEL</span>
    </button>
    <button
      mat-raised-button
      [ngClass]="{
        '!bg-sky-500 !text-white !font-semibold': orderOfOperations,
        '!opacity-60':
          !form.valid ||
          (form.value.stationBucketCtrl.questionType
            ? false
            : !form.value.stationBucketCtrl?.trim())
      }"
      color="accent"
      type="submit"
      id="done-action"
      data-testid="done-action"
      (click)="addActionToPower()"
      [disabled]="
        !form.valid ||
        ((form.value.stationBucketCtrl.questionType
          ? false
          : !form.value.stationBucketCtrl?.trim()) &&
          (savingAction || deletingAction) &&
          orderOfOperations)
      "
    >
      @if (orderOfOperations) {
        @if (savingAction) {
          <ng-container
            [ngTemplateOutlet]="loadingInlineText"
            [ngTemplateOutletContext]="{ $implicit: 'Saving...' }"
          />
        } @else {
          SAVE
        }
      } @else {
        DONE
      }
    </button>
  </div>
  @if (orderOfOperations && editingAction) {
    <div class="flex-grow text-right">
      <button
        mat-stroked-button
        class="!bg-red-500 !text-white"
        type="button"
        id="delete-action"
        data-testid="delete-action"
        [disabled]="(savingAction || deletingAction) && orderOfOperations"
        (click)="removeParentAction()"
      >
        @if (deletingAction) {
          <ng-container
            [ngTemplateOutlet]="loadingInlineText"
            [ngTemplateOutletContext]="{ $implicit: 'Deleting...' }"
          />
        } @else {
          <i class="fa-light fa-trash text-lg"></i> DELETE ACTION
        }
      </button>
    </div>
  }
</section>

<ng-template
  #stationChipsSelected
  [formGroup]="form"
  let-controlName="controlName"
  let-placeholder="placeholder"
>
  <div
    class="updating-stations flex w-full flex-col gap-4"
    (click)="setControlName(controlName)"
  >
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label class="text-base">{{ placeholder }} </mat-label>

      <mat-select
        [formControlName]="controlName"
        [placeholder]="placeholder"
        #multiSelect
        [attr.data-testid]="'select-' + controlName"
        [attr.id]="'select-' + controlName"
        [multiple]="true"
      >
        <mat-option>
          <ngx-mat-select-search
            placeholderLabel="Search..."
            [formControlName]="controlName + 'FilterCtrl'"
            noEntriesFoundLabel="Not match found..."
            [attr.data-testid]="'ngxselect-' + controlName"
            [attr.id]="'ngxselect-' + controlName"
          >
            <span aria-hidden="true" class="close" ngxMatSelectSearchClear>
              <i class="fa-solid fa-xmark"></i>
            </span>
          </ngx-mat-select-search>
        </mat-option>

        @if (controlName === 'matching') {
          <mat-optgroup
            class="opt-group-info"
            [label]="termsGeneric.Station.Single + ' components'"
          />
        }

        @for (
          option of form.controls[controlName + 'Filtered'].value;
          track $index
        ) {
          <mat-option
            class="custom-select flex-row-reverse"
            [ngClass]="{
              'custom-padding': controlName === 'matching',
              hidden:
                controlName === 'source' &&
                form.controls['stations'].value.rithmId ===
                  form.controls['stationBucketCtrl'].value.rithmId
            }"
            [value]="option"
            [attr.data-testid]="controlName + '-' + option.rithmId"
            [attr.id]="controlName + '-' + option.rithmId"
            [disabled]="
              controlName === 'source' && isInUpdatingSection(option.rithmId)
            "
          >
            <div class="w-[280px]">
              <p
                class="text-sm truncate"
                [ngClass]="{ 'font-semibold': !option.questionType }"
              >
                {{ option.name || option.prompt }}
              </p>
              @if (!option.questionType) {
                <p class="text-sm text-secondary-500 truncate">
                  ({{
                    option.groupName || 'Without ' + termsGeneric.Group.Single
                  }})
                </p>
              }
            </div>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="updating-stations-selected items-start justify-start">
      <mat-chip-set>
        @for (option of form.controls[controlName].value; track $index) {
          <mat-chip class="flex items-center">
            <div class="flex items-center gap-2">
              <span class="text-sm truncate max-w-[300px]">{{
                option.name || option.prompt
              }}</span>
              <button
                type="button"
                [id]="controlName + '-updated'"
                [attr.data-testid]="controlName + '-updated'"
                (click)="removeSelectedOption(controlName, $index)"
              >
                <i
                  class="fa-light fa-circle-xmark text-lg text-primary-200"
                  [ngClass]="{ 'hover:fa-solid': true }"
                ></i>
              </button>
            </div>
          </mat-chip>
        }
      </mat-chip-set>
    </div>
  </div>
</ng-template>

<ng-template #noResults let-field="field">
  <p class="text-sm text-secondary-500 tracking-wide cell-text">
    No {{ field }} to display
  </p>
</ng-template>

<!-- In line text loading indicator -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator id="loading-inline-text" [inlineText]="message" />
</ng-template>
