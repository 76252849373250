import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';
import {
  ComponentHelper,
  EmailValidator,
  JsonValidator,
  StationTerms,
} from 'src/helpers';
import {
  UserAssociateType,
  PowerAction,
  MemberPermissionData,
  ActionAlertInfo,
  ActionType,
  StationBucketQuestion,
  QuestionFieldType,
  QuestionFieldIcon,
  Question,
  StationFieldPrefix,
} from 'src/models';

/**
 * Email notifications item.
 */
@Component({
  selector: 'app-email-notifications-item',
  templateUrl: './email-notifications-item.component.html',
  styleUrls: ['./email-notifications-item.component.scss'],
  imports: [CommonModule, MatChipsModule, UserAvatarComponent],
  standalone: true,
})
export class EmailNotificationsItemComponent {
  /** Associate types except user.*/
  readonly associateTypes = [
    UserAssociateType.Organization,
    UserAssociateType.Team,
    UserAssociateType.StationGroup,
    UserAssociateType.Station,
  ];

  /** Internal Notification object. */
  private _notification!: PowerAction;

  /** Internal Notification object. */
  @Input() set notification(value: PowerAction) {
    this._notification = {
      order: 1,
      rithmId: '',
      type: ActionType.SendEmail,
      target: '',
      data: '',
      resultMapping: '',
      header: '',
    };

    if (value) {
      this._notification = value;
      if (this.notification.data && this.notification.data.length) {
        this.memberRecipients = this.getNotificationMembers();
      }
    }
  }

  /** All custom fields. */
  @Input() set allRecipientsCustomField(value: StationBucketQuestion[]) {
    this._allRecipientsCustomField = value;
    if (this._allRecipientsCustomField.length) {
      const alertType = JsonValidator.getObjectFromString<ActionAlertInfo>(
        this.notification.data,
      );
      this.emailRecipients = this._allRecipientsCustomField.filter(
        ({ rithmId }) => alertType.to.some((id) => id.includes(rithmId)),
      );
      this.emailRecipients = this.emailRecipients.concat(
        alertType.to.filter((item) => EmailValidator.isEmailValid(item)),
      );
    }
  }

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Component Helper. */
  componentHelper = ComponentHelper;

  /**
   * Get notification.
   * @returns Notification data.
   */
  get notification(): PowerAction {
    return this._notification;
  }

  /** All custom fields. */
  private _allRecipientsCustomField: StationBucketQuestion[] = [];

  /** Notification's custom fields. */
  emailRecipients: (StationBucketQuestion | string)[] = [];

  /** Member recipients selected. */
  memberRecipients: MemberPermissionData[] = [];

  /**
   * Gets the types list of members.
   * @returns Returns the members list.
   */
  getNotificationMembers(): MemberPermissionData[] {
    const actionAlertInfo = JsonValidator.getObjectFromString<ActionAlertInfo>(
      this.notification.data,
    );
    const recipientData = actionAlertInfo.recipients;
    return [
      ...recipientData.organizations,
      ...recipientData.teams,
      ...recipientData.stationGroups,
      ...recipientData.stations,
      ...recipientData.users,
    ];
  }

  /**
   * Get the notification subject.
   * @returns Returns notification subject.
   */
  getNotificationSubjects(): (StationBucketQuestion | string | Question)[] {
    const actionAlertInfo = JsonValidator.getObjectFromString<ActionAlertInfo>(
      this.notification.data,
    );
    if (!actionAlertInfo.subject?.length) return [];

    return actionAlertInfo.subject.map((subject) => {
      const hasCustomField = subject.includes(StationFieldPrefix.CustomField);
      const hasContainerInfoItem = subject.includes(
        StationFieldPrefix.ContainerInfoItem,
      );
      const subjectId = hasCustomField
        ? subject.replace(StationFieldPrefix.CustomField, '')
        : hasContainerInfoItem
          ? subject.replace(StationFieldPrefix.ContainerInfoItem, '')
          : '';

      if (hasCustomField) {
        return (
          this._allRecipientsCustomField.find(
            ({ rithmId }) => rithmId === subjectId,
          ) || subject
        );
      }

      if (hasContainerInfoItem) {
        return (
          StationTerms.ContainerInfoItemsAsQuestion?.find(
            ({ questionType }) => questionType === subjectId,
          ) || subject
        );
      }

      return subject;
    });
  }

  /**
   * Get the notification message.
   * @returns Returns notification message.
   */
  getNotificationMessage(): string {
    const alertType = JSON.parse(this.notification.data) as ActionAlertInfo;
    return alertType.message || '';
  }

  /**
   * If the given value is an email address.
   * @param value The email address to validate.
   * @returns True if the given value is an email address.
   */
  isEmail(value: StationBucketQuestion | string): boolean {
    if (typeof value !== 'string') return false;
    return EmailValidator.isEmailValid(value);
  }

  /**
   * Check whether it should show an icon or not.
   * @param option Option selected.
   * @returns A boolean.
   */
  showIcon(option: MemberPermissionData): boolean {
    return (
      !option.profileImageRithmId && option.type !== UserAssociateType.User
    );
  }

  /**
   * Get the label for a mat chip.
   * @param value Chip item.
   * @returns Chip's label.
   */
  getChipLabel(value: StationBucketQuestion | string | Question): string {
    return (
      (value as StationBucketQuestion).prompt ||
      (value as Question).prompt ||
      (value as string)
    );
  }

  /**
   * Get icon for a subject item.
   * @param subject The subject item.
   * @returns The icon class.
   */
  getSubjectIcon(subject: StationBucketQuestion | string | Question): string {
    if (typeof subject === 'string') return '';
    return QuestionFieldIcon[subject.questionType as QuestionFieldType];
  }
}
