import { Component, Input } from '@angular/core';
import { StationTerms } from 'src/helpers';
import {
  AppendActionData,
  PowerAction,
  Question,
  QuestionFieldIcon,
  QuestionFieldType,
} from 'src/models';
import _ from 'lodash';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

/** List a single item for each appended action item. */
@Component({
  selector: 'app-appended-action-item[appendedAction]',
  standalone: true,
  imports: [CommonModule, MatChipsModule],
  templateUrl: './appended-action-item.component.html',
  styleUrls: ['./appended-action-item.component.scss'],
})
export class AppendedActionItemComponent {
  /** Contains the action data for append field. */
  actionData!: AppendActionData;

  /** Contains the current displayed action. */
  action!: PowerAction;

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /**
   * Get the Action data through the power received.
   */
  @Input() set appendedAction(_power: PowerAction) {
    this.action = _power;
    this.actionData = JSON.parse(_power.data) as AppendActionData;
  }

  /** Contains a list of questions to extract readable text. */
  @Input() questionList: Question[] = [];

  /** A prompt to show for assigned to field. */
  readonly assignedUserPrompt = 'Assigned User';

  /**
   * Return the name of The base field.
   * @returns A name as string.
   */
  get baseFieldPrompt(): string {
    const baseFieldPrompt = this.questionList.find(
      (field) => field.rithmId === this.actionData.target,
    )?.prompt;
    return baseFieldPrompt || 'Field undefined';
  }

  /**
   * Translate all the array in readable text to show in chips.
   * @returns An array of readable text.
   */
  get readableDataValues(): string[] {
    const values = this.actionData.source.values.map((value) => {
      if (value.includes('rid:')) {
        const rithmId = value.substring(4);
        const prompt = this.questionList.find(
          (q) => q.rithmId === rithmId,
        )?.prompt;
        return prompt || 'Field undefined';
      } else if (value.includes('info:')) {
        const prompt = this.containerInfo.find(
          (q) => q.rithmId === value,
        )?.prompt;
        return prompt || 'Field undefined';
      } else if (value.includes('assignedTo:')) {
        return this.assignedUserPrompt;
      } else {
        if (value.trim().length) {
          return value;
        } else {
          return `"${value}"`;
        }
      }
    });
    return values;
  }

  containerInfo: Question[] = _.map(
    StationTerms.ContainerInfoItemsAsQuestion,
    (question) =>
      _.update(question, 'rithmId', () => 'info:' + question.questionType),
  );

  /**
   * Get the icon of the question type by prompt.
   * @param prompt The prompt to find.
   * @returns The icon class.
   */
  getQuestionTypeIcon(prompt: string): string {
    if (prompt === this.assignedUserPrompt) {
      return QuestionFieldIcon[QuestionFieldType.AssignedUser];
    }
    const questionListByKey = _.keyBy(this.questionList, 'prompt');
    const containerInfoByKey = _.keyBy(this.containerInfo, 'prompt');
    const currentQuestionType =
      questionListByKey[prompt]?.questionType ||
      containerInfoByKey[prompt]?.questionType;
    return QuestionFieldIcon[currentQuestionType] || '';
  }
}
