import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';

import { Component, Input, input, output } from '@angular/core';
import { first } from 'rxjs';
import { PopupService } from 'src/app/core/popup.service';
import { PowerService } from 'src/app/core/power.service';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import {
  ActionType,
  ConnectedStationInfo,
  GridsterItemWidget,
  OrderAction,
  PowerAction,
  PowerEventAction,
  Question,
  StationLibraryRelationship,
} from 'src/models';
import { ContainerActionsComponent } from 'src/app/station/rules/actions/container-actions/container-actions.component';
import { FlowContainerActionComponent } from 'src/app/station/rules/actions/flow-container-action/flow-container-action.component';
import { IntegrationsComponent } from 'src/app/station/rules/actions/integrations/integrations.component';
import { NotificationsActionComponent } from 'src/app/station/rules/actions/notifications/notifications-action.component';
import { RithmAiActionListComponent } from 'src/app/station/rules/actions/rithm-ai-action/rithm-ai-action-list/rithm-ai-action-list.component';
import { UserActionsComponent } from 'src/app/station/rules/actions/user-actions/user-actions.component';
import { RithmAiActionComponent } from 'src/app/station/rules/actions/rithm-ai-action/rithm-ai-action.component';
import { ActionHelper } from 'src/helpers';

/**
 * Component to show actions and add functionality of drag and drop.
 */
@Component({
  selector: 'app-actions-list',
  templateUrl: './actions-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DragDropModule,
    LoadingIndicatorComponent,
    FlowContainerActionComponent,
    ContainerActionsComponent,
    NotificationsActionComponent,
    IntegrationsComponent,
    RithmAiActionListComponent,
    UserActionsComponent,
    RithmAiActionComponent,
  ],
  styleUrl: './actions-list.component.scss',
})
export class ActionsListComponent {
  /** Helper methods for actions. */
  actionHelper = ActionHelper;

  /** The station id used to get shared values. */
  @Input() stationRithmId!: string;

  /** Actions of the current power. */
  @Input() actions: PowerAction[] = [];

  /** Stations Connected. */
  @Input() stations: ConnectedStationInfo[] = [];

  /** This is a setter. It is called when the value of the input property changes. */
  @Input() refreshIdContainer = '';

  /** Station Widgets array. */
  @Input() inputFrameWidgetItems: GridsterItemWidget[] = [];

  /** Bucket questions. */
  @Input() bucketQuestions: Question[] = [];

  /** Station Libraries.*/
  @Input() stationLibraryRelationships: StationLibraryRelationship[] = [];

  /** Station Libraries is Loading.*/
  @Input() stationLibrariesLoading = false;

  /** Feature flag Sql Integration. */
  @Input() showOptionSqlIntegration = false;

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Rule rithmId. */
  @Input() ruleRithmId = '';

  /** Feature flag for show old design/new design library tab.*/
  @Input({ required: true }) showRelationshipActions = false;

  /** Feature flag for show  Append Field actions.*/
  @Input({ required: true }) showAppendFieldActions = false;

  /** Feature flag for show action move container. */
  @Input({ required: true }) showActionMoveContainer = false;

  /** Feature flag for show advanced update action. */
  @Input({ required: true }) showAdvancedUpdateAction = false;

  /** Feature flag parent child relationship. */
  @Input({ required: true }) showParentChildRelationship = false;

  /** Show or not custom fields option. */
  @Input({ required: true }) showFlowedBy = false;

  /** Feature flag to show number fields. */
  showNumberFieldRules = input.required<boolean>();

  /** The action being saved. */
  savingAction = input<{
    /** The action id being saved. */
    rithmId: string;
    /** If the action is being saved. */
    isSaving: boolean;
  } | null>(null);

  /** The cancel action event. */
  cancelAction = output<void>();

  /** The save action event. */
  saveAction = output<Omit<PowerEventAction, 'powerRithmId'>>();

  /** The event action. */
  eventAction = output<Omit<PowerEventAction, 'powerRithmId'>>();

  /** Type of actions. */
  actionTypesEnum = ActionType;

  /** List of container action types. */
  readonly containerActions = [
    ActionType.CreateContainer,
    ActionType.ArchiveContainer,
    ActionType.UpdateField,
    ActionType.UpdateRelationship,
    ActionType.AddRelationship,
    ActionType.DeleteRelationship,
    ActionType.AppendField,
    ActionType.MoveContainer,
    ActionType.AdvancedUpdateField,
  ];

  /** List of notification action types. */
  readonly notificationActions = [
    ActionType.AlertMessage,
    ActionType.SendEmail,
  ];

  /** List of integration action types. */
  readonly integrationActions = [
    ActionType.SqlPull,
    ActionType.SqlPush,
    ActionType.ChatGPT,
  ];

  /** List of RithmAI action types. */
  readonly rithmAiActions = [
    ActionType.TextGenerator,
    ActionType.ImageGenerator,
  ];

  /** List of users action types. */
  readonly userActions = [ActionType.AddMember, ActionType.RemoveMember];

  /* Current rithmId of the action that has been dropped to order. */
  actionRithmIdOrdered = '';

  /* Whether or not you are loading the order of the actions. */
  isLoadingOrder = false;

  constructor(
    private powerService: PowerService,
    private popupService: PopupService,
  ) {}

  /**
   * Method to drop actions.
   * @param event Receive the powerAction draggable as CdkDragDrop type for move it.
   */
  dropAction(event: CdkDragDrop<PowerAction[]>): void {
    moveItemInArray(this.actions, event.previousIndex, event.currentIndex);
    if (event.previousIndex !== event.currentIndex) {
      this.actionRithmIdOrdered =
        event.previousContainer.data[event.currentIndex ?? 0].rithmId;
      // Set the current position of each action.
      this.actions.forEach((action, index) => {
        action.order = index + 1;
      });
      // We map to update the new data.
      const actionsOrder: OrderAction[] = this.actions.map(
        ({ rithmId, order }) => ({ actionRithmId: rithmId, order }),
      );
      this.updateOrderActions(actionsOrder);
    }
  }

  /**
   * Update order of power actions.
   * @param actionsOrder The corresponding order of the actions.
   */
  updateOrderActions(actionsOrder: OrderAction[]): void {
    this.isLoadingOrder = true;
    this.powerService
      .updateOrderActions(this.ruleRithmId, actionsOrder)
      .pipe(first())
      .subscribe({
        next: () => {
          this.notify('The actions have been correctly ordered');
          this.isLoadingOrder = false;
        },
        error: () => {
          this.notify('Unable to update the order of the actions', true);
          this.isLoadingOrder = false;
        },
      });
  }

  /**
   * Notify and show pop-up for actions.
   * @param message Message for show in popup.
   * @param isError If the popup is an error message.
   */
  private notify(message: string, isError = false): void {
    this.popupService.notify(message, isError);
  }
}
