@if (!loadingStationComponent) {
  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    [trackBy]="trackBy"
    class="component-tree"
  >
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodeToggle
      cdkDropList
      [cdkDropListConnectedTo]="
        node.questionType === widgetType.RelationshipWidget
          ? 'station-grid'
          : inputFrameList
      "
      [cdkDropListData]="[node]"
      [id]="
        node.isBucket ? 'station-components-bucket' : 'input-station-bucket'
      "
      [attr.data-testid]="
        node.isBucket ? 'station-components-bucket' : 'input-station-bucket'
      "
      [ngClass]="{
        'hide-parent-station-flag':
          node.questionType === questionFieldType.ParentStationLink &&
          parentStationLinkFlag,
        'hide-parent-container-flag':
          node.questionType === questionFieldType.ParentContainerLink &&
          parentContainerLinkFlag,
        'hide-self-assign':
          node.questionType === questionFieldType.SelfAssign && !showSelfAssign
      }"
      (click)="openSettingTabEvent(node)"
    >
      @if (!filterLeafNode(node)) {
        <div
          cdkDrag
          [cdkDragBoundary]="!editMode ? '.station-left-column' : ''"
          [cdkDragData]="
            node.widget !== undefined ? node.widget : node.questionType
          "
          [cdkDragDisabled]="
            !node.enabled ||
            node.questionType === disableQuestion ||
            (!editMode &&
              componentListType === stationComponentListType.LocalComponent)
          "
          (cdkDragStarted)="dragModeEvent(true)"
          (cdkDragEnded)="dragFinished($event)"
          (cdkDragReleased)="dragModeEvent(false)"
          matTooltipPosition="right"
          class="flex w-full justify-between items-center bg-white border border-transparent hover:rounded-md hover:border-secondary-100 hover:cursor-[grab]"
          [ngClass]="{
            'cursor-[grab]':
              node.enabled &&
              node.questionType !== disableQuestion &&
              ((editMode &&
                componentListType ===
                  stationComponentListType.LocalComponent) ||
                componentListType !== stationComponentListType.LocalComponent),
            'cursor-wait': node.questionType === disableQuestion,
            'cursor-default': !node.enabled,
            'animate-pulse bg-secondary-50':
              expandTreeInCustomId &&
              node.questionType === questionFieldType.CustomId &&
              componentListType === stationComponentListType.AvailableComponents
          }"
        >
          <div class="flex items-center overflow-auto">
            <button
              matTreeNodeToggle
              type="button"
              class="library-component-icon !flex-shrink-0"
              [ngClass]="{
                'text-primary-100': !node.enabled,
                'cursor-[grab]':
                  node.enabled &&
                  node.questionType !== disableQuestion &&
                  ((editMode &&
                    componentListType ===
                      stationComponentListType.LocalComponent) ||
                    componentListType !==
                      stationComponentListType.LocalComponent)
              }"
              id="btn-node-library"
              data-testid="btn-node-library"
              [attr.aria-label]="'Toggle ' + node.prompt"
              [disabled]="!node.enabled"
              matTooltipPosition="above"
              [matTooltip]="questionTypeName[node.questionType]"
            >
              <i
                [attr.class]="
                  (componentHelper.componentIcon(node.questionType) ||
                    node.icon) +
                  (node.enabled ? ' text-gray-500' : ' text-primary-100')
                "
              ></i>
            </button>
            <span
              class="mat-tree-label text-xs truncate"
              [ngClass]="{
                'text-primary-100': !node.enabled
              }"
              >{{ node.prompt }}</span
            >
          </div>
          @if (
            componentListType === stationComponentListType.LocalComponent &&
            node.isBucket
          ) {
            <div class="bucket-icons">
              @if (!node.enabled && isFieldIntoFrame(node)) {
                <i
                  class="fa-sharp fa-light fa-eye px-1"
                  matTooltip="on grid"
                  matTooltipPosition="above"
                ></i>
              }
              @if (node.originType === 'Previous') {
                <i
                  class="fa-light fa-book-open text-[#14A2A2] px-1"
                  [matTooltip]="node.origin"
                  matTooltipPosition="right"
                ></i>
              }
            </div>
          }
        </div>
      }
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div
        class="mat-tree-node"
        [ngClass]="{
          'animate-pulse':
            node.prompt === stationComponentName && loadingStationComponent,
          'pointer-events-none':
            (isErrorLoadingStationComponent || loadingStationComponent) &&
            node.prompt === stationComponentName
        }"
      >
        <button
          matTreeNodeToggle
          type="button"
          class="btn-node-child pr-2"
          id="btn-node-child"
          data-testid="btn-node-child"
          [attr.aria-label]="'Toggle ' + node.prompt"
          [disabled]="!node.enabled"
          (click)="treeNodeToggle(node)"
        >
          <i
            class="mat-icon-rtl-mirror fa-solid fa-md text-primary-100"
            [ngClass]="{
              'fa-sort-down': treeControl.isExpanded(node),
              'fa-caret-right': !treeControl.isExpanded(node),
              'hover:text-accent-500': node.enabled,
              'text-primary-50': !node.enabled
            }"
          >
          </i>
        </button>
        @if (node.icon) {
          <div class="flex items-center w-6 h-6">
            <i [ngClass]="node.icon" class="text-gray-400 icon-node-title"></i>
          </div>
        }
        <span
          class="mat-tree-label text-primary-400 font-medium text-xs"
          [ngClass]="node.enabled ? 'text-primary-400' : 'text-primary-50'"
          >{{ node.prompt }}</span
        >
      </div>

      <div
        [class.library-tree-invisible]="!treeControl.isExpanded(node)"
        role="group"
        class="group-with-children tree-node"
      >
        <ng-container matTreeNodeOutlet> </ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
} @else {
  <div class="flex justify-start pl-4 py-3 text-xs">
    <app-loading-indicator
      class="loading-component-tree"
      inlineText="Loading New Component..."
    />
  </div>
}
