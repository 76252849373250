import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import moment from 'moment';
import 'moment-timezone';
import { CustomVsBucketQuestionComponent } from 'src/app/shared/custom-vs-bucket/custom-vs-bucket.component';
import {
  JsonValidator,
  ScheduledTriggerHelper,
  TriggersHelper,
} from 'src/helpers';
import { UtcTimeConversionPipe } from 'src/helpers/pipes/utc-time-conversion/utc-time-conversion.pipe';
import {
  PowerTrigger,
  PowerTriggerToUpdate,
  Question,
  TriggerDateTime,
  TriggerDuration,
  TriggerType,
} from 'src/models';
import { ScheduledTriggerValue } from 'src/models/scheduled-trigger-value';
import { v4 as uuidv4 } from 'uuid';

/**
 * Scheduled Trigger Form Component.
 */
@Component({
  selector: 'app-scheduled-trigger-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomVsBucketQuestionComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatSlideToggleModule,
    UtcTimeConversionPipe,
  ],
  templateUrl: './scheduled-trigger-form.component.html',
  styleUrl: './scheduled-trigger-form.component.scss',
})
export class ScheduledTriggerFormComponent {
  /** The different options for the date interval repeat type. */
  readonly dateIntervalRepeatOptions = [
    'Never',
    'Every minute (coming soon)',
    'Hourly',
    'Daily',
    'Weekly',
    'Weekdays',
    'Weekends',
    'Monthly',
    'Yearly',
  ];

  /** List of days. */
  readonly days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  /** List of all the months. */
  readonly monthsList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  /** Repeat schedule types. */
  readonly repeatCriteria = [
    {
      exp: '0-23',
      type: 'Hourly',
    },
    { exp: '1-31', type: 'Daily' },
    { exp: '1-5', type: 'Weekdays' },
    { exp: '6,0', type: 'Weekends' },
  ];

  /** The scheduled trigger to be edited. */
  @Input() set scheduledTrigger(trigger: PowerTrigger | undefined) {
    this.scheduledTriggerOnEdit = trigger;
    this.setFormValues();
  }

  /** List of questions. */
  bucketQuestions = input.required<Question[]>();

  /** The modified/added trigger send back to flow. */
  @Output() saveScheduledTrigger = new EventEmitter<PowerTriggerToUpdate>();

  /** Hide the form. */
  @Output() hideForm = new EventEmitter<void>();

  /** Schedule trigger type form. */
  scheduleTriggerForm = new FormGroup({
    date: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(1)],
    }),
    frequency: new FormControl<string>('Never', { nonNullable: true }),
    repeatForever: new FormControl<boolean>(true),
    endRepeatDate: new FormControl<string>(''),
  });

  /** Details of the start date. */
  startDateValue!: TriggerDateTime | undefined;

  /** Details of the end date. */
  endDateValue!: TriggerDateTime | undefined;

  /** Selected start date question.*/
  selectedStartDateQuestion!: Question | undefined;

  /** Selected end repeat  question.*/
  selectedEndDateQuestion!: Question | undefined;

  /** The start start. */
  startDate = new Date();

  /** The end repeat start. */
  endDate = new Date();

  /** The scheduled trigger to be edited. */
  scheduledTriggerOnEdit: PowerTrigger | undefined;

  /**
   * Returns the if the trigger form is valid or not.
   * @returns A true if valid else false.
   */
  get isTriggerFormInValid(): boolean {
    return (
      !this.startDateValue ||
      (!this.scheduleTriggerForm.value.repeatForever && !this.endDateValue)
    );
  }

  /**
   * Returns is max date needs to set for start date field or not.
   * @returns A true if valid else false.
   */
  get isMaxDateSet(): boolean {
    return this.endDateValue !== undefined && this.endDateValue?.isCustom;
  }

  constructor(private utcTimeConversionPipe: UtcTimeConversionPipe) {}

  /**
   * Set form values for the scheduled trigger.
   */
  setFormValues(): void {
    if (!this.scheduledTriggerOnEdit) {
      this.startDateValue, (this.endDateValue = undefined);
      this.selectedEndDateQuestion,
        (this.selectedStartDateQuestion = undefined);
      this.selectDate();
      return;
    }

    if (this.scheduledTriggerOnEdit.startDateUTC) {
      this.scheduledTriggerOnEdit.startDateUTC = this.getConvertedTime(
        this.scheduledTriggerOnEdit.startDateUTC,
        'Local',
      );
      this.scheduleTriggerForm.controls.date.setValue(
        this.scheduledTriggerOnEdit.startDateUTC,
      );
      this.endDate.setDate(
        new Date(this.scheduledTriggerOnEdit.startDateUTC).getDate() + 1,
      );
    } else {
      this.selectDate();
    }

    if (this.scheduledTriggerOnEdit.settings) {
      const frequency = ScheduledTriggerHelper.readableScheduleTrigger(
        this.scheduledTriggerOnEdit,
        2,
        this.utcTimeConversionPipe.transform,
      );
      this.scheduleTriggerForm.controls.frequency.setValue(frequency);
    }
    const settings = JsonValidator.getObjectFromString<TriggerDuration>(
      this.scheduledTriggerOnEdit.settings,
    );
    this.startDateValue = settings.startDate;
    if (settings.startDate?.question) {
      this.selectedStartDateQuestion = settings.startDate.question;
    }
    if (settings.endDate?.question || settings.endDate?.value) {
      this.endDateValue = settings.endDate;
      this.scheduleTriggerForm.controls.repeatForever.setValue(false);
      if (this.scheduledTriggerOnEdit.endDateUTC) {
        this.scheduledTriggerOnEdit.endDateUTC = this.getConvertedTime(
          this.scheduledTriggerOnEdit.endDateUTC,
          'Local',
        );
        this.scheduleTriggerForm.controls.endRepeatDate.setValue(
          this.scheduledTriggerOnEdit.endDateUTC,
        );
      } else {
        this.selectedEndDateQuestion = settings.endDate?.question || undefined;
      }
    } else {
      this.scheduleTriggerForm.controls.repeatForever.setValue(true);
    }
  }

  /**
   * Creates a date-time expression.
   * @param date Date to which and expression has to be created.
   * @returns Returns date time expression.
   */
  getDateExpression(date: string): string {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    return `${
      this.days[moment(date, format).day()] +
      ', ' +
      this.monthsList[moment(date, format).month()] +
      '. ' +
      moment(date, format).date() +
      ' ' +
      moment(date, format).hours() +
      ':' +
      moment(date, format).minutes()
    }`;
  }

  /**
   * Set maximum date for start date field.
   * @returns A date to be set for start date.
   */
  setMaxDate(): Date {
    if (this.endDateValue && this.endDateValue?.isCustom) {
      return new Date(
        this.scheduleTriggerForm.controls.endRepeatDate.value || '',
      );
    }
    return new Date();
  }

  /**
   * Emits the event to set the date value object.
   * @param field The field to which date has to be assigned.
   * @param selectedData The value for the field.
   * @param isCustom The value is custom or bucket question.
   */
  setDateValue(
    field: string,
    selectedData: string | Question,
    isCustom: boolean,
  ): void {
    let expression = '';
    if (field === 'startDate') {
      this.scheduleTriggerForm.controls.date.setValue(selectedData.toString());
      if (isCustom) {
        const getDate = this.scheduleTriggerForm.getRawValue().date;
        expression = this.getDateExpression(getDate);
        moment(this.endDate).set(
          'hour',
          moment(this.scheduleTriggerForm.value.date).hour() + 1,
        );
      }
      if (selectedData === '') {
        this.startDateValue = undefined;
      } else {
        this.startDateValue = {
          isCustom: isCustom,
          repeat: this.scheduleTriggerForm.getRawValue().frequency,
          value: isCustom ? expression : null,
          question: !isCustom ? (selectedData as Question) : null,
        };
        this.selectedStartDateQuestion = selectedData as Question;
      }
    }
    if (field === 'endRepeat') {
      if (isCustom) {
        this.scheduleTriggerForm.controls.endRepeatDate.setValue(
          selectedData.toString(),
        );
        const getDate = this.scheduleTriggerForm.getRawValue().endRepeatDate;
        expression = this.getDateExpression(getDate || '');
      }
      if (selectedData === '') {
        this.endDateValue = undefined;
      } else {
        this.endDateValue = {
          isCustom: isCustom,
          repeat: this.scheduleTriggerForm.getRawValue().frequency,
          value: isCustom ? expression : null,
          question: !isCustom ? (selectedData as Question) : null,
        };
        this.selectedEndDateQuestion = selectedData as Question;
      }
    }
    if (
      this.startDateValue?.isCustom &&
      this.endDateValue?.isCustom &&
      moment
        .utc(new Date(this.endDateValue?.value || ''))
        .isSameOrBefore(moment.utc(new Date(this.startDateValue?.value || '')))
    ) {
      this.scheduleTriggerForm.controls.endRepeatDate.setErrors({
        valid: false,
      });
    }
    this.validateScheduleTriggerDate();
  }

  /**
   * Add or update schedules trigger at station level.
   */
  addUpdateScheduledTriggers(): void {
    let scheduledTriggerValue!: ScheduledTriggerValue;
    let startDate = '';
    let utcStartDate = '';
    /** We'll send the date an receive the initial parameters for the scheduled trigger values. */
    if (this.startDateValue?.isCustom) {
      startDate = this.scheduleTriggerForm.getRawValue().date;
      utcStartDate = this.getConvertedTime(startDate, 'UTC');
      scheduledTriggerValue =
        TriggersHelper.generateScheduleValue(utcStartDate);
      scheduledTriggerValue.repeat =
        this.scheduleTriggerForm.getRawValue().frequency;

      /*If the repetitive loop is weekly then we won't need the day of the month but the weekday name
        otherwise we will keep the date as day of the month instead of the weekday.*/
      if (scheduledTriggerValue.repeat !== 'Weekly') {
        scheduledTriggerValue.dayOfWeek = '';
      }
      if (!['Monthly', 'Yearly'].includes(scheduledTriggerValue.repeat)) {
        scheduledTriggerValue.dayOfMonth = null;
      }
      if (scheduledTriggerValue.repeat !== 'Yearly') {
        scheduledTriggerValue.month = '';
      }
    }
    this.startDateValue = {
      ...this.startDateValue,
      repeat: this.scheduleTriggerForm.getRawValue().frequency,
    } as TriggerDateTime;
    const triggerDuration: TriggerDuration = {
      startDate: this.startDateValue as TriggerDateTime,
      endDate: this.endDateValue as TriggerDateTime,
    };

    const endDate =
      this.scheduleTriggerForm.value.repeatForever ||
      !this.scheduleTriggerForm.value.endRepeatDate
        ? ''
        : this.scheduleTriggerForm.value.endRepeatDate;

    const triggerScheduled: PowerTrigger = {
      rithmId: this.scheduledTriggerOnEdit?.rithmId || uuidv4(),
      type: TriggerType.CronExpression,
      source: '',
      value: JSON.stringify(scheduledTriggerValue) || '',
      startDateUTC: utcStartDate,
      endDateUTC: this.getConvertedTime(endDate, 'UTC'),
      settings: JSON.stringify(triggerDuration),
      isDisabled: this.scheduledTriggerOnEdit?.isDisabled ?? false,
    };
    this.saveScheduledTrigger.emit({
      trigger: triggerScheduled,
      removeTrigger: false,
    });
    this.scheduledTriggerOnEdit = undefined;
    this.resetScheduleTriggerSection();
    this.hideForm.emit();
  }

  /**
   * OnFrequency change check/validate start and end date.
   */
  repeatChange(): void {
    this.validateScheduleTriggerDate();
  }

  /**
   * Validate Schedule trigger date fields.
   */
  validateScheduleTriggerDate(): void {
    if (this.scheduleTriggerForm.value.frequency === 'Never') {
      this.resetTriggerForm('endRepeat', true);
      this.scheduleTriggerForm.value.repeatForever = true;
      if (this.scheduledTriggerOnEdit) {
        this.scheduledTriggerOnEdit.endDateUTC = null;
        const settings = JsonValidator.getObjectFromString<TriggerDuration>(
          this.scheduledTriggerOnEdit.settings,
        );
        this.scheduledTriggerOnEdit.settings = JSON.stringify({
          ...settings,
          endDate: null,
        });
      }
    }

    this.scheduleTriggerForm.controls.endRepeatDate.setErrors(null);

    if (
      !this.scheduleTriggerForm.value.repeatForever &&
      this.startDateValue?.isCustom &&
      this.endDateValue?.isCustom
    ) {
      const startDate = moment(this.scheduleTriggerForm.controls.date.value);
      const endDate = moment(
        this.scheduleTriggerForm.controls.endRepeatDate.value,
      );
      const startDay = startDate.day();
      const endDay = endDate.day();

      const diffInHours = moment.duration(endDate.diff(startDate)).asHours();
      const diffInDays = moment.duration(endDate.diff(startDate)).asDays();

      if (
        this.scheduleTriggerForm.value.frequency === 'Hourly' &&
        diffInHours < 1
      ) {
        this.scheduleTriggerForm.controls.endRepeatDate.setErrors({
          valid: false,
        });
      }
      if (
        this.scheduleTriggerForm.value.frequency === 'Daily' &&
        diffInDays < 1
      ) {
        this.scheduleTriggerForm.controls.endRepeatDate.setErrors({
          valid: false,
        });
      }
      if (
        this.scheduleTriggerForm.value.frequency === 'Weekly' &&
        diffInDays < 1
      ) {
        this.scheduleTriggerForm.controls.endRepeatDate.setErrors({
          valid: false,
        });
      }
      if (
        this.scheduleTriggerForm.value.frequency === 'Monthly' &&
        diffInDays < 1
      ) {
        this.scheduleTriggerForm.controls.endRepeatDate.setErrors({
          valid: false,
        });
      }
      if (
        this.scheduleTriggerForm.value.frequency === 'Yearly' &&
        diffInDays < 1
      ) {
        this.scheduleTriggerForm.controls.endRepeatDate.setErrors({
          valid: false,
        });
      }
      if (
        this.scheduleTriggerForm.value.frequency === 'Weekdays' &&
        diffInDays <= 2
      ) {
        if (
          (startDay === 0 && endDay === 6) ||
          (startDay === 6 && endDay === 0) ||
          (startDay === 6 && endDay === 6) ||
          (startDay === 0 && endDay === 0) ||
          startDay === endDay
        ) {
          this.scheduleTriggerForm.controls.endRepeatDate.setErrors({
            valid: false,
          });
        }
      }
      if (
        this.scheduleTriggerForm.value.frequency === 'Weekends' &&
        diffInDays < 6
      ) {
        for (let i = startDay; i <= 6; i++) {
          if (i > 6) {
            i = 0;
          }
          if (
            diffInHours > 2 &&
            (startDay === 6 ||
              startDay === 0 ||
              endDay === 6 ||
              endDay === 0 ||
              i === 0 ||
              i >= 6)
          ) {
            break;
          } else {
            this.scheduleTriggerForm.controls.endRepeatDate.setErrors({
              valid: false,
            });
          }
        }
      }
    }
  }

  /**
   * Verify a date-time is in UTC.
   * @param date Date to verify to be in UTC date.
   * @returns Returns boolean.
   */
  isUTCDate(date: string): boolean {
    const editStartDate = new Date(date);
    return editStartDate.toISOString() === date.toString();
  }

  /**
   * Get UTC time based on the current timezone.
   * @param date Date to be converted to UTC.
   * @param type Type of conversion.
   * @returns Returns UTC date-time.
   */
  getConvertedTime(date: string, type: string): string {
    return this.utcTimeConversionPipe.transform(date, type);
  }

  /**
   * Reset scheduleTriggerForm form and clear cron expression.
   */
  resetScheduleTriggerSection(): void {
    if (this.scheduledTriggerOnEdit?.startDateUTC) {
      this.scheduledTriggerOnEdit.startDateUTC = this.getConvertedTime(
        this.scheduledTriggerOnEdit.startDateUTC,
        'UTC',
      );
    }
    if (this.scheduledTriggerOnEdit?.endDateUTC) {
      this.scheduledTriggerOnEdit.endDateUTC = this.getConvertedTime(
        this.scheduledTriggerOnEdit.endDateUTC,
        'UTC',
      );
    }
    this.scheduleTriggerForm.reset();
    this.scheduleTriggerForm.controls.repeatForever.setValue(true);
    this.selectDate();
    this.startDateValue = undefined;
    this.endDateValue = undefined;
    this.selectedEndDateQuestion = undefined;
    this.selectedStartDateQuestion = undefined;
    this.scheduledTriggerOnEdit = undefined;
  }

  /**
   * Emits the event and resets the trigger form.
   * @param field The field to which date has to be assigned.
   * @param isCustom The value is custom or bucket question.
   */
  resetTriggerForm(field: string, isCustom: boolean): void {
    if (field === 'startDate') {
      this.scheduleTriggerForm.controls.date.setValue('');
      this.startDateValue = undefined;
      if (!isCustom) {
        this.scheduleTriggerForm.controls.date.clearValidators();
      } else {
        this.scheduleTriggerForm
          .get('date')
          ?.setValidators([Validators.required, Validators.minLength(1)]);
      }
      this.scheduleTriggerForm.controls['date'].updateValueAndValidity();
      this.selectedStartDateQuestion = undefined;
    }
    if (field === 'endRepeat') {
      this.scheduleTriggerForm.controls.endRepeatDate.reset();
      this.endDateValue = undefined;
      this.selectedEndDateQuestion = undefined;
    }
  }

  /**
   * Enable or disable repeat forever.
   */
  enableRepeatForever(): void {
    this.endDateValue = undefined;
    this.scheduleTriggerForm.controls.endRepeatDate.setValue('');
    if (this.scheduledTriggerOnEdit) {
      this.scheduledTriggerOnEdit.endDateUTC = null;
    }
  }

  /** Select date. */
  selectDate(): void {
    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate() + 1);
  }
}
