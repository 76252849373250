import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { ComponentHelper, StationTerms, TermsGeneric } from 'src/helpers';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable, Subject, first, of, takeUntil } from 'rxjs';
import {
  QuestionFieldIcon,
  QuestionFieldType,
  StationBucketQuestion,
} from 'src/models';
import { MatListModule } from '@angular/material/list';
import { StationService } from 'src/app/core/station.service';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import _ from 'lodash';

/**
 * Email subject modal data.
 */
export interface EmailSubjectModalData {
  /** Station RithmId. */
  stationRithmId: string;
}

/**
 * Represents the modal to add a dynamic subject to Notifications.
 */
@Component({
  selector: 'app-email-subject-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatListModule,
    LoadingIndicatorComponent,
  ],
  templateUrl: './email-subject-modal.component.html',
  styleUrls: ['./email-subject-modal.component.scss'],
})
export class EmailSubjectModalComponent implements OnInit {
  /** Subject for when the component is destroyed. */
  destroyed$ = new Subject<void>();

  /** Component Helper. */
  componentHelper = ComponentHelper;

  /* System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /* Question Icon. */
  questionFieldIcon = QuestionFieldIcon;

  /** Info question observable. */
  filteredInfoOptions$!: Observable<StationBucketQuestion[]>;

  /** Observable of non-Info type questions. */
  filteredNonInfoOptions$!: Observable<StationBucketQuestion[]>;

  /** Subject form. */
  subjectForm = new FormGroup({
    subjectSearch: new FormControl<string>(''),
  });

  /** If the fields are loading. */
  customFieldsLoading = false;

  /** If an error was gotten loading the fields. */
  customFieldsError = false;

  /* Station buckets questions. */
  bucketQuestions: StationBucketQuestion[] = [];

  /* Container info list questions to hide. */
  readonly containerInfoListToHide = [
    QuestionFieldType.SelfAssign,
    QuestionFieldType.ParentStationLink,
    QuestionFieldType.ParentContainerLink,
  ];

  /**
   * Get the container info list excluding self-Assign and container/station-link.
   * @returns The types of the container info items.
   */
  get containerInfoList(): QuestionFieldType[] {
    return StationTerms.ContainerInfoItems.filter(
      ({ questionType }) =>
        !this.containerInfoListToHide.includes(
          questionType as QuestionFieldType,
        ),
    ).map(({ questionType }) => questionType) as QuestionFieldType[];
  }

  /**
   * Get the container info fields.
   * @returns The list of container info fields.
   */
  get containerInfoFields(): StationBucketQuestion[] {
    return this.bucketQuestions.filter(({ questionType }) =>
      this.containerInfoList.includes(questionType as QuestionFieldType),
    );
  }

  /**
   * Get the fields that are not container info and self-Assign and container/station-link.
   * @returns The list of fields.
   */
  get notContainerInfoFields(): StationBucketQuestion[] {
    return this.bucketQuestions.filter(
      ({ questionType }) =>
        !this.containerInfoList.includes(questionType as QuestionFieldType) &&
        !this.containerInfoListToHide.includes(
          questionType as QuestionFieldType,
        ),
    );
  }

  constructor(
    private dialogRef: MatDialogRef<EmailSubjectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: EmailSubjectModalData,
    private stationService: StationService,
  ) {}

  /**
   * Initialize the component.
   */
  ngOnInit(): void {
    this.getStationBucketQuestions();
  }

  /**
   * Set Subscription.
   */
  private setSubscription(): void {
    this.subjectForm.controls.subjectSearch.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.filteredInfoOptions$ = of(
          this._filterFields(value ?? '', this.containerInfoFields),
        );
        this.filteredNonInfoOptions$ = of(
          this._filterFields(value ?? '', this.notContainerInfoFields),
        );
      });
    this.subjectForm.controls.subjectSearch.setValue('');
  }

  /**
   * Get station bucket questions.
   */
  private getStationBucketQuestions(): void {
    this.customFieldsLoading = true;
    this.stationService
      .getStationBucketQuestions(this.modalData.stationRithmId)
      .pipe(first())
      .subscribe({
        next: (bucketQuestions) => {
          this.bucketQuestions = _.uniqBy(bucketQuestions, 'questionType');
          this.setSubscription();
          this.customFieldsError = false;
          this.customFieldsLoading = false;
        },
        error: () => {
          this.customFieldsError = true;
          this.customFieldsLoading = false;
        },
      });
  }

  /**
   * Filter the fields by prompt.
   * @param value Prompt value to filter the fields.
   * @param fields Fields to filter.
   * @returns Fields that match the filter.
   */
  private _filterFields(
    value: string,
    fields: StationBucketQuestion[],
  ): StationBucketQuestion[] {
    const filterValue = value.toLowerCase();

    return fields.filter(({ prompt }) =>
      prompt.toLowerCase().includes(filterValue),
    );
  }

  /**
   * Method to close the modal.
   * @param value Option selected.
   */
  closeModal(value: StationBucketQuestion): void {
    this.dialogRef.close(value);
  }
}
