<mat-form-field
  class="w-[620px]"
  appearance="outline"
  subscriptSizing="dynamic"
>
  <mat-label>Model</mat-label>
  <mat-select [(value)]="selectedModel">
    @for (model of availableModels; track model.value) {
      <mat-option
        [value]="model.value"
        (click)="selectedModelOutput.emit(model.value)"
        >{{ model.name }}</mat-option
      >
    }
  </mat-select>
</mat-form-field>
