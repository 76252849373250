<div class="notification-actions" [ngClass]="{ 'px-4': orderOfOperations }">
  @if (showNotification) {
    <section
      class="notification-content flex flex-col gap-4 w-full"
      [ngClass]="{ 'px-4': !orderOfOperations }"
    >
      @if (!selectedActionType) {
        <p class="font-medium text-base">New Notification</p>
        <mat-form-field
          appearance="outline"
          subscriptSizing="dynamic"
          class="max-w-lg w-full"
        >
          <mat-select
            placeholder="Select notification"
            #notificationSelection
            id="select-notification-type"
            data-testid="select-notification-type"
            (selectionChange)="notificationSelected($event.value)"
          >
            @for (item of notificationsType | keyvalue; track $index) {
              <mat-option
                [attr.data-testid]="'option-notification-' + $index"
                [id]="'option-notification-' + $index"
                [value]="item.value"
                [disabled]="isSmsNotification('', item.value)"
              >
                <span>{{ item.value }}</span>
                @if (isInternalNotification(item.value)) {
                  <span class="text-gray-400"> (push, browser, silent)</span>
                }
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <div class="content-controls flex gap-4 items-start">
          <button
            type="button"
            mat-button
            (click)="cancelNotificationForm()"
            class="!font-semibold"
            id="cancel-button"
            data-testid="cancel-button"
          >
            CANCEL
          </button>
        </div>
      }

      <ng-container
        *ngTemplateOutlet="
          notificationForms;
          context: { $implicit: selectedActionType }
        "
      ></ng-container>
    </section>
  } @else {
    @if (!orderOfOperations || !newCreationAction) {
      <ng-container [ngTemplateOutlet]="notificationList"></ng-container>
    }
    @if (!orderOfOperations || newCreationAction) {
      <div [ngClass]="{ 'px-4': !orderOfOperations }">
        <button
          mat-stroked-button
          type="button"
          color="accent"
          (click)="createNewAction()"
          id="new-action"
          data-testid="new-action"
          class="!leading-tight !tracking-wide"
          [ngClass]="{
            '!bg-sky-500 !text-white': newCreationAction && orderOfOperations
          }"
        >
          New Action
        </button>
      </div>
    }
  }
</div>

<ng-template #notificationForms>
  @if (selectedActionType === notificationsType.Internal) {
    <app-internal-notification-form
      [stationRithmId]="stationRithmId"
      [action]="actionToBeUpdated"
      [orderOfOperations]="orderOfOperations"
      [savingNotification]="savingNotification"
      (notificationPowerEmitter)="saveNotificationAction($event)"
      (cancelFormEmitter)="cancelNotificationForm()"
      (notificationToRemove)="confirmRemoveNotification($event)"
    />
  }
  @if (selectedActionType === notificationsType.Email) {
    <app-email-notifications-form
      [stationRithmId]="stationRithmId"
      [action]="actionToBeUpdated"
      [customFieldsLoading]="customFieldsLoading"
      [customFieldsError]="customFieldsError"
      [allRecipientsCustomField]="allRecipientsCustomFields"
      [orderOfOperations]="orderOfOperations"
      [savingNotification]="savingNotification"
      (notificationPowerEmitter)="saveNotificationAction($event)"
      (cancelFormEmitter)="cancelNotificationForm()"
      (notificationToRemove)="confirmRemoveNotification($event)"
    />
  }
</ng-template>

<ng-template #notificationList>
  <section class="notification-list flex flex-col">
    @for (item of alerts; track $index) {
      <div
        class="notification-item action-chips"
        [ngClass]="{ 'border-order-operations': !orderOfOperations }"
      >
        @if (isInternalNotification(item.data)) {
          <app-internal-notification-item
            [notification]="item"
            [orderOfOperations]="orderOfOperations"
          />
        }
        @if (isEmailNotification(item.data)) {
          <app-email-notifications-item
            [notification]="item"
            [allRecipientsCustomField]="allRecipientsCustomFields"
            [orderOfOperations]="orderOfOperations"
          />
        }

        <div>
          <button
            mat-icon-button
            type="button"
            [matMenuTriggerFor]="options_rule"
            class="text-lg"
            id="option-notification-rule"
            data-testid="option-notification-rule"
            aria-label="option"
          >
            <i class="fa-light fa-ellipsis"></i>
          </button>
          <mat-menu #options_rule="matMenu">
            <button
              mat-menu-item
              type="button"
              id="option-notification-rule-edit"
              data-testid="option-notification-rule-edit"
              (click)="editNotification(item)"
            >
              <span class="p-4">Edit Notification</span>
            </button>
            @if (orderOfOperations) {
              <button
                mat-menu-item
                type="button"
                id="option-integration-duplicate"
                data-testid="option-integration-duplicate"
                (click)="
                  addOrUpdateSpecificAction(item, powerEventType.Duplicate)
                "
              >
                <span class="p-4">Duplicate action</span>
              </button>
            }
            <button
              mat-menu-item
              type="button"
              id="option-notification-rule-delete"
              data-testid="option-notification-rule-delete"
              (click)="confirmRemoveNotification(item)"
            >
              <span class="p-4">Delete Notification</span>
            </button>
          </mat-menu>
        </div>
      </div>
    }
  </section>
</ng-template>
