<ng-container>
  <form
    [formGroup]="stationGridForm"
    autocomplete="off"
    class="station-base-form"
  >
    @if (!stationLoading && !widgetLoading) {
      <div
        cdkDropList
        id="station-grid"
        class="gridster-container"
        [ngClass]="{ 'gridster-container-edit-mode': editMode }"
        (mouseup)="hideQuickTipOnGrid()"
        (cdkDropListDropped)="addInputFrame($event)"
      >
        @if (stationOverlay) {
          <div class="station-overlay"></div>
        }

        <!-- The cdkScrollable needed for the question userSelect per mat-autocomplete element panel to follow its reference element. -->
        <gridster
          class="p-0 scrollbar bg-transparent disable-scroll-when-opened-autocomplete"
          [options]="options"
          cdkScrollable
        >
          @for (
            widgetItem of inputFrameWidgetItems;
            track widgetItem.rithmId;
            let i = $index
          ) {
            <gridster-item
              [id]="'gridster-item-' + i"
              [item]="widgetItem"
              class="gridster-item"
              [style]="
                widgetItem.rithmId === widgetIdPopperIsOpened
                  ? 'z-index: 3 !important'
                  : ''
              "
              [ngClass]="{
                'widget-item-layout': layoutMode && widgetFocused !== i,
                'shadow-md rounded-md': isDashboardWidget(
                  widgetItem.widgetType
                ),
                'circle-border':
                  widgetItem.widgetType === widgetType.CircleImageWidget,
                'widget-focused': widgetFocused === i && layoutMode,
                'pointer-events-none': stationOverlay
              }"
              (click)="layoutMode ? focusWidget(i) : ''"
              (mouseenter)="widgetMoveButton = i"
              (mouseleave)="widgetMoveButton = -1"
            >
              <div
                class="h-gridster"
                [ngClass]="{
                  'circle-image-container':
                    widgetItem.widgetType === widgetType.CircleImageWidget,
                  'overflow-y-auto scrollbar':
                    widgetItem.widgetType === widgetType.BodyWidget
                }"
              >
                @if (widgetMoveButton === i && layoutMode && !isMobileView) {
                  <button
                    type="button"
                    class="drag-handler"
                    data-testid="button-drag-handler"
                  >
                    <i class="fa-light fa-arrows fa-base"></i>
                  </button>
                }

                <ng-container>
                  <!-- Show headline widget. -->
                  @if (widgetItem.widgetType === widgetType.HeadlineWidget) {
                    <app-headline-widget
                      [widgetMode]="
                        editMode
                          ? layoutMode
                            ? 'layout'
                            : 'setting'
                          : 'preview'
                      "
                      (openSettingTab)="
                        openSettingTab($event, widgetType.HeadlineWidget)
                      "
                      [widget]="widgetItem"
                    />
                  }
                  <!-- Show title widget. -->
                  @if (widgetItem.widgetType === widgetType.TitleWidget) {
                    <app-title-widget
                      [widgetMode]="
                        editMode
                          ? layoutMode
                            ? 'layout'
                            : 'setting'
                          : 'preview'
                      "
                      (openSettingTab)="
                        openSettingTab($event, widgetType.TitleWidget)
                      "
                      [widget]="widgetItem"
                    />
                  }
                  <!-- Show body text widget. -->
                  @if (widgetItem.widgetType === widgetType.BodyWidget) {
                    <app-body-text-widget
                      [widgetMode]="
                        editMode
                          ? layoutMode
                            ? 'layout'
                            : 'setting'
                          : 'preview'
                      "
                      (openSettingTab)="
                        openSettingTab($event, widgetType.BodyWidget)
                      "
                      [widget]="widgetItem"
                      [isStation]="true"
                    />
                  }

                  <!-- Station Widgets -->
                  @if (isStationWidget(widgetItem.widgetType)) {
                    <ng-container
                      *ngTemplateOutlet="
                        stationWidget;
                        context: {
                          widgetItem: widgetItem,
                          editMode: editMode,
                          index: i
                        }
                      "
                    />
                  }

                  <!-- Container Widgets -->
                  @if (isContainerWidget(widgetItem.widgetType)) {
                    <ng-container
                      *ngTemplateOutlet="
                        containerWidget;
                        context: {
                          widgetItem: widgetItem,
                          editMode: editMode,
                          index: i
                        }
                      "
                    />
                  }

                  <!-- Groups search Widget -->
                  @if (
                    widgetItem.widgetType === widgetType.StationGroupSearch
                  ) {
                    <app-group-search-widget
                      [dataWidget]="widgetItem.data"
                      [widgetType]="widgetItem.widgetType"
                      [editMode]="editMode"
                      [isArchitect]="isArchitect"
                      [dashboardPermission]="true"
                      (toggleDrawer)="
                        openDashboardWidgetSetting(widgetItem, i, $event)
                      "
                      (deleteWidget)="removeWidget(widgetItem)"
                    />
                  }

                  <!-- Groups traffic Widget -->
                  @if (
                    widgetItem.widgetType === widgetType.StationGroupTraffic
                  ) {
                    <app-group-traffic-widget
                      [dataWidget]="widgetItem.data"
                      [editMode]="editMode"
                      [isArchitect]="isArchitect"
                      [widgetItem]="widgetItem"
                      [isMobileDevice]="mobileBrowserChecker.isMobileDevice()"
                      [indexWidget]="i"
                      [dashboardPermission]="true"
                      [headerFeatureFlag]="headerFeatureFlag"
                      (toggleDrawer)="
                        openDashboardWidgetSetting(widgetItem, i, $event)
                      "
                      (deleteWidget)="removeWidget(widgetItem)"
                    />
                  }

                  <!-- StationPreBuilt -->
                  @if (widgetItem.widgetType === widgetType.PreBuiltStation) {
                    <app-station-pre-built-widget
                      [editMode]="editMode"
                      [dashboardPermission]="true"
                      (toggleDrawer)="
                        openDashboardWidgetSetting(widgetItem, i, $event)
                      "
                      (deleteWidget)="removeWidget(widgetItem)"
                    />
                  }

                  <!-- ContainerPreBuilt -->
                  @if (widgetItem.widgetType === widgetType.PreBuiltContainer) {
                    <app-container-pre-built-widget
                      [editMode]="editMode"
                      [widgetId]="widgetItem.rithmId"
                      [widgetType]="widgetItem.widgetType"
                      [showWidgetFramesUpdate]="showWidgetFramesUpdate"
                      [dashboardPermission]="true"
                      [isArchitect]="isArchitect"
                      [headerFeatureFlag]="headerFeatureFlag"
                      [orderOfOperations]="orderOfOperations"
                      [relationshipWidgetFlag]="relationshipWidgetFlag"
                      [showSelfAssign]="showSelfAssign"
                      [containerStationOverlayFeature]="
                        containerStationOverlayFeature
                      "
                      [widgetColumnFeatureFlag]="widgetColumnFeatureFlag"
                      [multiSortingFeatureFlag]="multiSortingFeatureFlag"
                      (toggleDrawer)="
                        openDashboardWidgetSetting(widgetItem, i, $event)
                      "
                      (deleteWidget)="removeWidget(widgetItem)"
                    />
                  }

                  <!-- Group container widget -->
                  @if (
                    widgetItem.widgetType === widgetType.GroupContainerTable
                  ) {
                    <app-group-container-widget
                      [relationshipWidgetFlag]="relationshipWidgetFlag"
                      [dataWidget]="widgetItem.data"
                      [isArchitect]="isArchitect"
                      [widgetId]="widgetItem.rithmId"
                      [editMode]="editMode"
                      [widgetType]="widgetItem.widgetType"
                      [isStation]="true"
                      [dashboardPermission]="true"
                      [showWidgetFramesUpdate]="showWidgetFramesUpdate"
                      [limitListPerPage]="limitListPerPage(widgetItem)"
                      [headerFeatureFlag]="headerFeatureFlag"
                      [orderOfOperations]="orderOfOperations"
                      [showSelfAssign]="showSelfAssign"
                      [containerStationOverlayFeature]="
                        containerStationOverlayFeature
                      "
                      [widgetColumnFeatureFlag]="widgetColumnFeatureFlag"
                      [multiSortingFeatureFlag]="multiSortingFeatureFlag"
                      (toggleDrawer)="
                        openDashboardWidgetSetting(widgetItem, i, $event)
                      "
                      (deleteWidget)="removeWidget(widgetItem)"
                    />
                  }

                  <!-- Group container widget -->
                  @if (widgetItem.widgetType === widgetType.InputWidget) {
                    <app-input-frame-widget
                      formControlName="inputFrameFieldForm"
                      [fields]="widgetItem.questions"
                      [stationRithmId]="stationRithmId"
                      [id]="widgetItem.rithmId"
                      [inputFrameList]="inputFrameList"
                      [widgetData]="widgetItem.data"
                      [currentFieldSelected]="currentFieldSelected"
                      [containerAnswer]="false"
                      [widgetMode]="
                        editMode
                          ? layoutMode
                            ? 'layout'
                            : 'setting'
                          : 'preview'
                      "
                      (openSettingTab)="
                        openSettingTab($event, widgetType.InputWidget)
                      "
                      (recentQuestionAdded)="
                        checkQuestionAdded($event, selectedInnerLeftIndex)
                      "
                      (closeTabSetting)="closeTabSetting()"
                    />
                  }

                  <!-- toolbar image widget -->
                  @if (widgetItem.widgetType === widgetType.ImageWidget) {
                    <app-toolbar-image-widget
                      [dataWidget]="widgetItem.data"
                      (openSettingTab)="
                        openSettingTab(widgetItem, widgetType.ImageWidget)
                      "
                    />
                  }

                  <!-- Relationship widget -->
                  @if (
                    widgetItem.widgetType === widgetType.RelationshipWidget
                  ) {
                    <app-relationship-widget
                      [relationshipWidgetFlag]="relationshipWidgetFlag"
                      [widgetId]="widgetItem.widgetFrameRithmId || ''"
                      [dataWidget]="widgetItem.data"
                      [widgetType]="widgetItem.widgetType"
                      [image]="{
                        imageId: widgetItem.imageId || null,
                        imageName: widgetItem.imageName || null
                      }"
                      [editMode]="editMode"
                      [isArchitect]="isArchitect"
                      [dashboardPermission]="true"
                      [limitListPerPage]="limitListPerPage(widgetItem)"
                      [showWidgetFramesUpdate]="showWidgetFramesUpdate"
                      [headerFeatureFlag]="headerFeatureFlag"
                      [orderOfOperations]="orderOfOperations"
                      [isStation]="true"
                      [showSelfAssign]="showSelfAssign"
                      [widgetColumnFeatureFlag]="widgetColumnFeatureFlag"
                      [multiSortingFeatureFlag]="multiSortingFeatureFlag"
                      [containerStationOverlayFeature]="
                        containerStationOverlayFeature
                      "
                      (toggleDrawer)="
                        openDashboardWidgetSetting(widgetItem, i, $event)
                      "
                      (deleteWidget)="removeWidget(widgetItem)"
                    />
                  }
                </ng-container>
              </div>
            </gridster-item>
          }
        </gridster>
        @if (!inputFrameWidgetItems.length && showQuickTipOnGrid && editMode) {
          <ng-container *ngTemplateOutlet="quickTipSection" />
        }
      </div>
    } @else {
      <ng-container *ngTemplateOutlet="gridsterLoading" />
    }
  </form>

  <!--  BUTTONS CONTROL BAR AT THE BOTTOM -->

  <section class="button-controls">
    @if (nonEditableStation) {
      <p class="toolbar-warning">Editor only available at larger screen size</p>
    }

    @if (isArchitect) {
      @if (!editMode) {
        <button
          mat-raised-button
          type="button"
          color="accent"
          class="font-semibold text-sm !uppercase"
          id="station-edit-mode-button"
          data-testid="station-edit-mode-button"
          (click)="setEditMode(true)"
          [disabled]="stationLoading || widgetLoading || nonEditableStation"
        >
          Design
        </button>
      } @else {
        @if (isEmptyPrompt) {
          <div class="alert-in-toolbar">
            <p class="alert-label">
              There are some fields in blank, please fill them out.
            </p>
          </div>
        }
        @if (!nonEditableStation) {
          <div
            class="w-1/2 flex bg-secondary-white items-center justify-end h-[45px]"
          >
            <div class="flex space-x-2">
              <button
                mat-stroked-button
                type="button"
                color="accent"
                id="cancel-station-changes"
                data-testid="cancel-station-changes"
                class="font-semibold text-sm"
                [disabled]="widgetLoading"
                (click)="cancelStationChanges()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                type="button"
                color="accent"
                id="save-station-frames"
                data-testid="save-station-frames"
                class="font-semibold text-sm"
                (click)="saveStationWidgetChanges()"
                [disabled]="widgetLoading || stationGridForm.invalid"
              >
                Save
              </button>
            </div>
          </div>
        }
      }
    }
  </section>
</ng-container>

<!-- Section to display stations added into station grid. -->
<ng-template
  #stationWidget
  let-widgetItem="widgetItem"
  let-editMode="editMode"
  let-index="index"
>
  <app-station-widget
    [relationshipWidgetFlag]="relationshipWidgetFlag"
    [isStation]="true"
    [dataWidget]="widgetItem.data"
    [widgetId]="widgetItem.rithmId"
    [widgetType]="widgetItem.widgetType"
    [widgetSettings]="widgetItem.settings"
    [image]="{
      imageId: widgetItem.imageId || null,
      imageName: widgetItem.imageName || null
    }"
    [editMode]="editMode"
    [isArchitect]="isArchitect"
    [dashboardPermission]="true"
    [limitListPerPage]="limitListPerPage(widgetItem)"
    [showWidgetFramesUpdate]="showWidgetFramesUpdate"
    [headerFeatureFlag]="headerFeatureFlag"
    [orderOfOperations]="orderOfOperations"
    [showSelfAssign]="showSelfAssign"
    [containerStationOverlayFeature]="containerStationOverlayFeature"
    [widgetColumnFeatureFlag]="widgetColumnFeatureFlag"
    [multiSortingFeatureFlag]="multiSortingFeatureFlag"
    (toggleDrawer)="openDashboardWidgetSetting(widgetItem, index, $event)"
    (deleteWidget)="removeWidget(widgetItem)"
  />
</ng-template>

<!-- Section to display containers added into station grid. -->
<ng-template
  #containerWidget
  let-widgetItem="widgetItem"
  let-editMode="editMode"
  let-index="index"
>
  <app-container-widget
    [dataWidget]="widgetItem.data"
    [widgetItem]="widgetItem"
    [editMode]="editMode"
    [isArchitect]="isArchitect"
    [dashboardPermission]="true"
    (toggleDrawer)="openDashboardWidgetSetting(widgetItem, index, $event)"
    (deleteWidget)="removeWidget(widgetItem)"
  />
</ng-template>

<!-- Loading section when API requests are underway. -->
<ng-template #gridsterLoading>
  <div id="gridster-loading" class="gridster-container gridster-loading">
    <app-loading-indicator />
  </div>
</ng-template>

<!-- Quick tip section: A prompt for the user to add a new frame when the grid is empty.. -->
<ng-template #quickTipSection>
  <section class="station-empty-alert">
    <div class="quick-tip-section">
      <i class="fa-light fa-lightbulb-on text-6xl text-accent-500"></i>
      <h3 class="font-semibold py-4 text-base">Quick Tip</h3>
      <p class="text-secondary-500 text-sm">
        Get started with adding a frame to the
        {{ termsGeneric.Station.Single | lowercase }} grid.
      </p>
    </div>
  </section>
</ng-template>
