<div class="h-full w-full flow-container-action-list action-chips">
  <section
    class="px-4 action-list-item"
    [ngClass]="{ 'hover:!bg-white !items-start': orderOfOperations }"
  >
    <div class="py-4 w-full overflow-x-auto scrollbar">
      <p class="font-medium">Flow {{ termsGeneric.Container.Single }}</p>
      <p class="text-xs mt-4 sub-section-title">Flow to:</p>
      @for (station of stationsToFlow; track $index) {
        <section>
          <mat-chip-set>
            <mat-chip>
              @if (orderOfOperations) {
                <i class="fak fa-station text-gray-600 pr-1"></i>
                <span class="text-primary-500 text-sm label-chip">
                  {{ station.name }}
                </span>
              } @else {
                {{ station.name }}
              }
            </mat-chip>
          </mat-chip-set>
        </section>
      }
    </div>

    <button
      mat-icon-button
      type="button"
      [matMenuTriggerFor]="actionOptions"
      id="actions-container"
      data-testid="actions-container"
      class="text-lg"
    >
      <i class="fa-light fa-ellipsis"></i></button
    ><mat-menu #actionOptions="matMenu" xPosition="before">
      <button
        mat-menu-item
        type="button"
        class="text-md"
        data-testid="edit-action"
        id="edit-action"
        (click)="editMode.emit()"
      >
        <span>Edit action</span>
      </button>
      <button
        mat-menu-item
        type="button"
        class="text-md"
        data-testid="duplicate-action"
        id="duplicate-action"
        (click)="duplicateAction()"
      >
        <span>Duplicate action</span>
      </button>
      <button
        mat-menu-item
        type="button"
        class="text-md"
        data-testid="remove-container-action"
        id="remove-container-action"
        (click)="confirmRemoveIntegration()"
      >
        <span class="text-red-500">Delete action</span>
      </button>
    </mat-menu>
  </section>
</div>
