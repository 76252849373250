@if (isEdit) {
  <app-flow-container-form-action
    [action]="action"
    [nextStations]="stations"
    [ruleRithmId]="ruleRithmId"
    [isEditing]="isEdit"
    [totalActions]="totalActions"
    (closeForm)="isEdit = false"
  />
} @else {
  <app-flow-container-item-action
    class="w-full"
    [action]="action"
    [stations]="stations"
    [ruleRithmId]="ruleRithmId"
    [orderOfOperations]="orderOfOperations"
    [totalActions]="totalActions"
    (editMode)="isEdit = true"
  />
}
